import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const CallTrend = ({ data }) => {
  const svgRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 400 });
  const margin = { top: 20, right: 200, bottom: 30, left: 40 };

  const customColors = {
    busy: "#357595",
    rejected: "#5CA2C5",
    "not answered": "#7DB5D1",
    answered: "#FBC400",
    invalid: "#EB5757",
    "no network": "#224C61",
    pending: "#9B51E0",
    duration: "#219653",
    undefined: "#BDBDBD",
    null: "#7DB5D1",
  };

  // Add this function to generate colors for undefined outcomes
  const getColor = (outcome) => {
    if (customColors[outcome]) return customColors[outcome];

    // Generate a hash from the string
    const hash = outcome.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    // Convert hash to color
    return `hsl(${Math.abs(hash) % 360}, 70%, 50%)`;
  };

  // Function to update dimensions
  const updateDimensions = () => {
    if (svgRef.current) {
      const containerWidth = svgRef.current.parentElement.clientWidth;
      setDimensions({
        width: containerWidth,
        height: 400,
      });
    }
  };

  // Set up resize listener
  useEffect(() => {
    updateDimensions(); // Initial sizing
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!data || !data.length || !dimensions.width) return;

    // Sort data by date first
    const sortedData = [...data].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    // Clear and create SVG
    d3.select(svgRef.current).selectAll("*").remove();
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Parse dates and get unique outcomes
    const parseDate = d3.isoParse;
    const outcomes = Array.from(
      new Set(
        sortedData.flatMap((d) =>
          d.outcomes.map((o) => o.outcome || "undefined")
        )
      )
    ).filter((outcome) => outcome); // Remove null/undefined if present

    // Prepare data for individual lines
    const lineData = outcomes.map((outcome) => ({
      outcome,
      values: sortedData.map((d) => ({
        date: parseDate(d.date),
        value:
          d.outcomes.find((o) => (o.outcome || "undefined") === outcome)
            ?.count || 0,
      })),
    }));

    // Scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(sortedData, (d) => parseDate(d.date)))
      .range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(lineData, (d) => d3.max(d.values, (v) => v.value))])
      .range([height, 0]);

    // Add axes with updated date format
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3
          .axisBottom(x)
          .tickValues(sortedData.map((d) => parseDate(d.date)))
          .tickFormat((d) => d3.timeFormat("%d/%m")(d))
      );

    svg.append("g").call(d3.axisLeft(y));

    // Add X axis label
    svg
      .append("text")
      .attr("class", "x-label")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom)
      .text("Date")
      .style("fill", "#666")
      .style("font-size", "12px");

    // Add Y axis label
    svg
      .append("text")
      .attr("class", "y-label")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left + 10)
      .attr("x", -height / 2)
      .text("Call Count")
      .style("fill", "#666")
      .style("font-size", "12px");

    // Create tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "rgba(15, 15, 15, 0.8)")
      .style("color", "white")
      .style("border-radius", "12px")
      .style("padding", "8px 12px")
      .style("opacity", 0)
      .style("font-size", "12px");

    // Function to find overlapping points at the same x,y coordinates
    const findOverlappingPoints = (currentDate, currentValue) => {
      return lineData
        .map(({ outcome, values }) => {
          const point = values.find(
            (v) =>
              v.date.getTime() === currentDate.getTime() &&
              Math.abs(y(v.value) - y(currentValue)) < 2 // Threshold for considering points as overlapping
          );
          return point ? { outcome, value: point.value } : null;
        })
        .filter(Boolean);
    };

    // First, create a group for areas
    const areasGroup = svg.append("g").attr("class", "areas");

    // Then, create a group for lines
    const linesGroup = svg.append("g").attr("class", "lines");

    // Finally, create a group for dots
    const dotsGroup = svg.append("g").attr("class", "dots");

    // Add lines and dots for each outcome
    lineData.forEach(({ outcome, values }) => {
      // Add area (in areas group)
      const areaGenerator = d3
        .area()
        .x((d) => x(d.date))
        .y0(() => height)
        .y1((d) => y(d.value))
        .curve(d3.curveMonotoneX);

      areasGroup
        .append("path")
        .datum(values)
        .attr("fill", getColor(outcome))
        .attr("fill-opacity", 0.1)
        .attr("d", areaGenerator(values.map((d) => ({ ...d, value: 0 }))))
        .transition()
        .duration(1000)
        .attr("d", areaGenerator);

      // Add line (in lines group)
      const lineGenerator = d3
        .line()
        .x((d) => x(d.date))
        .y((d) => y(d.value))
        .curve(d3.curveMonotoneX);

      linesGroup
        .append("path")
        .datum(values)
        .attr("fill", "none")
        .attr("stroke", getColor(outcome))
        .attr("stroke-width", 2)
        .attr(
          "d",
          d3
            .line()
            .x((d) => x(d.date))
            .y(height)
        )
        .transition()
        .duration(1000)
        .attr("d", lineGenerator);

      // Add dots (in dots group)
      dotsGroup
        .selectAll(`.dot-${outcome}`)
        .data(values)
        .join("circle")
        .attr("class", `dot-${outcome}`)
        .attr("cx", (d) => x(d.date))
        .attr("cy", height)
        .attr("r", 4)
        .attr("fill", "white")
        .attr("stroke", getColor(outcome))
        .attr("stroke-width", 2)
        .transition()
        .duration(1000)
        .attr("cy", (d) => y(d.value))
        .selection()
        .on("mouseover", (event, d) => {
          const overlappingPoints = findOverlappingPoints(d.date, d.value);
          const tooltipContent = `
            <strong>Date: ${d3.timeFormat("%d/%m/%Y")(d.date)}</strong><br/>
            ${overlappingPoints
              .map(
                ({ outcome, value }) =>
                  `${
                    outcome.charAt(0).toUpperCase() + outcome.slice(1)
                  }: ${value}`
              )
              .join("<br/>")}
          `;

          tooltip.transition().duration(300).style("opacity", 1);
          tooltip
            .html(tooltipContent)
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", () => {
          tooltip.transition().duration(300).style("opacity", 0);
        });
    });

    // Add legend
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${width + 40}, 0)`);

    legend
      .selectAll("rect")
      .data(outcomes)
      .join("rect")
      .attr("y", (d, i) => i * 20)
      .attr("width", 15)
      .attr("height", 15)
      .attr("fill", (d) => getColor(d))
      .style("opacity", 0)
      .transition()
      .duration(1000)
      .style("opacity", 1);

    legend
      .selectAll("text")
      .data(outcomes)
      .join("text")
      .attr("x", 20)
      .attr("y", (d, i) => i * 20 + 12)
      .text((d) => {
        if (d === "undefined") return "Unknown";
        return d
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .style("opacity", 0)
      .style("font-size", "14px")
      .style("fill", "#7b7b7b")
      .transition()
      .duration(1000)
      .style("opacity", 1);

    // Clean up tooltip on unmount
    return () => {
      d3.select("body").selectAll(".tooltip").remove();
    };
  }, [data, dimensions]);

  return (
    <div className="call-trend" style={{ width: "100%", minHeight: 400 }}>
      <svg ref={svgRef} style={{ width: "100%", height: "400px" }}></svg>
    </div>
  );
};

export default CallTrend;
