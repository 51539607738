import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FunCollapse from "components/FunCollapse";
import { ChevronRight, Plus, Trash2, X } from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styles from "./index.module.css";
import theme from "theme";
import getParentCategories from "services/getParentCategories";
import { Fragment, useEffect, useState } from "react";
import FlexBox from "components/FlexBox";
import { useUserContext } from "context/user";
import { languages as defaultLanguages } from "text-constants/common";
import VariableDropdown from "../VariableDropdown";
import { useSkillrToast } from "context/toast";
import { checkDuplicateName } from "utils/utils";

const VariableProperties = (props) => {
  const { onSave, data, onEdit, workFlowId } = props;
  const [categories, setCategories] = useState([]);
  const { allBot } = useUserContext();
  const { showSkillrToast } = useSkillrToast();
  const [languages, setLanguages] = useState();
  const [inputValues, setInputValues] = useState({});

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      variables: [{ type: "custom", name: "", value: "" }],
    },
  });

  let variableTypes = [
    {
      name: "custom",
      label: "Custom",
    },
    {
      name: "language",
      label: "Language",
    },
    {
      name: "digression",
      label: "Digression",
      possibleValues: ["True", "False"],
    },
    {
      name: "subCategory",
      label: "Sub-category",
    },
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variables",
  });

  const getCategories = async () => {
    try {
      const response = await getParentCategories();
      let categories = [];
      response.forEach((parentCategory) => {
        parentCategory.categories.forEach((subCategory) => {
          categories.push(subCategory);
        });
      });
      setCategories(categories);
    } catch (error) {
      // const errorMessage = error?.response?.data
      //   ? error.response.data
      //   : "Something went wrong";
      console.log(error);
    }
  };

  // Add this helper function
  const getSubCategoryLabel = (categoryId) => {
    const category = categories.find((cat) => cat._id === categoryId);
    return category ? category.name : categoryId;
  };

  const onSubmit = async (data) => {
    // check if any variable is missing value or if custom type is missing name
    if (
      data.variables.some(
        (variable) =>
          !variable.value || (variable.type === "custom" && !variable.name)
      )
    ) {
      showSkillrToast("Please fill all required fields", "error");
      return;
    }
    setInputValues({}); // Clear all input values

    if (data.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        data.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    onSave(data);
  };

  useEffect(() => {
    getCategories();
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name?.endsWith(".type")) {
        const fieldIndex = fields.findIndex(
          (field, index) => name === `variables[${index}].type`
        );

        if (fieldIndex !== -1) {
          const newType = value.variables?.[fieldIndex]?.type;

          if (newType !== undefined) {
            // Reset value for all types
            setValue(`variables[${fieldIndex}].value`, null);

            if (newType === "custom") {
              // For custom type, reset name to empty string
              setValue(`variables[${fieldIndex}].name`, null);
            } else {
              // For non-custom types, remove the name field
              setValue(`variables[${fieldIndex}].name`, undefined);
            }
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, fields]);

  useEffect(() => {
    if (allBot && allBot[0]) {
      let languagesTemp = [];
      allBot[0]?.botVoices.forEach((voice) => {
        languagesTemp.push(
          defaultLanguages.find((lang) => lang.value === voice.languageCode)
            ?.name
        );
      });
      setLanguages(languagesTemp);
    }
  }, [allBot]);

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  return (
    <>
      {fields.map((field, index) => (
        <Box className={styles.variableContainer}>
          <FunCollapse
            iconPosition={"end"}
            key={field.id}
            collapsed={index === 0}
            header={
              <FlexBox>
                <Typography variant="body2" fontWeight={500}>
                  Variable {index + 1}
                </Typography>

                {fields.length > 1 && (
                  <IconButton
                    size="small"
                    sx={{ ml: "auto" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      remove(index);
                    }}
                  >
                    <Trash2 width={12} height={12} />
                  </IconButton>
                )}
              </FlexBox>
            }
          >
            <>
              <Typography variant="caption" component="div" mt={8}>
                Variable Type
              </Typography>

              <Controller
                control={control}
                name={`variables[${index}].type`}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    displayEmpty={true}
                    value={value}
                    onChange={onChange}
                    className={styles.select}
                    sx={{ mt: 2 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          borderRadius: 3,
                          backgroundColor: "white",
                          boxShadow: "0px 4px 17.6px 0px rgba(0, 0, 0, 0.06)",
                          maxHeight: "40vh",
                        },
                      },
                    }}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[800]}
                          >
                            Select Variable Type
                          </Typography>
                        );
                      else
                        return (
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[800]}
                          >
                            {
                              variableTypes.find(
                                (variableType) => selected === variableType.name
                              )?.label
                            }
                          </Typography>
                        );
                    }}
                  >
                    {variableTypes
                      .filter(
                        (variableType) =>
                          variableType.name === "custom" ||
                          !watch("variables")
                            .map((field) => field.type)
                            .includes(variableType.name)
                      )
                      .map((variableType) => (
                        <MenuItem
                          key={variableType.name}
                          value={variableType.name}
                          className={styles.menuItem}
                        >
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[800]}
                          >
                            {variableType.label}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />

              {watch(`variables[${index}].type`) === "custom" ? (
                <Fragment>
                  <Typography variant="caption" component="div" mt={4}>
                    Variable Name
                  </Typography>
                  <Controller
                    control={control}
                    name={`variables[${index}].name`}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        sx={{ mt: 2 }}
                      />
                    )}
                  />

                  <Typography variant="caption" component="div" mt={4}>
                    Value
                  </Typography>

                  <Controller
                    control={control}
                    name={`variables[${index}].value`}
                    render={({ field: { onChange, value } }) => (
                      <VariableDropdown
                        control={control}
                        watch={watch}
                        setValue={control.setValue}
                        value={value}
                        onChange={onChange}
                      >
                        <TextField
                          fullWidth
                          value={value}
                          onChange={onChange}
                          sx={{ mt: 2 }}
                        />
                      </VariableDropdown>
                    )}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="caption" component="div" mt={4}>
                    Value
                  </Typography>

                  {watch(`variables[${index}].type`) !== "subCategory" ? (
                    <Controller
                      control={control}
                      name={`variables[${index}].value`}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          fullWidth
                          displayEmpty={true}
                          value={value}
                          onChange={onChange}
                          className={styles.select}
                          sx={{ mt: 2 }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                borderRadius: 3,
                                backgroundColor: "white",
                                boxShadow:
                                  "0px 4px 17.6px 0px rgba(0, 0, 0, 0.06)",
                                maxHeight: "40vh",
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            if (!selected)
                              return (
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  Select Value
                                </Typography>
                              );
                            else if (
                              watch(`variables[${index}].type`) === "language"
                            )
                              return (
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  {
                                    defaultLanguages.find(
                                      (lang) => selected === lang.value
                                    )?.name
                                  }
                                </Typography>
                              );
                            else if (
                              watch(`variables[${index}].type`) ===
                              "subCategory"
                            )
                              return (
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  {
                                    categories.find(
                                      (category) => selected === category._id
                                    )?.name
                                  }
                                </Typography>
                              );
                            else
                              return (
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  {selected}
                                </Typography>
                              );
                          }}
                        >
                          {watch(`variables[${index}].type`) === "language" &&
                            languages?.map((language) => (
                              <MenuItem
                                value={
                                  defaultLanguages.find(
                                    (lang) => lang.name === language
                                  ).value
                                }
                                className={styles.menuItem}
                              >
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  {language}
                                </Typography>
                              </MenuItem>
                            ))}
                          {watch(`variables[${index}].type`) ===
                            "subCategory" &&
                            categories?.map((category) => (
                              <MenuItem
                                value={category._id}
                                className={styles.menuItem}
                              >
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  {category.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          {watch(`variables[${index}].type`) ===
                            "digression" && [
                            <MenuItem
                              value={"True"}
                              key={"true"}
                              className={styles.menuItem}
                            >
                              <Typography
                                variant="body2"
                                color={theme.palette.grey[800]}
                              >
                                True
                              </Typography>
                            </MenuItem>,
                            <MenuItem
                              value={"False"}
                              key={"false"}
                              className={styles.menuItem}
                            >
                              <Typography
                                variant="body2"
                                color={theme.palette.grey[800]}
                              >
                                False
                              </Typography>
                            </MenuItem>,
                          ]}
                        </Select>
                      )}
                    />
                  ) : null}

                  {watch(`variables[${index}].type`) === "subCategory" && (
                    <Controller
                      control={control}
                      name={`variables[${index}].value`}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          multiple
                          options={categories}
                          disableCloseOnSelect
                          getOptionLabel={(option) => {
                            // Handle both category objects and category IDs
                            if (typeof option === "string") {
                              const category = categories.find(
                                (cat) => cat._id === option
                              );
                              return category ? category.name : "";
                            }
                            return option.name;
                          }}
                          value={
                            value
                              ? categories.filter((cat) =>
                                  value.includes(cat._id)
                                )
                              : []
                          }
                          onChange={(_, newValue) => {
                            onChange(newValue.map((item) => item._id));
                          }}
                          filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                              option.name
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            );
                            return filtered;
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((category, index) => (
                              <Chip
                                key={index}
                                label={category.name}
                                {...getTagProps({ index })}
                                deleteIcon={
                                  <X style={{ height: 16, width: 16 }} />
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              sx={{
                                mt: 2,
                                "& > div": {
                                  padding: "8px",
                                  borderRadius: "8px",
                                },
                              }}
                              placeholder="Select categories"
                            />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          PaperComponent={({ children }) => (
                            <Paper
                              elevation={8}
                              sx={{
                                borderRadius: "12px",
                                backgroundColor: theme.palette.grey[50],
                                "& .MuiAutocomplete-listbox": {
                                  "& .MuiAutocomplete-option": {
                                    borderRadius: "8px",
                                    margin: "4px 0",
                                    '&[aria-selected="true"]': {
                                      backgroundColor:
                                        theme.palette.primary.light,
                                    },
                                    '&[data-focus="true"]': {
                                      backgroundColor:
                                        theme.palette.action.hover,
                                    },
                                  },
                                },
                              }}
                            >
                              {children}
                            </Paper>
                          )}
                        />
                      )}
                    />
                  )}
                </Fragment>
              )}
            </>
          </FunCollapse>
        </Box>
      ))}

      {fields.length < 5 && (
        <Button
          variant="text"
          color="inherit"
          startIcon={<Plus width={12} height={12} />}
          sx={{ my: 2, minWidth: 0, width: "max-content" }}
          onClick={() => append({ type: "custom", name: "", value: "" })}
        >
          Add Variable
        </Button>
      )}

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(onSubmit)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </>
  );
};

export default VariableProperties;
