export const gscUriPrefix = "https://storage.googleapis.com/";
export const sampleCSVLink =
  "https://s3.ap-south-1.amazonaws.com/assets.skillr.ai/BOT/SAMPLE-CSV-Q%26A.csv";
export const googleClientID =
  "506420215343-ffcqgbjdailt62k7hiiv9pgv44blo2bm.apps.googleusercontent.com";

export const languages = [
  {
    name: "English",
    value: "en-US",
  },
  // {
  //   name: "Hindi",
  //   value: "en-IN",
  // },
  {
    name: "Hindi",
    value: "hi-IN",
  },
  {
    name: "Bahasa",
    value: "id-ID",
  },
  {
    name: "Spanish",
    value: "es-ES",
  },
  {
    name: "Marathi",
    value: "mr-IN",
  },
  {
    name: "Malayalam",
    value: "ml-IN",
  },
  {
    name: "Punjabi",
    value: "pa-IN",
  },
  {
    name: "Tamil",
    value: "ta-IN",
  },
  {
    name: "Bengali",
    value: "bn-IN",
  },
  {
    name: "Telugu",
    value: "te-IN",
  },
  {
    name: "Gujarati",
    value: "gu-IN",
  },
  {
    name: "Arabic",
    value: "ar-XA",
  },
  {
    name: "Kannada",
    value: "kn-IN",
  },
  {
    name: "German",
    value: "de-DE",
  },
  {
    name: "Japanese",
    value: "ja-JP",
  },
  {
    name: "Mandarin",
    value: "cmn-CN",
  },
  // {
  //   name: "Spanish (US)",
  //   value: "es-US",
  // },
];
