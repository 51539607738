import { Slider, TextField, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import theme from "theme";

const FundamentoSlider = (props) => {
  const { value, onChange, min, max, step, textField } = props;

  return (
    <FlexBox columnGap={8}>
      <Slider
        min={min}
        max={max}
        valueLabelDisplay="auto"
        step={step}
        sx={{
          "& .MuiSlider-rail": {
            backgroundColor: theme.palette.grey[200],
          },
          "& .MuiSlider-track": {
            backgroundColor: theme.palette.text.primary,
            border: 0,
          },
          "& .MuiSlider-thumb": {
            backgroundColor: theme.palette.text.primary,
            width: 12,
            height: 12,
          },
          marginTop: 2,
          marginBottom: 0,
        }}
        marks={[
          {
            value: min,
            label: (
              <Typography
                variant="body2"
                fontFamily={"monospace"}
                fontWeight={500}
              >
                {min}
              </Typography>
            ),
          },
          {
            value: max,
            label: (
              <Typography
                variant="body2"
                fontFamily={"monospace"}
                fontWeight={500}
              >
                {max}
              </Typography>
            ),
          },
        ]}
        value={value}
        onChange={(e, value) => {
          onChange(value);
        }}
      />

      {textField && (
        <TextField
          size="small"
          variant="standard"
          value={value}
          onChange={(e) => {
            const newValue = Math.min(Math.max(e.target.value, min), max);
            onChange(newValue);
          }}
          sx={{
            backgroundColor: theme.palette.grey[200],
            borderRadius: "4px",
            outline: 0,
            overflow: "hidden",
          }}
          inputProps={{
            style: {
              fontSize: "14px",
              width: 40,
              height: 30,
              padding: 4,
              textAlign: "center",
            },
            min: min,
            max: max,
          }}
        />
      )}
    </FlexBox>
  );
};

FundamentoSlider.defaultProps = {
  textField: true,
};

export default FundamentoSlider;
