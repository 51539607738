import {
  Box,
  Button,
  Fade,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clock,
  Eye,
  Loader,
  TrendingDown,
  X,
  Zap,
} from "react-feather";
import {
  FlowArrow,
  Lightning,
  PencilSimple,
  PlusCircle,
} from "components/newSVG";
import theme from "theme";
import FunCollapse from "components/FunCollapse";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  addAuditDetails,
  editAgentCall as editAgentCallAPI,
  getAllWorkFlow,
  getAuditStatuses,
  getCategoriesByCompany,
  getPlaygroundHistory,
} from "services";
import { useSkillrToast } from "context/toast";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { getFormattedFullNameAndIntials, getJSONDecoded } from "utils/utils";
import { useUserContext } from "context/user";
import EditContainer from "pages/Bot/container/HistoryDetail/components/EditContainer";
import {
  editVariables as editVariablesAPI,
  getVariables as getVariablesAPI,
} from "services";
import AudioPlayer from "components/AudioPlayer";
import CustomJsonRenderer from "pages/Diagram/components/CustomRenderer";
import Variation from "assets/images/variation.png";
import _ from "lodash";

const CallDetail = (props) => {
  // check if advancedModeValue is present in context else take it from props
  const contextAdvancedMode = useOutletContext()?.advancedModeValue;
  const advancedModeValue =
    contextAdvancedMode !== undefined
      ? contextAdvancedMode
      : props?.advancedModeValue;

  // check if we are in playground history or not
  const playgroundHistory = props?.playgroundHistory || false;

  // const callDetails = useLocation().state;
  const [editAnswer, setEditAnswer] = useState(false);
  const [newAnswer, setNewAnswer] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [showValidMenu, setShowValidMenu] = useState(false);
  const [showBlankMenu, setShowBlankMenu] = useState(false);
  const [showInvalidMenu, setShowInvalidMenu] = useState(false);
  const [showJunkMenu, setShowJunkMenu] = useState(false);
  const [callTranscript, setCallTranscript] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [editContainer, setEditContainer] = useState(false);
  const [lastIntentObject, setLastIntentObject] = useState({});
  const [currentAuditLogType, setCurrentAuditLogType] = useState();
  const [currentAuditLogValue, setCurrentAuditLogValue] = useState();
  const [currentAuditLogEmail, setCurrentAuditLogEmail] = useState();
  const [workflowList, setWorkflowList] = useState([]);
  const [
    lastIntentMatchedAsperBotThreshold,
    setLastIntentMatchedAsperBotThreshold,
  ] = useState();
  const [digressionThreshold, setDigressionThreshold] = useState();
  const [callDetails, setCallDetails] = useState();
  const { showSkillrToast } = useSkillrToast();
  const { token, buildMode } = useUserContext();
  const tokenInfo = getJSONDecoded(token ?? null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const voiceCallId = queryParams.get("voiceCallId");
  const [variationToAdd, setVariationToAdd] = useState();
  const [addVariant, setAddVariant] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [variables, setVariables] = useState({});
  const [showCustomVariables, setCustomVariables] = useState(true);
  const [nextCall, setNextCall] = useState({});
  const [previousCall, setPreviousCall] = useState({});
  const [loading, setLoading] = useState(false);
  const [editedValues, setEditedValues] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [mainAuditStatuses, setMainAuditStatuses] = useState([]);
  const [auditStatuses, setAuditStatuses] = useState([]);
  const [subMenuStates, setSubMenuStates] = useState({});

  const nodeNames = [
    {
      nodeType: "messageNode",
      nodeName: "Message",
    },
    {
      nodeType: "conversationNode",
      nodeName: "Conversation",
    },
    {
      nodeType: "inputNode",
      nodeName: "Input",
    },
    {
      nodeType: "decisionNode",
      nodeName: "Decision",
    },
    {
      nodeType: "messageOptionsNode",
      nodeName: "Message with Options",
    },
    {
      nodeType: "audioNode",
      nodeName: "Audio",
    },
    {
      nodeType: "triggerNode",
      nodeName: "Trigger Workflow",
    },
    {
      nodeType: "scriptNode",
      nodeName: "Script",
    },
    {
      nodeType: "googleSheetNode",
      nodeName: "Google Sheet",
    },
    {
      nodeType: "startNode",
      nodeName: "Start",
    },
    {
      nodeType: "apiNode",
      nodeName: "API",
    },
    {
      nodeType: "variableNode",
      nodeName: "Set Variable",
    },
    {
      nodeType: "aiNode",
      nodeName: "AI Node",
    },
  ];

  const getWorkflow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlow(params.toString());
      setWorkflowList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      const response = await getCategoriesByCompany();
      setCategoryList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const handleEditAnswer = () => {
    setEditAnswer(true);
  };

  const handleDiscardChanges = () => {
    setEditAnswer(false);
  };

  const handleSaveAnswer = () => {
    setEditAnswer(false);
    if (newAnswer !== callDetails?.answer) {
      editAgentCall(callDetails?.callId, { endLine: newAnswer });
      callDetails.answer = newAnswer;
    }
  };

  const addAuditLog = async (id, data) => {
    try {
      const response = await addAuditDetails(id, data);
      setCurrentAuditLogType(
        auditStatuses.find(
          (status) => status.value === response.data.auditedCallType
        )?.name
      );
      setCurrentAuditLogValue(
        auditStatuses.find(
          (status) => status.value === response.data.auditedCallTypeSub
        )?.name
      );
      setCurrentAuditLogEmail(tokenInfo.email);
      showSkillrToast("Audit log added successfully", "success");
      setShowMenu(false);
      // onAuditLogChange();
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const editAgentCall = async (callId, data) => {
    try {
      const response = await editAgentCallAPI(callId, data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCallTranscript = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      // params.set("voiceCallId", voiceCallId);
      /**
       * isOnPhoneCall
       * For development build we will show all calls made from playground
       * and for production build we will show all calls made from actual phone number
       */
      // params.set("isOnPhoneCall", buildMode === "production" ? true : null);
      // const response = await getPlaygroundHistory(params.toString());
      const response = await getPlaygroundHistory(voiceCallId);
      // const response = await getPlaygroundHistory(
      //   "018d6f93-23c9-4286-b11c-ddab96cd7d4c"
      // );
      // setDigressionThreshold(response?.digressionThreshold);
      setCallDetails(response?.call);
      setLastIntentObject(response?.call?.lastIntentObject);
      setLastIntentMatchedAsperBotThreshold(
        response?.call?.lastIntentMatchedAsperBotThreshold
      );
      setVariationToAdd(response?.call?.lastQuestionAsked || {});
      // getVariables(response?.callSessionData[0]?.callId?._id);
      setCallTranscript(response?.call?.conversations);
      setVariables(response?.call?.variables);
      setNextCall({
        id: response?.callLinked?.nextSession?.id,
        voiceCallId: response?.callLinked?.nextSession?.voiceCallId,
      });
      setPreviousCall({
        id: response?.callLinked?.previousSession?.id,
        voiceCallId: response?.callLinked?.previousSession?.voiceCallId,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getVariables = async (id) => {
    try {
      const response = await getVariablesAPI(id);
      setVariables(response);
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleonChangeIntent = () => {
    getCallTranscript();
  };

  const editVariables = () => {
    const finalValues = editedValues.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    try {
      const response = editVariablesAPI(callDetails._id, finalValues);
      showSkillrToast("Variables updated successfully", "success");
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const renderTranscriptContents = (item) => {
    return (
      <>
        {item.nodeType === "triggerNode" && (
          <FlexBox columnGap={2}>
            <Typography variant="body2" fontWeight={500} color="grey.600">
              Triggered Workflow:
            </Typography>
            <FlexBox
              columnGap={1}
              sx={{
                backgroundColor: "#E3F2FF",
                padding: "2px 8px",
                borderRadius: "12px",
              }}
            >
              <FlowArrow
                width={12}
                height={12}
                color={theme.palette.primary.main}
              />
              <Link to={`/workflows/${item.toWorkflow}`} target="_blank">
                <Typography fontSize={10} color={theme.palette.primary.main}>
                  {
                    workflowList?.workFlows?.find(
                      (workflow) => workflow._id === item.toWorkflow
                    )?.name
                  }
                </Typography>
              </Link>
            </FlexBox>
          </FlexBox>
        )}

        {item.nodeType === "apiNode" && (
          <FunCollapse
            icon={<PlusCircle />}
            iconPosition={"start"}
            header={
              <Typography variant="body2" mt={1}>
                <Typography variant="body2" component={"span"} fontWeight={500}>
                  URL:
                </Typography>{" "}
                {item.url}
              </Typography>
            }
          >
            <Box pl={6} mt={2}>
              {item.error && (
                <Typography variant="body2" mt={1}>
                  <Typography
                    variant="body2"
                    component={"span"}
                    fontWeight={500}
                  >
                    Error:
                  </Typography>{" "}
                  {JSON.stringify(item.error)}
                </Typography>
              )}
              {item.statusCode && (
                <Typography variant="body2" mt={1}>
                  <Typography
                    variant="body2"
                    component={"span"}
                    fontWeight={500}
                  >
                    Response Code:
                  </Typography>{" "}
                  {item.statusCode}
                </Typography>
              )}

              <Typography variant="body2" fontWeight={500} color="grey.600">
                Response:
              </Typography>

              <Box width={"40%"}>
                <CustomJsonRenderer
                  className={styles.customJsonRenderer}
                  json={item.resultFromAPI ? item.resultFromAPI : {}}
                  // handleOnClick={handleClickOnKeys}
                />
              </Box>
            </Box>
          </FunCollapse>
        )}

        {item.nodeType === "scriptNode" && item.type !== "Bot" && (
          <FunCollapse
            icon={<PlusCircle />}
            iconPosition={"start"}
            header={
              <Typography variant="body2" mt={1}>
                <Typography variant="body2" component={"span"} fontWeight={500}>
                  Script
                </Typography>{" "}
                {item?.script?.slice(0, 50)}
              </Typography>
            }
          >
            <Box pl={6} mt={2}>
              {item.error && (
                <Typography variant="body2" mt={1}>
                  <Typography
                    variant="body2"
                    component={"span"}
                    fontWeight={500}
                  >
                    Error :
                  </Typography>{" "}
                  {item.error}
                </Typography>
              )}

              <TextField
                multiline
                fullWidth
                value={item.script}
                sx={{ "& div": { padding: 0 } }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </FunCollapse>
        )}

        {item.nodeType === "decisionNode" && (
          <FunCollapse
            icon={<PlusCircle />}
            iconPosition={"start"}
            header={
              <Typography variant="body2" mt={1}>
                <Typography variant="body2" component={"span"} fontWeight={500}>
                  Condition
                </Typography>{" "}
                {item.logs[0].formula}...
              </Typography>
            }
          >
            <Box pl={6} mt={2}>
              {item.logs.map((log, index) => (
                <>
                  <Typography variant="body2" mt={1}>
                    <Typography
                      variant="body2"
                      component={"span"}
                      fontWeight={500}
                    >
                      Condition {index + 1} :
                    </Typography>{" "}
                    {log.condition}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    <Typography
                      variant="body2"
                      component={"span"}
                      fontWeight={500}
                    >
                      Formula :
                    </Typography>{" "}
                    {log.formula}
                  </Typography>
                </>
              ))}
            </Box>
          </FunCollapse>
        )}

        {item.nodeType === "variableNode" &&
          item?.nodeVariables?.length &&
          item.nodeVariables.map((variable) => (
            <Typography variant="body2" mt={1}>
              <Typography variant="body2" component={"span"} fontWeight={500}>
                {variable.type === "custom"
                  ? `{{${variable.name}}}`
                  : variable.type.slice(0, 1).toUpperCase() +
                    variable.type.slice(1)}
                :
              </Typography>{" "}
              {variable.type === "subCategory"
                ? Array.isArray(variable.value) && variable.value.length > 0
                  ? variable.value
                      .map(
                        (value) =>
                          categoryList.find((c) => c._id === value)?.name
                      )
                      .join(", ")
                  : categoryList.find((c) => c._id === variable.value)?.name
                : variable.value}
            </Typography>
          ))}

        <>
          {/* show collapsible if transcript contains logs */}
          {item.nodeType !== "decisionNode" &&
          item.logs &&
          Object.keys(item.logs).length > 0 ? (
            <FunCollapse
              icon={<PlusCircle />}
              iconPosition={"start"}
              header={
                <Typography variant="body1" fontWeight={500}>
                  {item.message}
                </Typography>
              }
            >
              <Box pl={4} mt={2}>
                {item.logs.Translate && (
                  <FunCollapse
                    icon={<PlusCircle />}
                    iconPosition={"start"}
                    header={
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color={"grey.600"}
                      >
                        Translation
                      </Typography>
                    }
                  >
                    <Box pl={4} mt={1}>
                      <Typography
                        variant="body2"
                        sx={{ fontStyle: "italic" }}
                        color="grey.400"
                      >
                        {item.logs.Translate.userQuery}
                      </Typography>
                    </Box>
                  </FunCollapse>
                )}

                {item.logs.Digression && (
                  <FunCollapse
                    icon={<PlusCircle />}
                    iconPosition={"start"}
                    header={
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color={"grey.600"}
                      >
                        Digression
                      </Typography>
                    }
                  >
                    <Box pl={4} mt={1}>
                      {item.logs.Digression.isDigressed === false && (
                        <Typography variant="body2" fontWeight={500}>
                          Intent didn't match
                        </Typography>
                      )}
                      {item.logs.Digression.reason && (
                        <Typography variant="body2" fontWeight={500}>
                          {item.logs.Digression.reason}
                        </Typography>
                      )}

                      <Typography variant="body2" mt={1}>
                        <Typography
                          component={"span"}
                          variant="body2"
                          fontWeight={500}
                          mt={1}
                        >
                          Distance
                        </Typography>{" "}
                        {item.logs.Digression?.distance}
                      </Typography>
                      {item?.tags?.includes("No Confidence Match") && (
                        <Typography variant="body2" mt={1}>
                          <Typography
                            component={"span"}
                            variant="body2"
                            fontWeight={500}
                            mt={1}
                          >
                            Confidence Distance
                          </Typography>{" "}
                          {item.logs?.Digression?.confidenceDistance}
                        </Typography>
                      )}

                      {item.logs?.Digression?.vectorResponse?.length &&
                        item.logs?.Digression?.vectorResponse?.map(
                          (result, index) => (
                            <FlexBox>
                              <Typography variant="body2" color="grey.600">
                                {result.question}
                              </Typography>

                              <Box
                                className={`${styles.label} ${
                                  result._additional.distance <
                                    item.logs.Digression.distance && index === 0
                                    ? styles.greenLabel
                                    : result._additional.distance <
                                      item.logs.Digression.distance
                                    ? styles.yellowLabel
                                    : styles.redLabel
                                }`}
                              >
                                {result._additional.distance}
                              </Box>
                            </FlexBox>
                          )
                        )}
                    </Box>
                  </FunCollapse>
                )}
                <div style={{ height: 6 }} />

                {item.logs["Conversation Node"] && (
                  <Box>
                    <Typography variant="body2">
                      <Typography
                        variant="body2"
                        component={"span"}
                        fontWeight={500}
                        mt={1}
                      >
                        Search Type:
                      </Typography>{" "}
                      {item.logs["Conversation Node"].type}
                    </Typography>

                    <div style={{ height: 6 }} />

                    {item.logs["Conversation Node"].vectorLog?.vectorResponse
                      .length && (
                      <FunCollapse
                        iconPosition="start"
                        icon={<PlusCircle />}
                        header={
                          <Typography variant="body2" fontWeight={500}>
                            Vector Response:
                          </Typography>
                        }
                      >
                        <Box pl={4} mt={1}>
                          {item.logs[
                            "Conversation Node"
                          ]?.vectorLog?.vectorResponse?.map((option, index) => (
                            <FlexBox key={option}>
                              <Typography variant="body2" mt={1}>
                                <Typography
                                  variant="body2"
                                  component={"span"}
                                  fontWeight={500}
                                >
                                  Type:
                                </Typography>{" "}
                                {option.type}
                              </Typography>

                              <Typography variant="body2" mt={1}>
                                <Typography
                                  variant="body2"
                                  component={"span"}
                                  fontWeight={500}
                                >
                                  Example:
                                </Typography>{" "}
                                {option.example}
                              </Typography>

                              <Box
                                className={`${styles.label} ${
                                  (option._additional.distance <
                                  item.logs["Conversation Node"]
                                    ?.converstionDistance
                                    ? styles.greenLabel
                                    : styles.redLabel) ?? ""
                                }`}
                              >
                                {option._additional.distance}
                              </Box>
                            </FlexBox>
                          ))}
                        </Box>
                      </FunCollapse>
                    )}

                    <div style={{ height: 6 }} />

                    {item.logs["Conversation Node"]?.LLM && (
                      <FunCollapse
                        iconPosition="start"
                        icon={<PlusCircle />}
                        header={
                          <Typography variant="body2" fontWeight={500}>
                            LLM Response:
                          </Typography>
                        }
                      >
                        <Box pl={4} mt={1}>
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: "40ch",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            mt={1}
                          >
                            <Typography
                              component={"span"}
                              variant="body2"
                              fontWeight={500}
                              mt={1}
                            >
                              Prompt:
                            </Typography>{" "}
                            {item.logs["Conversation Node"]?.LLM?.prompt}
                          </Typography>

                          <Typography variant="body2" mt={1}>
                            <Typography
                              variant="body2"
                              component={"span"}
                              fontWeight={500}
                            >
                              Result:
                            </Typography>{" "}
                            {item.logs["Conversation Node"].LLM?.result}
                          </Typography>

                          <Typography variant="body2" mt={1}>
                            <Typography
                              variant="body2"
                              component={"span"}
                              fontWeight={500}
                            >
                              Model:
                            </Typography>{" "}
                            {item.logs["Conversation Node"].LLM?.model}
                          </Typography>

                          <Typography variant="body2" mt={1}>
                            <Typography
                              variant="body2"
                              component={"span"}
                              fontWeight={500}
                            >
                              Execution Time:
                            </Typography>{" "}
                            {(
                              item.logs["Conversation Node"]?.executionTime /
                              1000
                            ).toFixed(2)}
                            s
                          </Typography>
                        </Box>
                      </FunCollapse>
                    )}
                  </Box>
                )}

                <div style={{ height: 6 }} />

                {item.logs["InputNode"] && (
                  <Box>
                    {item.logs["InputNode"].validationType && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Validation Type:
                        </Typography>{" "}
                        {item.logs["InputNode"].validationType}
                      </Typography>
                    )}
                    {item.logs["InputNode"].model && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Model:
                        </Typography>{" "}
                        {item.logs["InputNode"].model}
                      </Typography>
                    )}

                    {item.logs["InputNode"].prompt && (
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "40ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        mt={1}
                      >
                        <Typography
                          component={"span"}
                          variant="body2"
                          fontWeight={500}
                          mt={1}
                        >
                          Prompt:
                        </Typography>{" "}
                        {item.logs["InputNode"].prompt}
                      </Typography>
                    )}
                    {item.logs["InputNode"].executionTime && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          component={"span"}
                          variant="body2"
                          fontWeight={500}
                          mt={1}
                        >
                          Execution Time:
                        </Typography>{" "}
                        {(item.logs["InputNode"].executionTime / 1000).toFixed(
                          2
                        )}
                        s
                      </Typography>
                    )}
                    {item.logs["InputNode"].response?.text && (
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "40ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        mt={1}
                      >
                        <Typography
                          component={"span"}
                          variant="body2"
                          fontWeight={500}
                          mt={1}
                        >
                          result:
                        </Typography>{" "}
                        {item.logs["InputNode"].response.text}
                      </Typography>
                    )}
                  </Box>
                )}

                {item.logs["MWO Execution"] && (
                  <Box>
                    {item?.logs["MWO Execution"]?.model && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Model:
                        </Typography>{" "}
                        {item.logs["MWO Execution"].model}
                      </Typography>
                    )}
                    {item.logs["MWO Execution"].matchedOption && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Matched Option:
                        </Typography>{" "}
                        {item.logs["MWO Execution"].matchedOption.option}
                      </Typography>
                    )}
                    {item.logs["MWO Execution"]?.llmLogs?.mwoVectorObject
                      ?.length &&
                      item.logs["MWO Execution"]?.llmLogs?.mwoVectorObject.map(
                        (option, index) => (
                          <FlexBox key={option}>
                            <Typography variant="body2" mt={1}>
                              <Typography
                                variant="body2"
                                component={"span"}
                                fontWeight={500}
                              >
                                Option {index}:
                              </Typography>{" "}
                              {option.option}
                            </Typography>

                            <Box
                              className={`${styles.label} ${
                                (option._additional.distance <
                                item.logs["MWO Execution"].distanceFormwoNode
                                  ? styles.greenLabel
                                  : styles.redLabel) ?? ""
                              } `}
                            >
                              {option._additional.distance}
                            </Box>
                          </FlexBox>
                        )
                      )}
                  </Box>
                )}

                {item?.logs?.aiNodeExecution && (
                  <Box>
                    {item?.logs?.aiNodeExecution?.source && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Model:
                        </Typography>{" "}
                        {item?.logs?.aiNodeExecution?.source}
                      </Typography>
                    )}
                    {item?.logs?.aiNodeExecution?.prompt && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Prompt:
                        </Typography>{" "}
                        {item?.logs?.aiNodeExecution?.prompt}
                      </Typography>
                    )}
                    {item?.logs?.aiNodeExecution?.executionTime && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Execution Time:
                        </Typography>{" "}
                        {(
                          item?.logs?.aiNodeExecution?.executionTime / 1000
                        ).toFixed(2)}{" "}
                        s
                      </Typography>
                    )}
                    {item?.logs?.aiNodeExecution?.value && (
                      <Typography variant="body2" mt={1}>
                        <Typography
                          variant="body2"
                          component={"span"}
                          fontWeight={500}
                        >
                          Value:
                        </Typography>{" "}
                        {item?.logs?.aiNodeExecution?.value?.message}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </FunCollapse>
          ) : // show message if no logs
          item.fillerType === "audio" ? (
            <AudioPlayer
              className={styles.audio}
              style={{ width: "60%" }}
              controls
              onlyAudio
              audioPath={item.message}
            />
          ) : item.message &&
            !(item.nodeType === "scriptNode" && item.type === "System") ? (
            <Typography variant="body1" fontWeight={500}>
              {item.message}
            </Typography>
          ) : (
            item.audio && (
              <AudioPlayer
                className={styles.audio}
                style={{ width: "60%" }}
                controls
                onlyAudio
                audioPath={item.audio}
              />
            )
          )}
        </>
      </>
    );
  };

  const getRenderValue = (key, value) => {
    if (key === "start_time" || key === "end_time") {
      return moment(value).format("hh:mm:ss A");
    }
    if (key === "call_duration") {
      return parseFloat(value)?.toFixed(1) || 0;
    } else if (key === "active_intent_subcategory") {
      if (Array.isArray(value) && value.length > 0) {
        return value
          .map((v) => categoryList?.find((item) => item._id === v)?.name)
          .join(", ");
      } else {
        return categoryList?.find((item) => item._id === value)?.name;
      }
    } else {
      return value !== undefined && value !== null ? "" + value : "--";
    }
  };

  const getAllAuditStatuses = async () => {
    const response = await getAuditStatuses();
    if (response) {
      setMainAuditStatuses(response);
      const allStatuses = response.reduce((acc, parentStatus) => {
        // Add parent status
        acc.push({
          value: parentStatus._id,
          name: parentStatus.name,
          parentStatus: null, // null indicates this is a parent status
        });

        // Add sub statuses
        const subStatuses = parentStatus.subStatuses.map((subStatus) => ({
          value: subStatus._id,
          name: subStatus.name,
          parentStatus: parentStatus.name,
        }));

        return [...acc, ...subStatuses];
      }, []);

      setAuditStatuses(allStatuses);
    }
  };

  useEffect(() => {
    setCurrentAuditLogType(
      auditStatuses.find((s) => s.value === callDetails?.auditedCallType)
        ?.name || callDetails?.auditedCallType
    );
    setCurrentAuditLogValue(
      auditStatuses.find((s) => s.value === callDetails?.auditedCallTypeSub)
        ?.name || callDetails?.auditedCallTypeSub
    );
    setCurrentAuditLogEmail(callDetails?.auditUser?.email);
  }, [callDetails?.auditedCallType, auditStatuses]);

  useEffect(() => {
    getCallTranscript();
    getWorkflow();
    getCategory();
    getAllAuditStatuses();
  }, [voiceCallId]);

  useEffect(() => {
    if (variables && variables.system) {
      setEditedValues(() => {
        const values = Object.entries(variables)
          .filter(([key]) => key !== "system" && key !== "session")
          .map(([key, value]) => ({
            key: key,
            value: value,
          }));

        return values;
      });
    }
  }, [variables]);

  // const handleOnCloseModal = () => {
  //   onClose();
  //   setEditAnswer(false);
  // };

  return (
    <>
      {loading ? (
        <FlexBox justifyContent="center" alignItems="center" height="100vh">
          <Loader color={theme.palette.grey[400]} width={32} height={32} />
        </FlexBox>
      ) : (
        <Box>
          <FlexBox className={styles.topBar}>
            <Typography variant="body1" fontWeight={500}>
              {callDetails?.voiceCallId}
            </Typography>
            <IconButton onClick={() => navigate(-1)}>
              <X width={20} height={20} />
            </IconButton>
          </FlexBox>

          {!playgroundHistory && (
            <FlexBox justifyContent="space-between" mt={4} px={6}>
              <Button
                variant="contained"
                startIcon={<ChevronLeft />}
                sx={{ backgroundColor: theme.palette.grey[50] }}
                color="inherit"
                onClick={() => {
                  if (previousCall.id && previousCall.voiceCallId) {
                    navigate(
                      `/calls/${previousCall.id}?voiceCallId=${previousCall.voiceCallId}`,
                      {
                        replace: true,
                      }
                    );
                  }
                }}
                disabled={!previousCall.id || !previousCall.voiceCallId}
              >
                Previous Call
              </Button>
              <Button
                variant="contained"
                endIcon={<ChevronRight />}
                color="inherit"
                sx={{ backgroundColor: theme.palette.grey[50] }}
                onClick={() => {
                  if (nextCall.id && nextCall.voiceCallId) {
                    navigate(
                      `/calls/${nextCall.id}?voiceCallId=${nextCall.voiceCallId}`,
                      {
                        replace: true,
                      }
                    );
                  }
                }}
                disabled={!nextCall.id || !nextCall.voiceCallId}
              >
                Next Call
              </Button>
            </FlexBox>
          )}

          {!playgroundHistory && (
            <FlexBox padding="16px 24px">
              <Typography variant="body2" color="grey.600">
                Audit log
              </Typography>
              <Box sx={{ position: "relative" }}>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: 82, padding: "4px 16px", minWidth: 0 }}
                  endIcon={<ChevronDown />}
                  onMouseOver={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                  {!currentAuditLogType && !currentAuditLogValue && `None`}
                  {currentAuditLogType &&
                    !currentAuditLogValue &&
                    `${currentAuditLogType}`}
                  {currentAuditLogType &&
                    currentAuditLogValue &&
                    `${currentAuditLogType} - ${currentAuditLogValue}`}
                </Button>
                <Fade in={showMenu} unmountOnExit={true} mountOnEnter={true}>
                  <Box
                    className={styles.menuContainer}
                    onMouseOver={() => setShowMenu(true)}
                    onMouseLeave={() => {
                      setShowMenu(false);
                      setSubMenuStates({});
                    }}
                  >
                    {mainAuditStatuses.map((status) => (
                      <Box
                        key={status._id}
                        sx={{ position: "relative" }}
                        onMouseOver={() =>
                          setSubMenuStates((prev) => ({
                            ...prev,
                            [status._id]: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setSubMenuStates((prev) => ({
                            ...prev,
                            [status._id]: false,
                          }))
                        }
                        onClick={() =>
                          addAuditLog(callDetails?._id, {
                            auditedCallType: status._id,
                            auditedCallTypeSub: null,
                          })
                        }
                      >
                        <Typography
                          variant="subtitle2"
                          mb={1}
                          sx={{ fontWeight: 500 }}
                        >
                          {status.name}
                        </Typography>
                        <Fade
                          in={subMenuStates[status._id]}
                          unmountOnExit={true}
                          mountOnEnter={true}
                        >
                          <Box
                            className={styles.menuContainer}
                            style={{ left: "100%" }}
                          >
                            {status.subStatuses.map((subStatus) => (
                              <Box
                                key={subStatus._id}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addAuditLog(callDetails?._id, {
                                    auditedCallType: status._id,
                                    auditedCallTypeSub: subStatus._id,
                                  });
                                }}
                              >
                                {subStatus.name}
                              </Box>
                            ))}
                          </Box>
                        </Fade>
                      </Box>
                    ))}
                  </Box>
                </Fade>
              </Box>
              <Tooltip
                title={`Last audited by ${callDetails?.auditUser?.firstName} ${callDetails?.auditUser?.lastName}`}
              >
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline" }}
                >
                  {currentAuditLogEmail ? currentAuditLogEmail : ""}
                </Typography>
              </Tooltip>
            </FlexBox>
          )}
          <FlexBox alignItems="stretch">
            <Box
              sx={{
                padding: "0px 24px 48px",
                width: playgroundHistory ? "100%" : "65%",
                maxHeight: playgroundHistory
                  ? "calc(100vh - 230px)"
                  : "calc(100vh - 350px)",
                overflowY: "auto",
              }}
            >
              {/* <Box
            sx={{
              my: 4,
              padding: "12px",
              border: `1px solid ${theme.palette.borderColor.light}`,
              borderRadius: "8px",
              pr: 16,
            }}
          >
            <Typography variant="body2" color="grey.600">
              Call Details
            </Typography>
            <FlexBox py={5} justifyContent="space-between">
              <FlexBox>
                <Phone style={{ flexShrink: 0 }} width={32} height={32} />
                <Box>
                  <Typography variant="body2">Phone Number</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {callDetails?.phoneNumber}
                  </Typography>
                </Box>
              </FlexBox>

              <FlexBox display="flex" gap={4}>
                <IDBox style={{ flexShrink: 0 }} width={32} height={32} />
                <Box>
                  <Typography variant="body2">ID</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {callDetails?.voiceCallId}
                  </Typography>
                </Box>
              </FlexBox>

              <FlexBox display="flex" gap={4}>
                <VoiceScan style={{ flexShrink: 0 }} width={32} height={32} />
                <Box>
                  <Typography variant="body1">Call Type</Typography>
                  <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                    {callDetails?.callType === "virtualAgent" &&
                      "Virtual Agent"}
                    {callDetails?.callType === "agentHandOff" &&
                      "Agent Handoff"}
                    {callDetails?.callType === "blank" && "Blank"}
                  </Typography>
                </Box>
              </FlexBox>
              <FlexBox display="flex" gap={4}>
                <CalendarBlank
                  style={{ flexShrink: 0 }}
                  width={32}
                  height={32}
                />
                <Box>
                  <Typography variant="body2">Date and time</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {moment
                      .utc(callDetails?.startTime)
                      .local()
                      .format("D MMM, hh:mm A")}
                  </Typography>
                </Box>
              </FlexBox>
            </FlexBox>
          </Box> */}

              <FlexBox mb={4} className={styles.stickyAudioContainer}>
                <AudioPlayer
                  className={styles.audio}
                  style={{ width: "100%" }}
                  controls
                  audioPath={callDetails?.gcsUri}
                  onlyAudio
                />
              </FlexBox>

              {/* transcript of the call */}
              <Typography variant="body2" fontWeight={500}>
                Transcript
              </Typography>

              <Box className={styles.transcriptContainer}>
                {callTranscript?.map((item, index) =>
                  !advancedModeValue && item.type === "System" ? null : (
                    <FlexBox key={index} className={styles.historyItem}>
                      <Box
                        className={`${styles.avatar} ${
                          item.type === "User"
                            ? styles.customer
                            : item.type === "System"
                            ? styles.system
                            : styles.yara
                        }`}
                      >
                        {item.type === "Bot" ? "Yara" : item.type}
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <FlexBox justifyContent="space-between" mb={2}>
                          {item.nodeType === "decisionNode" && (
                            <Box
                              className={`${styles.label} ${
                                item.result
                                  ? styles.greenLabel
                                  : styles.redLabel
                              }`}
                            >
                              {item.result
                                .toString()
                                .slice(0, 1)
                                .toUpperCase() +
                                item.result.toString().slice(1)}
                            </Box>
                          )}

                          <FlexBox>
                            {((item.logs && item?.logs[1]?.isDigressed) ||
                              (item.logs && item?.isKbSearch)) && (
                              <FlexBox
                                className={`${styles.label} ${styles.redLabel}`}
                              >
                                {item?.isKbSearch ? "KB Search" : "Digression"}
                                <TrendingDown width={12} height={12} />
                              </FlexBox>
                            )}

                            {item.nodeType === "apiNode" && (
                              <Box
                                className={`${styles.label} ${
                                  item.error && item.statusCode
                                    ? styles.redLabel
                                    : styles.greenLabel
                                }`}
                              >
                                {item.error && item.statusCode
                                  ? "Failure"
                                  : "Success"}
                              </Box>
                            )}

                            {item.nodeType === "scriptNode" && (
                              <Box
                                className={`${styles.label} ${
                                  item.error
                                    ? styles.redLabel
                                    : styles.greenLabel
                                }`}
                              >
                                {item.error ? "Error" : "Success"}
                              </Box>
                            )}

                            {item.nodeType && (
                              <Box className={styles.label}>
                                {
                                  nodeNames.find(
                                    (name) => name.nodeType === item.nodeType
                                  )?.nodeName
                                }
                              </Box>
                            )}

                            {item.nodeName && (
                              <Typography
                                variant="caption"
                                color="primary.main"
                              >
                                (
                                {item.nodeName
                                  .replace(/-/g, " ")
                                  .replace(/\b\w/g, (l) => l.toUpperCase())
                                  .replace(/ (\d+)/, " ($1)")}
                                )
                              </Typography>
                            )}

                            {item.fillerType && (
                              <Box className={styles.label}>
                                {item.fillerType === "audio"
                                  ? "Filler: Audio"
                                  : "Filler: Text"}
                              </Box>
                            )}

                            {item.intentType === "FAQ" && (
                              <Box className={styles.label}>FAQ</Box>
                            )}

                            {item.userInputType === "DTMF" && (
                              <Box className={styles.label}>DTMF</Box>
                            )}

                            {item?.tags?.length > 0 &&
                              item.tags.map((tag) => (
                                <Box
                                  className={`${styles.label} 
                                  ${tag === "Digression" ? styles.redLabel : ""}
                                  ${tag === "Error" ? styles.redLabel : ""}
                                  `}
                                >
                                  {tag}
                                </Box>
                              ))}

                            {/* {item.nextNode && (
                            <Typography variant="caption" color="primary.main">
                              ({item.nextNode})
                            </Typography>
                          )} */}
                          </FlexBox>

                          <FlexBox sx={{ ml: "auto" }}>
                            <FlexBox columnGap={1}>
                              <Clock
                                width={12}
                                height={12}
                                color={theme.palette.grey[400]}
                              />
                              <Typography variant="caption" color="grey.400">
                                {moment(item.timeStamp)
                                  .diff(callDetails?.startTime, "minutes")
                                  .toString()
                                  .padStart(2, "0")}
                                :
                                {moment(item.timeStamp)
                                  .diff(callDetails?.startTime, "seconds")
                                  .toString()
                                  .padStart(2, "0")}
                              </Typography>
                            </FlexBox>
                            {/* <FlexBox columnGap={1}>
                            <Zap
                              width={12}
                              height={12}
                              fill={theme.palette.grey[400]}
                              color={theme.palette.grey[400]}
                            />
                            <Typography variant="caption">0.9s</Typography>
                          </FlexBox> */}
                            <FlexBox>
                              <Typography variant="caption">
                                {moment(item.timeStamp).format("hh:mm:ss")}
                              </Typography>
                            </FlexBox>
                          </FlexBox>
                        </FlexBox>

                        {/* transcript contents */}
                        {renderTranscriptContents(item)}

                        {item.workflowId && (
                          <FlexBox columnGap={1} mt={4}>
                            <FlowArrow
                              className={styles.flowArrow}
                              width={16}
                              height={16}
                              style={{ flexShrink: 0 }}
                            />
                            <Typography
                              variant="body2"
                              color={theme.palette.primary.main}
                            >
                              Workflow linked:{" "}
                              <Link
                                target="_blank"
                                to={`/workflows/${item.workflowId}`}
                              >
                                {item.workflowName}
                              </Link>
                            </Typography>
                          </FlexBox>
                        )}
                      </Box>
                    </FlexBox>
                  )
                )}
              </Box>

              <Box className={styles.greyBox}>
                {lastIntentObject ? (
                  <>
                    <FlexBox>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        color="grey.600"
                      >
                        Identified Intent
                      </Typography>

                      <Box
                        className={`
                    ${styles.label}
                    ${
                      lastIntentMatchedAsperBotThreshold === false
                        ? styles.redLabel
                        : styles.greenLabel
                    }
                    `}
                      >
                        Distance: {lastIntentObject?._additional?.distance}
                      </Box>

                      <Box className={styles.categoryContainer}>
                        {callDetails?.parentCategoryName} &gt;{" "}
                        {callDetails?.subCategoryName}
                      </Box>

                      {lastIntentObject?.userEditedIntent && (
                        <Box
                          display="flex"
                          gap={2}
                          alignItems="center"
                          padding="4px 8px"
                        >
                          <Eye height={20} width={20} />

                          <Typography variant="caption" color="#0f0f0f">
                            {`Intent Edited - 
                        ${
                          getFormattedFullNameAndIntials(
                            lastIntentObject.userEditedIntent?.firstName,
                            lastIntentObject.userEditedIntent?.lastName
                          ).fullName
                        }, ${moment
                              .utc(
                                lastIntentObject?.userEditedIntentDateAndTime
                              )
                              .local()
                              .format("hh:mm a")}`}
                          </Typography>
                        </Box>
                      )}
                    </FlexBox>

                    <Typography variant="body2" mt={1}>
                      {lastIntentObject?.question}
                    </Typography>

                    <Typography
                      mt={4}
                      variant="body1"
                      fontWeight={500}
                      color="grey.600"
                    >
                      Response
                    </Typography>
                    <Typography variant="body2">
                      {lastIntentMatchedAsperBotThreshold !== false ? (
                        lastIntentObject?.type === "faq" ||
                        lastIntentObject?.type === "smartPlus" ? (
                          <Typography
                            variant="body2"
                            mt={2}
                            sx={{ whiteSpace: "break-spaces" }}
                          >
                            {lastIntentObject?.answer}
                          </Typography>
                        ) : (
                          lastIntentObject?.type === "workflow" && (
                            <FlexBox columnGap={1}>
                              <FlowArrow
                                className={styles.flowArrow}
                                width={12}
                                height={12}
                              />
                              <Typography
                                variant="body2"
                                color={theme.palette.primary.main}
                              >
                                Workflow linked:{" "}
                                <Link
                                  target="_blank"
                                  to={`/workflows/${
                                    workflowList?.workFlows?.find(
                                      (item) =>
                                        item._id ===
                                        lastIntentObject?.workFlowId
                                    )?._id
                                  }`}
                                >
                                  {
                                    workflowList?.workFlows?.find(
                                      (item) =>
                                        item._id ===
                                        lastIntentObject?.workFlowId
                                    )?.name
                                  }
                                </Link>
                              </Typography>
                            </FlexBox>
                          )
                        )
                      ) : (
                        <Typography variant="body2" mt={2}>
                          No recommended solution
                        </Typography>
                      )}
                    </Typography>

                    <FlexBox mt={2}>
                      {lastIntentMatchedAsperBotThreshold ? (
                        <Button
                          className={styles.button}
                          variant="outlined"
                          sx={{
                            borderColor: theme.palette.grey[600],
                            color: theme.palette.grey[600],
                          }}
                          startIcon={<PencilSimple width={16} height={16} />}
                          onClick={() => {
                            setSelectedQuestion(lastIntentObject.mongoId);
                            setEditContainer(true);
                            setAddVariant(false);
                          }}
                        >
                          Edit Intent
                        </Button>
                      ) : (
                        <>
                          <Button
                            className={styles.button}
                            variant="outlined"
                            startIcon={
                              <img
                                src={Variation}
                                alt="misc"
                                width={16}
                                height={16}
                              />
                            }
                            onClick={() => {
                              setSelectedQuestion(lastIntentObject.mongoId);
                              setEditContainer(true);
                              setAddVariant(true);
                            }}
                          >
                            Add as Variation
                          </Button>
                          <Button
                            className={styles.button}
                            variant="outlined"
                            startIcon={<PlusCircle width={16} height={16} />}
                            onClick={() => {
                              setEditContainer(true);
                              setAddQuestion(true);
                              setSelectedQuestion(variationToAdd);
                            }}
                          >
                            Add Intent
                          </Button>
                        </>
                      )}
                    </FlexBox>
                  </>
                ) : (
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    color="#0f0f0f99"
                  >
                    Blank Call
                  </Typography>
                )}
              </Box>

              {/* <Box mt={5}>
                <Typography variant="body2" mb={5}>
                  Agent Details
                </Typography>

                <FlexBox width={"100%"} alignItems="flex-start">
                  <FlexBox width="25%" alignItems="flex-start">
                    <IconUser1 width={32} height={32} />
                    <Box>
                      <Typography variant="body2" color="grey.600">
                        Agent Name
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {callDetails?.agentName}
                        {getFormattedFullNameAndIntials(
                          callDetails?.auditUser?.firstName,
                          callDetails?.auditUser?.lastName
                        ).fullName || "N/A"}
                      </Typography>
                    </Box>
                  </FlexBox>
                  <FlexBox width="25%" alignItems="flex-start">
                    <Mail color="#21272A" width={32} height={32} />
                    <Box>
                      <Typography variant="body2" color="grey.600">
                        Email
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {callDetails?.auditUser?.email}
                      </Typography>
                    </Box>
                  </FlexBox>
                  <FlexBox width="25%" alignItems="flex-start">
                    <MessageDots width={32} height={32} />
                    <Box>
                      <FlexBox>
                        <Typography variant="body2" color="grey.600">
                          Feedback 1
                        </Typography>
                        <Tooltip title="Is their an intent match?">
                          <Info width={16} height={16} />
                        </Tooltip>
                      </FlexBox>
                      <FlexBox columnGap={1}>
                        {callDetails?.isIntentMatchedUserResponse === true && (
                          <ThumbUpGreenFilled width={16} height={16} />
                        )}
                        {callDetails?.isIntentMatchedUserResponse === false && (
                          <ThumbDownRedFilled width={16} height={16} />
                        )}
                        <Typography
                          variant="body2"
                          // color={
                          //   callDetails?.isIntentMatchedUserResponse === true
                          //     ? "tag.main"
                          //     : callDetails?.isIntentMatchedUserResponse ===
                          //         false && "error.main"
                          // }
                        >
                          {callDetails?.isIntentMatchedUserResponse === false
                            ? "Dissatisfied"
                            : callDetails?.isIntentMatchedUserResponse == null
                            ? "Unrated"
                            : "Satisfied"}
                        </Typography>
                      </FlexBox>

                      {callDetails?.intentMatchedUserResponseExplanation &&
                        (callDetails?.intentMatchedUserResponseExplanation
                          .length > 40 ? (
                          <Tooltip
                            title={
                              callDetails?.intentMatchedUserResponseExplanation
                            }
                          >
                            <Typography
                              variant="caption"
                              fontWeight={400}
                              color="#0F0F0F99"
                            >
                              Comment:{" "}
                              {callDetails?.intentMatchedUserResponseExplanation.slice(
                                0,
                                40
                              ) + "..."}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            variant="caption"
                            fontWeight={400}
                            color="#0F0F0F99"
                          >
                            Comment:{" "}
                            {callDetails?.intentMatchedUserResponseExplanation}
                          </Typography>
                        ))}
                    </Box>
                  </FlexBox>
                  <FlexBox width="25%" alignItems="flex-start">
                    <MessageDots width={32} height={32} />
                    <Box>
                      <FlexBox>
                        <Typography variant="body2" color="grey.600">
                          Feedback 2
                        </Typography>
                        <Tooltip title="Is the answer correct?">
                          <Info width={16} height={16} />
                        </Tooltip>
                      </FlexBox>
                      <FlexBox columnGap={1}>
                        {callDetails?.isAnswerCorrect === true && (
                          <ThumbUpGreenFilled width={16} height={16} />
                        )}
                        {callDetails?.isAnswerCorrect === false && (
                          <ThumbDownRedFilled width={16} height={16} />
                        )}
                        <Typography
                          variant="body2"
                          // color={
                          //   callDetails?.isAnswerCorrect === true
                          //     ? "tag.main"
                          //     : callDetails?.isAnswerCorrect === false &&
                          //       "error.main"
                          // }
                        >
                          {callDetails?.isAnswerCorrect === false
                            ? "Dissatisfied"
                            : callDetails?.isAnswerCorrect == null
                            ? "Unrated"
                            : "Satisfied"}
                        </Typography>
                      </FlexBox>

                      {callDetails?.answerCorrectnessExplanation &&
                        (callDetails?.answerCorrectnessExplanation.length >
                        40 ? (
                          <Tooltip
                            title={callDetails?.answerCorrectnessExplanation}
                          >
                            <Typography
                              variant="caption"
                              fontWeight={400}
                              color="#0F0F0F99"
                            >
                              Comment:{" "}
                              {callDetails?.answerCorrectnessExplanation.slice(
                                0,
                                40
                              ) + "..."}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            fontWeight={400}
                            color="#0F0F0F99"
                            variant="caption"
                          >
                            Comment: {callDetails?.answerCorrectnessExplanation}
                          </Typography>
                        ))}
                    </Box>
                  </FlexBox>
                </FlexBox>
              </Box> */}
            </Box>

            {!playgroundHistory && (
              <Box
                sx={{
                  width: "35%",
                  maxHeight: "calc(100vh - 350px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Box
                  sx={{
                    padding: "17px",
                    backgroundColor: theme.palette.grey[50],
                  }}
                >
                  <Typography variant="body2">Call Metadata</Typography>
                </Box>
                <Box>
                  {editedValues && editedValues?.length > 0 && (
                    <>
                      <Box
                        sx={{
                          "& > div:first-of-type": {
                            paddingRight: 8,
                            backgroundColor: theme.palette.grey[50],
                            borderRadius: "12px",
                            marginTop: "16px",
                          },
                        }}
                      >
                        <FunCollapse
                          collapsed={showCustomVariables}
                          iconPosition={"end"}
                          header={
                            <Typography
                              variant="body2"
                              sx={{ padding: "16px" }}
                            >
                              Custom variables
                            </Typography>
                          }
                        >
                          <Table className={styles.table}>
                            <TableBody>
                              {editedValues?.map((value, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      backgroundColor: theme.palette.grey[50],
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {value.key}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <FlexBox>
                                      {editingIndex === index ? (
                                        <TextField
                                          sx={{
                                            flex: 1,
                                          }}
                                          variant="standard"
                                          value={editedValues[index].value}
                                          onChange={(e) => {
                                            const newValues = [...editedValues];
                                            newValues[index].value =
                                              e.target.value;
                                            setEditedValues(newValues);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Escape") {
                                              setEditingIndex(null);
                                            }

                                            if (e.key === "Enter") {
                                              setEditingIndex(null);
                                              editVariables();
                                            }
                                          }}
                                          autoFocus
                                          inputProps={{
                                            style: {
                                              fontSize: 14,
                                              transitionDuration: 0.4,
                                              paddingLeft: 0,
                                            },
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          sx={{
                                            flex: 1,
                                            maxWidth: "20ch",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                          }}
                                          variant="body2"
                                        >
                                          {value.value !== undefined &&
                                          value.value !== null
                                            ? "" + _.isObject(value.value)
                                              ? JSON.stringify(value.value)
                                              : value.value
                                            : "--"}
                                        </Typography>
                                      )}

                                      {editingIndex === index ? (
                                        <IconButton
                                          onClick={() => {
                                            setEditingIndex(null);
                                            editVariables();
                                          }}
                                        >
                                          <Check width={16} height={16} />
                                        </IconButton>
                                      ) : (
                                        !_.isObject(value.value) && (
                                          <IconButton
                                            onClick={() =>
                                              setEditingIndex(index)
                                            }
                                          >
                                            <PencilSimple
                                              width={16}
                                              height={16}
                                              color={theme.palette.text.primary}
                                            />
                                          </IconButton>
                                        )
                                      )}
                                    </FlexBox>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </FunCollapse>
                      </Box>
                    </>
                  )}

                  {typeof variables?.system === "object" &&
                    Object.keys(variables?.system)?.length > 0 && (
                      <>
                        <Box
                          sx={{
                            "& > div:first-of-type": {
                              backgroundColor: theme.palette.grey[50],
                              borderRadius: "12px",
                              marginTop: "16px",
                              paddingRight: 8,
                            },
                          }}
                        >
                          <FunCollapse
                            iconPosition={"end"}
                            header={
                              <Typography
                                variant="body2"
                                sx={{ padding: "16px" }}
                              >
                                System variables
                              </Typography>
                            }
                          >
                            <Table className={styles.table}>
                              <TableBody>
                                {Object.entries(variables.system).map(
                                  ([key, value]) => (
                                    <TableRow key={key}>
                                      <TableCell
                                        style={{
                                          backgroundColor:
                                            theme.palette.grey[50],
                                        }}
                                      >
                                        <Typography variant="body2">
                                          system.{key}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {value !== undefined && value !== null
                                            ? "" + value
                                            : "--"}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </FunCollapse>
                        </Box>
                      </>
                    )}

                  {typeof variables?.session === "object" &&
                    Object.keys(variables?.session)?.length > 0 && (
                      <>
                        <Box
                          sx={{
                            "& > div:first-of-type": {
                              paddingRight: 8,
                              backgroundColor: theme.palette.grey[50],
                              borderRadius: "12px",
                              marginTop: "16px",
                            },
                          }}
                        >
                          <FunCollapse
                            iconPosition={"end"}
                            header={
                              <Typography
                                variant="body2"
                                sx={{ padding: "16px" }}
                              >
                                Session variables
                              </Typography>
                            }
                          >
                            <Table className={styles.table}>
                              <TableBody>
                                {Object.entries(variables.session).map(
                                  ([key, value]) => (
                                    <TableRow key={key}>
                                      <TableCell
                                        style={{
                                          backgroundColor:
                                            theme.palette.grey[50],
                                        }}
                                      >
                                        <Typography variant="body2">
                                          session.{key}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {getRenderValue(key, value)}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </FunCollapse>
                        </Box>
                      </>
                    )}
                </Box>
              </Box>
            )}
          </FlexBox>
        </Box>
      )}
      <EditContainer
        show={editContainer}
        onClose={() => {
          setEditContainer(false);
          setSelectedQuestion(null);
          setAddVariant(false);
          setAddQuestion(false);
        }}
        question={selectedQuestion}
        onChangeIntent={handleonChangeIntent}
        questionVariantToAdd={addVariant ? variationToAdd : null}
        addQuestion={addQuestion}
      />
    </>
  );
};

export default CallDetail;
