import { Box, Tooltip, Typography } from "@mui/material";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import CallDurationCategories from "./components/CallDurationCategories";
import AuditedCallOutcomes from "./components/AuditedCallOutcomes";
import { getAnalytics } from "services";
import { useEffect, useState } from "react";
import { Info, Loader } from "react-feather";
import AHTtrends from "./components/AHTtrends";
import CallVolume from "./components/CallVolume";
import moment from "moment";
import EmptyState from "components/EmptyState";
import { useSkillrToast } from "context/toast";
import theme from "theme";

const Inbound = ({ filters }) => {
  const [inboundData, setInboundData] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    // Set initial dates
    const newStartDate =
      filters.startDate && filters.endDate
        ? moment(filters.startDate)
        : moment().subtract(30, "days").startOf("day");

    const newEndDate =
      filters.startDate && filters.endDate
        ? moment(filters.endDate)
        : moment().endOf("day");

    // Only make the API call if dates have actually changed
    if (!startDate?.isSame(newStartDate) || !endDate?.isSame(newEndDate)) {
      setStartDate(newStartDate);
      setEndDate(newEndDate);

      // Make API call with the new dates
      const params = new URLSearchParams({
        startDate: newStartDate,
        endDate: newEndDate,
      });

      setLoading(true);
      getAnalytics("inbound", params)
        .then((response) => {
          if (response) {
            setInboundData(response.analyticData);
          }
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error?.message ?? "Something went wrong";
          showSkillrToast(errorMessage, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filters]);

  const renderBlankCalls = () => {
    const blankCalls = inboundData?.countByMultipleStatuses?.find(
      (item) => item.status === "blank"
    )?.count;

    return isNaN(blankCalls) || !blankCalls
      ? "-"
      : `${Math.round(
          (blankCalls / inboundData?.totalCalls) * 100
        )}% (${blankCalls})`;
  };

  const renderTransferredCalls = () => {
    const transferredCalls = inboundData?.countByMultipleStatuses?.find(
      (item) => item.status === "agentHandOff"
    )?.count;

    return isNaN(transferredCalls) || !transferredCalls
      ? "-"
      : `${Math.round(
          (transferredCalls / inboundData?.totalCalls) * 100
        )}% (${transferredCalls})`;
  };

  return (
    <>
      {!loading ? (
        inboundData && inboundData.totalCalls ? (
          <>
            <FlexBox mt={4}>
              <Box className={styles.statContainer}>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Total Calls
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {inboundData?.totalCalls || "-"}
                </Typography>
              </Box>
              <Box className={styles.statContainer}>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Average Daily Call Count
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {Math.round(
                    inboundData?.totalCalls /
                      (moment(endDate).diff(moment(startDate), "days") === 0
                        ? 1
                        : moment(endDate).diff(moment(startDate), "days"))
                  )}
                </Typography>
              </Box>
              <Box className={styles.statContainer}>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Average Handling Time{" "}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {inboundData?.averageHandlingTime
                    ? `${Math.round(inboundData?.averageHandlingTime)} s`
                    : "-"}
                </Typography>
              </Box>
              <Box className={styles.statContainer}>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Blank Calls{" "}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {renderBlankCalls()}
                </Typography>
              </Box>
              <Box className={styles.statContainer}>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Calls Transferred{" "}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {renderTransferredCalls()}
                </Typography>
              </Box>
            </FlexBox>

            <Box mt={4} className={styles.statContainer}>
              <Typography variant="body1" fontWeight={700}>
                AHT Distribution
              </Typography>
              <Typography variant="body2" color="grey.600" mb={2}>
                Total Calls: {inboundData?.totalCalls}
              </Typography>

              <CallDurationCategories
                data={inboundData?.callDurationCategories}
              />
            </Box>

            <FlexBox alignItems="flex-stretch">
              <Box
                mt={4}
                className={styles.statContainer}
                sx={{ width: "50%" }}
              >
                <Typography variant="body1" fontWeight={700}>
                  Audited Call Outcomes
                </Typography>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Total Audited Calls:{" "}
                  {inboundData?.auditedCallOutcomes?.totalAuditedCalls}
                </Typography>

                {inboundData?.auditedCallOutcomes?.totalAuditedCalls ? (
                  <AuditedCallOutcomes
                    data={inboundData?.auditedCallOutcomes?.result}
                  />
                ) : (
                  <EmptyState title="No data available" />
                )}
              </Box>

              <Box
                mt={4}
                className={styles.statContainer}
                sx={{ width: "50%", minHeight: "50vh" }}
              >
                <FlexBox mb={4}>
                  <Typography variant="body1" fontWeight={700}>
                    Popular Intents
                  </Typography>
                  <Tooltip title="This list represents popular intents, taking into account their variations.">
                    <Info width={14} height={14} />
                  </Tooltip>
                </FlexBox>

                {inboundData?.popularIntents &&
                inboundData?.popularIntents.length > 0 ? (
                  inboundData?.popularIntents.map((intent) => (
                    <FlexBox
                      mb={4}
                      key={intent?.question?._id || intent.count}
                      justifyContent="space-between"
                    >
                      <Typography variant="body2" color="grey.400">
                        {intent?.question?.question || "Unknown Intent"}
                      </Typography>
                      <Typography variant="body2">
                        {intent.percentage
                          ? `${intent.percentage.toFixed(1)}%`
                          : "-"}
                      </Typography>
                    </FlexBox>
                  ))
                ) : (
                  <EmptyState title="No data available" />
                )}
              </Box>
            </FlexBox>

            <FlexBox alignItems="flex-stretch">
              <Box
                mt={4}
                className={styles.statContainer}
                sx={{ width: "50%", overflow: "hidden" }}
              >
                <Typography variant="body1" fontWeight={700}>
                  AHT Trends
                </Typography>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Total Calls: {inboundData?.totalCalls}
                </Typography>

                {inboundData?.AHTTrends.some(
                  (item) => item.averageHandlingTime > 0
                ) ? (
                  <AHTtrends
                    data={inboundData?.AHTTrends}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ) : (
                  <EmptyState title="No data available" />
                )}
              </Box>
              <Box
                mt={4}
                className={styles.statContainer}
                sx={{ width: "50%", overflow: "hidden" }}
              >
                <Typography variant="body1" fontWeight={700}>
                  Call Volume for Last 7 Days
                </Typography>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Total Calls:{" "}
                  {inboundData?.callVolume?.SevenDaysCallVolumeData}
                </Typography>

                {inboundData?.callVolume?.result.some(
                  (item) => item.totalCalls > 0
                ) ? (
                  <CallVolume
                    data={inboundData?.callVolume?.result}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ) : (
                  <EmptyState title="No data available" />
                )}
              </Box>
            </FlexBox>
          </>
        ) : (
          <FlexBox sx={{ minHeight: 300 }}>
            <EmptyState title="No data available" />
          </FlexBox>
        )
      ) : (
        <FlexBox sx={{ minHeight: 300 }} justifyContent="center">
          <Loader color={theme.palette.grey[600]} size={24} />
        </FlexBox>
      )}
    </>
  );
};

export default Inbound;
