import {
  Box,
  Dialog,
  Grow,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { FlowArrow } from "components/newSVG";
import moment from "moment";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { Link } from "react-router-dom";
import theme from "theme";

const styles = {
  dialogPaper: {
    width: 752,
    borderRadius: "20px",
    overflowY: "auto",
    position: "relative",
    padding: 6,
    maxHeight: "60vh",
    minHeight: "40vh",
  },

  tabs: {
    borderBottom: `1px solid ${theme.palette.borderColor.light}`,
    "& button": {
      fontSize: "12px",
      fontWeight: 500,
      textTransform: "capitalize",
      padding: 0,
      whiteSpace: "pre-wrap",
      fontFamily: "DM Sans, sans-serif",
      minWidth: 0,
      transitionDuration: "0.4s",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      gap: 16,
    },
    "& .Mui-selected": {
      color: "#0f0f0f !important",
    },
  },
  label: {
    padding: "4px 8px",
    borderRadius: "56px",
    width: "max-content",
    fontSize: "10px",
    fontWeight: 500,
    gap: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  statusCircle: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#a01e1e",
  },
};

const ErrorLogDetailModal = ({ open, onClose, errorLog }) => {
  const [tabValue, setTabValue] = useState("summary");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setTabValue("summary");
  }, [errorLog]);

  const getParsedRequest = (errorLog) => {
    try {
      return (
        <pre
          style={{
            backgroundColor: "#f4f4f4",
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            maxWidth: "100%",
            whiteSpace: "pre",
            fontSize: "14px",
          }}
        >
          {JSON.stringify(JSON.parse(errorLog?.request), null, 2)}
        </pre>
      );
    } catch (e) {
      return (
        <pre
          style={{
            backgroundColor: "#f4f4f4",
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            maxWidth: "100%",
            whiteSpace: "pre",
            fontSize: "14px",
          }}
        >
          {errorLog?.request}
        </pre>
      );
    }
  };

  const getParsedErrorStack = (errorLog) => {
    try {
      return (
        <pre
          style={{
            backgroundColor: "#f4f4f4",
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            maxWidth: "100%",
            whiteSpace: "pre",
            fontSize: "14px",
          }}
        >
          {JSON.parse(errorLog?.response)?.stack}
        </pre>
      );
    } catch (e) {
      return (
        <pre
          style={{
            backgroundColor: "#f4f4f4",
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            maxWidth: "100%",
            whiteSpace: "pre",
            fontSize: "14px",
          }}
        >
          {errorLog?.response}
        </pre>
      );
    }
  };

  const getParsedErrorMessage = (errorLog) => {
    try {
      return JSON.parse(errorLog?.response)?.message;
    } catch (e) {
      return "";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        sx: styles.dialogPaper,
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 20, top: 20 }}
      >
        <X size={20} />
      </IconButton>

      <Typography
        variant="h3"
        fontWeight={500}
        color="#0f0f0f"
        textAlign="center"
      >
        Log Detail
      </Typography>

      <div
        style={{
          textAlign: "center",
          marginBottom: 16,
        }}
      >
        {errorLog?.type === "api" || errorLog?.type === "webhook" ? (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },

              ...styles.tabs,
            }}
          >
            <Tab label="Summary" value="summary" />
            <Tab label="Request" value="request" />
            <Tab label="Response" value="response" />
          </Tabs>
        ) : (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },

              ...styles.tabs,
            }}
          >
            <Tab label="Summary" value="summary" />
            <Tab label="Script" value="script" />
            <Tab label="Error" value="error" />
          </Tabs>
        )}
      </div>
      <Box
        sx={{
          p: "16px 20px",
          border: `1px solid ${theme.palette.borderColor.light}`,
          borderRadius: 3,
          overflowX: "hidden",
        }}
      >
        {tabValue === "summary" && (
          <Box>
            <FlexBox mb={3}>
              <Typography variant="body2" fontWeight={500}>
                Call ID:
              </Typography>
              <Link
                to={`/calls/${errorLog?.callId}?voiceCallId=${errorLog?.voiceCallId}`}
              >
                <Typography variant="body2" color="primary">
                  {errorLog?.callId}
                </Typography>
              </Link>
            </FlexBox>
            {errorLog?.url && (
              <FlexBox mb={3}>
                <Typography variant="body2" fontWeight={500}>
                  URL:
                </Typography>
                <Typography variant="body2">{errorLog?.url}</Typography>
              </FlexBox>
            )}
            <FlexBox mb={3}>
              <Typography variant="body2" fontWeight={500}>
                Status:
              </Typography>
              <FlexBox
                sx={{
                  backgroundColor: "#FEEBEF",
                  color: "#A01E1E",
                  ...styles.label,
                }}
              >
                <Box sx={styles.statusCircle} />
                {errorLog?.status === "error" ? "ERR" : errorLog?.status}
              </FlexBox>
            </FlexBox>
            <FlexBox mb={3}>
              <Typography variant="body2" fontWeight={500}>
                Workflow:
              </Typography>
              <FlexBox
                sx={{
                  backgroundColor: "#E3F2FF",
                  color: theme.palette.primary.main,
                  ...styles.label,
                }}
                onClick={() => {
                  window.open(
                    errorLog?.location === "integration"
                      ? `/integrations/webhook`
                      : `/workflows/${errorLog?.workflow}`
                  );
                }}
              >
                <FlowArrow width={14} height={14} />
                {errorLog?.location.charAt(0).toUpperCase() +
                  errorLog?.location.slice(1)}
                : {errorLog?.node}
              </FlexBox>
            </FlexBox>
            <FlexBox mb={3}>
              <Typography variant="body2" fontWeight={500}>
                Latency:
              </Typography>
              <Typography variant="body2">{errorLog?.latency}</Typography>
            </FlexBox>
            <FlexBox mb={3}>
              <Typography variant="body2" fontWeight={500}>
                Date:
              </Typography>
              <Typography variant="body2">
                {moment(errorLog?.createdAt).format("DD MMM, hh:mm A")}
              </Typography>
            </FlexBox>
          </Box>
        )}

        {tabValue === "request" && <Box>{getParsedRequest(errorLog)}</Box>}
        {tabValue === "response" && (
          <Box>
            <FlexBox
              sx={{
                margin: "-16px -23px 16px -23px",
                backgroundColor: theme.palette.grey[50],
                padding: "16px 24px",
                borderRadius: "12px 12px 0 0",
              }}
            >
              <FlexBox
                sx={{
                  backgroundColor: "#FEEBEF",
                  color: "#A01E1E",
                  ...styles.label,
                }}
              >
                <Box sx={styles.statusCircle} />
                {errorLog?.status === "error" ? "ERR" : errorLog?.status}
              </FlexBox>
              <Typography variant="body2">
                {getParsedErrorMessage(errorLog)}
              </Typography>
            </FlexBox>
            {getParsedErrorStack(errorLog)}
          </Box>
        )}

        {tabValue === "script" && <Box>{getParsedRequest(errorLog)}</Box>}
        {tabValue === "error" && <Box>{getParsedErrorStack(errorLog)}</Box>}
      </Box>
    </Dialog>
  );
};

export default ErrorLogDetailModal;
