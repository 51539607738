import FlexBox from "components/FlexBox";
import FundamentoTable from "components/FundamentoTable";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import theme from "theme";

const {
  Dialog,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
} = require("@mui/material");

const OutStandingModal = ({ open, onClose, campaignDetails }) => {
  const handleClose = () => {
    onClose();
  };

  const columns = [
    {
      field: "callTypes",
      header: "Call Type",
      render: (row) => {
        console.log("row", row);
        return (
          <Box display="flex" alignItems="center" gap={4}>
            {["Fresh Outstanding Calls", "Redial Outstanding Calls"].includes(
              row?.callType
            ) ? (
              <Typography variant={"body2"} fontWeight={"500"}>
                {row?.callType}
              </Typography>
            ) : (
              row?.callType
            )}
          </Box>
        );
      },
    },
    {
      field: "description",
      header: "Description",
      render: (row) => {
        return row?.description;
      },
    },
    {
      field: "count",
      header: "Count",
      render: (row) => {
        return row?.count;
      },
    },
  ];
  const rows = [
    "Fresh Outstanding Calls",
    "Redial Outstanding Calls",
    "Not Reachable",
    "No Answer",
    "Busy",
  ];
  const data = campaignDetails?.statusCount;
  const alreadypushed = {};
  const finalData = [];
  for (let row of rows) {
    let callType = "";
    let description = "";
    let count = 0;
    if (row === "Fresh Outstanding Calls") {
      callType = "Fresh Outstanding Calls";
      count = (data.pending ?? 0) + (data.processing ?? 0);
      description = "Initial call attempt";
    } else if (row === "Redial Outstanding Calls") {
      callType = "Redial Outstanding Calls";
      count = data?.RedialOutstading ?? 0;
      description = "Follow-up attempts";
    } else if (row === "Not Reachable") {
      callType = "Not Reachable";
      count = data?.networkError ?? 0;
      description = "Call could not connect";
    } else if (row === "No Answer") {
      if (!alreadypushed.callType) {
        callType = row;
        count =
          (data.noAnswered ?? 0) + (data.busy ?? 0) + (data.rejected ?? 0);
        description = "Ringed but unanswered";
        alreadypushed[callType] = callType;
      }
    } else if (row === "Busy") {
      callType = "Busy";
      count = data.busy ?? 0;
      description = "Line was busy";
    }
    finalData.push({
      callType,
      count,
      description,
    });
  }
  console.log("data", data, campaignDetails?.statusCount);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 10,
          boxShadow: 10,
          width: 660,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>

      <Box>
        <Typography textAlign={"center"} variant="h4" fontWeight={500}>
          Outstanding Call
        </Typography>
        <Typography
          textAlign={"center"}
          variant="body2"
          mt={4}
          color={theme.palette.grey[600]}
        >
          Outstanding calls include new numbers that haven't yet been contacted
          and numbers pending their scheduled redial attempts.
        </Typography>
      </Box>

      <>
        <Box
          sx={{
            "&> div": { paddingBottom: 4 },
            "& th": { backgroundColor: `${theme.palette.grey[50]} !important` },
            mt: 8,
          }}
        >
          <FundamentoTable
            columns={columns}
            data={finalData}
            pagination={false}
            passRowToRender={true}
            // className={styles.table}
          />
        </Box>
      </>
    </Dialog>
  );
};

export default OutStandingModal;
