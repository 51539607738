import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import styles from "./index.module.css";
import { Outlet } from "react-router";
import { Box, Divider } from "@mui/material";
import theme from "theme";
import { useNavigate, useLocation } from "react-router-dom";
import FlexBox from "components/FlexBox";
import { LockSimple } from "components/newSVG";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

const KnowledgeBase = () => {
  const tabs = [
    "intents",
    "dictionary",
    <FlexBox sx={{ pointerEvents: "none", gap: 1, cursor: "default" }}>
      <LockSimple /> ontology
    </FlexBox>,
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const [, parentPath, childPath] = location.pathname.split("/");
  const currentRoute = useLocation().pathname;
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [advancedModeValue, setAdvancedModeValue] = useState(false);

  const handleSearchChange = (value) => {
    setSearchBoxValue(value);
  };

  const searchBarCondition = () => {
    const [, parentPath, childPath] = location.pathname.split("/");
    if (!childPath) {
      return true;
    } else if (childPath.includes("intents")) {
      return "intent";
    } else if (childPath.includes("dictionary")) {
      return "dictionary";
    }

    return false;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedSearch = useCallback(_.debounce(handleSearchChange, 1500), []);

  const handleTabChange = (value) => {
    navigate(value);
  };

  const handleDictionarySearch = (value) => {
    setSearchBoxValue(value);
  };

  const handleClearSearch = () => {
    setSearchBoxValue("");
    // If you're using the optimized search, you might want to call it here as well
    optimizedSearch("");
  };

  return (
    <PageWrapper className={styles.container}>
      <Box
        sx={{
          "& [role='tab']:last-of-type ": {
            pointerEvents: "none",
            cursor: "default",
          },
        }}
      >
        <Header
          title="Knowledge Base"
          subTitle="Centralize the knowledge on which your virtual agent is trained on."
          tabs={tabs}
          defaultActiveTab={childPath ? childPath : "intents"}
          onTabChange={handleTabChange}
          searchBox={true}
          onSearch={
            searchBarCondition() === "intent"
              ? optimizedSearch
              : handleDictionarySearch
          }
          defaultSearchValue={searchBoxValue}
          onClearSearch={handleClearSearch}
          showAdvancedMode={searchBarCondition() === "intent" ? true : false}
          advancedModeValue={advancedModeValue}
          changeAdvancedModeValue={(value) => setAdvancedModeValue(value)}
        />
      </Box>

      {/* <Divider sx={{ backgroundColor: theme.palette.borderColor.light }} /> */}
      <Outlet context={{ searchBoxValue, advancedModeValue }} />
    </PageWrapper>
  );
};

export default KnowledgeBase;
