import {
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomStepper from "pages/Campaign/components/CustomStepper";
import { useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronLeft, Loader, X } from "react-feather";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import { Controller, useForm } from "react-hook-form";
import {
  createExportEvent,
  editExportEvent,
  getAllVariables,
  getCampaigns,
} from "services";
import { useSkillrToast } from "context/toast";
import { useUserContext } from "context/user";
import theme from "theme";

const CreateWebhookEventModal = ({
  open,
  onClose,
  integrations,
  onConfirm,
  previousData,
}) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const { allBot } = useUserContext();
  const [variables, setVariables] = useState([]);
  const selectAllRef = useRef(null);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      inbound: previousData ? false : true,
      outbound: false,
      eventName: "",
      systemVariables: true,
      sessionVariables: true,
      customVariables: true,
      selectAllVariables: true,
      system: {},
      session: {},
    },
  });
  const fields = [
    {
      value: "callId",
      label: "Call ID",
    },
    {
      value: "phoneNumber",
      label: "Phone Number",
    },
    {
      value: "callType",
      label: "Call Type",
    },
    {
      value: "parentCategory",
      label: "Category",
    },
    {
      value: "subCategory",
      label: "Sub Category",
    },
    {
      value: "callDuration",
      label: "Duration",
    },
    // {
    //   value: "transcript",
    //   label: "Transcript",
    // },
    // {
    //   value: "audioRecording",
    //   label: "Audio Recording",
    // },
    // {
    //   value: "bot",
    //   label: "Bot",
    // },
    {
      value: "status",
      label: "Audit Status",
    },
    // {
    //   value: "intentIdentified",
    //   label: "Intent Identified",
    // },
    // {
    //   value: "agentFeedback",
    //   label: "Agent Feedback",
    // },
    {
      value: "isIntentMatchedUserResponse",
      label: "Feedback 1",
    },
    {
      value: "isAnswerCorrect",
      label: "Feedback 2",
    },
    {
      value: "startTime",
      label: "Start Time",
    },
    {
      value: "endTime",
      label: "End Time",
    },
    {
      value: "lastIntentObject",
      label: "Last Intent Object",
    },
    {
      value: "lastQuestionAsked",
      label: "Last Question Asked",
    },
  ];

  const handleClose = () => {
    setStep(1);
    const obj = {};
    fields.forEach((item) => (obj[item.value] = true));
    reset({
      eventName: "",
      selectedWebhook: null,
      inbound: true,
      outbound: false,
      systemVariables: true,
      sessionVariables: true,
      customVariables: true,
      selectAllVariables: true,
      system: {},
      session: {},
      custom: {},
      bots: {},
      campaigns: {},
      ...obj,
    });
    setVariables([]);
    setAllCampaigns([]);
    onClose();
  };

  const getVariables = async () => {
    try {
      const response = await getAllVariables();
      setVariables(response);
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getAllCampaigns = async () => {
    try {
      const queryParams = new URLSearchParams();
      // queryParams.set("offset", (currentPage - 1) * countPerPage);
      // queryParams.set("count", countPerPage);
      queryParams.set("active", true);
      // queryParams.set("status", "active");
      const response = await getCampaigns(queryParams.toString());
      setAllCampaigns(response.data);
    } catch (error) {
      console.log(error);
      const errorMessage = error?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateSystemVariables = (checked) => {
    const currentValues = getValues();
    if (currentValues.system && currentValues.system.system) {
      const updatedSystemVariables = Object.keys(
        currentValues.system.system
      ).reduce((acc, variable) => {
        acc[variable] = checked;
        return acc;
      }, {});
      setValue("system.system", updatedSystemVariables);
    }
    updateSelectAllVariables();
  };

  const updateSessionVariables = (checked) => {
    const currentValues = getValues();
    if (currentValues.session && currentValues.session.session) {
      const updatedSessionVariables = Object.keys(
        currentValues.session.session
      ).reduce((acc, variable) => {
        acc[variable] = checked;
        return acc;
      }, {});
      setValue("session.session", updatedSessionVariables);
    }
    updateSelectAllVariables();
  };

  const updateCustomVariables = (checked) => {
    const currentValues = getValues();
    if (currentValues.custom) {
      const updatedCustomVariables = Object.keys(currentValues.custom).reduce(
        (acc, variable) => {
          if (typeof currentValues.custom[variable] === "boolean") {
            acc[variable] = checked;
          } else if (typeof currentValues.custom[variable] === "object") {
            acc[variable] = Object.keys(currentValues.custom[variable]).reduce(
              (nestedAcc, nestedVar) => {
                nestedAcc[nestedVar] = checked;
                return nestedAcc;
              },
              {}
            );
          }
          return acc;
        },
        {}
      );
      setValue("custom", updatedCustomVariables);
    }
    updateSelectAllVariables();
  };

  const updateSelectAllVariables = () => {
    const systemVariables = watch("systemVariables");
    const sessionVariables = watch("sessionVariables");
    const customVariables = watch("customVariables");

    const allSelected = systemVariables && sessionVariables && customVariables;
    const someSelected = systemVariables || sessionVariables || customVariables;

    setValue("selectAllVariables", allSelected);

    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = someSelected && !allSelected;
    }
  };

  const handleSelectAllChange = (checked) => {
    setValue("selectAllVariables", checked);
    setValue("systemVariables", checked);
    setValue("sessionVariables", checked);
    setValue("customVariables", checked);
    updateSystemVariables(checked);
    updateSessionVariables(checked);
    updateCustomVariables(checked);

    if (Array.isArray(variables)) {
      variables.forEach((variable) => {
        setValue(`${variable.type}.${variable.variable}`, checked);
      });
    }

    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = false;
    }
  };

  const handleSystemVariablesChange = (checked) => {
    setValue("systemVariables", checked);
    updateSystemVariables(checked);
  };

  const handleSessionVariablesChange = (checked) => {
    setValue("sessionVariables", checked);
    updateSessionVariables(checked);
  };

  const handleCustomVariablesChange = (checked) => {
    setValue("customVariables", checked);
    updateCustomVariables(checked);
  };

  const handleIndividualVariableChange = (category, variable, checked) => {
    setValue(`${variable}`, checked);
    const anyCategoryVariableChecked = variables
      .filter((v) => v.type === category)
      .some((v) => watch(`${category}.${v.variable}`));

    // Update the category switch
    setValue(`${category}Variables`, anyCategoryVariableChecked);

    updateSelectAllVariables();
  };

  const renderVariables = (variables) => {
    const groupedVariables = variables?.reduce((acc, variable) => {
      const { type } = variable;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(variable);
      return acc;
    }, {});

    return (
      <Box>
        {Object.entries(groupedVariables).map(([type, vars]) => (
          <Box key={type} mt={2}>
            <FlexBox justifyContent="space-between">
              <Typography variant="body1" fontWeight={500}>
                {type.charAt(0).toUpperCase() + type.slice(1)} Variables
              </Typography>

              <Controller
                name={`${type}Variables`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      type === "system" &&
                        handleSystemVariablesChange(e.target.checked);
                      type === "session" &&
                        handleSessionVariablesChange(e.target.checked);
                      type === "custom" &&
                        handleCustomVariablesChange(e.target.checked);
                    }}
                    color="primary"
                  />
                )}
              />
            </FlexBox>

            <FlexBox>
              <Typography variant="body1" fontWeight={500}></Typography>
            </FlexBox>
            <Box sx={{ columnCount: 2 }}>
              {vars.map((variable, index) => (
                <FlexBox
                  columnGap={0}
                  key={index}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Controller
                    name={`${type}.${variable.variable}`}
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          handleIndividualVariableChange(
                            type,
                            variable.variable,
                            e.target.checked
                          );
                        }}
                      />
                    )}
                  />
                  <Typography variant="body2">{variable.variable}</Typography>
                </FlexBox>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const handleSave = async () => {
    let data = getValues();
    // const selectiveFields = [
    //   ...fields
    //     .filter((field) => data[field.value])
    //     .map((field) => field.value),
    // ];

    let variableMappings = {};

    fields.forEach((field) => {
      if (data[field.value]) {
        variableMappings[field.label] = `{{${field.value}}}`;
      }
    });

    // Handle system variables
    if (data.systemVariables) {
      data?.system?.system &&
        Object.keys(data.system.system).forEach((variable) => {
          if (data.system.system[variable]) {
            variableMappings[variable] = `{{system.${variable}}}`;
          }
        });
      // }
    }

    // Handle session variables
    if (data.sessionVariables) {
      data?.session?.session &&
        Object.keys(data.session.session).forEach((variable) => {
          if (data.session.session[variable]) {
            variableMappings[variable] = `{{session.${variable}}}`;
          }
        });
      // }
    }

    // Handle custom variables
    if (data.customVariables) {
      Object.keys(data.custom).forEach((variable) => {
        if (data.custom[variable] === true) {
          variableMappings[variable] = `{{${variable}}}`;
        } else if (!["session", "system"].includes(variable)) {
          variableMappings[variable] = `{{${variable}}}`;
        } else if (typeof data.custom[variable] === "object") {
          // Handle nested objects like 'session' in custom
          Object.keys(data.custom[variable]).forEach((nestedVar) => {
            if (data.custom[variable][nestedVar]) {
              variableMappings[
                `${variable}.${nestedVar}`
              ] = `{{${variable}.${nestedVar}}}`;
            }
          });
        }
      });
    }

    let callType = [];
    if (data.inbound) {
      callType.push("inbound");
      if (!data.bots || !Object.values(data?.bots).some((item) => item)) {
        showSkillrToast("Please select at least one bot", "error");
        return;
      }
    }
    if (data.outbound) {
      callType.push("outbound");
      if (
        !data.campaigns ||
        !Object.values(data?.campaigns).some((item) => item)
      ) {
        showSkillrToast("Please select at least one campaign", "error");
        return;
      }
    }

    if (callType.length === 0) {
      showSkillrToast("Please select at least one call type", "error");
      return;
    }

    try {
      const response = previousData
        ? await editExportEvent(previousData?._id, {
            name: data.eventName,
            type: "webhook",
            integration: data.selectedWebhook._id,
            // selectiveFields: selectiveFields.join(","),
            variableMappings,
            bots:
              (data?.bots &&
                data.inbound &&
                Object.keys(data.bots).filter((bot) => data.bots[bot])) ||
              [],
            campaigns:
              (data?.campaigns &&
                data.outbound &&
                Object.keys(data?.campaigns)?.filter(
                  (campaign) => data.campaigns[campaign]
                )) ||
              [],
            callType,
          })
        : await createExportEvent({
            name: data.eventName,
            type: "webhook",
            integration: data.selectedWebhook._id,
            // selectiveFields: selectiveFields.join(","),
            variableMappings,
            bots:
              (data?.bots &&
                data?.inbound &&
                Object.keys(data.bots).filter((bot) => data.bots[bot])) ||
              [],
            campaigns:
              (data?.campaigns &&
                data?.outbound &&
                Object.keys(data?.campaigns)?.filter(
                  (campaign) => data.campaigns[campaign]
                )) ||
              [],
            callType,
          });
      if (response) {
        showSkillrToast(
          `Event ${previousData ? "updated" : "created"} successfully`,
          "success"
        );
        onConfirm();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }

    // handleExport({
    //   selectiveFields: selectiveFields.join(","),
    // });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formValues = getValues();

      // Check if any system variables are selected
      const hasSystemVariables = Object.values(
        formValues.system?.system || {}
      ).some((value) => value === true);

      // Check if any session variables are selected
      const hasSessionVariables = Object.values(
        formValues.session?.session || {}
      ).some((value) => value === true);

      // Check if any custom variables are selected
      const hasCustomVariables = Object.entries(formValues.custom || {}).some(
        ([key, value]) => {
          if (typeof value === "boolean") {
            return value === true;
          }
          if (typeof value === "object") {
            return Object.values(value).some((v) => v === true);
          }
          return false;
        }
      );

      if (!hasSystemVariables && !hasSessionVariables && !hasCustomVariables) {
        setError("selectAllVariables", {
          message: "Please select at least one variable",
        });
        showSkillrToast("Please select at least one variable", "error");
        return;
      }

      handleSubmit(async (data) => {
        if (Object.keys(errors).length === 0) {
          setStep(2);
        }
      })();
    } else {
      handleSave();
    }
  };

  useEffect(() => {
    if (open) {
      getAllCampaigns();
      getVariables();
    }
  }, [open]);

  const removeCurly = (str) => str.replace("{{", "").replace("}}", "");
  useEffect(() => {
    if (previousData) {
      const webhookData = integrations.find(
        (item) => item._id === previousData?.integration
      );
      const fieldsLabel = fields.map((item) => item.value);
      const checkedVar = Object.entries(previousData.variableMappings).map(
        (item) => removeCurly(item[1])
      );
      const formObj = {
        eventName: previousData.name,
        selectedWebhook: webhookData,
      };
      for (let variable of variables) {
        const varName = variable.variable;
        if (!checkedVar.includes(varName)) {
          if (variable.type === "system") {
            formObj[`system.${varName}`] = false;
          } else if (variable.type === "session") {
            formObj[`session.${varName}`] = false;
          } else {
            if (!fieldsLabel.includes(varName)) {
              formObj[`custom.${varName}`] = false;
            }
          }
        } else {
          if (variable.type === "system") formObj["systemVariables"] = true;
          if (variable.type === "session") formObj["sessionVariables"] = true;
          if (variable.type === "custom") formObj["customVariables"] = true;
        }
      }
      fieldsLabel.forEach((item) => {
        if (!checkedVar.includes(item)) {
          formObj[item] = false;
        }
      });
      if (previousData.callType.length) {
        previousData.callType.forEach((callType) => (formObj[callType] = true));
      }
      const campaigns = {};
      const bots = {};
      allBot?.forEach((bot) => {
        if (
          previousData?.bots?.length &&
          !previousData?.bots?.includes(bot._id)
        ) {
          bots[`bots.${bot._id}`] = false;
        }
      });

      allCampaigns?.forEach((camp) => {
        if (
          previousData?.campaigns?.length &&
          !previousData?.campaigns?.includes(camp._id)
        ) {
          campaigns[`campaigns.${camp._id}`] = false;
        }
      });
      reset({ ...formObj, ...bots, ...campaigns });
    }
  }, [previousData, integrations, variables, allCampaigns, allBot]);

  useEffect(() => {
    updateSelectAllVariables();
  }, [
    watch("systemVariables"),
    watch("sessionVariables"),
    watch("customVariables"),
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 6,
          boxShadow: 10,
          width: 800,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>

      <Box>
        <Typography mb={8} variant="h4" fontWeight={500} textAlign="center">
          {previousData ? "Edit Event" : "Create Event"}
        </Typography>
      </Box>

      <Box className={styles.stepper}>
        <CustomStepper
          step={step - 1}
          steps={["Event Details", "Assign Event"]}
        />
      </Box>

      {step === 1 && (
        <>
          <Controller
            name="eventName"
            control={control}
            rules={{
              required: "Event name is required",
              maxLength: {
                value: 30,
                message: "Name should be less than 30 characters",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Enter event name"
                variant="outlined"
                fullWidth
                sx={{ borderRadius: "12px" }}
                error={errors?.eventName}
                helperText={errors?.eventName?.message}
              />
            )}
          />

          <Controller
            name="selectedWebhook"
            control={control}
            rules={{ required: "Webhook is required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                IconComponent={ChevronDown}
                sx={{
                  borderRadius: 4,
                  "& li": {
                    fontSize: 12,
                  },
                  mt: 4,
                }}
                fullWidth
                className={styles.select}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: theme.palette.grey[50],
                    },
                  },
                }}
                displayEmpty
                renderValue={(value) => {
                  if (!value) {
                    return (
                      <Typography
                        variant="caption"
                        // color={theme.palette.grey[800]}
                        color="#919191"
                      >
                        Select Webhook
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        {value.name}
                      </Typography>
                    );
                  }
                }}
              >
                {loading ? (
                  <MenuItem>
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                      textAlign={"center"}
                    >
                      <Loader
                        width={12}
                        height={12}
                        color={theme.palette.grey[600]}
                      />
                    </Typography>
                  </MenuItem>
                ) : (
                  integrations?.map(
                    (integration) =>
                      integration.type === "webhook" && (
                        <MenuItem key={integration._id} value={integration}>
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            {integration.name}
                          </Typography>
                        </MenuItem>
                      )
                  )
                )}
              </Select>
            )}
          />
          {errors?.selectedWebhook && (
            <Typography color="error" variant="caption" mt={1}>
              {errors?.selectedWebhook?.message}
            </Typography>
          )}

          <Box className={styles.contentContainer} mt={6}>
            <Typography variant="body1" fontWeight={500}>
              Call Details
            </Typography>

            <Box mt={4}>
              {fields.map((field, index) => (
                <Box key={index} mb={index === fields.length - 1 ? 0 : 2}>
                  <FlexBox alignItems="center" justifyContent="space-between">
                    <Typography
                      variant="caption"
                      color={theme.palette.text.primary}
                      fontWeight={400}
                    >
                      {field.label}
                    </Typography>

                    <Controller
                      name={field.value}
                      control={control}
                      defaultValue={true}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          disabled={index < 2}
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  </FlexBox>
                </Box>
              ))}
            </Box>
          </Box>

          <Box className={styles.contentContainer} mt={4}>
            <Typography variant="body1" fontWeight={500} mb={4}>
              Call Metadata
            </Typography>

            <Typography variant="body2">
              By default all call metadata is selected for export. To customise
              click on show more.
            </Typography>

            <Box my={4}>
              <FlexBox
                columnGap={0}
                component="label"
                sx={{ cursor: "pointer" }}
              >
                <Controller
                  name="selectAllVariables"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      indeterminate={selectAllRef.current?.indeterminate}
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        handleSelectAllChange(e.target.checked);
                      }}
                      inputRef={selectAllRef}
                    />
                  )}
                />
                <Typography variant="body2">Select / Deselect All</Typography>
              </FlexBox>
            </Box>

            {/* <FlexBox justifyContent="space-between">
            <Typography variant="body1" fontWeight={500}>
              System Variables
            </Typography>

            <Controller
              name="systemVariables"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleSystemVariablesChange(e.target.checked);
                  }}
                  color="primary"
                />
              )}
            />
          </FlexBox> */}

            {variables && variables.length > 0 && renderVariables(variables)}
          </Box>
        </>
      )}

      {step === 2 && (
        <>
          <Box className={styles.container}>
            <Typography variant="subtitle1" fontWeight={500}>
              Data to Export
            </Typography>

            <FlexBox>
              <FlexBox
                component="label"
                sx={{ cursor: "pointer" }}
                columnGap={1}
              >
                <Controller
                  name="inbound"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={value} onChange={onChange} />
                  )}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color={"text.primary"}
                >
                  Inbound
                </Typography>
              </FlexBox>
              <FlexBox
                component="label"
                sx={{ cursor: "pointer" }}
                columnGap={1}
              >
                <Controller
                  name="outbound"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={value} onChange={onChange} />
                  )}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color={"text.primary"}
                >
                  Outbound
                </Typography>
              </FlexBox>
            </FlexBox>

            {watch("inbound") && (
              <>
                <Typography mt={4} variant="subtitle1" fontWeight={500}>
                  Bots
                </Typography>

                <FlexBox>
                  {allBot?.map((bot) => (
                    <FlexBox
                      component="label"
                      sx={{ cursor: "pointer" }}
                      columnGap={1}
                      key={bot._id}
                    >
                      <Controller
                        name={`bots.${bot._id}`}
                        control={control}
                        defaultValue={true}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox checked={value} onChange={onChange} />
                        )}
                      />
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        color={"text.primary"}
                      >
                        {bot.name}
                      </Typography>
                    </FlexBox>
                  ))}
                </FlexBox>
              </>
            )}

            {watch("outbound") && (
              <>
                <Typography mt={4} variant="subtitle1" fontWeight={500}>
                  Campaigns
                </Typography>

                <Box sx={{ columnCount: 2 }}>
                  {allCampaigns?.map((campaign) => (
                    <FlexBox
                      component="label"
                      sx={{ cursor: "pointer" }}
                      columnGap={1}
                      key={campaign._id}
                    >
                      <Controller
                        name={`campaigns.${campaign._id}`}
                        control={control}
                        defaultValue={true}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox checked={value} onChange={onChange} />
                        )}
                      />
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        color={"text.primary"}
                      >
                        {campaign.name}
                      </Typography>
                    </FlexBox>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </>
      )}

      <FlexBox sx={{ mt: 4 }} justifyContent="flex-end">
        {step === 2 && (
          <Button
            variant="text"
            startIcon={<ChevronLeft width={16} height={16} />}
            sx={{ marginRight: "auto" }}
            color="inherit"
            onClick={() => setStep(1)}
          >
            Back
          </Button>
        )}

        <Button
          variant="whiteRound"
          className={`${styles.modalButton} ${styles.bordered}`}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          className={styles.modalButton}
          onClick={handleNext}
        >
          {step === 1 ? "Next" : "Save"}
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default CreateWebhookEventModal;
