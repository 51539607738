import Sidebar from "components/Sidebar";
import styles from "./index.module.css";
import { Box } from "@mui/material";

const PageWrapper = ({
  children,
  content,
  className,
  contentClass,
  noPadding,
}) => {
  return (
    <Box className={styles.container}>
      <Sidebar />
      <Box
        className={`${styles.main} ${className ? className : ""}`}
        style={noPadding ? { padding: 0 } : {}}
      >
        {content && (
          <Box className={`${styles.content} ${contentClass}`}>{content}</Box>
        )}

        {!content && (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PageWrapper;
