import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { ChevronDown, Loader, PlusCircle, XCircle } from "react-feather";
import FundamentoPopup from "components/FundamentoPopup";
import FundamentoTable from "components/FundamentoTable";
import { IconMoreVert, IconPencilLine1, IconTrash } from "components/SVG";
import theme from "theme";
import { useEffect, useState } from "react";
import { useURLState } from "custom-hooks/useUrlState";
import {
  getRoles as getRolesApi,
  getTeams as getTeamsApi,
  createTeam as createTeamApi,
  createUser as createUserApi,
  getAllUser as getAllUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  cancelUserInvite as cancelUserInviteApi,
} from "services";
import { useSkillrToast } from "context/toast";
import AddUser from "../components/AddUser";
import moment from "moment";
import EmptyState from "components/EmptyState";
import DeleteModal from "components/DeleteModal";
import RoleBasedAccess from "components/RoleBasedAccess";
import { useSocket } from "../../../context/socket";
import { getJSONDecoded } from "utils/utils";
import FlexBox from "components/FlexBox";

const UserManagement = () => {
  const { showSkillrToast } = useSkillrToast();
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [roleFilter, setRoleFilter] = useURLState([], "role");
  const [teamFilter, setTeamFilter] = useURLState([], "team");
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [addUserModal, setAddUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState();
  const [deleteModal, setDeleteModal] = useState({
    status: false,
    title: "",
    subtitle: "",
    list: [],
  });

  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ? token : null);
  const socket = useSocket(tokenInfo.agentId, token);

  const handleTablePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getRoles = async () => {
    try {
      const response = await getRolesApi();
      setRoles(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const getTeams = async () => {
    try {
      const response = await getTeamsApi();
      setTeams(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const createNewTeam = async (newTeam) => {
    try {
      const teamIndex = teams.findIndex(
        (t) => t.team.toLowerCase() === newTeam.toLowerCase()
      );
      if (teamIndex === -1) {
        const response = await createTeamApi({ team: newTeam });
        const newTeams = [response, ...teams];
        setTeams(newTeams);
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const onlineCircle = {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.success.main,
    boxShadow: `inset rgba(204, 246, 231, 1) 0px 0px 0px 2px`,
  };

  const updateTableDataInFormat = (data, connectedAgents) => {
    const newdata = data.map((u) => {
      let userObj = {};
      if (u?.lastName) {
        userObj.fullName = u.firstName + " " + u.lastName;
      } else {
        userObj.fullName = u.firstName;
      }
      if (connectedAgents.indexOf(u?.additionalInfo?.agentId) != -1) {
        userObj.lastSeen = (
          <FlexBox columnGap={2}>
            <div style={onlineCircle} /> online
          </FlexBox>
        );
      } else if (u?.inviteStatus === "invited") {
        userObj.lastSeen = "invited";
      } else {
        userObj.lastSeen = u?.lastSeen
          ? moment.utc(u?.lastSeen).format("DD MMMM")
          : moment.utc(u?.createdAt).format("DD MMMM");
      }
      userObj.email = u.email;
      userObj.role = u?.role;
      userObj.team = u?.team;
      userObj._id = u._id;
      return userObj;
    });
    setTableData(newdata);
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams();
      urlParams.set("page", currentPage);
      urlParams.set("teamIds", teamFilter);
      urlParams.set("roleIds", roleFilter);
      const response = await getAllUserApi(urlParams.toString());
      setUsers(response);
      updateTableDataInFormat(response?.data, []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const createNewUser = async (newUser) => {
    try {
      const response = await createUserApi(newUser);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const deleteUser = async () => {
    try {
      const response = await deleteUserApi(selectedUserDetails?._id);
      return response;
    } catch (error) {
      if (error?.response?.status === 403) {
        showSkillrToast("Only admin can delete user");
      } else {
        const errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong";
        showSkillrToast(errorMessage);
      }
    }
  };

  const cancelUserInvite = async (userId) => {
    try {
      const response = await cancelUserInviteApi(userId);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const onDeleteConfirm = async () => {
    if (selectedUserDetails?.lastSeen === "invited") {
      await cancelUserInvite(selectedUserDetails?._id);
    } else {
      await deleteUser();
    }
    if (users?.data?.length === 1 && currentPage > 1) {
      /**
       * If user is on page 2, 3 ..., and is trying to delete user and there is only one record present
       *  then we need to make page count - 1 else it will show empty state
       */
      setCurrentPage(currentPage - 1);
    }
    setSelectedUserDetails();
    getAllUsers();
  };

  const handleRoleFilter = (event) => {
    const selectedRole = event.target.value;
    const newRoleFilter = [...roleFilter];
    const index = roleFilter.indexOf(selectedRole);
    if (index > -1) {
      newRoleFilter.splice(index, 1);
    } else {
      newRoleFilter.push(selectedRole);
    }
    setRoleFilter(newRoleFilter);
  };

  const handleTeamFilter = (event) => {
    const selectedTeam = event.target.value;
    const newTeamFilter = [...teamFilter];
    const index = teamFilter.indexOf(selectedTeam);
    if (index > -1) {
      newTeamFilter.splice(index, 1);
    } else {
      newTeamFilter.push(selectedTeam);
    }
    setTeamFilter(newTeamFilter);
  };

  const addNewUser = () => {
    setAddUserModal(true);
  };

  const handleEditUser = (info) => {
    const formattedInfo = {
      _id: info?._id,
      name: info?.fullName,
      email: info?.email,
      role: info?.role?._id ?? "",
      team: info?.team ?? "",
      type: "edit",
    };
    setSelectedUserDetails(formattedInfo);
    setAddUserModal(true);
  };

  const updateUserDetails = async (updatedUserDetails) => {
    try {
      setLoading(true);
      const response = await updateUserApi(
        selectedUserDetails?._id,
        updatedUserDetails
      );
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error?.response.data.message
        : "Something went wrong";

      if (error?.response?.data?.message === "not authorized") {
        showSkillrToast("Action unauthorized");
      } else showSkillrToast(errorMessage, "error");
    }
  };

  const handleCancelInvite = (rowInfo) => {
    setDeleteModal({
      status: true,
      title: "Cancel user invite?",
      description: "",
      list: [
        "The user will no longer have access",
        "This action cannot be undone",
      ],
    });
    setSelectedUserDetails({ ...rowInfo });
  };

  const handleDeleteUser = (rowInfo) => {
    setDeleteModal({
      status: true,
      title: "Delete User?",
      description: "",
      list: [
        "The user will no longer have access",
        "This action cannot be undone",
      ],
    });
    setSelectedUserDetails({ ...rowInfo });
  };

  const clearFilters = () => {
    setRoleFilter([]);
    setTeamFilter([]);
  };

  const columns = [
    {
      field: "userDetails",
      header: "User",
      render: (row) => (
        <Box>
          <Typography variant="body2" className={styles.userName}>
            {row.fullName}
          </Typography>
          <Typography variant="caption" className={styles.userEmail}>
            {row.email}
          </Typography>
        </Box>
      ),
    },
    {
      field: "role",
      header: "Role",
      render: (row) => (
        <Typography variant="body2" className={styles.userName}>
          {row?.role?.role}
        </Typography>
      ),
    },
    {
      field: "team",
      header: "Team",
      render: (row) => (
        <Typography variant="body2" className={styles.userName}>
          {row?.team?.team}
        </Typography>
      ),
    },
    {
      field: "lastSeen",
      header: "Last seen",
      render: (row) => (
        <Typography
          variant="body2"
          className={styles.userName}
          sx={{ textTransform: "capitalize" }}
        >
          {row?.lastSeen}
        </Typography>
      ),
    },
    {
      field: "quickActions",
      header: " ",
      render: (rowInfo) => {
        return (
          <FundamentoPopup
            className={styles.quickActionsModal}
            disableCloseOnClick={false}
            triggeringComponent={
              <IconButton variant="outlined">
                <IconMoreVert />
              </IconButton>
            }
          >
            {rowInfo?.lastSeen === "invited" ? (
              <RoleBasedAccess>
                <Box
                  component={"label"}
                  className={styles.quickActionsItem}
                  sx={{ color: theme.palette.error.main }}
                  onClick={() => handleCancelInvite(rowInfo)}
                >
                  <IconTrash width={16} height={16} />
                  <Typography variant="body2">Cancel Invite</Typography>
                </Box>
              </RoleBasedAccess>
            ) : (
              <Box display="flex" flexDirection="column">
                <Box
                  className={styles.quickActionsItem}
                  onClick={() => handleEditUser(rowInfo)}
                >
                  <IconPencilLine1 width={16} height={16} />
                  <Typography variant="body2">Edit user</Typography>
                </Box>
                <RoleBasedAccess>
                  {rowInfo?.role?.role !== "Admin" && (
                    <Box
                      component={"label"}
                      className={styles.quickActionsItem}
                      sx={{ color: theme.palette.error.main }}
                      onClick={() => handleDeleteUser(rowInfo)}
                    >
                      <IconTrash width={16} height={16} />
                      <Typography variant="body2">Delete user</Typography>
                    </Box>
                  )}
                </RoleBasedAccess>
              </Box>
            )}
          </FundamentoPopup>
        );
      },
    },
  ];

  const updateFileSocket = (connectedAgents) => {
    setUsers((prev) => {
      prev?.data?.length &&
        updateTableDataInFormat(prev?.data, connectedAgents);
      return prev;
    });
  };

  useEffect(() => {
    getRoles();
    getTeams();
    socket.on("connectedAgents", (connectedAgents) => {
      updateFileSocket(connectedAgents);
    });

    return () => {
      socket.off("connected-agents");
    };
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [currentPage, roleFilter, teamFilter]);

  return (
    <Box sx={{ padding: "0 32px" }}>
      {/* <Typography textAlign="center" variant="h3" fontWeight={500} mb={4}>
      User management
    </Typography> */}
      <Box className={styles.header}>
        <Box display="flex" columnGap={2}>
          <FundamentoPopup
            disableCloseOnClick={true}
            triggeringComponent={
              <Button
                variant="outlined"
                className={roleFilter.length > 0 && styles.active}
                sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                endIcon={<ChevronDown width={16} height={16} />}
              >
                Role
              </Button>
            }
          >
            <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              {roles.map((role) => (
                <Box
                  key={role?._id}
                  columnGap={2}
                  display="flex"
                  alignItems="center"
                  component={"label"}
                >
                  <Checkbox
                    value={role?._id}
                    onChange={handleRoleFilter}
                    checked={roleFilter.indexOf(role?._id) > -1}
                  />
                  <Typography variant="body2">{role?.role}</Typography>
                </Box>
              ))}
            </Box>
          </FundamentoPopup>

          <FundamentoPopup
            disableCloseOnClick={true}
            disabled={teams.length === 0}
            triggeringComponent={
              <Button
                variant="outlined"
                disabled={teams.length === 0}
                className={teamFilter.length > 0 && styles.active}
                sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                endIcon={<ChevronDown width={16} height={16} />}
              >
                Team
              </Button>
            }
          >
            <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              {teams.map((team) => (
                <Box
                  key={team?._id}
                  columnGap={2}
                  display="flex"
                  alignItems="center"
                  component={"label"}
                >
                  <Checkbox
                    value={team?._id}
                    onChange={handleTeamFilter}
                    checked={teamFilter.indexOf(team?._id) > -1}
                  />
                  <Typography variant="body2">{team?.team}</Typography>
                </Box>
              ))}
            </Box>
          </FundamentoPopup>

          {(teamFilter.length > 0 || roleFilter.length > 0) && (
            <Tooltip title="Clear filters">
              <IconButton onClick={clearFilters}>
                <XCircle width={18} height={18} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Button
          startIcon={<PlusCircle width={16} height={16} />}
          variant="dark"
          className={styles.addNewUserButton}
          onClick={addNewUser}
        >
          Invite user
        </Button>
      </Box>

      {loading && (
        <Box className={styles.emptyContainer}>
          <Loader color={theme.palette.grey[400]} width={40} height={40} />
        </Box>
      )}

      {!loading && users?.data?.length > 0 && (
        <Box className={styles.table}>
          <FundamentoTable
            columns={columns}
            data={tableData}
            passRowToRender={true}
            pagination={users?.totalCount > 10}
            totalPageCount={Math.ceil(users?.totalCount / 10)}
            currentPage={currentPage}
            onPageChange={handleTablePageChange}
          />
        </Box>
      )}

      {!loading && users?.data?.length === 0 && (
        <EmptyState title="No User Found" />
      )}

      <AddUser
        show={addUserModal}
        onClose={() => {
          setAddUserModal(false);
          setSelectedUserDetails();
        }}
        teams={teams}
        roles={roles}
        createNewUser={createNewUser}
        add={selectedUserDetails ? false : true}
        editUserDetails={selectedUserDetails}
        updateUserDetails={updateUserDetails}
        getAllUsers={getAllUsers}
        createNewTeam={createNewTeam}
      />

      <DeleteModal
        open={deleteModal.status}
        onClose={() => setDeleteModal({ ...deleteModal, status: false })}
        onConfirm={onDeleteConfirm}
        title={deleteModal.title}
        subtitle={""}
        list={deleteModal.list}
      />
    </Box>
  );
};

export default UserManagement;
