import {
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import CustomStepper from "pages/Campaign/components/CustomStepper";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft, Loader, X } from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import theme from "theme";
import styles from "./index.module.css";
import getGoogleSheets from "services/getGoogleSheets";
import { useSkillrToast } from "context/toast";
import getWorksheets from "services/getWorksheets";
import getColumnsInWorksheet from "services/getColumnsInWorksheet";
import VariableDropdown from "pages/Diagram/components/Sidebars/VariableDropdown";
import { useUserContext } from "context/user";
import { createExportEvent, editExportEvent, getCampaigns } from "services";

const CreateEventModal = ({
  open,
  onClose,
  integrations,
  onConfirm,
  previousData,
  onEdit,
}) => {
  const [step, setStep] = useState(1);
  const [googleSheets, setGoogleSheets] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [columns, setColumns] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [googleSheetLoading, setGoogleSheetLoading] = useState(false);
  const [workSheetLoading, setWorkSheetLoading] = useState(false);
  const [columnLoading, setColumnLoading] = useState(false);

  const { showSkillrToast } = useSkillrToast();
  const { allBot } = useUserContext();
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      eventName: "",
      selectedAccount: null,
      selectedGoogleSheet: null,
      selectedWorksheet: null,
      variableMappings: [],
      bots: {},
      campaigns: {},
      inbound: true,
      outbound: false,
    },
  });

  const { fields, add, remove } = useFieldArray({
    control,
    name: "variableMappings",
  });

  const loader = (
    <FlexBox height={48} justifyContent={"center"}>
      <Loader width={20} height={20} color={theme.palette.grey[600]} />
    </FlexBox>
  );

  const handleClose = () => {
    setStep(1);
    reset({
      eventName: "",
      selectedAccount: null,
      selectedGoogleSheet: null,
      selectedWorksheet: null,
      variableMappings: [],
      bots: {},
      campaigns: {},
      inbound: true,
      outbound: false,
    });
    setGoogleSheets([]);
    setWorksheets([]);
    setColumns([]);
    onClose();
  };

  const handleSave = async () => {
    try {
      let formData = getValues();
      let finalFormData = {};

      const selectedBots = formData.bots
        ? Object.keys(formData.bots).filter((key) => formData.bots[key])
        : [];

      const selectedCampaigns =
        formData.campaigns && formData.outbound
          ? Object.keys(formData.campaigns).filter(
              (key) => formData.campaigns[key]
            )
          : [];

      finalFormData.bots = selectedBots;
      finalFormData.campaigns = selectedCampaigns;

      finalFormData.callType = [];
      if (formData.inbound) {
        finalFormData.callType.push("inbound");
        if (!selectedBots.length) {
          showSkillrToast("Please select at least one bot", "error");
          return;
        }
      }
      if (formData.outbound) {
        finalFormData.callType.push("outbound");
        if (!selectedCampaigns.length) {
          showSkillrToast("Please select at least one campaign", "error");
          return;
        }
      }

      // Convert variableMappings from array to object
      let variableMappings = {};
      formData.variableMappings.forEach((mapping, index) => {
        const columnName = Object.keys(fields[index]).find(
          (key) => key !== "id"
        );
        variableMappings[columnName] = mapping[columnName];
      });

      finalFormData = {
        name: formData.eventName,
        type: "google-sheet",
        integration: formData.selectedAccount._id,
        details: {
          sheetId: formData.selectedGoogleSheet.id,
          tabName: formData.selectedWorksheet.title,
        },
        variableMappings: variableMappings,
        bots: selectedBots,
        campaigns: selectedCampaigns,
        callType: finalFormData.callType,
      };

      if (finalFormData.callType.length === 0) {
        showSkillrToast("Please select at least one call type", "error");
        return;
      }

      if (selectedBots.length === 0 && selectedCampaigns.length === 0) {
        showSkillrToast("Please select at least one bot or campaign", "error");
        return;
      }

      const response = previousData
        ? await editExportEvent(previousData?._id, finalFormData)
        : await createExportEvent(finalFormData);
      if (response) {
        showSkillrToast(
          `Event ${previousData ? "edited" : "created"} successfully`,
          "success"
        );
        onConfirm();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleNext = async () => {
    if (step === 1) {
      // show error if no variableMappings value present
      const hasAnyValue = watch("variableMappings").some((field) => {
        // Get the first key-value pair from the mapping object
        const [key, value] = Object.entries(field)[0] || [];
        return value && value.trim() !== "";
      });

      if (!hasAnyValue) {
        showSkillrToast("Please map at least one variable", "error");
        return;
      }

      handleSubmit(async (data) => {
        if (Object.keys(errors).length === 0) {
          setStep(2);
        }
      })();
    } else {
      handleSave();
    }
  };

  const getAllSheetsFromAccount = async () => {
    setGoogleSheetLoading(true);
    try {
      const response = await getGoogleSheets(watch("selectedAccount")._id);
      setGoogleSheets(response);

      if (previousData && previousData?.details?.sheetId) {
        const googleSheetsData = response.find(
          (item) => item.id === previousData.details.sheetId
        );
        setValue("selectedGoogleSheet", googleSheetsData);
      }
      setGoogleSheetLoading(false);

      return response;
    } catch (error) {
      setGoogleSheetLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllWorksheets = async () => {
    setWorkSheetLoading(true);
    try {
      const response = await getWorksheets({
        integrationId: watch("selectedAccount")._id,
        sheetId: watch("selectedGoogleSheet").id,
      });
      setWorksheets(response);

      if (previousData && previousData?.details?.tabName) {
        const worksheetData = response.find(
          (item) => item.title === previousData.details.tabName
        );
        setValue("selectedWorksheet", worksheetData);
      }
      setWorkSheetLoading(false);

      return response;
    } catch (error) {
      setWorkSheetLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllColumns = async (shouldMapPreviousData = true) => {
    setColumnLoading(true);
    try {
      const response = await getColumnsInWorksheet({
        integrationId: watch("selectedAccount")._id,
        sheetId: watch("selectedGoogleSheet").id,
        tabName: watch("selectedWorksheet").title,
      });

      // Create the field mappings
      const newFields = response.map((column) => ({
        [column]: shouldMapPreviousData
          ? previousData?.variableMappings?.[column] || ""
          : "",
        id: Math.random().toString(),
      }));

      // Set the fields using setValue
      setValue("variableMappings", newFields);
      setColumnLoading(false);
      setColumns(response);
      return response;
    } catch (error) {
      setColumnLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllCampaigns = async () => {
    try {
      const queryParams = new URLSearchParams();
      // queryParams.set("offset", (currentPage - 1) * countPerPage);
      // queryParams.set("count", countPerPage);
      queryParams.set("active", true);
      // queryParams.set("status", "active");
      const response = await getCampaigns(queryParams.toString());
      setAllCampaigns(response.data);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (watch("selectedAccount")) {
      // Reset subsequent fields
      setValue("selectedGoogleSheet", null);
      setValue("selectedWorksheet", null);
      setValue("variableMappings", []);
      setWorksheets([]);
      setColumns([]);

      getAllSheetsFromAccount();
    }
  }, [watch("selectedAccount")]);

  useEffect(() => {
    if (watch("selectedGoogleSheet")?.id) {
      // Reset subsequent fields
      setValue("selectedWorksheet", null);
      setValue("variableMappings", []);
      setColumns([]);

      getAllWorksheets();
    }
  }, [watch("selectedGoogleSheet")]);

  useEffect(() => {
    if (watch("selectedWorksheet")?.title) {
      const isSameWorksheet =
        previousData?.details?.tabName === watch("selectedWorksheet").title;

      // Reset variable mappings before getting new columns
      setValue("variableMappings", []);

      // Only map previous data if it's the same worksheet
      getAllColumns(isSameWorksheet);
    }
  }, [watch("selectedWorksheet")]);

  useEffect(() => {
    if (open) {
      getAllCampaigns();
    }
  }, [open]);

  useEffect(() => {
    if (previousData) {
      // const formObj = { eventName: previousData.name };
      // if (previousData?.integration && integrations.length > 0) {
      //   const integrationsData = integrations.find(
      //     (item) => item._id === previousData.integration
      //   );
      //   formObj["selectedAccount"] = integrationsData;
      // }
      // if (previousData?.callType?.length) {
      //   previousData.callType.forEach((callType) => (formObj[callType] = true));
      // }
      // if (previousData?.bots?.length) {
      //   previousData.bots.forEach((bot) => {
      //     const botData = allBot.find((item) => item._id === bot);
      //     formObj[`bots.${bot}`] = botData;
      //   });
      // }
      // if (previousData?.campaigns?.length) {
      //   previousData.campaigns.forEach((camp) => {
      //     const campaignData = allCampaigns.find((item) => item._id === camp);
      //     formObj[`campaigns.${camp}`] = campaignData;
      //   });
      // }
      const campaigns = {};
      const bots = {};
      allBot.forEach((bot) => {
        if (
          previousData?.bots?.length &&
          !previousData?.bots?.includes(bot._id)
        ) {
          bots[`bots.${bot._id}`] = false;
        }
      });

      allCampaigns.forEach((camp) => {
        if (
          previousData?.campaigns?.length &&
          !previousData?.campaigns?.includes(camp._id)
        ) {
          campaigns[`campaigns.${camp._id}`] = false;
        }
      });
      reset({
        eventName: previousData.name,
        selectedAccount: integrations.find(
          (item) => item._id === previousData.integration
        ),
        inbound: previousData.callType.includes("inbound"),
        outbound: previousData.callType.includes("outbound"),
        ...bots,
        ...campaigns,
        selectedGoogleSheet: googleSheets.find(
          (item) => item.id === previousData.details.sheetId
        ),
        selectedWorksheet: worksheets.find(
          (item) => item.title === previousData.details.tabName
        ),
        variableMappings: Object.keys(previousData.variableMappings).map(
          (key) => ({ [key]: previousData.variableMappings[key] })
        ),
      });
    }
  }, [previousData, integrations]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 6,
          boxShadow: 10,
          width: 800,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>
      <Box>
        <Typography mb={8} variant="h4" fontWeight={500} textAlign="center">
          {previousData ? "Edit Event" : "Create Event"}
        </Typography>

        <Box className={styles.stepper}>
          <CustomStepper
            step={step - 1}
            steps={["Event Details", "Assign Event"]}
          />
        </Box>

        {step === 1 && (
          <>
            <Controller
              name="eventName"
              control={control}
              rules={{
                required: "Event name is required",
                maxLength: {
                  value: 30,
                  message: "Name should be less than 30 characters",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  placeholder="Enter event name"
                  variant="outlined"
                  fullWidth
                  sx={{ borderRadius: "12px" }}
                  error={errors?.eventName}
                  helperText={errors?.eventName?.message}
                />
              )}
            />
            <Controller
              name="selectedAccount"
              control={control}
              rules={{ required: "Google Account is required" }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    // if (e.target.value?._id !== previousData?.integration) {
                    //   resetSubsequentFields("selectedAccount");
                    // }
                  }}
                  IconComponent={ChevronDown}
                  sx={{
                    borderRadius: 4,
                    "& li": {
                      fontSize: 12,
                    },
                    mt: 4,
                  }}
                  fullWidth
                  className={styles.select}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: theme.palette.grey[50],
                      },
                    },
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (!value) {
                      return (
                        <Typography
                          variant="caption"
                          // color={theme.palette.grey[800]}
                          color="#919191"
                          fontWeight={400}
                        >
                          Select Google Account
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {value.name}
                        </Typography>
                      );
                    }
                  }}
                >
                  {integrations?.map(
                    (integration) =>
                      integration.type === "google-sheet" && (
                        <MenuItem key={integration._id} value={integration}>
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            {integration.name}
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </Select>
              )}
            />
            {errors?.selectedAccount && (
              <Typography color="error" variant="caption">
                {errors?.selectedAccount?.message}
              </Typography>
            )}

            {watch("selectedAccount") && (
              <>
                {googleSheetLoading ? (
                  loader
                ) : (
                  <Controller
                    name="selectedGoogleSheet"
                    control={control}
                    rules={{ required: "Google Sheet is required" }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        key={value}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          // if (
                          //   e.target.value?.id !== previousData?.details?.sheetId
                          // ) {
                          //   resetSubsequentFields("selectedGoogleSheet");
                          // }
                        }}
                        IconComponent={ChevronDown}
                        sx={{
                          borderRadius: 4,
                          "& li": {
                            fontSize: 12,
                          },
                          mt: 4,
                        }}
                        fullWidth
                        className={styles.select}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              borderRadius: 0,
                              boxShadow: "none",
                              backgroundColor: theme.palette.grey[50],
                            },
                          },
                        }}
                        displayEmpty
                        renderValue={(value) => {
                          if (!value) {
                            return (
                              <Typography
                                variant="caption"
                                // color={theme.palette.grey[800]}
                                color="#919191"
                                fontWeight={400}
                              >
                                Select Google Sheet
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                variant="caption"
                                color={theme.palette.grey[800]}
                              >
                                {value?.name}
                              </Typography>
                            );
                          }
                        }}
                      >
                        {googleSheets?.map((sheet) => (
                          <MenuItem value={sheet} key={sheet.id}>
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {sheet.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )}

                {errors?.selectedGoogleSheet && (
                  <Typography color="error" variant="caption">
                    {errors?.selectedGoogleSheet?.message}
                  </Typography>
                )}
              </>
            )}

            {watch("selectedGoogleSheet") && (
              <>
                {workSheetLoading ? (
                  loader
                ) : (
                  <Controller
                    name="selectedWorksheet"
                    control={control}
                    rules={{ required: "Worksheet is required" }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        key={value}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          // if (
                          //   e.target.value?.title !==
                          //   previousData?.details?.tabName
                          // ) {
                          //   resetSubsequentFields("selectedWorksheet");
                          // }
                        }}
                        IconComponent={ChevronDown}
                        sx={{
                          borderRadius: 4,
                          "& li": {
                            fontSize: 12,
                          },
                          mt: 4,
                        }}
                        fullWidth
                        className={styles.select}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              borderRadius: 0,
                              boxShadow: "none",
                              backgroundColor: theme.palette.grey[50],
                            },
                          },
                        }}
                        displayEmpty
                        renderValue={(value) => {
                          if (!value) {
                            return (
                              <Typography
                                variant="caption"
                                // color={theme.palette.grey[800]}
                                color="#919191"
                                fontWeight={400}
                              >
                                Select Work Sheet
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                variant="caption"
                                color={theme.palette.grey[800]}
                              >
                                {value?.title}
                              </Typography>
                            );
                          }
                        }}
                      >
                        {worksheets?.map((sheet) => (
                          <MenuItem value={sheet} key={sheet.id}>
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {sheet.title}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )}

                {errors?.selectedWorksheet && (
                  <Typography color="error" variant="caption">
                    {errors?.selectedWorksheet?.message}
                  </Typography>
                )}
              </>
            )}

            {watch("selectedWorksheet") && (
              <>
                <Typography variant="body2" fontWeight={500} mt={8}>
                  Variable Mapping
                </Typography>
                {columnLoading
                  ? loader
                  : fields.map((field, index) => {
                      // Get the column name by filtering out the 'id' key
                      const columnName = Object.keys(field).find(
                        (key) => key !== "id"
                      );

                      return (
                        <Box sx={{ mt: 4 }} key={field.id}>
                          <Typography
                            variant="caption"
                            component="div"
                            fontWeight={500}
                            color="text.primary"
                          >
                            {columnName}
                          </Typography>

                          <Controller
                            name={`variableMappings.${index}.${columnName}`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <VariableDropdown
                                control={control}
                                watch={watch}
                                setValue={setValue}
                                value={value} // Use existing value or default from field
                                onChange={onChange}
                              >
                                <TextField
                                  fullWidth
                                  onChange={onChange}
                                  value={value}
                                  sx={{ mt: 2 }}
                                />
                              </VariableDropdown>
                            )}
                          />
                          {errors?.variableMappings?.[index]?.[columnName] && (
                            <Typography color="error" variant="caption">
                              {
                                errors?.variableMappings?.[index]?.[columnName]
                                  ?.message
                              }
                            </Typography>
                          )}
                        </Box>
                      );
                    })}
              </>
            )}
          </>
        )}

        {step === 2 && (
          <>
            <Box className={styles.container}>
              <Typography variant="subtitle1" fontWeight={500}>
                Data to Export
              </Typography>

              <FlexBox>
                <FlexBox
                  component="label"
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                >
                  <Controller
                    name="inbound"
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={onChange} />
                    )}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color={"text.primary"}
                  >
                    Inbound
                  </Typography>
                </FlexBox>
                <FlexBox
                  component="label"
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                >
                  <Controller
                    name="outbound"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={onChange} />
                    )}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color={"text.primary"}
                  >
                    Outbound
                  </Typography>
                </FlexBox>
              </FlexBox>

              {watch("inbound") && (
                <>
                  <Typography mt={4} variant="subtitle1" fontWeight={500}>
                    Bots
                  </Typography>

                  <FlexBox>
                    {allBot?.map((bot) => (
                      <FlexBox
                        component="label"
                        sx={{ cursor: "pointer" }}
                        columnGap={1}
                        key={bot._id}
                      >
                        <Controller
                          name={`bots.${bot._id}`}
                          control={control}
                          defaultValue={true}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox checked={value} onChange={onChange} />
                          )}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color={"text.primary"}
                        >
                          {bot.name}
                        </Typography>
                      </FlexBox>
                    ))}
                  </FlexBox>
                </>
              )}

              {watch("outbound") && (
                <>
                  <Typography mt={4} variant="subtitle1" fontWeight={500}>
                    Campaigns
                  </Typography>

                  <Box sx={{ columnCount: 2 }}>
                    {allCampaigns?.map((campaign) => (
                      <FlexBox
                        component="label"
                        sx={{ cursor: "pointer" }}
                        columnGap={1}
                        key={campaign._id}
                      >
                        <Controller
                          name={`campaigns.${campaign._id}`}
                          control={control}
                          defaultValue={true}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox checked={value} onChange={onChange} />
                          )}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color={"text.primary"}
                        >
                          {campaign.name}
                        </Typography>
                      </FlexBox>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      <FlexBox sx={{ mt: 4 }} justifyContent="flex-end">
        {step === 2 && (
          <Button
            variant="text"
            startIcon={<ChevronLeft width={16} height={16} />}
            sx={{ marginRight: "auto" }}
            color="inherit"
            onClick={() => setStep(1)}
          >
            Back
          </Button>
        )}

        <Button
          variant="whiteRound"
          className={`${styles.modalButton} ${styles.bordered}`}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          className={styles.modalButton}
          onClick={handleNext}
          disabled={googleSheetLoading || workSheetLoading || columnLoading}
        >
          {step === 1 ? "Next" : "Save"}
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default CreateEventModal;
