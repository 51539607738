import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Radio,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { RadioButton } from "components/newSVG";
import { RedAlert } from "components/SVG";
import AddFields from "pages/Diagram/components/AddFields";
import VariableDropdown from "pages/Diagram/components/Sidebars/VariableDropdown";
import { Circle, Loader, Send, X } from "react-feather";
import { Controller, FormProvider, useForm } from "react-hook-form";
import theme from "theme";
import styles from "./index.module.css";
import { createIntegration, editIntegration } from "services";
import { useSkillrToast } from "context/toast";
import { useEffect } from "react";

const AddWebhookModal = ({ open, onClose, onConfirm, previousData }) => {
  const tabs = ["params", "headers", "body"];
  const { showSkillrToast } = useSkillrToast();

  const restApiRegex =
    /^(https?:\/\/)?([\w\d-]+\.)*[\w\d-]+\.[\w\d]{2,}(\/[\w\d-.{}]+)*\/?$/;

  const methods = useForm({
    defaultValues: {
      name: "",
      method: "POST",
      apiUrl: "",
      tab: tabs[0],
      params: [],
      headers: [],
      body: "",
      variables: [],
    },
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSave = async (data) => {
    try {
      if (data.params.length > 0) {
        const emptyParam = data.params.find(
          (param) => !param.key || !param.value
        );
        if (emptyParam) {
          showSkillrToast("Please fill all the param fields", "error");
          return;
        }
      }

      if (data.headers.length > 0) {
        const emptyHeader = data.headers.find(
          (header) => !header.key || !header.value
        );
        if (emptyHeader) {
          showSkillrToast("Please fill all the header fields", "error");
          return;
        }
      }

      const finalFormData = {
        name: data.name,
        type: "webhook",
        details: {
          method: data.method,
          url: data.apiUrl,
          body: data.body,
          ...(data.params.length > 0 && {
            params: data.params.reduce(
              (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
              {}
            ),
          }),
          ...(data.headers.length > 0 && {
            headers: data.headers.reduce(
              (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
              {}
            ),
          }),
        },
      };

      const response = previousData
        ? await editIntegration(previousData?._id, finalFormData)
        : await createIntegration(finalFormData);
      if (response) {
        onConfirm();
        handleClose();
        showSkillrToast(
          `Webhook ${previousData ? "updated" : "created"} successfully`,
          "success"
        );
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (previousData) {
      const formObj = {
        name: previousData.name,
        method: "POST",
        apiUrl: previousData?.details?.url || "",
        tab: tabs[0],
        params:
          (previousData?.details?.params &&
            Object.entries(previousData?.details?.params)
              .map((item) => ({ key: item[0], value: item[1] }))
              .filter(Boolean)) ||
          [],
        headers:
          (previousData?.details?.headers &&
            Object.entries(previousData?.details?.headers)
              .map((item) => ({ key: item[0], value: item[1] }))
              .filter(Boolean)) ||
          [],
        body: previousData?.details?.body || "",
        variables: previousData?.details?.variables || [],
      };
      reset({ ...formObj });
    }
  }, [previousData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 6,
          boxShadow: 10,
          width: 800,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>

      <Box mb={8}>
        <Typography textAlign={"center"} variant="h4" fontWeight={500}>
          {`${previousData ? "Edit" : "Connect"} Webhook`}
        </Typography>
      </Box>

      <Typography variant="caption" color="#0F0F0F">
        Select method
      </Typography>
      <Box mt={2}>
        <Controller
          control={control}
          name="method"
          defaultValue="POST"
          render={({ field: { onChange, value } }) => (
            <>
              <Box mt={2} display="flex" gap={5}>
                {/* <FlexBox
                  component="label"
                  columnGap={1}
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    onClick={() => onChange("GET")}
                    checked={value === "GET"}
                    sx={{ padding: 0, minWidth: 24 }}
                    icon={<Circle width={22} height={22} />}
                    checkedIcon={<RadioButton width={24} height={24} />}
                  />

                  <Typography variant="caption" color="#0F0F0F">
                    GET
                  </Typography>
                </FlexBox> */}
                <FlexBox
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                  component="label"
                >
                  <Radio
                    onClick={() => onChange("POST")}
                    checked={value === "POST"}
                    sx={{ padding: 0, minWidth: 24 }}
                    icon={<Circle width={22} height={22} />}
                    checkedIcon={<RadioButton width={24} height={24} />}
                  />
                  <Typography variant="caption" color="#0F0F0F">
                    POST
                  </Typography>
                </FlexBox>
                {/* <FlexBox
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                  component="label"
                >
                  <Radio
                    onClick={() => onChange("PUT")}
                    checked={value === "PUT"}
                    sx={{ padding: 0 }}
                    icon={<Circle width={12} height={12} />}
                    checkedIcon={<RadioButton width={12} height={12} />}
                  />
                  <Typography variant="caption" color="#0F0F0F">
                    PUT
                  </Typography>
                </FlexBox> */}
              </Box>
            </>
          )}
        />
      </Box>

      <Typography
        variant="caption"
        color="#0F0F0F"
        sx={{ marginTop: 6 }}
        fontWeight={500}
      >
        Name
      </Typography>

      <FlexBox mt={2}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: "Name is required",
            maxLength: {
              value: 30,
              message: "Name should be less than 30 characters",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              value={value}
              onChange={onChange}
              placeholder="Enter Name"
              sx={{
                "& div": {
                  borderRadius: 3,
                },
              }}
              helperText={
                errors?.name && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.name?.message}
                  </Box>
                )
              }
            />
          )}
        />
      </FlexBox>

      <Typography
        variant="caption"
        color="#0F0F0F"
        sx={{ marginTop: 6 }}
        fontWeight={500}
      >
        URL
      </Typography>

      <FlexBox mt={2}>
        <Controller
          control={control}
          name="apiUrl"
          rules={{
            required: "Url is required",
            pattern: {
              value: restApiRegex,
              message: "Please enter valid api endpoint",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <VariableDropdown
              control={control}
              watch={watch}
              setValue={setValue}
              value={value}
              onChange={onChange}
            >
              <TextField
                fullWidth
                value={value}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/ /g, "");
                  onChange(e.target.value);
                }}
                placeholder="Enter URL"
                sx={{
                  "& div": {
                    borderRadius: 3,
                  },
                }}
                helperText={
                  errors?.apiUrl && (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert style={{ marginRight: 8 }} />
                      {errors?.apiUrl?.message}
                    </Box>
                  )
                }
              />
            </VariableDropdown>
          )}
        />

        {/* <IconButton
          className={watch("apiLoader") ? styles.disabled : {}}
          sx={{
            backgroundColor: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: theme.palette.grey[600],
            },
          }}
          onClick={() => handleSampleResponse()}
        >
          {watch("apiLoader") ? (
            <Loader color="white" width={20} height={20} />
          ) : (
            <Send color="white" width={20} height={20} />
          )}
        </IconButton> */}
      </FlexBox>

      {watch("apiUrl") && (
        <>
          <Box mt={6}>
            <Controller
              control={control}
              name="tab"
              render={({ field: { onChange, value } }) => (
                <Box>
                  {tabs?.length > 0 && (
                    <Tabs
                      value={value}
                      onChange={(event, newTab) => onChange(newTab)}
                      aria-label="tabs"
                      className={styles.tabs}
                      TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
                      sx={{
                        "& span.MuiTabs-indicator": {
                          borderRadius: "10px 10px 0 0",
                          height: 3,
                        },
                      }}
                    >
                      {tabs.map((tab, index) => {
                        if (watch("method") === "GET" && tab === "body")
                          return null;
                        return (
                          <Tab
                            className={styles.tab}
                            label={tab}
                            value={tab}
                            key={tab}
                          />
                        );
                      })}
                    </Tabs>
                  )}
                </Box>
              )}
            />

            <Divider
              sx={{ backgroundColor: theme.palette.borderColor.light, mx: -6 }}
            />
          </Box>

          <Box mt={4} mb={1}>
            {watch("tab") === "body" && (
              <Controller
                control={control}
                name="body"
                render={({ field: { onChange, value } }) => (
                  <VariableDropdown
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    value={value}
                    onChange={onChange}
                  >
                    <TextField
                      placeholder="add request body here"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      rows={8}
                      multiline
                      sx={{
                        "& div": {
                          padding: 0,
                        },
                      }}
                    />
                  </VariableDropdown>
                )}
              />
            )}

            {watch("tab") === "params" && (
              <FormProvider {...methods}>
                <AddFields path={watch("tab")} buttonText="Add Params" />
              </FormProvider>
            )}

            {watch("tab") === "headers" && (
              <FormProvider {...methods}>
                <AddFields path={watch("tab")} buttonText="Add Headers" />
              </FormProvider>
            )}
          </Box>
        </>
      )}

      <FlexBox justifyContent="center" columnGap={4} mt={2}>
        <Button variant="whiteRound" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          variant="dark"
          sx={{
            minWidth: 0,
            width: "fit-content",
          }}
          onClick={handleSubmit(handleSave)}
        >
          Connect
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default AddWebhookModal;
