import { Box, Button, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import FundamentoTable from "components/FundamentoTable";
import { useEffect, useState } from "react";
import { getSmartDb as getSmartDbApi } from "services";
import { Link, useNavigate } from "react-router-dom";
import theme from "theme";
import { IconPlus } from "components/SVG";

const SmartDB = () => {
  const [smartDbs, setSmartDbs] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      field: "_id",
      header: "id",
    },
    {
      field: "name",
      header: "Name",
    },
    {
      field: "totalRecords",
      header: "Total Records",
      render: (row) => row.totalRecords ?? 0,
    },
    {
      field: "metadata",
      header: "Metadata",
      render: (row) => (row.metadata ? JSON.stringify(row.metadata) : "-"),
    },
  ];

  const handlAddSmartDb = () => {};

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row) {
      console.log({ id: row.id });
      navigate(`/database/smart-db/${row.id}`);
    }
  };

  const callSmartRecords = async () => {
    const response = await getSmartDbApi();
    setSmartDbs(response);
  };

  useEffect(() => {
    callSmartRecords();
  }, []);

  return (
    <PageWrapper className={styles.container}>
      <Box padding={8}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4" fontWeight={500} mb={2}>
              Smart Database
            </Typography>
            <Typography variant="body1" color={theme.palette.grey[500]}>
              Create and manage multiple smart database to store and organise
              your contact data
            </Typography>
          </Box>
          <FlexBox ml="auto">
            <Link to="/database/contacts/create">
              <Button
                startIcon={<IconPlus color="white" />}
                variant="dark"
                sx={{ borderRadius: "12px", padding: "12px 24px" }}
              >
                Create Database
              </Button>
            </Link>
          </FlexBox>
        </Box>
        <Box mt={4}>
          {smartDbs.length > 0 && (
            <FundamentoTable
              columns={columns}
              data={smartDbs}
              passRowToRender={true}
              rowIdKey="_id"
              tableOnClick={handleTableOnClick}
            />
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default SmartDB;
