import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grow,
  IconButton,
  InputAdornment,
  Radio,
  Switch,
  TextField,
  Typography,
  Tooltip,
  Select,
  MenuItem,
  LinearProgress,
  Collapse,
} from "@mui/material";
import { ChevronDown, IconInfo2, IconUpload2, RedAlert } from "components/SVG";
import { useEffect, useRef, useState } from "react";
import { ChevronRight, Plus, Square, Trash2 } from "react-feather";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import styles from "./index.module.css";
import {
  CheckSquare,
  Circle,
  Eye,
  EyeClose,
  RadioButton,
  TextFlatMp3,
  TextFlatWav,
} from "components/newSVG";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { useSkillrToast } from "context/toast";
import VariableDropdown from "../VariableDropdown";
import {
  languages as defaultLanguages,
  gscUriPrefix,
} from "text-constants/common";
import { multiModelResponseTypes } from "text-constants/diagram";
import AudioPlayer from "pages/Bots/components/AudioPlayer";
import AddFillerModal from "../../AddFillerModal";
import {
  checkDuplicateName,
  getAudioDuration,
  getNewFileName,
} from "utils/utils";
import uploadAudioFileApi from "services/uploadAudioFile";
import UploadFile from "../../UploadFile";

const MAX_AUDIO_TIME = 20; // 20 sec

const AIProperties = (props) => {
  const {
    data,
    onSave,
    workflows,
    localizationMode,
    currentLanguage,
    workFlowId,
    onEdit,
  } = props;

  const {
    control,
    reset,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      prompt: "",
      startLine: "",
      options: [{ option: "", id: Math.random().toString(36).substring(7) }],
      validationType: "validationCounter",
      failureMessage: "",
      variableName: "",
      defaultText: "",
      validationCount: 2,
      defaultType: "triggerWorkflow",
      selectedWorkflow: {
        workflowName: data?.workflowName || "",
        workflowId: data?.workflowId || "",
      },
      localizedMessage: "",
      localizedAudio: "",
      localizedOptions: [{ option: "" }],
      localizedFailureMessage: "",
      multiModelResponse: false,
      multiModelResponseType: "dtmf",
      multiModelFirstResponse: true,
      multiModelRetryResponse: false,
      filler: [],
      retryAudio: "",
      localizedRetryAudio: "",
      maxConversation: 0,
      model: "gpt-4o-mini",
    },
  });
  const { showSkillrToast } = useSkillrToast();
  const [showDefault, setShowDefault] = useState(false);
  const [deleteEdges, setDeleteEdges] = useState(false);
  const [deleteDefault, setDeleteDefault] = useState(false);
  const [deleteSource, setDeleteSource] = useState(false);
  const [showFillerModal, setShowFillerModal] = useState(false);
  const supportedFileTypes = ["audio/wav", "audio/mpeg"];

  const filesType = {
    "audio/mpeg": (
      <TextFlatMp3 width={24} height={32} style={{ flexShrink: 0 }} />
    ),
    "audio/wav": (
      <TextFlatWav width={24} height={32} style={{ flexShrink: 0 }} />
    ),
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
    rules: {
      validate: (options) =>
        options.length > 0 || "At least one option is required",
    },
  });

  const { fields: localizedFields, append: appendLocalized } = useFieldArray({
    control,
    name: "localizedOptions",
    rules: {
      validate: (options) =>
        options.length > 0 || "At least one option is required",
    },
  });

  const fileInputRef = useRef();
  const [uploadedDetails, setUploadedDetails] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const handleOnSave = async () => {
    const formData = getValues();

    const filteredFormData = {
      ...formData,
      ...(deleteEdges ? { deleteEdges: true } : { deleteEdges: false }),
      ...(deleteDefault ? { deleteDefault: true } : { deleteDefault: false }),
      ...(deleteSource ? { deleteSource: true } : { deleteSource: false }),
      gcsUri: uploadedDetails?.gcsUri || "",
    };

    if (localizationMode && currentLanguage) {
      filteredFormData.languageVariations = {
        ...(data?.languageVariations || {}),
        [currentLanguage]: {
          ...(data?.languageVariations?.[currentLanguage] || {}),
          message: formData.localizedMessage,
          gcsUri: formData.localizedAudio,
          options: formData.localizedOptions,
          failureMessage: formData.localizedFailureMessage,
          retryAudio: formData.localizedRetryAudio,
        },
      };
    }

    if (
      formData.selectedWorkflow?.workflowId.length === 0 &&
      formData.defaultType === "triggerWorkflow"
    ) {
      showSkillrToast("Workflow is required", "error");
      return;
    }

    if (formData.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        formData.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    onSave(filteredFormData);
  };

  const watchOnAllFields = () => {
    const subscribe = watch((value, { name, type }) => {
      if (name === "triggerWorkflow" || name === "setVariable") {
        if (type === "change") {
          if (name === "triggerWorkflow" && !value.triggerWorkflow) {
            setDeleteEdges(true);
          } else {
            setDeleteEdges(false);
            setDeleteSource(true);
          }
        }
      } else if ((name = "defaultType" && value.defaultType !== "addNode")) {
        if (type === "change") {
          setDeleteDefault(true);
        }
      } else if (name === "defaultType" && value.defaultType === "addNode") {
        if (type === "change") {
          setDeleteSource(true);
        }
      }
    });

    return subscribe;
  };

  const addOption = () => {
    const newId = Math.random().toString(36).substring(7);
    append({ option: "", id: newId });
  };

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const subscription = watchOnAllFields();
    return () => subscription.unsubscribe();
  }, []);

  // add fields in localized fields array which are there in fields array
  useEffect(() => {}, [data, currentLanguage]);

  useEffect(() => {
    if (data) {
      reset({
        ...getValues(),
        ...data,
        localizedMessage:
          data.languageVariations?.[currentLanguage]?.message || "",
        localizedOptions: data.languageVariations?.[currentLanguage]
          ?.options || [{ option: "" }],
        localizedFailureMessage:
          data.languageVariations?.[currentLanguage]?.failureMessage || "",
        localizedAudio:
          data.languageVariations?.[currentLanguage]?.gcsUri || "",
        localizedRetryAudio:
          data.languageVariations?.[currentLanguage]?.retryAudio || "",
      });
      setUploadedDetails(data);
    }
  }, [data, currentLanguage]);

  useEffect(() => {
    if (localizationMode) {
      // Synchronize localizedFields with fields
      const optionsCount = watch("options")?.length;
      const localizedOptionsCount = watch("localizedOptions")?.length;

      if (optionsCount > localizedOptionsCount) {
        const diff = optionsCount - localizedOptionsCount;
        for (let i = 0; i < diff; i++) {
          appendLocalized({ option: "" });
        }
      }
    }
  }, [watch, appendLocalized, localizationMode]);

  useEffect(() => {
    if (watch("responseValidation")) {
      if (!watch("validationType")) {
        setValue("validationType", "validationCounter");
        setValue("validationCount", 2);
      }
    }
  }, [watch("responseValidation")]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <>
        <Typography variant="caption" color="text.primary" mb={1}>
          Node Name
        </Typography>

        <Controller
          control={control}
          name="customName"
          rules={{
            validate: (value) => {
              if (/[^a-zA-Z\s]/.test(value))
                return "Node name can only contain letters and spaces";
              return true;
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              inputProps={{ maxLength: 20 }}
              variant="outlined"
              fullWidth
              placeholder="Add Name"
              value={value}
              onChange={onChange}
              error={errors?.customName}
              helperText={errors?.customName?.message}
              sx={{
                mb: 4,
                "& div": {
                  borderRadius: "12px",
                  padding: 0,
                },
              }}
            />
          )}
        />

        <Typography variant="caption" color="text.primary" mb={1}>
          Prompt
        </Typography>
        <Controller
          control={control}
          name="prompt"
          rules={{
            required: "Prompt is required",
          }}
          render={({ field: { onChange, value } }) => (
            <VariableDropdown
              control={control}
              watch={watch}
              setValue={setValue}
              value={value}
              onChange={onChange}
            >
              <TextField
                variant="outlined"
                multiline
                rows={5}
                fullWidth
                placeholder={"Add prompt"}
                sx={{
                  "& div": {
                    // overflow: "hidden",
                    borderRadius: "12px",
                    padding: 0,
                  },
                  "& textarea": {
                    marginBottom: "32px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        alignSelf: "flex-end",
                        mb: 4,
                        mr: 2,
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      {`${watch("prompt")?.length} / 10000`}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10000 }}
              />
            </VariableDropdown>
          )}
        />
        {errors?.prompt && (
          <FlexBox
            mt={2}
            component="span"
            columnGap={1}
            fontSize={12}
            color={"error.main"}
          >
            <RedAlert />
            {errors?.prompt?.message}
          </FlexBox>
        )}

        <Typography variant="body2" fontWeight={500} mt={4}>
          Start Line
        </Typography>
        <Controller
          control={control}
          name="startLine"
          rules={{ required: "Start line is required" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              placeholder="Start Line"
              sx={{ mt: 2 }}
              value={value}
              onChange={onChange}
              helperText={
                errors?.startLine && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.startLine?.message}
                  </Box>
                )
              }
            />
          )}
        />

        <Typography variant="body2" fontWeight={500} mt={4}>
          Model
        </Typography>
        <Controller
          control={control}
          name="model"
          rules={{ required: "Model is required" }}
          render={({ field: { onChange, value } }) => (
            <Select
              displayEmpty
              fullWidth
              className={styles.select}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              sx={{ mt: 2, mb: 2 }}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: 0,
                    boxShadow: "none",
                    backgroundColor: theme.palette.grey[50],
                    maxHeight: "40vh",
                  },
                },
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                    >
                      Select Model
                    </Typography>
                  );
                }
                return (
                  <Typography variant="caption" color={theme.palette.grey[800]}>
                    {selected}
                  </Typography>
                );
              }}
            >
              <MenuItem value="gpt-4o-mini">GPT-4o Mini</MenuItem>
              <MenuItem value="gemini-1.5-flash">Gemini 1.5 Flash</MenuItem>
            </Select>
          )}
        />

        <Typography variant="body2" fontWeight={500} mt={4}>
          Variable Name
        </Typography>
        <Controller
          control={control}
          name="variableName"
          rules={{ required: "Variable name is required" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              placeholder="Variable Name"
              sx={{ mt: 2 }}
              value={value}
              onChange={onChange}
              helperText={
                errors?.variableName && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.variableName?.message}
                  </Box>
                )
              }
            />
          )}
        />

        {fields.map((field, index) => (
          <div key={field.id} style={{ width: "100%" }}>
            <Typography sx={{ mt: 4 }} variant="body2" fontWeight={500}>
              Exit Condition {index + 1}
            </Typography>
            <Controller
              name={`options.${index}.option`}
              control={control}
              defaultValue=""
              rules={{ required: "Option is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mt: 2 }}
                  fullWidth
                  variant="outlined"
                  placeholder={`Option ${index + 1}`}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => remove(index)}>
                        <Trash2 width={12} height={12} />
                      </IconButton>
                    ),
                  }}
                  helperText={
                    errors?.options?.[index]?.option && (
                      <Box component="span" display="flex" alignItems="center">
                        <RedAlert style={{ marginRight: 8 }} />
                        {errors?.options?.[index]?.option?.message}
                      </Box>
                    )
                  }
                />
              )}
            />
            {watch("setVariable") && (
              <FlexBox component="label" my={2}>
                <Typography
                  variant="caption"
                  color="text.primary"
                  fontFamily={"inter"}
                  sx={{ cursor: "pointer" }}
                >
                  Value
                </Typography>
                <Controller
                  name={`options.${index}.value`}
                  control={control}
                  defaultValue=""
                  rules={{ required: "Value is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ "& div": { maxHeight: 40 } }}
                      fullWidth
                      variant="outlined"
                      placeholder="Value"
                      helperText={
                        errors?.options?.[index]?.value && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {errors?.options?.[index]?.value?.message}
                          </Box>
                        )
                      }
                    />
                  )}
                />
              </FlexBox>
            )}
          </div>
        ))}
        {errors?.options?.root && (
          <Box component="span" display="flex" alignItems="center">
            <RedAlert style={{ marginRight: 8 }} />
            <Typography variant="caption" color="error">
              {errors?.options?.root?.message}
            </Typography>
          </Box>
        )}

        {fields.length < 20 && (
          <Button
            startIcon={<Plus width={12} height={12} />}
            onClick={() => addOption()}
            sx={{ mt: 2 }}
            variant="text"
            color="inherit"
          >
            Add Exit Condition
          </Button>
        )}

        <Typography variant="body2" fontWeight={500} mt={4}>
          Max Conversation
        </Typography>
        <Controller
          name="maxConversation"
          control={control}
          defaultValue={1}
          rules={{
            required: "Max Conversation is required",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: 0,
                boxShadow: "none",
                backgroundColor: theme.palette.grey[50],
              },
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={onChange}
              className={styles.select}
              fullWidth
              sx={{ mt: 2, mb: 4 }}
            >
              {[...Array(51)].map((_, i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        {(!watch("responseValidation") ||
          watch("validationType") !== "forceValidate") && (
          <div style={{ width: "100%" }}>
            <FlexBox columnGap={1}>
              <Typography variant="body2" fontWeight={500}>
                Default
              </Typography>
              <Tooltip
                title="If nothing matches, trigger workflow"
                placement="top"
              >
                <IconInfo2 />
              </Tooltip>
            </FlexBox>

            {/* {watch("triggerWorkflow") && ( */}
            <FlexBox mt={2} mb={4}>
              <FlexBox
                columnGap={1}
                component="label"
                sx={{ cursor: "pointer" }}
              >
                <Controller
                  control={control}
                  name="defaultType"
                  render={({ field: { onChange, value } }) => (
                    <Radio
                      onClick={() => onChange("addNode")}
                      checked={value === "addNode"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                  )}
                />

                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                  sx={{ cursor: "pointer" }}
                >
                  Add Node
                </Typography>
              </FlexBox>

              <FlexBox
                columnGap={1}
                component="label"
                sx={{ cursor: "pointer" }}
              >
                <Controller
                  control={control}
                  name="defaultType"
                  render={({ field: { onChange, value } }) => (
                    <Radio
                      onClick={() => onChange("triggerWorkflow")}
                      checked={value === "triggerWorkflow"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                  )}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                  sx={{ cursor: "pointer" }}
                >
                  Trigger Workflow
                </Typography>
              </FlexBox>
            </FlexBox>
            {/* )} */}

            {watch("defaultType") === "triggerWorkflow" && (
              <Controller
                control={control}
                name="selectedWorkflow"
                rules={{ required: "Workflow is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    className={styles.select}
                    onChange={(e) => onChange(e.target.value)}
                    defaultValue={{
                      workflowName: data.workflowName,
                      workflowId: data.workflowId,
                    }}
                    value={value}
                    sx={{ mt: 2, mb: 4 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: theme.palette.grey[50],
                          maxHeight: "40vh",
                        },
                      },
                    }}
                    // render the selected value after selection
                    renderValue={(selected) => {
                      return (
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {selected.workflowName}
                        </Typography>
                      );
                    }}
                  >
                    {workflows?.workFlows?.map((workflow) => (
                      <MenuItem
                        value={{
                          workflowName: workflow.name,
                          workflowId: workflow._id,
                        }}
                        key={workflow.id}
                        classes={{ selected: styles.selected }}
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {workflow.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
            {errors?.selectedWorkflow && (
              <Box mb={4} component="span" display="flex" alignItems="center">
                <RedAlert style={{ marginRight: 8 }} />
                <Typography variant="caption" color="error">
                  {errors?.selectedWorkflow?.message}
                </Typography>
              </Box>
            )}

            {watch("setVariable") && (
              <FlexBox component="label" mb={4} sx={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  color="text.primary"
                  fontFamily={"inter"}
                  sx={{ cursor: "pointer" }}
                >
                  Value
                </Typography>

                <Controller
                  control={control}
                  name="defaultText"
                  rules={{ required: "Default text is required" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      placeholder="Default Text"
                      sx={{ "& div": { maxHeight: "40px" } }}
                      value={value}
                      onChange={onChange}
                      helperText={
                        errors?.defaultText && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {errors?.defaultText?.message}
                          </Box>
                        )
                      }
                    />
                  )}
                />
                <Tooltip title="Default value to be set" placement="top">
                  <IconInfo2 sx={{ flexShrink: 0 }} width={16} height={16} />
                </Tooltip>
              </FlexBox>
            )}
          </div>
        )}
      </>
      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default AIProperties;
