import { useEffect, useRef } from "react";
import * as d3 from "d3";

const AHTTrends = ({ data, startDate, endDate }) => {
  const svgRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (!data || !data.length) return;

    // Ensure minimum 7 days by adjusting startDate if needed
    let effectiveStartDate = new Date(startDate);
    const endDateTime = new Date(endDate);
    const daysDifference =
      (endDateTime - effectiveStartDate) / (1000 * 60 * 60 * 24);

    if (daysDifference < 7) {
      effectiveStartDate = new Date(endDateTime);
      effectiveStartDate.setDate(effectiveStartDate.getDate() - 6);
    }

    // Create array of all dates between effectiveStartDate and endDate
    const allDates = [];
    let currentDate = new Date(effectiveStartDate);
    const lastDate = new Date(endDate);

    // Reset time components to ensure clean date comparison
    currentDate.setHours(0, 0, 0, 0);
    lastDate.setHours(0, 0, 0, 0);

    while (currentDate <= lastDate) {
      allDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Create complete dataset with zeros for missing dates
    const completeData = allDates.map((date) => {
      const existingData = data.find((d) => {
        const compareDate = new Date(d.date);
        compareDate.setHours(0, 0, 0, 0);
        return compareDate.getTime() === date.getTime();
      });

      return {
        date: date.toISOString(),
        averageHandlingTime: existingData
          ? existingData.averageHandlingTime
          : 0,
      };
    });

    // Get container dimensions
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    // Update dimensions to use container height
    const margin = { top: 20, right: 40, bottom: 40, left: 40 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Update SVG with responsive dimensions
    const svg = d3
      .select(svgRef.current)
      .attr("width", "100%")
      .attr("height", "100%")
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create tooltip div
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("background-color", "rgba(15, 15, 15, 0.8)")
      .style("color", "white")
      .style("border-radius", "12px")
      .style("padding", "8px 12px")
      .style("font-size", "12px");

    // Set scales
    const xScale = d3
      .scaleTime()
      .domain([effectiveStartDate, new Date(endDate)])
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(completeData, (d) => d.averageHandlingTime)])
      .range([height, 0])
      .nice();

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale(d.averageHandlingTime));

    // Format date for x-axis - use a more specific format
    const formatDate = d3.timeFormat("%d/%m");

    // Add X axis with formatted dates and explicit tick values
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(formatDate)
          // Calculate appropriate number of ticks based on width
          .ticks(Math.min(width / 60, allDates.length)) // Show a tick roughly every 60 pixels
      )
      .selectAll("text")
      .style("text-anchor", "middle");
    // .attr("dx", "-.8em")
    // .attr("dy", ".15em");
    // .attr("transform", "rotate(-45)"); // Rotate labels for better readability

    // Add Y axis
    svg.append("g").call(d3.axisLeft(yScale));

    // Add X axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom)
      .text("Date Range")
      .style("font-size", "12px")
      .style("fill", "#7b7b7b")
      .style("font-weight", "400")
      .style("font-family", "DM Sans");

    // Add Y axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left + 12)
      .attr("x", -height / 2)
      .text("AHT (s)")
      .style("font-size", "12px")
      .style("fill", "#7b7b7b")
      .style("font-weight", "400")
      .style("font-family", "DM Sans");

    // Add the line with transition
    const path = svg
      .append("path")
      .datum(completeData)
      .attr("fill", "none")
      .attr("stroke", "#357595")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Get total length of the path
    const totalLength = path.node().getTotalLength();

    // Set up the line transition
    path
      .attr("stroke-dasharray", totalLength + " " + totalLength)
      .attr("stroke-dashoffset", totalLength)
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .attr("stroke-dashoffset", 0);

    // Add hollow dots with tooltip and transition
    svg
      .selectAll("circle")
      .data(completeData)
      .join("circle")
      .attr("cx", (d) => xScale(new Date(d.date)))
      .attr("cy", (d) => yScale(d.averageHandlingTime))
      .attr("r", 0)
      .attr("fill", "white")
      .attr("stroke", "#357595")
      .attr("stroke-width", 2)
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(300).style("opacity", 1);
        tooltip
          .html(
            `Date: ${formatDate(
              new Date(d.date)
            )}<br/>AHT: ${d.averageHandlingTime.toFixed(2)}`
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .transition()
      // .delay((d, i) => i * 100) // Stagger the appearance of dots
      .duration(300)
      .attr("r", 4); // Final radius

    // Update resize handler to consider height
    const handleResize = () => {
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;

      d3.select(svgRef.current)
        .attr("viewBox", `0 0 ${newWidth} ${newHeight}`)
        .attr("preserveAspectRatio", "xMidYMid meet");
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      d3.select(".tooltip").remove();
      window.removeEventListener("resize", handleResize);
    };
  }, [data, startDate, endDate]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "50vh",
        position: "relative",
      }}
    >
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default AHTTrends;
