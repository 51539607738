import PageWrapper from "components/PageWrapper";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import Header from "components/Header";
import { useState } from "react";

const Account = () => {
  const tabs = ["User Management", "Profile Settings", "Notifications"];
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop();

  const getInitialActiveTab = () => {
    if (!currentPath) return "User Management";

    // Convert URL path to tab name format
    const tabName = currentPath
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return tabs.includes(tabName) ? tabName : "User Management";
  };

  const handleTabChange = (tab) => {
    navigate(tab.toLowerCase().replace(" ", "-"));
  };

  return (
    <PageWrapper noPadding={true}>
      <Header
        title={"Account"}
        subTitle="The account settings page allows users to manage permissions, set primary language and manage notifications on the platform."
        tabs={tabs}
        defaultActiveTab={getInitialActiveTab()}
        onTabChange={(tab) => handleTabChange(tab)}
      />
      <Outlet />
    </PageWrapper>
  );
};

export default Account;
