import theme from "theme";

const styles = {
  dialogPaper: {
    width: 576,
    borderRadius: "20px",
    overflowY: "auto",
    position: "relative",
    // maxHeight: "60vh",
  },
  button: {
    borderRadius: 4,
    padding: "12px 24px",
  },
  tab: {
    textTransform: "none",
    alignItems: "flex-start",
    padding: "16px 24px",
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "DM Sans",
    color: "#0f0f0f",
    borderBottom: `1px solid ${theme.palette.borderColor.light}`,
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  rightContainer: {
    overflowY: "auto",
    // maxHeight: "calc(60vh - 135px)",
  },
  searchBox: {
    background: "#efefef",
    border: 0,
    outline: 0,
    borderRadius: "12px",
    "&>div": { border: 0 },
    fieldset: { border: 0 },
  },
};

export default styles;
