import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { IconFilter2, IconPlus } from "components/SVG";
import theme from "theme";
import { useCallback, useEffect, useState } from "react";
import CampaignList from "./components/CampaignList";
import {
  getCampaigns as getCampaignsApi,
  updateCampaign as updateCampaignApi,
  duplicateCampaign as duplicateCampaignApi,
  deleteCampaign as deleteCampaignApi,
} from "services";
import { useSkillrToast } from "context/toast";
import { useURLState } from "custom-hooks/useUrlState";
import EmptyState from "components/EmptyState";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FundamentoPopup from "components/FundamentoPopup";
import { SortAscending } from "components/newSVG";
import FlexBox from "components/FlexBox";
import { Loader, X } from "react-feather";
import FilterModal from "./components/FilterModal";
import moment from "moment";
import { statusTextMapping } from "text-constants/campaign";

const countPerPage = 10;

const Campaign = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tabs = ["Active Campaigns", "Archived Campaigns"];
  const [selectedTab, setSelectedTab] = useState("Active Campaigns");
  const [campaignData, setCampaignData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [active, setIsActive] = useURLState(true, "active");
  const { showSkillrToast } = useSkillrToast();
  const [sortOrder, setSortOrder] = useURLState("", "sorting");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    status: [],
    minRedialAttempts: "",
    maxRedialAttempts: "",
    minDuration: "",
    maxDuration: "",
    dateFilter: "",
  });
  const [showDraftWarning, setShowDraftWarning] = useState(false);
  const campaignDetailsFields = [
    {
      value: "status",
      label: "Status",
    },
    {
      value: "dateRange",
      label: "Date Range",
    },
  ];
  const getCampaings = async () => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams();
      queryParams.set("offset", (currentPage - 1) * countPerPage);
      queryParams.set("count", countPerPage);
      queryParams.set("active", active);
      queryParams.set("sorting", sortOrder);
      Object.entries(appliedFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          queryParams.set(key, value.join(","));
        } else if (value !== "" && value !== null && value !== undefined) {
          queryParams.set(key, value.toString());
        }
        if (key === "dateFilter" && value === "custom") {
          queryParams.set("dateFilter", "custom");
        }
        if (key === "dateFilter" && value === "last7days") {
          queryParams.set("dateFilter", "7 days");
        }
        if (key === "dateFilter" && value === "today") {
          queryParams.set("dateFilter", "today");
        }
        if (key === "dateFilter" && value === "thisMonth") {
          queryParams.set("dateFilter", "this month");
        }
      });
      const campaignResponse = await getCampaignsApi(queryParams.toString());
      setCampaignData(campaignResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateCampaign = async (campaignId, payload) => {
    try {
      await updateCampaignApi(campaignId, payload);
      if (payload.isArchived) {
        showSkillrToast("Campaign Archived Successfully", "success");
      } else if (payload.isArchived === false) {
        showSkillrToast("Campaign Unarchived Successfully", "success");
      } else {
        showSkillrToast("Campaign status updated", "success");
      }
      if (campaignData?.data?.length === 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      } else {
        getCampaings();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      if (errorMessage?.trim()?.toLowerCase() === "phone number unavailable") {
        setShowDraftWarning(true);
      } else {
        showSkillrToast(errorMessage, "error");
      }
    }
  };

  const createDuplicateCampaign = async (campaignId) => {
    try {
      await duplicateCampaignApi(campaignId);
      showSkillrToast("Campaign duplicated", "success");
      getCampaings();
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteCampaign = async (campaignId) => {
    try {
      await deleteCampaignApi(campaignId);
      showSkillrToast("Campaign deleted", "success");
      // Adjust currentPage to navigate to the previous page if the current campaign is deleted and only one record remains.
      if (campaignData?.data?.length === 1) {
        setCurrentPage(currentPage - 1);
      } else {
        getCampaings();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const applyFilters = (newFilters) => {
    setAppliedFilters(newFilters);
    setCurrentPage(1);
    // Update URL with new filters
    const searchParams = new URLSearchParams();
    Object.entries(newFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        searchParams.set(key, value.join(","));
      } else if (value !== "" && value !== null && value !== undefined) {
        if (key === "dateFilter") {
          if (value === "last7days") searchParams.set(key, "7 days");
          else if (value === "today") searchParams.set(key, "today");
          else if (value === "thisMonth") searchParams.set(key, "this month");
          else if (value === "custom") {
            searchParams.set(key, "range");
            searchParams.set("startDate", newFilters.startDate);
            searchParams.set("endDate", newFilters.endDate);
          } else searchParams.set(key, value);
        } else {
          searchParams.set(key, value.toString());
        }
      }
    });
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleRemoveFilter = (filterType, filterValue) => {
    if (filterType === "startDate" || filterType === "endDate") {
      setAppliedFilters((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
        dateFilter: "",
      }));

      // Update URL without date filters
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("startDate");
      searchParams.delete("endDate");
      searchParams.delete("dateFilter");
      navigate(`?${searchParams.toString()}`, { replace: true });
      return;
    }

    const newFilters = { ...appliedFilters };
    if (Array.isArray(newFilters[filterType])) {
      newFilters[filterType] = newFilters[filterType].filter(
        (item) => item !== filterValue
      );
    } else {
      newFilters[filterType] = "";
    }
    setAppliedFilters(newFilters);
    applyFilters(newFilters);
  };

  const clearAllFilters = () => {
    const clearedFilters = {
      status: [],
      minRedialAttempts: "",
      maxRedialAttempts: "",
      minDuration: "",
      maxDuration: "",
      startDate: null,
      endDate: null,
      dateFilter: "",
    };
    setAppliedFilters(clearedFilters);
    setCurrentPage(1);
    navigate(location.pathname, { replace: true });
  };

  const isFilterApplied = () => {
    if (!appliedFilters || typeof appliedFilters !== "object") {
      return false;
    }
    return Object.entries(appliedFilters).some(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (key === "dateFilter") {
        return value !== "";
      }
      if (key === "startDate" || key === "endDate") {
        return value !== null;
      }
      return value !== "" && value !== null && value !== undefined;
    });
  };

  const parseFiltersFromURL = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {
      status: searchParams.get("status")
        ? searchParams.get("status").split(",").filter(Boolean)
        : [],
      startDate: searchParams.get("startDate")
        ? moment(searchParams.get("startDate")).toDate()
        : null,
      endDate: searchParams.get("endDate")
        ? moment(searchParams.get("endDate")).toDate()
        : null,
      dateFilter: searchParams.get("dateFilter") || "",
    };

    // Map the dateFilter values
    if (filters.dateFilter === "7 days") {
      filters.dateFilter = "last7days";
    } else if (filters.dateFilter === "today") {
      filters.dateFilter = "today";
    } else if (filters.dateFilter === "this month") {
      filters.dateFilter = "thisMonth";
    } else if (filters.dateFilter === "range") {
      filters.dateFilter = "custom";
    }

    return filters;
  }, [location.search]);

  const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.isSame(end, "day")) {
      return start.format("MMMM D, YYYY");
    } else if (start.isSame(end, "year")) {
      return `${start.format("MMM D")} - ${end.format("MMM D, YYYY")}`;
    } else {
      return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`;
    }
  };
  // useEffect(() => {
  //   getCampaings();
  // }, [currentPage, selectedTab]);
  useEffect(() => {
    if (filtersInitialized) {
      getCampaings();
    }
  }, [currentPage, selectedTab, filtersInitialized, sortOrder, appliedFilters]);

  useEffect(() => {
    const filtersFromURL = parseFiltersFromURL();
    setAppliedFilters(filtersFromURL);
    setFiltersInitialized(true);
  }, [parseFiltersFromURL]);

  return (
    <PageWrapper className={styles.container}>
      <Box padding={8}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4" fontWeight={500} mb={2}>
              Campaigns
            </Typography>
            <Typography variant="body1" color={theme.palette.grey[500]}>
              Manage your campaigns here
            </Typography>
          </Box>
          <Link to="/campaign/create">
            <Button
              variant="dark"
              startIcon={<IconPlus />}
              sx={{ height: 40, borderRadius: 12 }}
            >
              Create Campaign
            </Button>
          </Link>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(newValue);
            setCurrentPage(1);
            setIsActive(newValue === "Active Campaigns" ? true : false);
          }}
          aria-label="tabs"
          className={styles.tabs}
          TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
        >
          {tabs.map((tab, index) => (
            <Tab className={styles.tab} label={tab} value={tab} key={index} />
          ))}
        </Tabs>
        <FlexBox mt={4} mb={2}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<IconFilter2 width={20} height={20} />}
            sx={{
              backgroundColor: "#f9f9f9",
              minWidth: 0,
              alignSelf: "flex-start",
            }}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>

          {isFilterApplied() && (
            <Button
              variant="text"
              color="primary"
              sx={{ minWidth: 0, ml: 2 }}
              onClick={clearAllFilters}
            >
              Clear All
            </Button>
          )}

          {Object.entries(appliedFilters).map(([filterType, filterValues]) => {
            if (
              filterType === "startDate" ||
              filterType === "endDate" ||
              filterType === "dateFilter"
            ) {
              return null;
            }
            if (Array.isArray(filterValues)) {
              return filterValues.map((filterValue) =>
                filterType === "status" && filterValue === "" ? (
                  <></>
                ) : (
                  <FlexBox key={filterValue} className={styles.chip}>
                    {`${
                      filterType.charAt(0).toUpperCase() + filterType.slice(1)
                    }: ${statusTextMapping[filterValue]}`}
                    <IconButton
                      size="small"
                      sx={{ padding: 1 }}
                      onClick={() =>
                        handleRemoveFilter(filterType, filterValue)
                      }
                    >
                      <X width={12} height={12} />
                    </IconButton>
                  </FlexBox>
                )
              );
            } else if (filterValues) {
              return (
                <FlexBox key={filterType} className={styles.chip}>
                  {`${
                    filterType.charAt(0).toUpperCase() + filterType.slice(1)
                  }: ${filterValues}`}

                  <IconButton
                    sx={{ padding: 1 }}
                    size="small"
                    onClick={() => handleRemoveFilter(filterType, filterValues)}
                  >
                    <X width={12} height={12} />
                  </IconButton>
                </FlexBox>
              );
            }
            return null;
          })}

          {appliedFilters.dateFilter &&
            appliedFilters.dateFilter !== "custom" && (
              <FlexBox className={styles.chip}>
                {`Date: ${
                  appliedFilters.dateFilter === "last7days"
                    ? "Last 7 Days"
                    : appliedFilters.dateFilter === "today"
                    ? "Today"
                    : appliedFilters.dateFilter === "thisMonth"
                    ? "This Month"
                    : ""
                }`}
                <IconButton
                  sx={{ padding: 1 }}
                  size="small"
                  onClick={() => handleRemoveFilter("dateFilter")}
                >
                  <X width={12} height={12} />
                </IconButton>
              </FlexBox>
            )}

          {appliedFilters.dateFilter === "custom" &&
            appliedFilters.startDate &&
            appliedFilters.endDate && (
              <FlexBox className={styles.chip}>
                {`Date: ${formatDateRange(
                  appliedFilters.startDate,
                  appliedFilters.endDate
                )}`}
                <IconButton
                  sx={{ padding: 1 }}
                  size="small"
                  onClick={() => {
                    handleRemoveFilter("startDate");
                    handleRemoveFilter("endDate");
                  }}
                >
                  <X width={12} height={12} />
                </IconButton>
              </FlexBox>
            )}

          <FilterModal
            open={filterModalOpen}
            onClose={() => setFilterModalOpen(false)}
            filters={appliedFilters}
            applyFilters={applyFilters}
            fields={campaignDetailsFields}
            mainPage={true}
            campaignPage={true}
          />
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className={styles.popup}
              triggeringComponent={
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<SortAscending />}
                  sx={{
                    padding: 3,
                    minWidth: 0,
                    width: "fit-content",
                  }}
                >
                  Sort
                </Button>
              }
            >
              <Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-ASC")}
                >
                  <Typography variant="body2">Date - Ascending</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-DESC")}
                >
                  <Typography variant="body2">Date - Descending</Typography>
                </Box>
              </Box>
            </FundamentoPopup>
          </div>
        </FlexBox>
        {loading && campaignData?.length === 0 && (
          <Box className={styles.emptyContainer}>
            <Loader width={40} height={40} color={theme.palette.grey[400]} />
          </Box>
        )}
        <Box mt={4}>
          {!loading && campaignData?.data?.length === 0 && (
            <Box
              sx={{
                height: "60vh",
                width: "100%",
              }}
            >
              <EmptyState title="No campaign found" />
            </Box>
          )}
          {!loading && campaignData?.data?.length > 0 && (
            <CampaignList
              campaignData={campaignData?.data}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPageCount={Math.ceil(
                campaignData?.totalRecords / countPerPage
              )}
              updateCampaign={updateCampaign}
              createDuplicateCampaign={createDuplicateCampaign}
              deleteCampaign={deleteCampaign}
              active={active}
              showDraftWarning={showDraftWarning}
              setShowDraftWarning={setShowDraftWarning}
            />
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Campaign;
