import { DragHandle } from "@mui/icons-material";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import DeleteModal from "components/DeleteModal";
import EmptyState from "components/EmptyState";
import FlexBox from "components/FlexBox";
import FunCollapse from "components/FunCollapse";
import Header from "components/Header";
import Loader from "components/Loader";
import { Pencil, PencilSimple } from "components/newSVG";
import PageWrapper from "components/PageWrapper";
import { IconSixVeritcalDots } from "components/SVG";
import { useSkillrToast } from "context/toast";
import AuditStatusModal from "pages/Calls/components/AuditStatusModal";
import { useEffect, useState } from "react";
import { Edit2, Plus, Trash2 } from "react-feather";
import {
  createAuditStatus,
  createAuditSubStatus,
  editAuditStatus,
  editAuditSubStatus,
  getAuditStatuses,
} from "services";
import theme from "theme";

const AnalyticsSettings = () => {
  const [auditStatuses, setAuditStatuses] = useState([]);
  const [auditStatusModal, setAuditStatusModal] = useState(false);
  const [selectedAuditStatus, setSelectedAuditStatus] = useState(null);
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getAllAuditStatuses = async () => {
    setLoading(true);
    try {
      const response = await getAuditStatuses();
      if (response) {
        setAuditStatuses(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateAuditStatus = async (data) => {
    try {
      const response = await editAuditStatus(selectedAuditStatus._id, data);
      if (response) {
        showSkillrToast("Audit status updated", "success");
        getAllAuditStatuses();
        setAuditStatusModal(false);
        setSelectedAuditStatus(null);
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const createNewSubStatus = async (data) => {
    try {
      const response = await createAuditSubStatus({
        name: data.name,
        parentStatus: data.id,
      });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteSubStatus = async (id) => {
    try {
      const response = await editAuditSubStatus(id, {
        isActive: false,
      });
      if (response) {
        showSkillrToast("Substatus Deleted", "success");
        getAllAuditStatuses();
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const createNewAuditStatus = async (data) => {
    try {
      const response = await createAuditStatus({ name: data.name });
      if (response) {
        await Promise.all(
          data.subStatuses.map((subStatus) =>
            createNewSubStatus({
              name: subStatus.name,
              id: response._id,
            })
          )
        );

        showSkillrToast("New Custom Audit Status Added", "success");
        getAllAuditStatuses();
        setAuditStatusModal(false);
        setSelectedAuditStatus(null);
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteAuditStatus = async (id) => {
    try {
      const response = await editAuditStatus(id, {
        isActive: false,
      });
      if (response) {
        showSkillrToast("Audit Status Deleted", "success");
        setSelectedAuditStatus(null);
        getAllAuditStatuses();
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    getAllAuditStatuses();
  }, []);

  return (
    <PageWrapper noPadding>
      <Header
        title="Analytics Settings"
        subTitle="Manage and customize analytics settings."
        breadcrumbs={[
          { label: "Call Analytics", link: "/calls/audit" },
          { label: "Analytics Settings", link: "/analytics-settings" },
        ]}
      />
      <Box p={8} pt={0}>
        <Box
          sx={{
            p: 4,
            borderRadius: 3,
            border: `1px solid ${theme.palette.borderColor.light}`,
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            Audit Statuses
          </Typography>
          <Typography variant="body2" color="grey.600" mb={6}>
            Define and manage audit statuses to categorize bot call data for
            streamlined analysis and reporting.
          </Typography>
          {loading ? (
            <Loader />
          ) : auditStatuses.length > 0 ? (
            auditStatuses.map((status) => (
              <Box
                sx={{
                  p: 4,
                  borderRadius: 3,
                  border: `1px solid ${theme.palette.borderColor.light}`,
                  mb: 4,
                }}
              >
                <FunCollapse
                  header={
                    <FlexBox
                      sx={{
                        "&:hover .actions": {
                          visibility: "visible",
                          opacity: 1,
                        },
                      }}
                    >
                      <Typography variant="body1" fontWeight={500}>
                        {status.name}
                      </Typography>
                      <FlexBox
                        gap={1}
                        ml="auto"
                        className="actions"
                        sx={{
                          opacity: 0,
                          visibility: "hidden",
                          transition: "all 0.3s ease-in-out",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAuditStatus(status);
                            setAuditStatusModal(true);
                          }}
                        >
                          <PencilSimple
                            strokeWidth={1.5}
                            color={theme.palette.text.primary}
                            width={16}
                            height={16}
                          />
                        </IconButton>
                        {status.subStatuses.length === 0 && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedAuditStatus(status);
                              setDeleteModal(true);
                            }}
                          >
                            <Trash2
                              color={theme.palette.text.primary}
                              size={16}
                            />
                          </IconButton>
                        )}
                      </FlexBox>
                    </FlexBox>
                  }
                >
                  <Box
                    sx={{
                      borderRadius: 3,
                      border: `1px solid ${theme.palette.borderColor.light}`,
                      my: 4,
                    }}
                  >
                    {status.subStatuses.map((subStatus) => (
                      <FlexBox
                        sx={{
                          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                          "&:last-of-type": {
                            borderBottom: "none",
                          },
                          p: 4,
                        }}
                        key={subStatus.id}
                      >
                        <Chip
                          label={subStatus.name}
                          sx={{
                            backgroundColor: "#C9E4F1",
                            color: "#224C61",
                            borderRadius: 10,
                            fontSize: 10,
                            fontWeight: 500,
                            padding: "4px 8px",
                          }}
                        />

                        <IconButton
                          sx={{ ml: "auto" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAuditStatus(subStatus);
                            setDeleteModal(true);
                          }}
                        >
                          <Trash2 size={16} />
                        </IconButton>
                      </FlexBox>
                    ))}
                  </Box>

                  <Button
                    variant="text"
                    color="inherit"
                    startIcon={<Plus width={16} height={16} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedAuditStatus(status);
                      setAuditStatusModal(true);
                    }}
                  >
                    Add Substatus
                  </Button>
                </FunCollapse>
              </Box>
            ))
          ) : (
            <Box sx={{ my: 4 }}>
              <EmptyState
                title="No audit statuses found"
                description="No audit statuses found. Please create a new audit status."
              />
            </Box>
          )}

          <Button
            variant="text"
            color="inherit"
            startIcon={<Plus width={16} height={16} />}
            onClick={() => {
              setSelectedAuditStatus(null);
              setAuditStatusModal(true);
            }}
          >
            Add Custom Audit Status
          </Button>
        </Box>
      </Box>

      <AuditStatusModal
        open={auditStatusModal}
        onClose={() => setAuditStatusModal(false)}
        prevData={selectedAuditStatus}
        onSubmit={(data) =>
          selectedAuditStatus
            ? updateAuditStatus(data)
            : createNewAuditStatus(data)
        }
      />

      <DeleteModal
        title={"Delete Audit Status"}
        subtitle={"Are you sure you want to delete this audit status?"}
        onConfirm={() =>
          selectedAuditStatus.parentStatus
            ? deleteSubStatus(selectedAuditStatus._id)
            : deleteAuditStatus(selectedAuditStatus._id)
        }
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </PageWrapper>
  );
};

export default AnalyticsSettings;
