import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FlexBox from "components/FlexBox";
import { useUserContext } from "context/user";
import BotPersonality from "./pages/BotPersonality";
import { useSkillrToast } from "context/toast";
import { editBot, getAllWorkFlow as getAllWorkFlowApi } from "services";
import BasicSettings from "pages/Bots/components/BasicSettings";
import AISettings from "pages/Bots/components/AdvancedSettings";
import PostProcess from "./components/PostProcess";

const BotSettings = () => {
  const navigate = useNavigate();
  const tabs = [
    "Bot Personality",
    "Basic Settings",
    "AI Settings",
    "Post Process",
  ];
  const [selectedTab, setSelectedTab] = useState("Bot Personality");
  const { showSkillrToast } = useSkillrToast();
  const { allBot, getAllBot } = useUserContext();
  const [workFlows, setWorkFlows] = useState([]);

  const handleSave = async (data) => {
    let finalData = {
      ...data,
    };

    // if (
    //   finalData?.basicSettingsError &&
    //   Object.keys(finalData.basicSettingsError).length > 0
    // ) {
    //   showSkillrToast(`Please fix basic settings errors`, "error");
    //   return;
    // }
    // Removing basicSettingsError as its not required
    // delete finalData.basicSettingsError;

    if (
      finalData?.fillersConfig?.intermittentLatencyDuration < 5 ||
      finalData?.fillersConfig?.intermittentLatencyDuration > 60
    ) {
      showSkillrToast(
        "Latency duration should be between 5 to 60 seconds",
        "error"
      );
      return;
    }

    try {
      const response = await editBot(allBot[0]._id, finalData);
      if (response) {
        showSkillrToast("Bot details updated successfully", "success");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getAllWorkFlow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlows(response);
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    getAllWorkFlow();
    getAllBot();

    return () => {
      // We are making this call because we want to keep latest bot setting in the context.
      // So when user updates anything in bot settings then it should get reflected
      getAllBot();
    };
  }, []);

  return (
    <PageWrapper className={styles.container}>
      <FlexBox justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Typography variant="h4" fontWeight={500}>
            {allBot?.[0].name}
          </Typography>
          <Typography variant="body1" mt={2} color={"text.secondary"}>
            Bot Settings
          </Typography>
        </Box>
        <Button
          variant="dark"
          sx={{ borderRadius: "12px", padding: "12px 16px" }}
          onClick={() => navigate("/bot/playground")}
        >
          Playground
        </Button>
      </FlexBox>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
        aria-label="tabs"
        className={styles.tabs}
        TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
        sx={{
          "& span.MuiTabs-indicator": {
            borderRadius: "10px 10px 0 0",
            height: 3,
          },
          mt: 4,
          borderBottom: `1px solid #e0e0e0`,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab className={styles.tab} label={tab} value={tab} key={index} />
        ))}
      </Tabs>

      {selectedTab === "Bot Personality" && (
        <BotPersonality onSave={(data) => handleSave(data)} />
      )}
      {selectedTab === "Basic Settings" && (
        <BasicSettings
          data={allBot[0]}
          // onSave={handleUpdate}
          workFlows={workFlows?.workFlows}
          apiData={allBot[0]}
          onSave={(data) => handleSave(data)}
        />
      )}
      {selectedTab === "AI Settings" && (
        <AISettings
          workFlows={workFlows?.workFlows}
          data={allBot[0]}
          onSave={(data) => handleSave(data)}
        />
      )}

      {selectedTab === "Post Process" && (
        <PostProcess data={allBot[0]} onSave={(data) => handleSave(data)} />
      )}

      <div style={{ paddingBottom: "48px" }} />
    </PageWrapper>
  );
};

export default BotSettings;
