import {
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DeleteModal from "components/DeleteModal";
import FlexBox from "components/FlexBox";
import { PencilSimple } from "components/newSVG";
import { useSkillrToast } from "context/toast";
import { useEffect, useState } from "react";
import { Edit2, Trash2, X } from "react-feather";
import { createAuditSubStatus, editAuditSubStatus } from "services";
import theme from "theme";

const AuditStatusModal = ({ open, onClose, prevData, onSubmit }) => {
  const [auditStatusValue, setAuditStatusValue] = useState("");
  const [subAuditValue, setSubAuditValue] = useState("");
  const { showSkillrToast } = useSkillrToast();
  const [subStatus, setSubStatus] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const createNewSubStatus = async () => {
    try {
      const response = await createAuditSubStatus({
        name: subAuditValue,
        parentStatus: prevData._id,
      });

      if (response) {
        setSubStatus([...subStatus, response]);
        showSkillrToast("Added New Substatus", "success");
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const editSubStatus = async (id, value) => {
    // Check if the new value already exists in other substatus items
    const isDuplicate = subStatus.some(
      (status) => status.name === value && (status._id || status.tempId) !== id
    );

    if (isDuplicate) {
      showSkillrToast("Substatus already exists", "error");
      setEditingValue("");
      setEditingId(null);
      return;
    }

    if (!prevData) {
      setSubStatus(
        subStatus.map((status) =>
          (status._id || status.tempId) === id
            ? { ...status, name: value }
            : status
        )
      );
      setEditingValue("");
      setEditingId(null);
      return;
    }

    try {
      const response = await editAuditSubStatus(id, {
        name: value,
        parentStatus: prevData._id,
      });

      if (response) {
        setSubStatus(
          subStatus.map((status) =>
            status._id === id ? { ...status, name: value } : status
          )
        );
        setEditingValue("");
        setEditingId(null);

        showSkillrToast("Substatus Updated", "success");
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const removeSubStatus = async (id) => {
    try {
      const response = await editAuditSubStatus(id, { isActive: false });
      if (response) {
        showSkillrToast("Substatus Removed", "success");
        setSubStatus(subStatus.filter((subStatus) => subStatus._id !== id));
      }
    } catch (error) {
      console.log(error);

      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleSubStatus = () => {
    if (subAuditValue.trim() !== "") {
      if (subStatus.find((subStatus) => subStatus.name === subAuditValue)) {
        showSkillrToast("Substatus already exists", "error");
      } else {
        if (prevData) {
          createNewSubStatus();
        } else {
          setSubStatus([
            ...subStatus,
            { name: subAuditValue, tempId: Date.now() },
          ]);
        }
      }
    }
  };

  const handleSave = () => {
    let finalData = {};

    // If editing an existing status, check for changes
    if (prevData) {
      const isNameChanged = prevData.name !== auditStatusValue;

      // Check if substatuses have changed by comparing arrays
      const isSubStatusChanged =
        JSON.stringify(
          prevData.subStatuses.map((s) => ({
            _id: s._id,
            name: s.name,
          }))
        ) !==
        JSON.stringify(
          subStatus.map((s) => ({
            _id: s._id,
            name: s.name,
          }))
        );

      isNameChanged && (finalData.name = auditStatusValue);
      isSubStatusChanged && (finalData.subStatuses = subStatus);

      if (!isNameChanged && !isSubStatusChanged) {
        onClose();
        return;
      }
    } else {
      finalData = {
        name: auditStatusValue,
        subStatuses: subStatus,
      };
    }

    onSubmit(finalData);
  };

  useEffect(() => {
    if (open) {
      if (prevData) {
        setAuditStatusValue(prevData.name);
        setSubStatus(prevData.subStatuses);
        setSubAuditValue("");
        setEditingId(null);
        setEditingValue("");
      } else {
        setAuditStatusValue("");
        setSubStatus([]);
        setSubAuditValue("");
        setEditingId(null);
        setEditingValue("");
      }
    }
  }, [prevData, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          width: 576,
          borderRadius: 5,
          padding: 8,
          position: "relative",
        },
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 5, top: 5 }}
        onClick={onClose}
      >
        <X size={16} />
      </IconButton>
      <Box textAlign="center">
        <Typography variant="h4" fontWeight="500" mb={8}>
          {prevData ? "Edit" : "Add"} Audit Status
        </Typography>
      </Box>

      <Typography variant="caption" color="text.primary" mb={1}>
        Audit Status
      </Typography>

      <TextField
        variant="outlined"
        value={auditStatusValue}
        placeholder="Enter Audit Status"
        onChange={(e) => setAuditStatusValue(e.target.value)}
        sx={{ mb: 4 }}
        inputProps={{
          maxLength: 35,
        }}
      />

      <TextField
        variant="outlined"
        value={subAuditValue}
        placeholder={`Enter Substatus and Press "Enter"`}
        onChange={(e) => setSubAuditValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSubAuditValue("");
            handleSubStatus();
          }
        }}
        inputProps={{
          maxLength: 35,
        }}
      />

      <Box
        sx={{
          border: `1px solid ${theme.palette.borderColor.light}`,
          borderRadius: 3,
          mt: 4,
        }}
      >
        {subStatus?.map((status) => (
          <FlexBox
            key={status._id || status.tempId}
            sx={{
              p: 4,
              borderBottom: `1px solid ${theme.palette.borderColor.light}`,
              "&:last-of-type": {
                borderBottom: "none",
              },
            }}
          >
            {editingId === (status._id || status.tempId) ? (
              <TextField
                autoFocus
                variant="standard"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && editingValue.trim() !== "") {
                    if (editingValue !== status.name) {
                      editSubStatus(status._id || status.tempId, editingValue);
                    } else {
                      setEditingId(null);
                      setEditingValue("");
                    }
                  }
                }}
                size="small"
                sx={{ minWidth: 150 }}
                inputProps={{
                  style: {
                    fontSize: 12,
                    height: 32,
                    padding: "0 8px",
                  },
                  maxLength: 35,
                }}
              />
            ) : (
              <Chip
                label={status.name}
                sx={{
                  backgroundColor: "#C9E4F1",
                  color: "#224C61",
                  borderRadius: 10,
                  fontSize: 10,
                  fontWeight: 500,
                  padding: "4px 8px",
                }}
              />
            )}
            <FlexBox gap={1} ml="auto" className="actions">
              {editingId === (status._id || status.tempId) ? (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingId(null);
                  }}
                >
                  <X color={theme.palette.text.primary} size={16} />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingId(status._id || status.tempId);
                    setEditingValue(status.name);
                  }}
                >
                  <PencilSimple
                    color={theme.palette.text.primary}
                    width={16}
                    height={16}
                  />
                </IconButton>
              )}
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  if (prevData) {
                    setDeletingId(status._id);
                    setDeleteModalOpen(true);
                  } else {
                    setSubStatus(
                      subStatus.filter(
                        (subStatus) =>
                          (subStatus._id || subStatus.tempId) !==
                          (status._id || status.tempId)
                      )
                    );
                  }
                }}
              >
                <Trash2 color={theme.palette.text.primary} size={16} />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </Box>

      <FlexBox justifyContent="center" gap={2} mt={4}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="dark"
          sx={{ borderRadius: 3, padding: "10px 20px" }}
          onClick={() => handleSave()}
          disabled={auditStatusValue === "" || subAuditValue !== ""}
        >
          Save
        </Button>
      </FlexBox>
      <DeleteModal
        title="Are you sure?"
        subtitle="Are you sure you want to delete this substatus?"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => removeSubStatus(deletingId)}
      />
    </Dialog>
  );
};

export default AuditStatusModal;
