import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Pagination,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import FundamentoPagination from "components/FundamentoPagination";

const FundamentoTable = ({
  columns,
  data,
  className,
  style,
  tableOnClick,
  rowIdKey,
  passRowToRender,
  pagination,
  totalPageCount,
  currentPage,
  onPageChange,
}) => {
  // const { columns, data, className, style, tableOnClick, rowIdKey } = props;
  return (
    <Box className={styles.container}>
      <Table
        className={`${className} ${styles.datasetList}`}
        style={style}
        onClick={tableOnClick}
      >
        <TableHead>
          <TableRow>
            {columns.map(
              (column) =>
                column.header && (
                  <TableCell key={column.field} style={column.style}>
                    {typeof column.header === "string" ? (
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        color="theme.primary"
                      >
                        {column.header}
                      </Typography>
                    ) : (
                      column.header
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} id={row[rowIdKey]}>
              {columns.map(
                (column) =>
                  column.header && (
                    <TableCell key={column.field}>
                      {/* if passRowTo render is true, we'll pass the whole row to the render function
                    else we pass the value of the field after checking if render is present or not.
                */}

                      {column.render && passRowToRender
                        ? column.render(row)
                        : column.render
                        ? column.render(row[column.field])
                        : row[column.field]}
                    </TableCell>
                  )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {pagination && (
        <Box className={styles.pagination}>
          {/* <Pagination
            page={currentPage}
            count={totalPageCount}
            shape="rounded"
            onChange={(e, pageCount) => {
              onPageChange(pageCount);
            }}
          /> */}
          <FundamentoPagination
            currentPage={currentPage}
            totalPageCount={totalPageCount}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </Box>
  );
};

FundamentoTable.propTypes = {
  rowIdKey: PropTypes.string,
  tableOnClick: PropTypes.func,
};

FundamentoTable.defaultProps = {
  rowIdKey: "id",
  tableOnClick: () => {},
  pagination: false,
};

export default FundamentoTable;
