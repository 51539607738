import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  sampleCSVDownloadLink,
  statusColorMapping,
} from "text-constants/campaign";
import {
  IconDownload,
  IconEdit2,
  IconFilter2,
  IconPause,
  IconPlay,
  IconUpload2,
} from "components/SVG";
import { useURLState } from "custom-hooks/useUrlState";
import {
  getCampaignContactDetails as getCampaignContactDetailsApi,
  getCampaignDetailsV1 as getCampaignDetailsApi,
  updateCampaign as updateCampaignApi,
} from "services";
import { useCallback, useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import DetailsList from "../DetailsList";
import EmptyState from "components/EmptyState";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Edit,
  Edit2,
  Edit3,
  Pause,
  Play,
  Square,
  StopCircle,
  X,
} from "react-feather";
import FundamentoPopup from "components/FundamentoPopup";
import FlexBox from "components/FlexBox";
import {
  AnalyticsOutline,
  Attachment,
  Duplicate,
  Pencil,
  PencilSimple,
  RightArrow,
} from "components/newSVG";
import ExportModal from "pages/Calls/components/ExportModal";
import FilterModal from "../FilterModal";
import moment from "moment";
import UploadCallData from "../UploadCallData";
import theme from "theme";
import { getFormattedTime } from "utils/utils";
import OutStandingModal from "components/OutStandingModal";

const filters = {
  contacts: [
    {
      value: "uploadStatus",
      label: "Upload Status",
    },
    {
      value: "preProcess",
      label: "preProcess Status",
    },
    {
      value: "dateRange",
      label: "Upload Date",
    },
  ],
  callLogs: [
    {
      value: "status",
      label: "Call Outcome",
    },
    {
      value: "dialAttempts",
      label: "Redial Attempts",
    },
    {
      value: "dateRange",
      label: "Date",
    },
  ],
  callDues: [
    {
      value: "dialAttempts",
      label: "Upcoming Dial Attempt",
    },
    {
      value: "scheduledDate",
      label: "Schedule Date and Time",
    },
    {
      value: "lastDialedDate",
      label: "Last Dialed Date and Time",
    },
  ],
};
const campaignDetailsFields = [
  {
    value: "callId",
    label: "Call ID",
  },
  {
    value: "phoneNumber",
    label: "Phone Number",
  },
  {
    value: "status",
    label: "Status",
  },
  {
    value: "redialAttempts",
    label: "Redial Attempts",
  },

  {
    value: "dateRange",
    label: "Date Range",
  },
  {
    value: "callDuration",
    label: "Call Duration",
  },
  {
    value: "createdAt",
    label: "Contact Creation Date",
  },
  {
    value: "customOutcome",
    label: "Custom Outcome",
  },
  {
    value: "didNumber",
    label: "DID Number",
  },
];

const recordsPerPage = 10;

const CampaignDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useURLState("contacts", "tab");
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [campaignContact, setCampaignContact] = useState();
  const [campaignDetails, setCampaignDetails] = useState();
  const [exportModal, setExportModal] = useState(false);
  const { showSkillrToast } = useSkillrToast();
  const campaignId = params.campaignId;
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [maxDurationOfCalls, setMaxDurationOfCalls] = useState(0);
  const [maxRedialAttempts, setMaxRedialAttempts] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({
    status: [],
    minRedialAttempts: "",
    maxRedialAttempts: "",
    minDuration: "",
    maxDuration: "",
    dateFilter: "",
    scheduledDate: "",
    lastDialedDate: "",
    uploadStatus: [],
    preProcessStatus: [],
    redialAttempts: [],
  });
  const [uploadDataCall, setUploadDataCall] = useState(false);
  const [campaignTimerRefreshCount, setCampaignTimerRefreshCount] = useState(0);
  const [campaignTimerRefreshTimout, setCampaignTimerRefreshTimout] =
    useState(null);
  const [nextCall, setNextCall] = useState({});
  const [previousCall, setPreviousCall] = useState({});
  const [outstandingModal, setOutstandingModal] = useState(false);
  const [tabs, setTabs] = useState([
    { name: "Contacts", values: "contacts" },
    { name: "Call Logs", values: "callLogs" },
    { name: "Calls Due", values: "callDues" },
  ]);

  const statusLabels = {
    pending: "Pending",
    invalidNumber: "Invalid Number",
    noAnswered: "No Answer",
    busy: "Busy",
    rejected: "Rejected",
    networkError: "Network Error",
    answered: "Answered",
    processing: "Processing",
    undefinedError: "Undefined Error",
    incorrectFormat: "Incorrect Format",
    blocked: "Spam Block",
    processed: "Processed",
    complete: "Complete",
  };

  const handleTabChange = (event, newTab) => {
    clearAllFilters();
    setSelectedTab(newTab);
    setCurrentPage(1);
  };

  const getCampaignContactDetails = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("offset", (currentPage - 1) * recordsPerPage);
      queryParams.set("count", recordsPerPage);
      queryParams.set("callType", selectedTab);

      Object.entries(appliedFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          queryParams.set(key, value.join(","));
        } else if (value !== "" && value !== null && value !== undefined) {
          queryParams.set(key, value.toString());
        }
        if (key === "dateFilter" && value === "custom") {
          queryParams.set("dateFilter", "range");
        }
        if (key === "dateFilter" && value === "last7days") {
          queryParams.set("dateFilter", "7 days");
        }
        if (key === "dateFilter" && value === "today") {
          queryParams.set("dateFilter", "today");
        }
        if (key === "dateFilter" && value === "thisMonth") {
          queryParams.set("dateFilter", "this month");
        }
        if (
          ["scheduledDate", "lastDialedDate"].includes(key) &&
          ["scheduledDate", "lastDialedDate"].includes(value)
        ) {
          queryParams.set(key, value);
        }
      });

      const response = await getCampaignContactDetailsApi(
        campaignId,
        queryParams.toString()
      );
      setMaxDurationOfCalls(response?.maxCallDuration);
      setMaxRedialAttempts(response?.maxRedialAttempts);
      setCampaignContact(response);
    } catch (error) {
      const errorMessage = error?.data?.response?.message
        ? error.data.response.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCampaignDetails = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("callType", selectedTab);

      Object.entries(appliedFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          queryParams.set(key, value.join(","));
        } else if (value !== "" && value !== null && value !== undefined) {
          queryParams.set(key, value.toString());
        }
        if (key === "dateFilter" && value === "custom") {
          queryParams.set("dateFilter", "range");
        }
        if (key === "dateFilter" && value === "last7days") {
          queryParams.set("dateFilter", "7 days");
        }
        if (key === "dateFilter" && value === "today") {
          queryParams.set("dateFilter", "today");
        }
        if (key === "dateFilter" && value === "thisMonth") {
          queryParams.set("dateFilter", "this month");
        }
        if (
          ["scheduledDate", "lastDialedDate"].includes(key) &&
          ["scheduledDate", "lastDialedDate"].includes(value)
        ) {
          queryParams.set(key, value);
        }
      });

      const campaignDetailsResponse = await getCampaignDetailsApi(
        params.campaignId,
        queryParams.toString()
      );
      setNextCall({
        id: campaignDetailsResponse?.campaignLinked?.nextSession?.id,
      });
      setPreviousCall({
        id: campaignDetailsResponse?.campaignLinked?.previousSession?.id,
      });
      setCampaignDetails(campaignDetailsResponse);
      if (campaignDetailsResponse?.statusCount?.["callLogs"] !== 0) {
        setTabs([
          { name: "Call Logs", values: "callLogs" },
          { name: "Calls Due", values: "callDues" },
          { name: "Contacts", values: "contacts" },
        ]);
        // setSelectedTab(tabs[0].values);
      }
    } catch (error) {
      const errorMessage =
        error?.data?.response?.message ??
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong";

      showSkillrToast(errorMessage, "error");
    }
  };

  const updateCampaign = async (payload) => {
    try {
      await updateCampaignApi(campaignId, payload);
      setCampaignDetails({ ...campaignDetails, status: payload?.status });
      showSkillrToast("Campaign status updated", "success");
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleUpdateCampaignStatus = () => {
    if (campaignDetails?.status === "active") {
      updateCampaign({ status: "paused" });
    } else if (campaignDetails?.status === "paused") {
      updateCampaign({ status: "active" });
    }
  };

  const handleEndCampaign = () => {
    updateCampaign({ status: "complete" });
  };

  const handleOnCopyWebHookIdentifier = () => {
    navigator.clipboard.writeText(
      `${
        process.env.REACT_APP_BACKEND_API_ENDPOINT ||
        window.location.protocol + "//" + window.location.hostname
      }/api/campaign/webhook?importId=${campaignDetails?.webhookIdentifier}`
    );
    showSkillrToast("Copied to clipboard", "success");
  };

  const handleUploadCsv = () => {
    setUploadDataCall(true);
  };

  const handleOnCloseUploadCall = (event, reason) => {
    if (reason !== "backdropClick") {
      setUploadDataCall(false);
    }
  };

  const handleDownloadSampleTemplate = () => {
    window.open(sampleCSVDownloadLink);
  };

  const handleOnSaveUploadedFiles = () => {};

  const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.isSame(end, "day")) {
      return start.format("MMMM D, YYYY");
    } else if (start.isSame(end, "year")) {
      return `${start.format("MMM D")} - ${end.format("MMM D, YYYY")}`;
    } else {
      return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`;
    }
  };

  /**
   * This function will refresh the data after every 10 seconds
   * it will stop calling once its call for 2 minutes or we do not have any remaining calls to process.
   */
  const refreshDataAfterSomeTime = () => {
    clearTimeout(campaignTimerRefreshTimout);
    setCampaignTimerRefreshTimout(undefined);
    const timeOutId = setTimeout(() => {
      getCampaignDetails();
      getCampaignContactDetails();
      setCampaignTimerRefreshCount((prevCount) => prevCount + 1);
    }, 10000);
    setCampaignTimerRefreshTimout(timeOutId);
  };

  const applyFilters = (newFilters) => {
    setAppliedFilters(newFilters);
    setCurrentPage(1);
    // Update URL with new filters
    const searchParams = new URLSearchParams(location.search);
    Object.entries(newFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        searchParams.set(key, value.join(","));
      } else if (value !== "" && value !== null && value !== undefined) {
        if (key === "dateFilter") {
          if (value === "last7days") searchParams.set(key, "7 days");
          else if (value === "today") searchParams.set(key, "today");
          else if (value === "thisMonth") searchParams.set(key, "this month");
          else if (value === "custom") {
            searchParams.set(key, "range");
            searchParams.set("startDate", newFilters.startDate);
            searchParams.set("endDate", newFilters.endDate);
          } else searchParams.set(key, value);
        } else if (["scheduledDate", "lastDialedDate"].includes(key)) {
          searchParams.set(key, value);
          searchParams.set(`start_${value}`, newFilters[`start_${value}`]);
          searchParams.set(`end_${value}`, newFilters[`end_${value}`]);
        } else {
          searchParams.set(key, value.toString());
        }
      }
    });
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleRemoveFilter = (filterType, filterValue) => {
    if (
      filterType === "startDate" ||
      filterType === "endDate" ||
      [
        "start_scheduledDate",
        "end_scheduledDate",
        "start_lastDialedDate",
        "end_lastDialedDate",
      ].includes(filterType)
    ) {
      setAppliedFilters((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
        start_scheduledDate: null,
        end_scheduledDate: null,
        start_lastDialedDate: null,
        end_lastDialedDate: null,
        dateFilter: "",
        scheduledDate: "",
        lastDialedDate: "",
      }));

      // Update URL without date filters
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("startDate");
      searchParams.delete("endDate");
      searchParams.delete("start_scheduledDate");
      searchParams.delete("end_scheduledDate");
      searchParams.delete("start_lastDialedDate");
      searchParams.delete("end_lastDialedDate");
      searchParams.delete("dateFilter");
      searchParams.delete("scheduledDate");
      searchParams.delete("lastDialedDate");
      navigate(`?${searchParams.toString()}`, { replace: true });
      return;
    }

    const newFilters = { ...appliedFilters };
    if (Array.isArray(newFilters[filterType])) {
      newFilters[filterType] = newFilters[filterType].filter(
        (item) => item !== filterValue
      );
    } else {
      newFilters[filterType] = "";
    }

    if (filterType === "duration") {
      newFilters.minDuration = "";
      newFilters.maxDuration = "";
    }
    setAppliedFilters(newFilters);
    applyFilters(newFilters);
  };

  const clearAllFilters = () => {
    const clearedFilters = {
      status: [],
      minRedialAttempts: "",
      maxRedialAttempts: "",
      minDuration: "",
      maxDuration: "",
      startDate: null,
      start_scheduledDate: null,
      end_scheduledDate: null,
      start_lastDialedDate: null,
      end_lastDialedDate: null,
      endDate: null,
      dateFilter: "",
      scheduledDate: "",
      lastDialedDate: "",
      uploadStatus: [],
      preProcessStatus: [],
      redialAttempts: [],
    };
    setAppliedFilters(clearedFilters);
    setCurrentPage(1);
    navigate(`?tab=${selectedTab}&page=${currentPage}`, {
      replace: true,
    });
  };

  const isFilterApplied = () => {
    if (!appliedFilters || typeof appliedFilters !== "object") {
      return false;
    }
    return Object.entries(appliedFilters).some(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (key === "minDurationFilter") {
        return value !== "";
      }
      if (
        key === "dateFilter" ||
        key === "scheduledDate" ||
        key === "lastDialedDate"
      ) {
        return value !== "";
      }

      if (key === "maxDurationFilter") {
        return value !== 0 && value !== "";
      }
      if (key === "startDate" || key === "endDate") {
        return value !== null;
      }
      if (key === "start_scheduledDate" || key === "end_scheduledDate") {
        return value !== null;
      }
      if (key === "start_lastDialedDate" || key === "end_lastDialedDate") {
        return value !== null;
      }
      return value !== "" && value !== null && value !== undefined;
    });
  };

  const parseFiltersFromURL = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {
      status: searchParams.get("status")
        ? searchParams.get("status").split(",").filter(Boolean)
        : [],
      minRedialAttempts: searchParams.get("minRedialAttempts") || "",
      maxRedialAttempts: searchParams.get("maxRedialAttempts") || "",
      minDuration: searchParams.get("minDuration") || "",
      maxDuration: searchParams.get("maxDuration") || "",
      startDate: searchParams.get("startDate")
        ? moment(searchParams.get("startDate")).toDate()
        : null,
      endDate: searchParams.get("endDate")
        ? moment(searchParams.get("endDate")).toDate()
        : null,
      start_scheduledDate: searchParams.get("start_scheduledDate")
        ? moment(searchParams.get("start_scheduledDate")).toDate()
        : null,
      end_scheduledDate: searchParams.get("end_scheduledDate")
        ? moment(searchParams.get("end_scheduledDate")).toDate()
        : null,
      start_lastDialedDate: searchParams.get("start_lastDialedDate")
        ? moment(searchParams.get("start_lastDialedDate")).toDate()
        : null,
      end_lastDialedDate: searchParams.get("end_lastDialedDate")
        ? moment(searchParams.get("end_lastDialedDate")).toDate()
        : null,
      dateFilter: searchParams.get("dateFilter") || "",
      scheduledDate: searchParams.get("scheduledDate") || "",
      lastDialedDate: searchParams.get("lastDialedDate") || "",
      uploadStatus: searchParams.get("uploadStatus")
        ? searchParams.get("uploadStatus").split(",").filter(Boolean)
        : [],
      preProcessStatus: searchParams.get("preProcessStatus")
        ? searchParams.get("preProcessStatus").split(",").filter(Boolean)
        : [],
      redialAttempts: searchParams.get("redialAttempts")
        ? searchParams.get("redialAttempts").split(",").filter(Boolean)
        : [],
    };

    // Map the dateFilter values
    if (filters.dateFilter === "7 days") {
      filters.dateFilter = "last7days";
    } else if (filters.dateFilter === "today") {
      filters.dateFilter = "today";
    } else if (filters.dateFilter === "this month") {
      filters.dateFilter = "thisMonth";
    } else if (filters.dateFilter === "range") {
      filters.dateFilter = "custom";
    }

    return filters;
  }, [location.search]);

  useEffect(() => {
    const filtersFromURL = parseFiltersFromURL();
    setAppliedFilters(filtersFromURL);
    setFiltersInitialized(true);
  }, [parseFiltersFromURL]);

  useEffect(() => {
    if (filtersInitialized) {
      getCampaignDetails();
      getCampaignContactDetails();
    }
  }, [
    currentPage,
    selectedTab,
    appliedFilters,
    filtersInitialized,
    params.campaignId,
  ]);

  useEffect(() => {
    const remainingCount =
      (campaignDetails?.statusCount?.pending ?? 0) +
      (campaignDetails?.statusCount?.processing ?? 0);
    if (campaignTimerRefreshCount < 12 && remainingCount > 0) {
      refreshDataAfterSomeTime();
    }
    return () => {
      clearTimeout(campaignTimerRefreshTimout);
    };
  }, [campaignTimerRefreshCount, campaignDetails]);
  return (
    <PageWrapper className={styles.container}>
      {campaignDetails && (
        <Box>
          <Breadcrumbs separator={">"} aria-label="breadcrumb">
            <Typography variant="body2">
              <Link
                to="/campaign"
                color="#7b7b7b"
                className={styles.campaignLink}
              >
                Campaign
              </Link>
            </Typography>
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              {campaignDetails?.name}
            </Typography>
          </Breadcrumbs>

          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h4">{campaignDetails?.name}</Typography>
            <Box
              className={styles.box}
              sx={{
                background:
                  statusColorMapping[campaignDetails?.status ?? "pending"]
                    ?.backgroundColor ??
                  statusColorMapping.undefinedError.backgroundColor,
              }}
            >
              <Typography
                fontSize={10}
                fontWeight={500}
                lineHeight="normal"
                color={
                  statusColorMapping[campaignDetails?.status ?? "pending"]
                    ?.color ?? statusColorMapping.undefinedError.color
                }
              >
                {campaignDetails?.status.charAt(0).toUpperCase() +
                  campaignDetails?.status.slice(1)}
              </Typography>
            </Box>
          </Box>

          <Box mt={2} display="flex" alignItems="center">
            <Typography variant="body2" color="#797979">
              {/* Phone Number - {campaignDetails.didNumber} */}
              Phone Number -
            </Typography>
            <Box display="flex" gap={2} ml={2}>
              {campaignDetails.didNumbers?.slice(0, 2).map((didNumber) => (
                <Box className={styles.box} sx={{ background: "#C9E4F1" }}>
                  <Typography
                    fontSize={10}
                    fontWeight={500}
                    lineHeight="normal"
                    color={"#224C61"}
                  >
                    {didNumber}
                  </Typography>
                </Box>
              ))}

              {campaignDetails.didNumbers?.length > 2 && (
                <Tooltip
                  title={campaignDetails.didNumbers
                    .slice(2)
                    .map((n) => n)
                    .join(", ")}
                >
                  <Box className={styles.box} sx={{ background: "#C9E4F1" }}>
                    <Typography
                      fontSize={10}
                      fontWeight={500}
                      lineHeight="normal"
                      color={"#224C61"}
                    >
                      {`+ ${campaignDetails.didNumbers.length - 2} More`}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </Box>
            <Typography sx={{ mx: 4 }} color="#9d9d9d">
              |
            </Typography>
            <Typography variant="body2" color="#797979">
              Call Slot - {campaignDetails?.startTime} -{" "}
              {campaignDetails?.endTime}
            </Typography>
            {campaignDetails?.startDate && campaignDetails?.endDate && (
              <>
                <Typography sx={{ mx: 4 }} color="#9d9d9d">
                  |
                </Typography>
                <Typography variant="body2" color="#797979">
                  Date Range -{" "}
                  {moment(campaignDetails.startDate).format("DD/MM/YYYY")} -{" "}
                  {moment(campaignDetails.endDate).format("DD/MM/YYYY")}
                </Typography>
              </>
            )}
            <FlexBox ml="auto">
              {campaignDetails?.status !== "complete" && (
                <FundamentoPopup
                  className={styles.popoverContainer}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  triggeringComponent={
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 3,
                        border: "1px solid #505050",
                        "&:hover": {
                          border: "1px solid #505050",
                        },
                        height: 40,
                      }}
                      endIcon={<ChevronDown width={16} height={16} />}
                    >
                      Actions
                    </Button>
                  }
                >
                  <Box>
                    {campaignDetails?.status === "active" ? (
                      <FlexBox
                        className={styles.popupItem}
                        columnGap={2}
                        onClick={handleUpdateCampaignStatus}
                      >
                        <Pause
                          width={16}
                          height={16}
                          style={{ flexShrink: 0 }}
                        />
                        <Typography variant="body2">Pause Campaign</Typography>
                      </FlexBox>
                    ) : (
                      <FlexBox
                        className={styles.popupItem}
                        columnGap={2}
                        onClick={handleUpdateCampaignStatus}
                      >
                        <Play
                          width={16}
                          height={16}
                          style={{ flexShrink: 0 }}
                        />
                        <Typography variant="body2">
                          Restart Campaign
                        </Typography>
                      </FlexBox>
                    )}
                    <FlexBox
                      className={styles.popupItem}
                      columnGap={2}
                      onClick={handleEndCampaign}
                    >
                      <StopCircle
                        // fill="#0f0f0f"
                        width={16}
                        height={16}
                        style={{ flexShrink: 0 }}
                      />
                      <Typography variant="body2">Stop Campaign</Typography>
                    </FlexBox>
                    <FlexBox
                      className={styles.popupItem}
                      columnGap={2}
                      onClick={() =>
                        navigate(`/campaign/edit/${params.campaignId}`)
                      }
                    >
                      <Edit2
                        // fill="#0f0f0f"
                        width={16}
                        height={16}
                        style={{ flexShrink: 0 }}
                      />
                      <Typography variant="body2">Edit Campaign</Typography>
                    </FlexBox>
                    <FlexBox
                      className={styles.popupItem}
                      columnGap={2}
                      onClick={handleUploadCsv}
                    >
                      <IconUpload2
                        width={16}
                        height={16}
                        style={{ flexShrink: 0 }}
                      />
                      <Typography variant="body2">Upload Call Data</Typography>
                    </FlexBox>
                    <FlexBox
                      className={styles.popupItem}
                      columnGap={2}
                      onClick={handleDownloadSampleTemplate}
                    >
                      <IconDownload
                        width={16}
                        height={16}
                        style={{ flexShrink: 0 }}
                      />
                      <Typography variant="body2">
                        Download Sample Template
                      </Typography>
                    </FlexBox>
                  </Box>
                </FundamentoPopup>
              )}

              <Button
                startIcon={<RightArrow color="white" />}
                variant="dark"
                sx={{ borderRadius: "12px", padding: "12px 24px" }}
                onClick={() => setExportModal(true)}
              >
                Export Data
              </Button>

              <ExportModal
                campaignId={campaignId}
                open={exportModal}
                onClose={() => setExportModal(false)}
                totalCalls={campaignDetails?.statusCount?.callLogs}
                fields={campaignDetailsFields}
                filters={appliedFilters}
                exportName={true}
              />
            </FlexBox>
          </Box>
        </Box>
      )}

      <Box display="flex" gap={5} mt={8}>
        <Box className={styles.headerBoxContainer} sx={{ flex: 1 }}>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Total Calls</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.statusCount?.all ?? 0}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Connected</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.statusCount?.answered ?? 0}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Not Answered</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.noAnswered ?? 0) +
                (campaignDetails?.statusCount?.busy ?? 0) +
                (campaignDetails?.statusCount?.rejected ?? 0)}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Not Reachable</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.statusCount?.networkError ?? 0}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Invalid</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.invalidNumber ?? 0) +
                (campaignDetails?.statusCount?.undefinedError ?? 0) +
                (campaignDetails?.statusCount?.incorrectFormat ?? 0) +
                (campaignDetails?.statusCount?.blocked ?? 0)}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Remaining</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.pending ?? 0) +
                (campaignDetails?.statusCount?.processing ?? 0)}
            </Typography>
          </Box>
          {/* <Box className={styles.headerBox} sx={{ position: "relative" }}>
            <IconButton
              sx={{
                position: "absolute",
                top: -6,
                right: 4,
                backgroundColor: theme.palette.grey[100],
                "&:hover": { backgroundColor: theme.palette.grey[300] },
                borderRadius: 1,
                width: 26,
                height: 26,
                padding: 0,
              }}
              onClick={() => setOutstandingModal(true)}
            >
              <AnalyticsOutline width={12} height={12} />
            </IconButton>
            <Typography variant="body2">Outstanding</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.pending ?? 0) +
                (campaignDetails?.statusCount?.processing ?? 0) +
                (campaignDetails?.statusCount?.RedialOutstading ?? 0) +
                (campaignDetails?.statusCount?.networkError ?? 0) +
                (campaignDetails?.statusCount?.noAnswered ?? 0) +
                (campaignDetails?.statusCount?.busy ?? 0) +
                (campaignDetails?.statusCount?.rejected ?? 0)}
            </Typography>
            {outstandingModal && (
              <OutStandingModal
                open={outstandingModal}
                onClose={() => setOutstandingModal(false)}
                campaignDetails={campaignDetails}
              ></OutStandingModal>
            )}
          </Box> */}
        </Box>

        <Box className={styles.headerBoxContainer}>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Total Duration</Typography>
            <Typography variant="body1" fontWeight={500}>
              {getFormattedTime(campaignDetails?.totalCallDuration) || 0}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          className={styles.tabs}
          TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
          sx={{
            "& span.MuiTabs-indicator": {
              borderRadius: "10px 10px 0 0",
              height: 3,
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              className={styles.tab}
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="caption"
                  fontWeight={500}
                  color={"inherit"}
                >
                  {tab.name} ({campaignDetails?.statusCount?.[tab.values] ?? 0})
                </Typography>
              }
              value={tab.values}
              key={index}
            />
          ))}
        </Tabs>
        <Divider
          sx={{
            backgroundColor: theme.palette.borderColor.light,
          }}
        />
      </Box>

      <FlexBox mt={4}>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<IconFilter2 width={20} height={20} />}
          sx={{
            backgroundColor: "#f9f9f9",
            minWidth: 0,
            alignSelf: "flex-start",
          }}
          onClick={() => setFilterModalOpen(true)}
        >
          Filter
        </Button>

        {isFilterApplied() && (
          <Button
            variant="text"
            color="primary"
            sx={{ minWidth: 0, ml: 2 }}
            onClick={clearAllFilters}
          >
            Clear All
          </Button>
        )}

        {Object.entries(appliedFilters).map(([filterType, filterValues]) => {
          if (
            filterType === "startDate" ||
            filterType === "endDate" ||
            [
              "start_scheduledDate",
              "end_scheduledDate",
              "start_lastDialedDate",
              "end_lastDialedDate",
              "scheduledDate",
              "lastDialedDate",
            ].includes(filterType) ||
            filterType === "dateFilter"
          ) {
            return null;
          }
          if (filterType === "minDuration" || filterType === "maxDuration") {
            return null;
          }
          if (Array.isArray(filterValues)) {
            return filterValues.map((filterValue) =>
              [
                "status",
                "uploadStatus",
                "preProcessStatus",
                "redialAttempts",
              ].includes(filterType) && filterValue === "" ? (
                <></>
              ) : (
                <FlexBox key={filterValue} className={styles.chip}>
                  {`${
                    filterType.charAt(0).toUpperCase() + filterType.slice(1)
                  }: ${
                    filterType != "redialAttempts"
                      ? statusLabels[filterValue]
                      : (filterValue === "0" && "Initial Call") ||
                        (filterValue?.[filterValue.length - 1] === "1" &&
                          `${filterValue}st Attempt`) ||
                        (filterValue?.[filterValue.length - 1] === "2" &&
                          `${filterValue}nd Attempt`) ||
                        (filterValue?.[filterValue.length - 1] === "3" &&
                          `${filterValue}rd Attempt`) ||
                        `${filterValue}th Attempt`
                  }`}
                  <IconButton
                    size="small"
                    sx={{ padding: 1 }}
                    onClick={() => handleRemoveFilter(filterType, filterValue)}
                  >
                    <X width={12} height={12} />
                  </IconButton>
                </FlexBox>
              )
            );
          } else if (filterValues) {
            return (
              <FlexBox key={filterType} className={styles.chip}>
                {`${
                  filterType.charAt(0).toUpperCase() + filterType.slice(1)
                }: ${filterValues}`}

                <IconButton
                  sx={{ padding: 1 }}
                  size="small"
                  onClick={() => handleRemoveFilter(filterType, filterValues)}
                >
                  <X width={12} height={12} />
                </IconButton>
              </FlexBox>
            );
          }
          return null;
        })}

        {appliedFilters.dateFilter &&
          !["custom", "scheduledDate", "lastDialedDate"].includes(
            appliedFilters.dateFilter
          ) && (
            <FlexBox className={styles.chip}>
              {`Date: ${
                appliedFilters.dateFilter === "last7days"
                  ? "Last 7 Days"
                  : appliedFilters.dateFilter === "today"
                  ? "Today"
                  : appliedFilters.dateFilter === "thisMonth"
                  ? "This Month"
                  : ""
              }`}
              <IconButton
                sx={{ padding: 1 }}
                size="small"
                onClick={() => handleRemoveFilter("dateFilter")}
              >
                <X width={12} height={12} />
              </IconButton>
            </FlexBox>
          )}

        {(appliedFilters.minDuration || appliedFilters.maxDuration) && (
          <FlexBox className={styles.chip}>
            {`Duration: ${appliedFilters.minDuration || "0"} - ${
              appliedFilters.maxDuration || "Max"
            }`}
            <IconButton
              sx={{ padding: 1 }}
              size="small"
              onClick={() => {
                handleRemoveFilter("duration");
              }}
            >
              <X width={12} height={12} />
            </IconButton>
          </FlexBox>
        )}

        {["custom"].includes(appliedFilters.dateFilter) &&
          appliedFilters.startDate &&
          appliedFilters.endDate && (
            <FlexBox className={styles.chip}>
              {`Date: ${formatDateRange(
                appliedFilters.startDate,
                appliedFilters.endDate
              )}`}
              <IconButton
                sx={{ padding: 1 }}
                size="small"
                onClick={() => {
                  handleRemoveFilter("startDate");
                  handleRemoveFilter("endDate");
                }}
              >
                <X width={12} height={12} />
              </IconButton>
            </FlexBox>
          )}
        {appliedFilters.scheduledDate &&
          appliedFilters?.[`start_scheduledDate`] &&
          appliedFilters?.[`end_scheduledDate`] && (
            <FlexBox className={styles.chip}>
              {`Scheduled Date: ${formatDateRange(
                appliedFilters?.[`start_scheduledDate`],
                appliedFilters?.[`end_scheduledDate`]
              )}`}
              <IconButton
                sx={{ padding: 1 }}
                size="small"
                onClick={() => {
                  handleRemoveFilter(`start_scheduledDate`);
                  handleRemoveFilter(`end_scheduledDate`);
                }}
              >
                <X width={12} height={12} />
              </IconButton>
            </FlexBox>
          )}
        {appliedFilters.lastDialedDate &&
          appliedFilters?.[`start_lastDialedDate`] &&
          appliedFilters?.[`end_lastDialedDate`] && (
            <FlexBox className={styles.chip}>
              {`Last Dialed Date: ${formatDateRange(
                appliedFilters?.[`start_lastDialedDate`],
                appliedFilters?.[`end_lastDialedDate`]
              )}`}
              <IconButton
                sx={{ padding: 1 }}
                size="small"
                onClick={() => {
                  handleRemoveFilter(`start_lastDialedDate`);
                  handleRemoveFilter(`end_lastDialedDate`);
                }}
              >
                <X width={12} height={12} />
              </IconButton>
            </FlexBox>
          )}

        <FilterModal
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          filters={appliedFilters}
          applyFilters={applyFilters}
          fields={filters[selectedTab]}
          maxDurationOfCalls={maxDurationOfCalls}
          maxRedialAttempts={maxRedialAttempts}
        />
      </FlexBox>
      <Box mt={4} sx={{ flex: 1 }}>
        {selectedTab === "contacts" && !campaignContact?.contacts?.length && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Attachment width={50} height={50} />
            <Typography
              fontSize={24}
              fontWeight={500}
              textAlign="center"
              color="#7b7b7b"
              my={4}
            >
              No Contacts Uploaded
            </Typography>
            <Button
              variant="outlined"
              className={styles.uploadCsvButton}
              onClick={handleUploadCsv}
            >
              Upload CSV
            </Button>
            <Typography variant="body2" my={2}>
              Or
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="body2" whiteSpace="nowrap">
                Automatically add contacts via the webhook at{" "}
              </Typography>
              <Tooltip
                title={`${
                  process.env.REACT_APP_BACKEND_API_ENDPOINT ||
                  window.location.protocol + "//" + window.location.hostname
                }/api/campaign/webhook?importId=${
                  campaignDetails?.webhookIdentifier
                }`}
              >
                <Typography
                  href="https://www.fundamento.ai/"
                  className={styles.webhookUrl}
                >
                  {`${
                    process.env.REACT_APP_BACKEND_API_ENDPOINT ||
                    window.location.protocol + "//" + window.location.hostname
                  }/api/campaign/webhook?importId=${
                    campaignDetails?.webhookIdentifier
                  }`}
                </Typography>
              </Tooltip>
              <IconButton onClick={handleOnCopyWebHookIdentifier}>
                <Duplicate width={16} height={16} />
              </IconButton>
            </Box>
          </Box>
        )}
        {selectedTab !== "contacts" && !campaignContact?.contacts?.length && (
          <EmptyState title="No Data Found"></EmptyState>
        )}

        {campaignContact?.contacts?.length > 0 ? (
          <DetailsList
            currentPage={currentPage}
            campaingContacts={campaignContact?.contacts ?? []}
            setCurrentPage={setCurrentPage}
            totalPageCount={Math.ceil(
              campaignContact?.totalRecords / recordsPerPage
            )}
            isPreProcessEnabled={campaignDetails?.preProcessCampaign ?? false}
            selectedTab={selectedTab}
            maxRedialAttempts={maxRedialAttempts}
            previousCallId={previousCall.id}
            nextCallId={nextCall.id}
          />
        ) : (
          <>
            <FlexBox justifyContent="space-between" my={4}>
              <Button
                variant="contained"
                startIcon={<ChevronLeft />}
                sx={{ backgroundColor: theme.palette.grey[50] }}
                color="inherit"
                onClick={() => {
                  if (previousCall.id) {
                    navigate(
                      `/campaign/${previousCall.id}?tab=contacts&page=1`,
                      {
                        replace: true,
                      }
                    );
                  }
                  setSelectedTab("contacts");
                }}
                disabled={!previousCall.id}
              >
                Previous Campaign
              </Button>
              <Button
                variant="contained"
                endIcon={<ChevronRight />}
                color="inherit"
                sx={{ backgroundColor: theme.palette.grey[50] }}
                onClick={() => {
                  if (nextCall.id) {
                    navigate(`/campaign/${nextCall.id}?tab=contacts&page=1`, {
                      replace: true,
                    });
                  }
                  setSelectedTab("contacts");
                }}
                disabled={!nextCall.id}
              >
                Next Campaign
              </Button>
            </FlexBox>
            <div
              style={{
                height: 24,
                backgroundColor: theme.palette.common.white,
              }}
            />
          </>
        )}
      </Box>

      <UploadCallData
        open={uploadDataCall}
        onClose={handleOnCloseUploadCall}
        onSave={handleOnCloseUploadCall}
        campaignDetails={campaignDetails}
        getCampaignContactDetails={getCampaignContactDetails}
        getCampaignDetails={getCampaignDetails}
      />
    </PageWrapper>
  );
};

export default CampaignDetails;
