import { getRequest } from "./common";

const getCampaignDetailsV1 = (campaignId, params, config = {}) => {
  if (params) {
    return getRequest(`/campaign/v1/${campaignId}?${params}`, config);
  }
  return getRequest(`/campaign/v1/${campaignId}`, config);
};

export default getCampaignDetailsV1;
