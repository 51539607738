import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Grow,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  Slider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Popover,
  InputAdornment,
  TextField,
  Icon,
  IconButton,
} from "@mui/material";
import styles from "./index.style";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { DateCalendar } from "@mui/x-date-pickers";
import { ChevronDown, ChevronUp, Circle } from "react-feather";
import { RadioButton } from "components/newSVG";
import moment from "moment";
import { CalendarToday, ConstructionOutlined } from "@mui/icons-material";
import { statusTextMapping as statusLabels } from "text-constants/campaign";
import { useSkillrToast } from "context/toast";
import FundamentoSlider from "components/FundamentoSlider";

const DateSelector = ({ label, value, onChange, minDate, maxDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date) => {
    onChange(date);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box mb={2}>
      <Typography variant="caption" mb={1} color="text.primary">
        {label}
      </Typography>
      <TextField
        fullWidth
        value={value ? moment(value).format("DD/MM/YYYY") : ""}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        placeholder="dd/mm/yyyy"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ zIndex: 9999 }}
      >
        <DateCalendar
          value={value || null}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Popover>
    </Box>
  );
};

const TimeSelector = ({ label, time, onChange }) => {
  const H = time?.split(":")[0];
  const M = time?.split(":")[1]?.split(" ")[0];
  const AmPm = time?.split(":")[1]?.split(" ")[1];
  const [hour, setHour] = useState(parseInt(H));
  const [minute, setMinute] = useState(parseInt(M));
  const [amPm, setAmPm] = useState(AmPm);
  const handleTimeChange = () => {
    const formattedTime = moment(`${hour}:${minute} ${amPm}`, "h:mm A").format(
      "hh:mm A"
    );
    onChange(formattedTime);
  };
  useEffect(() => {
    if (parseInt(H) !== hour) {
      setHour(parseInt(H));
    }
    if (parseInt(M) !== minute) {
      setMinute(parseInt(M));
    }
    if (AmPm !== amPm) {
      setAmPm(AmPm);
    }
  }, [H, M, AmPm]);

  useEffect(() => {
    handleTimeChange();
  }, [hour, minute, amPm]);

  return (
    <Box mb={2}>
      <Typography variant="caption" color="text.primary">
        {label}
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        {/* Hour Selector */}
        <Box textAlign={"center"}>
          <IconButton
            size="small"
            onClick={() => {
              if (hour === 12) {
                setHour(1);
              } else {
                setHour(hour + 1);
              }
              if (hour === 11) {
                if (amPm === "AM") {
                  setAmPm("PM");
                } else {
                  setAmPm("AM");
                }
              }
            }}
          >
            <ChevronUp></ChevronUp>
          </IconButton>
          <TextField
            value={hour < 10 ? `0${hour}` : hour}
            onChange={(e) => setHour(Math.max(1, Math.min(12, e.target.value)))}
            inputProps={{ min: 1, max: 12 }}
            sx={{ width: 60, textAlign: "center" }}
          />
          <IconButton
            size="small"
            onClick={() => {
              if (hour === 1) {
                setHour(12);
              } else {
                setHour(hour - 1);
              }
              if (hour === 12) {
                if (amPm === "AM") {
                  setAmPm("PM");
                } else {
                  setAmPm("AM");
                }
              }
            }}
          >
            <ChevronDown></ChevronDown>
          </IconButton>
        </Box>
        <Typography fontSize={54} fontWeight={"bold"} mx={1}>
          :
        </Typography>
        {/* Minute Selector */}{" "}
        <Box textAlign={"center"}>
          <IconButton
            size="small"
            onClick={() => {
              if (minute === 59) {
                if (hour === 12) {
                  setHour(1);
                } else {
                  setHour(hour + 1);
                }
                setMinute(0);
                if (hour === 11) {
                  if (amPm === "AM") {
                    setAmPm("PM");
                  } else {
                    setAmPm("AM");
                  }
                }
              } else {
                setMinute(minute + 1);
              }
            }}
          >
            <ChevronUp></ChevronUp>
          </IconButton>
          <TextField
            value={minute < 10 ? `0${minute}` : minute}
            onChange={(e) =>
              setMinute(Math.max(0, Math.min(59, e.target.value)))
            }
            inputProps={{ min: 0, max: 59 }}
            sx={{ width: 60, textAlign: "center" }}
          />
          <IconButton
            size="small"
            onClick={() => {
              if (minute === 0) {
                if (hour === 1) {
                  setHour(12);
                } else {
                  setHour(hour - 1);
                }
                setMinute(59);
                if (hour === 12) {
                  if (amPm === "AM") {
                    setAmPm("PM");
                  } else {
                    setAmPm("AM");
                  }
                }
              } else {
                setMinute(minute - 1);
              }
            }}
          >
            <ChevronDown></ChevronDown>
          </IconButton>
        </Box>
        {/* AM/PM Selector */}
        <Box mb={2}>
          <Button
            variant="outlined"
            onClick={() => setAmPm("AM")}
            sx={{ ...styles.amButton, ...(amPm === "AM" ? styles.active : {}) }}
          >
            AM
          </Button>
          <Button
            variant="outlined"
            onClick={() => setAmPm("PM")}
            sx={{ ...styles.pmButton, ...(amPm === "PM" ? styles.active : {}) }}
          >
            PM
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const FilterModal = ({
  open,
  onClose,
  filters,
  applyFilters,
  fields,
  maxDurationOfCalls,
  campaignPage,
  campaigns,
  maxRedialAttempts,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tempFilters, setTempFilters] = useState(filters);
  const [scheduledStartTimeString, setScheduledStartTimeString] =
    useState("12:0 AM");
  const [scheduledEndTimeString, setScheduledEndTimeString] =
    useState("12:0 AM");
  const [lastDialedStartTimeString, setLastDialedStartTimeString] =
    useState("12:0 AM");
  const [lastDialedEndTimeString, setLastDialedEndTimeString] =
    useState("12:0 AM");

  const { showSkillrToast } = useSkillrToast();
  const campaignStatus = campaignPage
    ? ["active", "paused", "draft", "complete", "pending"]
    : [
        "pending",
        "invalidNumber",
        "noAnswered",
        "busy",
        "rejected",
        "networkError",
        "answered",
        "processing",
        "undefinedError",
        "incorrectFormat",
        "blocked",
      ];

  const preProcesssStatus = ["processed", "pending"];

  const uploadStatuses = ["complete", "incorrectFormat"];
  // const statusLabels = {
  //   pending: "Pending",
  //   invalidNumber: "Invalid Number",
  //   noAnswered: "No Answer",
  //   busy: "Busy",
  //   rejected: "Rejected",
  //   networkError: "Network Error",
  //   answered: "Answered",
  //   processing: "Processing",
  //   undefinedError: "Undefined Error",
  //   incorrectFormat: "Incorrect Format",
  //   blocked: "Spam Block",
  // };

  const httpStatusCodes = [
    "ERR",
    100,
    101,
    102,
    103,
    200,
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    226,
    300,
    301,
    302,
    303,
    304,
    305,
    306,
    307,
    308,
    400,
    401,
    402,
    403,
    404,
    405,
    406,
    407,
    408,
    409,
    410,
    411,
    412,
    413,
    414,
    415,
    416,
    417,
    418,
    421,
    422,
    423,
    424,
    425,
    426,
    428,
    429,
    431,
    451,
    500,
    501,
    502,
    503,
    504,
    505,
    506,
    507,
    508,
    509,
    510,
    511,
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleClose = () => {
    setActiveTab(0);
    onClose();
  };

  const handleFilterChange = (filterName, value) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleApply = () => {
    const filtersToApply = { ...tempFilters };
    if (["scheduledDate", "lastDialedDate"].includes(fields[activeTab].value)) {
      filtersToApply[fields[activeTab].value] = fields[activeTab].value;
      filtersToApply[`start_${fields[activeTab].value}`] =
        filtersToApply[`start_${fields[activeTab].value}`] &&
        moment(
          `${
            moment(filtersToApply?.[`start_${fields[activeTab].value}`]).format(
              "MM-DD-YYYY"
            ) || new Date().toLocaleDateString()
          } ${
            fields[activeTab].value === "scheduledDate"
              ? scheduledStartTimeString
              : lastDialedStartTimeString
          }`,
          "MM-DD-YYYY h:mm A"
        );
      filtersToApply[`end_${fields[activeTab].value}`] =
        filtersToApply[`end_${fields[activeTab].value}`] &&
        moment(
          `${
            moment(filtersToApply?.[`end_${fields[activeTab].value}`]).format(
              "MM-DD-YYYY"
            ) || new Date().toLocaleDateString
          } ${
            fields[activeTab].value === "scheduledDate"
              ? scheduledEndTimeString
              : lastDialedEndTimeString
          }`,
          "MM-DD-YYYY h:mm A"
        );
    } else if (filtersToApply.dateFilter === "custom") {
      filtersToApply.dateFilter = "range";
    } else {
      delete filtersToApply.startDate;
      delete filtersToApply.endDate;
    }

    if (
      filtersToApply.dateFilter === "range" &&
      ((filtersToApply.startDate && !filtersToApply.endDate) ||
        (filtersToApply.endDate && !filtersToApply.startDate) ||
        (!filtersToApply.endDate && !filtersToApply.startDate))
    ) {
      showSkillrToast("Date range must be selected", "error");
      return;
    }
    if (filtersToApply.scheduledDate) {
      const startDate = filtersToApply?.[`start_scheduledDate`];
      const endDate = filtersToApply?.[`end_scheduledDate`];
      if (
        (!startDate && !endDate) ||
        (startDate && !endDate) ||
        (endDate && !startDate)
      ) {
        showSkillrToast("Date range must be selected", "error");
        return;
      }
    }
    if (filtersToApply.lastDialedDate) {
      const startDate = filtersToApply?.[`start_lastDialedDate`];
      const endDate = filtersToApply?.[`end_lastDialedDate`];
      if (
        (!startDate && !endDate) ||
        (startDate && !endDate) ||
        (endDate && !startDate)
      ) {
        showSkillrToast("Date range must be selected", "error");
        return;
      }
    }
    applyFilters(filtersToApply);
    handleClose();
  };

  useEffect(() => {
    if (filters.startDate) {
      filters.startDate = moment(filters.startDate);
    }
    if (filters.endDate) {
      filters.endDate = moment(filters.endDate);
    }
    // setTempFilters(updatedFilters);
    if (filters.start_scheduledDate) {
      filters.start_scheduledDate = moment(filters.start_scheduledDate);
    }
    if (filters.end_scheduledDate) {
      filters.end_scheduledDate = moment(filters.end_scheduledDate);
    }
    if (filters.start_lastDialedDate) {
      filters.start_lastDialedDate = moment(filters.start_lastDialedDate);
    }
    if (filters.end_lastDialedDate) {
      filters.end_lastDialedDate = moment(filters.end_lastDialedDate);
    }
    const updatedFilters = {
      ...filters,
      dateFilter:
        filters.dateFilter === "range" ? "custom" : filters.dateFilter,
    };
    setTempFilters(updatedFilters);
  }, [filters]);

  const renderTabContent = () => {
    const field = fields[activeTab];
    if (!field) return null;

    switch (field.value) {
      case "status":
        return (
          <>
            {campaignStatus.map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters?.status?.includes(option)}
                  onChange={() => {
                    const updatedStatus = tempFilters?.status?.includes(option)
                      ? tempFilters.status.filter((item) => item !== option)
                      : [...tempFilters.status, option];
                    handleFilterChange("status", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {statusLabels[option]}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case "redialAttempts":
        return (
          <>
            {Array(maxRedialAttempts + 1)
              .fill(null)
              .map((_, i) => i)
              ?.map((option) => {
                const redialAttempts = option?.toString() || "0";
                <FlexBox
                  component="label"
                  key={option}
                  columnGap={1}
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={tempFilters?.redialAttempts?.includes(option)}
                    onChange={() => {
                      const updatedStatus =
                        tempFilters?.redialAttempts?.includes(option)
                          ? tempFilters.redialAttempts.filter(
                              (item) => item !== option
                            )
                          : [...tempFilters.redialAttempts, option];
                      handleFilterChange("redialAttempts", updatedStatus);
                    }}
                  />
                  <Typography variant="caption" color="text.primary">
                    {(option === "0" && "Initial Call") ||
                      (redialAttempts?.[redialAttempts.length - 1] == "1" &&
                        `${redialAttempts}st Attempt`) ||
                      (redialAttempts?.[redialAttempts.length - 1] == "2" &&
                        `${redialAttempts}nd Attempt`) ||
                      (redialAttempts?.[redialAttempts.length - 1] == "3" &&
                        `${redialAttempts}rd Attempt`) ||
                      `${redialAttempts}th Attempt`}
                  </Typography>
                </FlexBox>;
              })}
          </>
        );
      case "callDuration":
        return (
          <Box
            pt={8}
            sx={{
              textAlign: "center",
              ...(isNaN(maxDurationOfCalls) && {
                pointerEvents: "none",
                opacity: 0.5,
              }),
            }}
          >
            <Slider
              disabled={isNaN(maxDurationOfCalls)}
              value={[
                tempFilters.minDuration || 0,
                tempFilters.maxDuration || maxDurationOfCalls,
              ]}
              onChange={(event, value) => {
                handleFilterChange("minDuration", value[0]);
                handleFilterChange("maxDuration", value[1]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={maxDurationOfCalls}
              marks={[{ value: 0 }, { value: maxDurationOfCalls }]}
              sx={{
                width: "80%",
                "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                "& .MuiSlider-track": { backgroundColor: "#0F0F0F", border: 0 },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#0F0F0F",
                  width: 15,
                  height: 15,
                },
              }}
            />
          </Box>
        );
      case "dateRange":
        return (
          <Box>
            <RadioGroup
              value={tempFilters.dateFilter}
              onChange={(e) => handleFilterChange("dateFilter", e.target.value)}
            >
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="today"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Today"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="last7days"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Last 7 Days"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="thisMonth"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="This Month"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="custom"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Custom"
              />
            </RadioGroup>
            <Grow
              in={tempFilters.dateFilter === "custom"}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              <div>
                <DateSelector
                  label="Start Date"
                  value={
                    tempFilters.endDate && !tempFilters.startDate
                      ? moment(tempFilters.endDate)
                          .subtract(15, "days")
                          .startOf("day")
                      : tempFilters.startDate
                  }
                  onChange={(date) => {
                    handleFilterChange("startDate", date);
                    if (!tempFilters.endDate) {
                      handleFilterChange(
                        "endDate",
                        moment(date).add(15, "days").endOf("day")
                      );
                    }
                  }}
                  maxDate={tempFilters.endDate}
                />
                <DateSelector
                  label="End Date"
                  value={
                    tempFilters.startDate && !tempFilters.endDate
                      ? moment(tempFilters.startDate)
                          .add(15, "days")
                          .endOf("day")
                      : tempFilters.endDate
                  }
                  onChange={(date) => {
                    handleFilterChange("endDate", moment(date).endOf("day"));
                    if (!tempFilters.endDate) {
                      handleFilterChange(
                        "startDate",
                        moment(date).subtract(15, "days").startOf("day")
                      );
                    }
                  }}
                  minDate={tempFilters.startDate}
                />
              </div>
            </Grow>
          </Box>
        );
      case "downloadStatus":
        return (
          <Box>
            {["generated", "failed", "progress"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters?.downloadStatus?.includes(option)}
                  onChange={() => {
                    const currentDownloadStatus =
                      tempFilters.downloadStatus || [];
                    const updatedStatus = currentDownloadStatus?.includes(
                      option
                    )
                      ? currentDownloadStatus.filter((item) => item !== option)
                      : [...currentDownloadStatus, option];
                    handleFilterChange("downloadStatus", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </Typography>
              </FlexBox>
            ))}
          </Box>
        );
      case "downloadType":
        return (
          <Box>
            {["analytics", "campaign"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters?.downloadType?.includes(option)}
                  onChange={() => {
                    const currentDownloadType = tempFilters.downloadType || [];
                    const updatedStatus = currentDownloadType?.includes(option)
                      ? currentDownloadType.filter((item) => item !== option)
                      : [...currentDownloadType, option];
                    handleFilterChange("downloadType", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option === "analytics" ? "Call Data" : "Campaign Data"}
                </Typography>
              </FlexBox>
            ))}
          </Box>
        );
      case "campaign":
        return (
          <>
            <FlexBox
              component="label"
              columnGap={1}
              sx={{ cursor: "pointer", mb: 1 }}
            >
              <Checkbox
                checked={
                  campaigns.length > 0 &&
                  tempFilters?.campaignIds?.length === campaigns.length
                }
                indeterminate={
                  tempFilters?.campaignIds?.length > 0 &&
                  tempFilters?.campaignIds?.length < campaigns.length
                }
                onChange={() => {
                  const allCampaignIds = campaigns.map(
                    (campaign) => campaign._id
                  );
                  const newCampaignIds =
                    tempFilters?.campaignIds?.length === campaigns.length
                      ? []
                      : allCampaignIds;
                  handleFilterChange("campaignIds", newCampaignIds || []);
                }}
              />
              <Typography variant="caption" color="text.primary">
                Select All
              </Typography>
            </FlexBox>

            {campaigns.map((campaign) => (
              <FlexBox
                component="label"
                key={campaign._id}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={Boolean(
                    tempFilters?.campaignIds?.includes(campaign._id)
                  )}
                  onChange={() => {
                    const currentCampaignIds = tempFilters.campaignIds || [];
                    const updatedStatus = currentCampaignIds?.includes(
                      campaign._id
                    )
                      ? currentCampaignIds.filter(
                          (item) => item !== campaign._id
                        )
                      : [...currentCampaignIds, campaign._id];
                    handleFilterChange("campaignIds", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {campaign.name}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case "preProcess":
        return (
          <>
            {preProcesssStatus.map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters?.preProcessStatus?.includes(option)}
                  onChange={() => {
                    const updatedStatus =
                      tempFilters?.preProcessStatus?.includes(option)
                        ? tempFilters.preProcessStatus.filter(
                            (item) => item !== option
                          )
                        : [...tempFilters.preProcessStatus, option];
                    handleFilterChange("preProcessStatus", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {statusLabels[option]}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case "uploadStatus":
        return (
          <>
            {uploadStatuses.map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters?.uploadStatus?.includes(option)}
                  onChange={() => {
                    const updatedStatus = tempFilters?.uploadStatus?.includes(
                      option
                    )
                      ? tempFilters.uploadStatus.filter(
                          (item) => item !== option
                        )
                      : [...tempFilters.uploadStatus, option];
                    handleFilterChange("uploadStatus", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {statusLabels[option]}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case "dialAttempts":
        return (
          <>
            {Array(maxRedialAttempts + 1)
              .fill(null)
              .map((_, i) => i)
              ?.map((option) => {
                const redialAttempts = option?.toString() || "0";
                return (
                  <FlexBox
                    component="label"
                    key={redialAttempts}
                    columnGap={1}
                    sx={{ cursor: "pointer" }}
                  >
                    <Checkbox
                      checked={tempFilters?.redialAttempts?.includes(
                        redialAttempts
                      )}
                      onChange={() => {
                        const updatedStatus =
                          tempFilters?.redialAttempts?.includes(redialAttempts)
                            ? tempFilters.redialAttempts.filter(
                                (item) => item !== redialAttempts
                              )
                            : [...tempFilters.redialAttempts, redialAttempts];
                        handleFilterChange("redialAttempts", updatedStatus);
                      }}
                    />
                    <Typography variant="caption" color="text.primary">
                      {(redialAttempts === "0" && "Initial Call") ||
                        (redialAttempts?.[redialAttempts.length - 1] === "1" &&
                          `${redialAttempts}st Attempt`) ||
                        (redialAttempts?.[redialAttempts.length - 1] === "2" &&
                          `${redialAttempts}nd Attempt`) ||
                        (redialAttempts?.[redialAttempts.length - 1] === "3" &&
                          `${redialAttempts}rd Attempt`) ||
                        `${redialAttempts}th Attempt`}
                    </Typography>
                  </FlexBox>
                );
              })}
          </>
        );
      case "scheduledDate":
        return (
          <>
            <div>
              <DateSelector
                label="Start Date"
                value={
                  tempFilters.end_scheduledDate &&
                  !tempFilters.start_scheduledDate
                    ? moment(tempFilters.end_scheduledDate)
                        .subtract(15, "days")
                        .startOf("day")
                    : tempFilters.start_scheduledDate
                }
                onChange={(date) => {
                  handleFilterChange("scheduledDate", "scheduledDate");
                  handleFilterChange("start_scheduledDate", date);
                  if (!tempFilters.end_scheduledDate) {
                    handleFilterChange(
                      "end_scheduledDate",
                      moment(date).add(15, "days").endOf("day")
                    );
                  }
                }}
                maxDate={tempFilters.end_scheduledDate}
              />
              <DateSelector
                label="End Date"
                value={
                  tempFilters.start_scheduledDate &&
                  !tempFilters.end_scheduledDate
                    ? moment(tempFilters.start_scheduledDate)
                        .add(15, "days")
                        .endOf("day")
                    : tempFilters.end_scheduledDate
                }
                onChange={(date) => {
                  handleFilterChange("scheduledDate", "scheduledDate");
                  handleFilterChange(
                    "end_scheduledDate",
                    moment(date).endOf("day")
                  );
                  if (!tempFilters.start_scheduledDate) {
                    handleFilterChange(
                      "start_scheduledDate",
                      moment(date).subtract(15, "days").startOf("day")
                    );
                  }
                }}
                minDate={tempFilters.start_scheduledDate}
              />
              <TimeSelector
                label={"Start Time"}
                time={
                  tempFilters?.start_scheduledDate
                    ? moment(tempFilters?.start_scheduledDate).format("h:mm A")
                    : scheduledStartTimeString
                }
                onChange={(time) => setScheduledStartTimeString(time)}
              ></TimeSelector>
              <TimeSelector
                label={"End Time"}
                time={
                  tempFilters?.end_scheduledDate
                    ? moment(tempFilters?.end_scheduledDate).format("h:mm A")
                    : scheduledEndTimeString
                }
                onChange={(time) => setScheduledEndTimeString(time)}
              ></TimeSelector>
            </div>
          </>
        );
      case "lastDialedDate":
        return (
          <>
            <div>
              <DateSelector
                label="Start Date"
                value={
                  tempFilters.end_lastDialedDate &&
                  !tempFilters.start_lastDialedDate
                    ? moment(tempFilters.end_lastDialedDate)
                        .subtract(15, "days")
                        .startOf("day")
                    : tempFilters.start_lastDialedDate
                }
                onChange={(date) => {
                  handleFilterChange("lastDialedDate", "lastDialedDate");
                  handleFilterChange("start_lastDialedDate", date);
                  if (!tempFilters.end_lastDialedDate) {
                    handleFilterChange(
                      "end_lastDialedDate",
                      moment(date).add(15, "days").endOf("day")
                    );
                  }
                }}
                maxDate={tempFilters.end_lastDialedDate}
              />
              <DateSelector
                label="End Date"
                value={
                  tempFilters.start_lastDialedDate &&
                  !tempFilters.end_lastDialedDate
                    ? moment(tempFilters.start_lastDialedDate)
                        .add(15, "days")
                        .endOf("day")
                    : tempFilters.end_lastDialedDate
                }
                onChange={(date) => {
                  handleFilterChange("lastDialedDate", "lastDialedDate");
                  handleFilterChange(
                    "end_lastDialedDate",
                    moment(date).endOf("day")
                  );
                  if (!tempFilters.start_lastDialedDate) {
                    handleFilterChange(
                      "start_lastDialedDate",
                      moment(date).subtract(15, "days").startOf("day")
                    );
                  }
                }}
                minDate={tempFilters.start_lastDialedDate}
              />
              <TimeSelector
                label={"Start Time"}
                time={
                  tempFilters?.start_lastDialedDate
                    ? moment(tempFilters?.start_lastDialedDate).format("h:mm A")
                    : lastDialedStartTimeString
                }
                onChange={(time) => setLastDialedStartTimeString(time)}
              ></TimeSelector>
              <TimeSelector
                label={"End Time"}
                time={
                  tempFilters?.end_lastDialedDate
                    ? moment(tempFilters?.end_lastDialedDate).format("h:mm A")
                    : lastDialedEndTimeString
                }
                onChange={(time) => setLastDialedEndTimeString(time)}
              ></TimeSelector>
            </div>
          </>
        );
      case "errorStatus":
        return (
          // put checkboxes filter for all http status codes
          <>
            {httpStatusCodes.map((code) => (
              <FlexBox
                component="label"
                key={code}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters?.errorStatus?.includes(code)}
                  onChange={() => {
                    const updatedStatus = tempFilters?.errorStatus?.includes(
                      code
                    )
                      ? tempFilters.errorStatus.filter((item) => item !== code)
                      : [...tempFilters.errorStatus, code];
                    handleFilterChange("errorStatus", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {code}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case "errorType":
        return (
          <>
            <FlexBox component="label" columnGap={1} sx={{ cursor: "pointer" }}>
              <Checkbox
                checked={tempFilters?.errorType?.includes("api")}
                onChange={() => {
                  const updatedStatus = tempFilters?.errorType?.includes("api")
                    ? tempFilters.errorType.filter((item) => item !== "api")
                    : [...tempFilters.errorType, "api"];
                  handleFilterChange("errorType", updatedStatus);
                }}
              />
              <Typography variant="caption" color="text.primary">
                API
              </Typography>
            </FlexBox>
            <FlexBox component="label" columnGap={1} sx={{ cursor: "pointer" }}>
              <Checkbox
                checked={tempFilters?.errorType?.includes("script")}
                onChange={() => {
                  const updatedStatus = tempFilters?.errorType?.includes(
                    "script"
                  )
                    ? tempFilters.errorType.filter((item) => item !== "script")
                    : [...tempFilters.errorType, "script"];
                  handleFilterChange("errorType", updatedStatus);
                }}
              />
              <Typography variant="caption" color="text.primary">
                Script
              </Typography>
            </FlexBox>
          </>
        );
      case "errorLocation":
        return (
          <>
            <FlexBox component="label" columnGap={1} sx={{ cursor: "pointer" }}>
              <Checkbox
                checked={tempFilters?.errorLocation?.includes("integration")}
                onChange={() => {
                  const updatedStatus = tempFilters?.errorLocation?.includes(
                    "integration"
                  )
                    ? tempFilters.errorLocation.filter(
                        (item) => item !== "integration"
                      )
                    : [...tempFilters.errorLocation, "integration"];
                  handleFilterChange("errorLocation", updatedStatus);
                }}
              />
              <Typography variant="caption" color="text.primary">
                Integration
              </Typography>
            </FlexBox>
            <FlexBox component="label" columnGap={1} sx={{ cursor: "pointer" }}>
              <Checkbox
                checked={tempFilters?.errorLocation?.includes("workflow")}
                onChange={() => {
                  const updatedStatus = tempFilters?.errorLocation?.includes(
                    "workflow"
                  )
                    ? tempFilters.errorLocation.filter(
                        (item) => item !== "workflow"
                      )
                    : [...tempFilters.errorLocation, "workflow"];
                  handleFilterChange("errorLocation", updatedStatus);
                }}
              />
              <Typography variant="caption" color="text.primary">
                Workflow
              </Typography>
            </FlexBox>
          </>
        );
      case "latency":
        return (
          <Box width="75%" sx={{ margin: "64px auto" }}>
            <FundamentoSlider
              label="Latency"
              value={[
                tempFilters?.latency?.[0] || 0,
                tempFilters?.latency?.[1] || 30000,
              ]}
              min={0}
              max={30000}
              step={1000}
              textField={false}
              onChange={(value) => handleFilterChange("latency", value)}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        sx: styles.dialogPaper,
      }}
    >
      <Box
        sx={{
          p: "16px 24px",
          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Filter
        </Typography>
      </Box>

      <FlexBox alignItems="stretch">
        <Box
          width={"50%"}
          sx={{ borderRight: `1px solid ${theme.palette.borderColor.light}` }}
        >
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {fields.map((field, index) => (
              <Tab
                key={field.value}
                label={field.label}
                sx={styles.tab}
                disabled={field.value === "redialAttempts"}
              />
            ))}
          </Tabs>
        </Box>
        <Box width={"50%"} p={2} sx={styles.rightContainer}>
          {renderTabContent()}
        </Box>
      </FlexBox>

      <FlexBox
        sx={{
          p: "16px 24px",
          borderTop: `1px solid ${theme.palette.borderColor.light}`,
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" sx={styles.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="dark" sx={styles.button} onClick={handleApply}>
          Apply
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default FilterModal;
