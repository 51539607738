import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  SkillrAlert,
  SkillrButton,
  SkillrTextField,
  SkillrPasswordStrength,
} from "components";
import { IconVisibility, IconVisibilityoff, RedAlert } from "components/SVG";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { emailRegEx, getJSONDecoded } from "utils/utils";
import { AuthCardHeader, AuthFormGroup, Divider } from "./index.style";
import { signup as signUpApi } from "services";
import { useUserContext } from "context/user";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SignupForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.search
    ? new URLSearchParams(location.search.substring(1))
    : null;

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
  } = useForm({ reValidateMode: "onChange" });
  const [status, setStatus] = useState({ message: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  // const [
  //   onUserSignup,
  //   { loading: signUpLoading, data: signUpData, error: signUpError },
  // ] = useMutation(SIGN_UP);
  const [showPassword, toggleShowPassword] = useState(false);
  const [inviteId, setInviteId] = useState();
  const [verificationToken, setVerificationToken] = useState();
  const [loading, setLoading] = useState(false);
  const { setToken, setUserDetails } = useUserContext();
  const [defaultEmail, setDefaultEmail] = useState(() =>
    params?.get("token") ? true : false
  );
  const [passwordStrength, setPasswordStrength] = useState({
    minChar: false,
    upperLower: false,
    number: false,
  });
  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  useEffect(() => {
    if (params?.get("token")) {
      setVerificationToken(params.get("token"));
      const { email } = getJSONDecoded(params.get("token"));
      setValue("email", email);
    }
    const password = getValues("password");
    if (password) {
      setPasswordStrength({
        minChar: password.length > 7,
        upperLower: !!(password.match(/[A-Z]/g) && password.match(/[a-z]/g)),
        number: !!password.match(/[0-9]/g),
      });
    }
    const subscription = watch((values, { name }) => {
      if (name === "password") {
        setPasswordStrength({
          minChar: values.password.length > 7,
          upperLower: !!(
            values.password.match(/[A-Z]/g) && values.password.match(/[a-z]/g)
          ),
          number: !!values.password.match(/[0-9]/g),
        });
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const onSuccess = (loginDetails) => {
    if (props.candidateAuth) {
      localStorage.setItem("candidateToken", loginDetails.token);
      // navigate("/tests");
      // props.history.push("/tests");
      return;
    }
    // setAuthTokens(loginDetails.token);
    localStorage.setItem("token", loginDetails.token);
    // LandingPageSignUp(loginDetails);
    // updateDetails(loginDetails);
    if (inviteId) {
      // navigate(`/init?${location.search.split("?")[1]}`);
      // props.history.push({
      //   pathname: "/init",
      //   search: props.location.search.split("?")[1],
      // });
    } else {
      // navigate("/onboarding");
      // props.history.push("/onboarding");
    }
  };

  // useEffect(() => {
  //   if (signUpLoading) {
  //     setLoading(true);
  //   }
  //   if (signUpData && signUpData.createUser) {
  //     onSuccess(signUpData.createUser);
  //     mixpanel.track("USER_SIGNUP", {
  //       email: signUpData.createUser.email,
  //       userId: signUpData.createUser.id,
  //       userType: "employer",
  //     });
  //     setLoading(false);
  //   }
  //   if (signUpError) {
  //     setLoading(false);
  //     setStatus({ status: "error", message: getErrorMessage(signUpError) });
  //   }
  // }, [signUpLoading, signUpData, signUpError]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignup = async (data) => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams();
      if (verificationToken) {
        urlParams.set("token", verificationToken);
      }
      const response = await signUpApi(data, urlParams.toString());
      setLoading(false);
      if (response) {
        localStorage.setItem("token", response?.token);
        setToken(response?.token);
        setUserDetails(response?.user);
        if (response?.user?.companies?.length > 0) {
          navigate("/calls/audit");
        } else {
          navigate("/create-company");
        }
      }
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message ?? "Something went wrong";
      setStatus({
        status: "error",
        message: errorMessage,
      });
    }
  };

  const onSubmit = (data) => {
    const apiData = {
      name: data?.fullName,
      email: data?.email,
      password: data?.password,
    };
    handleSignup(apiData);
  };

  return (
    <Box>
      <AuthCardHeader>
        <Typography variant="h4" gutterBottom>
          Create an account
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Already have an account? {/* <Box color="primary.main" clone> */}
          {/* <Link to={props.candidateAuth ? "/assessment/login" : "/"}>
            Log in
          </Link> */}
          {/* </Box> */}
        </Typography>
      </AuthCardHeader>

      {/* <GoogleLogin loginSuccess={onSuccess} classes={classes} isSquad={true} /> */}

      <Divider>OR</Divider>
      {status.status && (
        <Box mb={6}>
          <SkillrAlert variant={status.status}>{status.message}</SkillrAlert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={false}>
        <AuthFormGroup>
          <SkillrTextField
            control={control}
            name="fullName"
            id="fullName"
            rules={{
              required: "Full Name field is required",
            }}
            error={errors.firstName}
            label="Full Name"
          />

          <SkillrTextField
            control={control}
            name="email"
            error={errors.email}
            disabled={defaultEmail}
            rules={{
              required: "Email field is required",
              pattern: {
                value: emailRegEx,
                message: "Please enter valid email id",
              },
            }}
            id="login"
            label="Business Email"
          />
          <SkillrTextField
            id="password"
            control={control}
            name="password"
            reValidateMode="onChange"
            error={errors.password}
            label="Create a password"
            type={showPassword ? "text" : "password"}
            onFocus={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            onBlur={() => {
              setAnchorEl(null);
            }}
            rules={{
              required: "Password field is required",
              minLength: {
                value: 8,
                message: "Password min length should be 8",
              },
              validate: {
                containNumber: (v) =>
                  v.match(/[0-9]/gi) === null
                    ? "Password should contain number"
                    : true,
                containCapital: (v) =>
                  v.match(/[A-Z]/g) === null
                    ? "At least one capital letter required"
                    : true,
                containLower: (v) =>
                  v.match(/[a-z]/g) === null
                    ? "At least one small letter required"
                    : true,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <IconVisibility /> : <IconVisibilityoff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <SkillrPasswordStrength
            anchorEl={anchorEl}
            passwordStrength={passwordStrength}
          />

          <Box mt={6} mx="auto" display="flex">
            <Controller
              name="tandc"
              control={control}
              defaultValue={false}
              rules={{
                required: "Please accept terms and condition",
              }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Checkbox
                  color="primary"
                  size="small"
                  style={{ marginLeft: -12 }}
                  name={name}
                  onBlur={onBlur}
                  checked={value}
                  onChange={onChange}
                />
              )}
            />

            <Typography variant="body2" style={{ color: "#9d9d9d" }}>
              By creating an account, you agree to accept our{" "}
              <Link
                target="_blank"
                to="https://fundamentostatic.s3.ap-south-1.amazonaws.com/Skillr+Talent(Funadamento)-Terms+of+Services_June'23.pdf"
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                to="https://s3.ap-south-1.amazonaws.com/assets.skillr.ai/Privacy+Policy+Latest.pdf"
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>

          {errors.tandc && (
            <Box component="span" display="flex" alignItems="center">
              <RedAlert style={{ marginRight: 8 }} />
              <FormHelperText error>{errors.tandc.message}</FormHelperText>
            </Box>
          )}
        </AuthFormGroup>
        <SkillrButton size="large" type="submit" loading={loading} fullWidth>
          Create account
        </SkillrButton>
      </form>
    </Box>
  );
};

SignupForm.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  candidateAuth: PropTypes.bool,
};

export default SignupForm;
