import FundamentoTable from "components/FundamentoTable";
import styles from "./index.module.css";
import { Box, Button, Typography } from "@mui/material";
import {
  redialColors,
  statusColorMapping,
  statusTextMapping,
} from "text-constants/campaign";
import PropTypes from "prop-types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FundamentoPagination from "components/FundamentoPagination";
import { getFormattedTime } from "utils/utils";
import FlexBox from "components/FlexBox";
import { ChevronLeft, ChevronRight } from "react-feather";
import theme from "theme";

const DetailsList = (props) => {
  const navigate = useNavigate();
  const {
    campaingContacts,
    currentPage,
    totalPageCount,
    setCurrentPage,
    isPreProcessEnabled,
    selectedTab,
    maxRedialAttempts,
    previousCallId,
    nextCallId,
  } = props;

  const getRedialColor = (attempt) => {
    const redThreshold = Math.ceil(maxRedialAttempts * 0.7); // Last 30%
    const yellowThreshold = Math.ceil(maxRedialAttempts * 0.3); // First 30%
    if (attempt >= redThreshold) {
      return redialColors["red"]; // Last redial attempts
    } else if (attempt >= yellowThreshold) {
      return redialColors["yellow"]; // Mid-range redial attempts
    } else {
      return redialColors["green"]; // Initial attempts
    }
  };
  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id && row.id !== "") {
      const selectedCampaign = campaingContacts.find(
        (cd) => cd.voiceCallId === row.id
      );
      navigate(
        `/calls/${selectedCampaign.callSessionId}?voiceCallId=${selectedCampaign.voiceCallId}`
      );
    }
  };

  const columns = {
    contacts: [
      {
        field: "phoneNumber",
        header: "Phone Number",
        render: (row) => {
          console.log("roewwwww", row);
          return row?.contactNumber;
        },
      },
      ...(isPreProcessEnabled
        ? [
            {
              field: "PreProcessStatus",
              header: "Preprocess Status",
              render: (row) => {
                return (
                  <Box
                    className={styles.box}
                    sx={{
                      background: row?.preProcessed?.length
                        ? "#E8F5E9"
                        : "#FFF4CB",
                    }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight={500}
                      lineHeight="normal"
                      color={row?.preProcessed?.length ? "#388E3C" : "#8A6C00"}
                    >
                      {row?.preProcessed?.length ? "Processed" : "Pending"}
                    </Typography>
                  </Box>
                );
              },
            },
          ]
        : []),
      {
        field: "callOutcome",
        header: "Upload Status",
        render: (row) => {
          const statusColor =
            row?.status === "incorrectFormat"
              ? statusColorMapping["invalidNumber"]
              : statusColorMapping["complete"];
          return (
            <Box
              className={styles.box}
              sx={{ background: statusColor.backgroundColor }}
            >
              <Typography
                fontSize={10}
                fontWeight={500}
                lineHeight="normal"
                color={statusColor.color}
              >
                {row?.status === "incorrectFormat"
                  ? statusTextMapping["incorrectFormat"]
                  : statusTextMapping["complete"]}
              </Typography>
            </Box>
          );
        },
      },
      // {
      //   field: "redialAttempts",
      //   header: "Redial Attempts",
      //   render: (row) => {
      //     return row?.redialAttempts ?? "N/A";
      //   },
      // },

      {
        field: "dateTime",
        header: "Upload Date and Time",
        render: (row) => {
          return moment(row?.createdAt).format("D MMM, hh:mm a");
        },
      },
    ],
    callLogs: [
      {
        field: "phoneNumber",
        header: "Phone Number",
        render: (row) => {
          return row?.contactNumber;
        },
      },
      {
        field: "callId",
        header: "Call ID",
        render: (row) => {
          return row?.voiceCallId ?? "-";
        },
      },
      {
        field: "callOutcome",
        header: "Call Outcome",
        render: (row) => {
          const statusColor =
            statusColorMapping[row?.callOutcome || row?.status] ??
            statusColorMapping["custom"];
          return (
            <Box
              className={styles.box}
              sx={{ background: statusColor.backgroundColor }}
            >
              <Typography
                fontSize={10}
                fontWeight={500}
                lineHeight="normal"
                color={statusColor.color}
              >
                {statusTextMapping[row?.callOutcome ?? row?.status] ||
                  row?.callOutcome ||
                  "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "callDuration",
        header: "Call Duration",
        render: (row) => {
          return getFormattedTime(row?.callDuration) || 0;
        },
      },
      {
        field: "didNumber",
        header: "DID Number",
        render: (row) => {
          return row?.didNumber;
        },
      },
      {
        field: "redialAttempts",
        header: "Redial Attempts",
        render: (row) => {
          return row?.redialAttempts ?? "N/A";
        },
      },
      {
        field: "dateTime",
        header: "Date and Time",
        render: (row) => {
          return moment(row?.createdAt).format("D MMM, hh:mm a");
        },
      },
    ],
    callDues: [
      {
        field: "phoneNumber",
        header: "Phone Number",
        render: (row) => {
          return row?.contactNumber;
        },
      },
      {
        field: "redialAttempts",
        header: "Upcoming Dial Attempt",
        render: (row) => {
          const redialAttempts = row.redialAttempts.toString() || "0";
          const statusColor =
            row.status == "pending"
              ? redialColors["green"]
              : getRedialColor(row.redialAttempts);
          return (
            <Box
              className={styles.box}
              sx={{ background: statusColor.backgroundColor }}
            >
              <Typography
                fontSize={10}
                fontWeight={500}
                lineHeight="normal"
                color={statusColor.color}
              >
                {(row.status === "pending" && "Initial Call") ||
                  (redialAttempts?.[redialAttempts.length - 1] == "1" &&
                    `${redialAttempts}st Redial`) ||
                  (redialAttempts?.[redialAttempts.length - 1] == "2" &&
                    `${redialAttempts}nd Redial`) ||
                  (redialAttempts?.[redialAttempts.length - 1] == "3" &&
                    `${redialAttempts}rd Redial`) ||
                  `${redialAttempts}th Redial`}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "dateTime",
        header: "Schedule Date and Time",
        render: (row) => {
          if (row?.nextRedialAfter) {
            return moment(row?.nextRedialAfter).format("D MMM, hh:mm a");
          } else {
            const nextRedial = new Date(new Date().getTime() + 60000);
            return moment(nextRedial).format("D MMM, hh:mm a");
          }
        },
      },
      {
        field: "lastDialed",
        header: "Last Dialed Date and Time",
        render: (row) => {
          return moment(row?.updatedAt).format("D MMM, hh:mm a");
        },
      },
    ],
  };

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const handleTableOnClick = () => {};
  return (
    <>
      <Box
        sx={{
          "&> div": { paddingBottom: 4 },
          "& tr": selectedTab === "callLogs" ? { cursor: "pointer" } : {},
        }}
      >
        <FundamentoTable
          columns={columns[selectedTab]}
          data={campaingContacts}
          passRowToRender={true}
          pagination={false}
          currentPage={currentPage}
          onPageChange={handlePageOnChange}
          totalPageCount={totalPageCount}
          tableOnClick={
            selectedTab === "callLogs" ? handleTableOnClick : () => {}
          }
          className={styles.table}
          rowIdKey="voiceCallId"
        />
      </Box>
      <FlexBox justifyContent="space-between" my={4}>
        <Button
          variant="contained"
          startIcon={<ChevronLeft />}
          sx={{ backgroundColor: theme.palette.grey[50] }}
          color="inherit"
          onClick={() => {
            if (previousCallId) {
              navigate(`/campaign/${previousCallId}?tab=contacts&page=1`, {
                replace: true,
              });
            }
          }}
          disabled={!previousCallId}
        >
          Previous Campaign
        </Button>
        <FundamentoPagination
          currentPage={currentPage}
          onPageChange={handlePageOnChange}
          totalPageCount={totalPageCount}
        />
        <Button
          variant="contained"
          endIcon={<ChevronRight />}
          color="inherit"
          sx={{ backgroundColor: theme.palette.grey[50] }}
          onClick={() => {
            if (nextCallId) {
              navigate(`/campaign/${nextCallId}?tab=contacts&page=1`, {
                replace: true,
              });
            }
          }}
          disabled={!nextCallId}
        >
          Next Campaign
        </Button>
      </FlexBox>
    </>
  );
};

DetailsList.propTypes = {
  campaingContacts: PropTypes.array,
  currentPage: PropTypes.number,
  totalPageCount: PropTypes.number,
};

export default DetailsList;
