import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { RefreshCcw } from "react-feather";
import { SpeechToText } from "components";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowRight } from "components/SVG";
import { useSkillrToast } from "context/toast";
import {
  createNewChat as createNewChatApi,
  getChatAnswer as getChatAnswerApi,
  getAudio as getAudioApi,
  generateChatSummary as generateChatSummaryApi,
  getSmartPlus,
  handleUserInputV3,
  endCall,
} from "services";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useUserContext } from "context/user";
import { useURLState } from "custom-hooks/useUrlState";
import { gscUriPrefix } from "text-constants/common";
// import AudioPlayer from "components/AudioPlayer";
import { wait } from "utils/utils";
import assetsAPI from "services/assetAPI";

const AudioPlayer = ({ src, onPlay }) => {
  const audioRef = useRef(null);

  return (
    <Box className={styles.audioPlayer}>
      <audio
        ref={audioRef}
        controls
        src={src}
        style={{ width: "100%" }}
        className={styles.audio}
        onPlay={() => onPlay(audioRef.current)}
      />
    </Box>
  );
};

const Chat = (props) => {
  const { clearChatFeature, chatFeature, singleChatHistoryDetails } = props;
  const { playgroundCurrentChat, allBot } = useUserContext();
  const [callStatus, setCallStatus] = useState(false);
  const assistantContainer = useRef(null);
  const messagesContainer = useRef(null);
  const audioRefs = useRef({});
  const [chat, setChat] = useState([]);
  const [customerActive, setCustomerActive] = useState(false);
  const [summary, setSummary] = useState();
  const [chatId, setChatId] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [chatMode, setChatMode] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const { showSkillrToast } = useSkillrToast();
  const audioRef = useRef();
  const [operationModeModal, setOperationModeModal] = useState(false);
  const [currentMode, setCurrentMode] = useURLState("weaviate", "mode");
  const [answerSource, setAnswerSource] = useState(
    currentMode ? currentMode : "weaviate"
  );
  const token = localStorage.getItem("token");
  const [currentVoiceCallId, setCurrentVoiceCallId] = useState();

  const [selectedBot, setSelectedBot] = useState();
  const [dtmfInputEnabled, setDtmfInputEnable] = useState(false);

  const getAudio = (apiData) => {
    return new Promise(async (resolve, reject) => {
      const response = await getAudioApi(apiData);
      const base64AudioContent = response;
      audioRef.current = new Audio(
        "data:audio/wav;base64," + base64AudioContent
      );
      // const audio = new Audio("data:audio/wav;base64," + base64AudioContent);
      audioRef.current.play();

      audioRef.current.addEventListener("ended", () => {
        resolve();
      });
    });
    // audio.play();
    // return response;
  };

  const createSummary = async (apiData) => {
    try {
      setLoading(true);
      const response = await generateChatSummaryApi(apiData);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.error
        ? error.response.data.error
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleCreateSummary = async () => {
    if (!summary) {
      const response = await createSummary({
        callId: chatId,
        mode: answerSource === "LLM" ? "prompt" : "faq",
      });
      setSummary(response);
    }
  };

  const createNewChat = async () => {
    try {
      const response = await createNewChatApi();
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getChatAnswer = async (apiData) => {
    try {
      const response = await getChatAnswerApi(apiData);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  // const getAgentQuery = async (apiData) => {
  //   try {
  //     const response = await agentQuery(apiData);
  //     return response;
  //   } catch (error) {
  //     const errorMessage = error?.message
  //       ? error.message
  //       : "Something went wrong";
  //     showSkillrToast(errorMessage, "error");
  //   }
  // };

  const workflowChat = async (workflowChatData) => {
    try {
      // const response = await workflowChatApi(workflowChatData);
      const response = await handleUserInputV3(workflowChatData);
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      return;
    }
  };

  const getAnswer = async (message) => {
    let response;
    if (answerSource === "LLM") {
      // getchatanswer
      response = await getChatAnswer({
        question: message,
        callId: chatId,
      });
    } else if (answerSource === "weaviate") {
      // agentquery
      // response = await getAgentQuery({
      //   question: message,
      // });

      // workflow chat
      response = await workflowChat({
        question: dtmfInputEnabled ? null : message,
        DTMF: dtmfInputEnabled ? +message : null,
        // nodeId: chat[chat.length - 1]?.agent?.nodeId,
        // companyId: chat[chat.length - 1]?.agent?.companyId,
        // workFlowId: chat[chat.length - 1]?.agent?.workFlowId,
        // sessionId: chat[chat.length - 1]?.agent?.sessionId,
        // voiceCallId: chat[0]?.agent?.voiceCallId,
        voiceCallId: currentVoiceCallId,
        // botId: selectedBot,
        // didNo: allBot[0].didNumbers,
        botId: allBot[0]?._id.toString(),
      });
    } else {
      // smart+
      response = await getSmartPlus({
        callId: chatId,
        question: message,
      });
    }
    return response;
  };

  const playAudio = (gcsUri) => {
    return new Promise((resolve) => {
      audioRefs.current[gcsUri].play();

      audioRefs.current[gcsUri].addEventListener("ended", () => {
        delete audioRefs.current[gcsUri];
        resolve();
      });
    });
  };

  const getAudioUrl = async (url) => {
    try {
      const signedUrl = await assetsAPI(
        url?.split("//")?.[1],
        `authToken=${token}`
      );
      return signedUrl;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const addNewMessage = async (message, type) => {
    try {
      if (message.trim() === "") {
        return;
      }
      let newConversation = [
        ...chat,
        {
          _id: chat.length + 1,
          customer: {
            query: message,
            createdAt: new Date().toISOString(),
          },
        },
      ];
      setChat(newConversation);
      const chatAnswer = await getAnswer(message);
      /**
       * If user says please wait or anything which means to wait,
       * then we will get isWait true in response and we will wait for "responseWaitTime" time
       */
      if (chatAnswer?.intermittentFiller?.isWait) {
        await wait(chatAnswer?.intermittentFiller?.waitTime * 1000);
      }

      for (let a of chatAnswer?.answer) {
        if (a?.audio) {
          const signedAudioUrl = await getAudioUrl(a?.audio);
          a.audio = signedAudioUrl?.url
            ? signedAudioUrl?.url.split("?")[0]
            : "";
        }
        newConversation.push({
          agent: {
            response: a,
            createdAt: new Date().toISOString(),
            ...chatAnswer,
          },
        });
      }

      // if (!chatAnswer?.answer) return;
      // if (Array.isArray(chatAnswer?.answer)) {
      //   chatAnswer?.answer?.forEach((a) => {
      //     newConversation.push({
      //       agent: {
      //         response: a,
      //         createdAt: new Date().toISOString(),
      //         ...chatAnswer,
      //       },
      //     });
      //   });
      // } else {
      //   newConversation[newConversation.length - 1].agent = {
      //     response: answerSource === "smart+" ? chatAnswer : chatAnswer?.answer,
      //     createdAt: new Date().toISOString(),
      //     ...chatAnswer,
      //   };
      // }
      setChat([...newConversation]);
      // for playaudio
      if (!chatMode) {
        // setTimeout(async () => {
        // for (let i = 0; i < chatAnswer?.answer.length; i++) {
        //   const ans = chatAnswer?.answer[i];
        //   if (ans.message) {
        //     await getAudio({ text: ans.message });
        //   } else {
        //     await playAudio(ans.audio);
        //   }
        // }
        // handleTextToSpeech(chatAnswer);
        // }, 0);
        // const getAudioList = chatAnswer?.answer?.forEach((ans, ansIndex) => {
        //   // answer can be audio or message
        //   if (ans.message) {
        //     promiseOfAudio.push(getAudio({ text: ans.message }));
        //   } else {
        //     // if audio then comes here
        //     promiseOfAudio.push(Promise.resolve(ansIndex));
        //   }
        // });
        // const audioList = await Promise.all(promiseOfAudio);
        // audioList.map((singleAudio) => {
        //   if (typeof singleAudio === "number") {
        //     audioRefs.current[singleAudio].play();
        //   } else {
        //     audioRef.current = new Audio("data:audio/wav;base64," + singleAudio);
        //     audioRef.current.play();
        //   }
        // });
      }
      // if (!chatMode) {
      //   let answerText;
      //   if (answerSource === "smart+") {
      //     answerText = chatAnswer;
      //   } else {
      //     answerText = Array.isArray(chatAnswer?.answer)
      //       ? chatAnswer?.answer.join(", ")
      //       : chatAnswer?.answer;
      //   }
      //   getAudio({ text: answerText });
      //   // answerSource === "smart+"
      //   //   ? getAudio({ text: chatAnswer })
      //   //   : getAudio({ text: chatAnswer?.answer });
      // }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const initializeNewChat = async () => {
    try {
      const response = await createNewChat();
      setChatId(response?._id);
      setChat(response?.chat);
      if (!chatMode) {
        getAudio({
          text: response?.chat?.[0]?.agent?.response?.message,
          botVoiceLanguage: response?.userConversationLanguage,
        });
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleChatInput = () => {
    const dtmfInputRegex = /^[0-9]+$/;
    if (dtmfInputEnabled && !dtmfInputRegex.test(chatInput)) {
      showSkillrToast("DTMF can only accept numbers", "error");
      return;
    }
    addNewMessage(chatInput, "customer");
    setChatInput("");
  };

  const handleOnKeyUp = (e) => {
    if (e.key === "Enter") {
      handleChatInput();
    }
  };

  const handleSwitchToChat = () => {
    setChatMode(!chatMode);
  };

  const handleDtmfInput = () => {
    setDtmfInputEnable(!dtmfInputEnabled);
  };

  const audioQueue = [];
  const playAudioQueue = () => {
    if (audioQueue.length === 0) return;

    const { url, type } = audioQueue.shift();
    const audio = new Audio(url);
    audio.type = type;
    audio.play();

    audio.addEventListener("ended", () => {
      playAudioQueue();
    });
  };

  const playGcsUri = ({ url, type }) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.type = type;
      audio.play();

      audio.addEventListener("ended", () => {
        resolve();
      });
    });
  };

  const handleTextToSpeech = async (chatData) => {
    try {
      const [key] = Object.keys(chatData);
      let combinedText = "";

      for (let i = 0; i < chatData?.[key]?.answer?.length; i++) {
        const ans = chatData?.[key]?.answer[i];
        const nextAns = chatData?.[key]?.answer[i + 1];

        if (Array.isArray(ans?.gcsUri)) {
          if (ans?.gcsUri.length) {
            for (let gcsData of ans?.gcsUri) {
              if (gcsData?.gcsUri) {
                const audioUrl = gcsData?.gcsUri.replace("gs://", gscUriPrefix);
                const audioType = `audio/${gcsData?.gcsUri.split(".").pop()}`;
                await playGcsUri({ url: `${audioUrl}`, type: audioType });
              } else if (gcsData?.text) {
                await getAudio({
                  text: gcsData?.text,
                  botVoiceLanguage: chatData?.[key]?.userConversationLanguage,
                });
              } else if (ans?.message) {
                await getAudio({
                  text: ans?.message,
                  botVoiceLanguage: chatData?.[key]?.userConversationLanguage,
                });
              }
            }
          } else if (ans?.message) {
            await getAudio({
              text: ans?.message,
              botVoiceLanguage: chatData?.[key]?.userConversationLanguage,
            });
          }
        } else if (ans?.gcsUri) {
          const audioUrl = ans.gcsUri.replace("gs://", gscUriPrefix);
          const audioType = `audio/${ans.gcsUri.split(".").pop()}`;
          await playGcsUri({ url: `${audioUrl}`, type: audioType });
        } else if (ans.message) {
          combinedText += ans.message + " ";
        } else if (ans.audio) {
          await playAudio(ans.audio, chat.length - 1);
        }

        if (
          combinedText?.length > 0 &&
          (nextAns?.gcsUri || nextAns?.audio || !nextAns)
        ) {
          await getAudio({
            text: combinedText,
            botVoiceLanguage: chatData?.[key]?.userConversationLanguage,
          });
          combinedText = "";
        }
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleWeaviateWorkflow = async () => {
    // const workflowChatResponse = await startWorkFlowChat({
    //   botId: selectedBot,
    //   voiceCallId: Math.random().toFixed(10),
    // });
    let newWorkflowCallId = Math.random().toFixed(10);
    setCurrentVoiceCallId(newWorkflowCallId);

    // if (!allBot[0]?.didNumbers.length) {
    //   showSkillrToast("Please add DID number to the account");
    //   return;
    // }
    const workflowChatResponse = await handleUserInputV3({
      voiceCallId: newWorkflowCallId,
      // didNo: allBot[0]?.didNumbers,
      botId: allBot[0]?._id?.toString(),
    });

    for (let message of workflowChatResponse?.answer) {
      if (message?.audio) {
        const signedAudioUrl = await getAudioUrl(message?.audio);
        message.audio = signedAudioUrl?.url
          ? signedAudioUrl?.url.split("?")[0]
          : "";
      }
    }

    const agentQuery = Array.isArray(workflowChatResponse?.answer)
      ? workflowChatResponse?.answer?.map((a) => ({
          agent: { response: a, ...workflowChatResponse },
        }))
      : {
          agent: {
            response: workflowChatResponse?.answer,
            ...workflowChatResponse,
          },
        };

    setChat(agentQuery);
  };

  const handleStartCall = async () => {
    // if (!allBot[0]?.didNumbers.length) {
    //   showSkillrToast("Please add DID number to the account");
    //   return;
    // }
    setCallStatus(true);
    if (currentMode === "weaviate") {
      handleWeaviateWorkflow();
    } else {
      initializeNewChat();
    }
  };

  const handleEndCall = async () => {
    try {
      const response = await endCall(currentVoiceCallId, {
        endTime: new Date().toISOString(),
      });

      if (response) {
        audioRef.current?.pause();
        setCallStatus(false);
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleRefreshButton = () => {
    setChat([]);
    setCallStatus(false);
    setSummary();
    setChatId();
    setChatMode(false);
  };

  const storeCurrentChatDetails = () => {
    playgroundCurrentChat.current = {
      ...playgroundCurrentChat.current,
      playgroundChat: {
        chat,
        summary: summary?.summary,
        startLine: summary?.startLine,
        endLine: summary?.endLine,
        callStatus,
        chatMode,
        _id: chatId,
      },
    };
  };

  const scrollToBottom = () => {
    messagesContainer.current.scrollTop =
      messagesContainer.current.scrollHeight;
  };

  const scrollToBottomForAssistance = () => {
    assistantContainer.current.scrollTop =
      assistantContainer.current.scrollHeight;
  };

  const configureObserver = () => {
    const ref = messagesContainer.current;
    // Configuration options for the observer
    const observerConfig = {
      childList: true, // Observe changes to the child nodes of the parent element
      subtree: true, // Observe changes in all descendants of the parent element
    };
    const containerObserverInstance = new MutationObserver((mutationList) => {
      scrollToBottom();
    });
    containerObserverInstance.observe(ref, observerConfig);
    return containerObserverInstance;
  };

  // const configureObserverForAssistance = () => {
  //   const ref = assistantContainer.current;
  //   // Configuration options for the observer
  //   const observerConfig = {
  //     childList: true, // Observe changes to the child nodes of the parent element
  //     subtree: true, // Observe changes in all descendants of the parent element
  //   };
  //   const containerObserverInstance = new MutationObserver((mutationList) => {
  //     scrollToBottomForAssistance();
  //   });
  //   containerObserverInstance.observe(ref, observerConfig);
  //   return containerObserverInstance;
  // };

  const handleFilter = (e, selectedBot, setSelectedBot) => {
    const newSelectedBot = e.target.value;
    let newBot;
    // if (Array.isArray(filter)) {
    //   newFilter = [...filter];
    //   const index = filter.indexOf(selectedFilter);
    //   if (index > -1) {
    //     newFilter.splice(index, 1);
    //   } else {
    //     newFilter.push(selectedFilter);
    //   }
    // } else {
    newBot = newSelectedBot === selectedBot ? selectedBot : newSelectedBot;
    // }
    setSelectedBot(newBot);
  };

  useEffect(() => {
    const observer = configureObserver();
    // const observer2 = configureObserverForAssistance();

    return () => {
      observer.disconnect();
      // observer2.disconnect();
    };
  }, []);

  useEffect(() => {
    allBot && allBot?.length && setSelectedBot(allBot[0]?._id);
  }, [allBot]);

  useEffect(() => {
    if (singleChatHistoryDetails?.chat?.length > 0) {
      if (
        singleChatHistoryDetails?.chat &&
        singleChatHistoryDetails?.chat.length
      ) {
        setCurrentVoiceCallId(
          singleChatHistoryDetails.chat[0]?.agent?.voiceCallId
        );
      }
      setChatId(singleChatHistoryDetails?._id);
      setChat(
        singleChatHistoryDetails?.chat.map((ch) => {
          return { ...ch, noAudio: true };
        })
      );
    }
    if (singleChatHistoryDetails?.summary) {
      setSummary({
        summary: singleChatHistoryDetails?.summary,
        startLine: singleChatHistoryDetails?.startLine,
        endLine: singleChatHistoryDetails?.endLine,
      });
    }
    setCallStatus(singleChatHistoryDetails?.callStatus ?? false);
    setChatMode(singleChatHistoryDetails?.chatMode ?? false);
  }, [singleChatHistoryDetails]);

  useEffect(() => {
    if (chatFeature) {
      storeCurrentChatDetails();
    }
  }, [chat, callStatus, chatMode, summary]);

  useEffect(() => {
    // This useeffect is used for handling speech to text
    if (!chatMode && chat.length > 0) {
      if (!chat[chat.length - 1].noAudio) {
        handleTextToSpeech(chat[chat.length - 1]);
      }
    }
  }, [chat.length]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (callStatus) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [callStatus]);

  useEffect(() => {
    return () => {
      // Cleanup audio refs on unmount
      audioRefs.current = {};
    };
  }, []);

  return (
    <Box style={{ display: "flex", flex: 1, overflowY: "auto" }}>
      {/* <Box className={styles.left}>
        <Box className={styles.yaraAssistance}>
          <Box
            style={{
              position: "sticky",
              top: 0,
              background: "#fbfbfb",
            }}
          >
            <Typography variant="subtitle2" mb={4}>
              Yara assistance
            </Typography>

            <Box className={styles.flexcenter} pb={4}>
              <Button
                variant="outlined"
                className={`${styles.actionButton} ${
                  activeTab === 0 ? styles.active : ""
                }`}
                startIcon={<Atom width={16} height={16} />}
                onClick={() => setActiveTab(0)}
              >
                Real time suggestions
              </Button>

              <FundamentoPopup
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                disableCloseOnClick={true}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    className={allBot?.length > 0 && styles.activeFilter}
                    sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Select Bot
                  </Button>
                }
              >
                <Box
                  style={{ display: "flex", flexDirection: "column", gap: 12 }}
                >
                  {allBot?.map((item) => (
                    <FlexBox columnGap={2} component="label">
                      <Checkbox
                        value={item?._id}
                        onChange={(e) => {
                          handleFilter(e, selectedBot, setSelectedBot);
                        }}
                        checked={item?._id == selectedBot}
                      />
                      <Typography variant="body2">{item.name}</Typography>
                    </FlexBox>
                  ))}
                </Box>
              </FundamentoPopup>

              <Dialog
                open={operationModeModal}
                onClose={() => setOperationModeModal(false)}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  style: {
                    maxWidth: 650,
                    borderRadius: 24,
                    position: "relative",
                  },
                }}
              >
                <Box
                  style={{
                    padding: 32,
                  }}
                >
                  <IconButton
                    sx={{ position: "absolute", top: 16, right: 16 }}
                    onClick={() => setOperationModeModal(false)}
                  >
                    <X width={16} height={16} />
                  </IconButton>
                  <Typography
                    textAlign="center"
                    mb={8}
                    variant="h3"
                    fontWeight={500}
                  >
                    Select operation mode
                  </Typography>
                  <Box
                    className={styles.operationModeItem}
                    onClick={() => setCurrentMode("weaviate")}
                  >
                    <Radio
                      icon={<Circle width={16} height={16} />}
                      checkedIcon={<RadioButton width={16} height={16} />}
                      checked={currentMode === "weaviate"}
                    />
                    <div>
                      <Typography variant="subtitle1">
                        FAQ based suggestions
                      </Typography>
                      <Typography variant="caption">
                        Power the Assistant with your proprietary data
                      </Typography>
                    </div>
                  </Box>
                  <Box
                    className={styles.operationModeItem}
                    onClick={() => setCurrentMode("LLM")}
                  >
                    <Radio
                      checked={currentMode === "LLM"}
                      icon={<Circle width={16} height={16} />}
                      checkedIcon={<RadioButton width={16} height={16} />}
                    />
                    <div>
                      <Typography variant="subtitle1">
                        Prompt based suggestions
                      </Typography>
                      <Typography variant="caption">
                        Power the Assistant with a Large Language Model tuned
                        for your Enterprise
                      </Typography>
                    </div>
                  </Box>
                  <Box
                    className={styles.operationModeItem}
                    onClick={() => setCurrentMode("smart+")}
                  >
                    <Radio
                      checked={currentMode === "smart+"}
                      icon={<Circle width={16} height={16} />}
                      checkedIcon={<RadioButton width={16} height={16} />}
                    />
                    <div>
                      <Typography variant="subtitle1">Smart+</Typography>
                      <Typography variant="caption">
                        Power the Assistant with a combination of proprietary
                        data and an Enterprise trained LLM
                      </Typography>
                    </div>
                  </Box>

                  <FlexBox justifyContent="center" mt={8}>
                    <Button
                      sx={{
                        padding: "14px 24px",
                        borderRadius: 82,
                      }}
                      variant="outlined"
                      onClick={() => setOperationModeModal(false)}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="dark"
                      endIcon={<ChevronRight />}
                      onClick={() => {
                        setAnswerSource(currentMode);
                        setOperationModeModal(false);
                      }}
                    >
                      Continue
                    </Button>
                  </FlexBox>
                </Box>
              </Dialog>
            </Box>
          </Box>
          <Box style={{ flex: 1, overflowY: "auto" }} ref={assistantContainer}>
            {activeTab === 0 && (
              <Box>
                {chat?.map(
                  (conversation) =>
                    conversation?.agent && (
                      <Box className={styles.yaraSuggestion}>
                        <p className={styles.yara}>
                          {moment
                            .utc(conversation.agent.createdAt)
                            .local()
                            .format("HH:mm")}
                        </p>

                        <Box className={styles.yaraMessage}>
                          {conversation.agent.response?.message
                            ? `Tell Customer: ${conversation.agent.response?.message}`
                            : conversation.agent.response?.audio && (
                                // conditional check to see if the message aint empty{}
                                <audio
                                  controls
                                  style={{ width: "100%" }}
                                  className={styles.audio}
                                >
                                  <source
                                    src={conversation.agent.response?.audio}
                                    // src={`${
                                    //   process.env.REACT_APP_BACKEND_API_ENDPOINT || window.location.protocol + "//" + window.location.hostname
                                    // }/api/asset/${
                                    //   ?.split(
                                    //     "//"
                                    //   )[1]
                                    // }?authToken=${token}`}
                                    type={
                                      "audio/" +
                                      conversation.agent.response?.audio
                                        ?.split(".")
                                        ?.pop()
                                    }
                                  ></source>
                                </audio>
                              )}
                        </Box>
                      </Box>
                      // </Box>
                    )
                )}
              </Box>
            )}

            {activeTab === 1 && (
              <Box style={{ height: "80%", width: "100%" }}>
                {!summary && chat.length > 0 && (
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={
                        loading ? (
                          <Loader width={18} height={18} />
                        ) : (
                          <Wand width={18} height={24} />
                        )
                      }
                      className={`${styles.actionButton} ${styles.wand}`}
                      onClick={handleCreateSummary}
                      disabled={loading}
                      style={{ minWidth: 170 }}
                    >
                      Generate summary
                    </Button>
                  </Box>
                )}
                {summary && (
                  <>
                    <Box className={styles.yaraSuggestion}>
                      <p className={styles.yara}>Opening remarks</p>

                      <Box className={styles.yaraMessage}>
                        {summary?.startLine}
                      </Box>
                    </Box>
                    <Box className={styles.yaraSuggestion}>
                      <p className={styles.yara}>Closing remarks</p>

                      <Box className={styles.yaraMessage}>
                        {summary?.endLine}
                      </Box>
                    </Box>
                    <Box className={styles.yaraSuggestion}>
                      <p className={styles.yara}>Summary</p>

                      <Box className={styles.yaraMessage}>
                        {summary?.summary}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box> */}

      <Box className={styles.content}>
        <Box className={styles.transcriptBox}>
          <Box className={styles.flexcenter}>
            <Typography variant="subtitle2" color="text.primary">
              Transcript
            </Typography>

            {clearChatFeature && (
              <Tooltip title="Clear call history" placement="top">
                <Button
                  size="small"
                  variant="text"
                  sx={{ marginLeft: "auto" }}
                  color="inherit"
                  onClick={handleRefreshButton}
                  startIcon={<RefreshCcw width={16} height={16} />}
                >
                  Clear chat
                </Button>
              </Tooltip>
            )}
          </Box>

          {/* <Box className={styles.sentimentContainer}>
            <Avatar>😔</Avatar>
            <Typography color="text.primary" variant="caption">
              Negative sentiment detected
            </Typography>
            <Typography
              color="text.primary"
              style={{ marginLeft: "auto" }}
              variant="caption"
              fontWeight={500}
            >
              Dismiss
            </Typography>
          </Box> */}

          <Box style={{ flex: 1, overflowY: "auto" }} ref={messagesContainer}>
            <Box>
              {chat.map((conversation, chatIndex) =>
                Object.keys(conversation)
                  .sort((a) => (a === "customer" ? -1 : 0)) // to make  ["agent", "customer", "id"] => ["id", "customer", "agent"]
                  .map(
                    (key) =>
                      ["customer", "agent"].indexOf(key) > -1 && (
                        <Box
                          className={`${styles.message}`}
                          key={`${chatIndex}_${key}`}
                        >
                          <Box style={{ width: "10dvw" }}>
                            <Box
                              className={`${styles.avatar} ${
                                key === "customer"
                                  ? styles.customer
                                  : styles.agent
                              }`}
                            >
                              {key === "customer" ? "Customer" : "Agent"}
                            </Box>
                          </Box>

                          <Box style={{ flex: 1 }}>
                            {key === "customer" && conversation[key].query && (
                              <Typography
                                variant="body2"
                                style={{
                                  whiteSpace: "pre-line",
                                  maxWidth: "90%",
                                  wordWrap: "break-word",
                                }}
                              >
                                {conversation[key].query}
                              </Typography>
                            )}
                            {key === "agent" &&
                              "message" in conversation[key].response && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    whiteSpace: "pre-line",
                                    maxWidth: "90%",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {conversation[key].response.message}
                                </Typography>
                              )}

                            {key === "agent" &&
                              "audio" in conversation[key].response &&
                              conversation[key].response.audio.length > 0 && (
                                <audio
                                  controls
                                  style={{ width: "100%" }}
                                  className={styles.audio}
                                  ref={(ref) =>
                                    (audioRefs.current[
                                      conversation[key].response.audio
                                    ] = ref)
                                  }
                                >
                                  <source
                                    src={conversation[key].response.audio}
                                    type={
                                      "audio/" +
                                      conversation[key].response.audio
                                        ?.split(".")
                                        ?.pop()
                                    }
                                  ></source>
                                </audio>
                              )}
                          </Box>

                          <Box style={{ width: "5dvw" }}>
                            <p className={styles.timestamp}>
                              {moment
                                .utc(conversation[key].createdAt)
                                .local()
                                .format("HH:mm")}
                            </p>
                          </Box>
                        </Box>
                      )
                  )
              )}
            </Box>
          </Box>
        </Box>

        {chatFeature && (
          <Box className={styles.chatBoxContainer}>
            {callStatus && (
              <Collapse in={chatMode} mountOnEnter unmountOnExit>
                <Box
                  className={`${styles.chatBox} ${
                    chatMode ? styles.active : ""
                  }`}
                >
                  <TextField
                    value={chatInput}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      style: {
                        fontSize: 14,
                      },
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleChatInput}
                            disabled={!chatInput}
                          >
                            <ArrowRight
                              color="text.primary"
                              width={20}
                              height={20}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Start typing..."
                    onChange={(e) => setChatInput(e.target.value)}
                    onKeyUp={handleOnKeyUp}
                  />
                </Box>
              </Collapse>
            )}
            <Box className={styles.callControls}>
              <Box style={{ marginRight: "auto" }}>
                <Typography variant="body2" fontWeight={500}>
                  {callStatus
                    ? `${
                        chatMode
                          ? "Chat in progress..."
                          : "Voice chat in progress..."
                      } `
                    : `${chatMode ? "Chat mode" : "Voice chat mode"} `}
                </Typography>

                <Box className={styles.flexcenter} style={{ gap: 0 }}>
                  <Switch
                    size="small"
                    checked={chatMode}
                    onChange={handleSwitchToChat}
                  />
                  <Typography variant="caption">switch to chat</Typography>

                  {chatMode && (
                    <Box ml={2}>
                      <label>
                        <Checkbox
                          color="primary"
                          checked={dtmfInputEnabled}
                          onChange={handleDtmfInput}
                        />
                        <Typography variant="caption">DTMF input</Typography>
                      </label>
                    </Box>
                  )}
                </Box>
              </Box>
              {!chatMode && callStatus && (
                <SpeechToText
                  onResult={(result) => {
                    addNewMessage(result, "customer");
                    setCustomerActive(false);
                  }}
                  active={customerActive}
                  onClick={() => setCustomerActive(true)}
                  type="customer"
                />
              )}
              {!callStatus && (
                <Button
                  // startIcon={<Phone fill="white" width={16} height={16} />}
                  className={`${styles.callButton} ${styles.send}`}
                  onClick={handleStartCall}
                >
                  Start
                </Button>
              )}
              {callStatus && (
                <>
                  <Box className={styles.flexcenter} style={{ gap: 12 }}></Box>

                  <Button
                    // startIcon={<PhoneOff fill="white" width={16} height={16} />}
                    className={`${styles.callButton} ${styles.end}`}
                    onClick={handleEndCall}
                  >
                    End
                    {/* {chatMode ? "chat" : "call"} */}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Chat.propTypes = {
  clearChatFeature: PropTypes.bool,
  chatFeature: PropTypes.bool,
  singleChatHistoryDetails: PropTypes.object,
};

Chat.defaultProps = {
  clearChatFeature: true,
  chatFeature: true,
  singleChatHistoryDetails: {},
};

export default Chat;
