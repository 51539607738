import { javascript } from "@codemirror/lang-javascript";
import { Box, Button, Switch, Typography } from "@mui/material";
import ReactCodeMirror from "@uiw/react-codemirror";
import FlexBox from "components/FlexBox";
import { Controller, useForm } from "react-hook-form";
import theme from "theme";

const PostProcess = ({ onSave, data }) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      postCallProcess: data?.postCallProcess || false,
      postCallScript: data?.postCallScript || "",
    },
  });

  const handleSave = (data) => {
    onSave(data);
  };

  return (
    <Box
      sx={{
        mt: 8,
        padding: 6,
        border: `1px solid ${theme.palette.borderColor.light}`,
        borderRadius: 3,
      }}
    >
      <FlexBox>
        <Typography variant="body1" fontWeight={500}>
          Post Process
        </Typography>
        <Controller
          control={control}
          name="postCallProcess"
          render={({ field: { onChange, value } }) => (
            <Switch size="small" onChange={onChange} checked={value} />
          )}
        />
      </FlexBox>

      <div
        style={
          !watch("postCallProcess")
            ? { pointerEvents: "none", opacity: 0.6 }
            : {}
        }
      >
        <Controller
          name="postCallScript"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <ReactCodeMirror
              value={value}
              height="40vh"
              language="js"
              placeholder=""
              onChange={onChange}
              extensions={[javascript()]}
              padding={15}
              wrap={true}
              readOnly={!watch("postCallProcess")}
              style={{
                marginTop: 32,
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                border: `0 !important`,
                borderRadius: 12,
              }}
            />
          )}
        />
      </div>
      <FlexBox justifyContent="flex-end" mt={2}>
        <Button
          variant="dark"
          sx={{
            borderRadius: 3,
            padding: "10px 20px",
            minWidth: "fit-content",
          }}
          onClick={handleSubmit(handleSave)}
        >
          Save
        </Button>
      </FlexBox>
    </Box>
  );
};

export default PostProcess;
