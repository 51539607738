import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const CallDurationCategories = ({ data }) => {
  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Function to update dimensions
  const updateDimensions = () => {
    if (chartRef.current) {
      const containerWidth = chartRef.current.clientWidth;
      const containerHeight = containerWidth * 0.5; // Maintaining a 2:1 aspect ratio
      setDimensions({ width: containerWidth, height: containerHeight });
    }
  };

  // Initial setup and window resize handler
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!chartRef.current || dimensions.width === 0 || !data) return;

    // Define the fixed order of categories
    const categoryOrder = ["0 - 0.5", "0.5 - 1", "1 - 1.5", "1.5 - 2", ">2"];

    // Map the data to the fixed order, using 0 for missing categories
    const orderedData = categoryOrder.map((category) => {
      const found = data.find((d) => d.label === category);
      return found || { label: category, count: 0 };
    });

    // Clear any existing chart
    d3.select(chartRef.current).selectAll("*").remove();

    // Chart dimensions
    const margin = { top: 20, right: 20, bottom: 40, left: 60 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Scales
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(categoryOrder)
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(orderedData, (d) => d.count)]);

    // Add X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "12px");

    // Add X axis label
    svg
      .append("text")
      .attr("transform", `translate(${width / 2},${height + margin.bottom})`)
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#7b7b7b")
      .text("Duration");

    // Add Y axis
    svg
      .append("g")
      .call(d3.axisLeft(y))
      .selectAll("text")
      .style("font-size", "12px");

    // Add Y axis label
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left + 15)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#7b7b7b")
      .text("Call Count");

    // Create tooltip div
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "d3-tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("background-color", "rgba(15, 15, 15, 0.8)")
      .style("color", "white")
      .style("border-radius", "12px")
      .style("padding", "8px 12px")
      .style("font-size", "12px");

    // Modify the bars code to include tooltip interactions
    svg
      .selectAll("rect")
      .data(orderedData)
      .join("rect")
      .attr("x", (d) => x(d.label))
      .attr("y", height)
      .attr("width", x.bandwidth())
      .attr("height", 0)
      .attr("fill", "#2B7BA9")
      .on("mouseover", (event, d) => {
        tooltip
          .style("opacity", 1)
          .html(`Duration: ${d.label}<br/>Calls: ${d.count}`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("top", event.pageY - 10 + "px")
          .style("left", event.pageX + 10 + "px");
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      })
      .transition()
      .duration(300)
      .ease(d3.easeQuadOut)
      .attr("y", (d) => y(d.count))
      .attr("height", (d) => height - y(d.count));

    // Cleanup function to remove tooltip when component unmounts
    return () => {
      d3.select(".d3-tooltip").remove();
    };
  }, [dimensions, data]);

  return (
    <div>
      <div ref={chartRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default CallDurationCategories;
