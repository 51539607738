import { useEffect, useRef } from "react";
import * as d3 from "d3";

const RedialBasedRates = ({ data, campaigns }) => {
  const svgRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (!data || !data.length) return;

    // Function to create/update chart
    const createChart = () => {
      // Get current container dimensions
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      // Clear any existing SVG content
      d3.select(svgRef.current).selectAll("*").remove();

      // Set dimensions based on container
      const margin = { top: 20, right: 30, bottom: 40, left: 60 };
      const width = containerWidth - margin.left - margin.right;
      const height = containerHeight - margin.top - margin.bottom;

      // Process data to group by campaignId
      const groupedData = d3.group(data, (d) => d.campaignId);

      // Create a color scale for unique campaign colors
      const colorScale = d3
        .scaleOrdinal()
        .domain(Array.from(groupedData.keys()))
        .range(d3.schemeCategory10); // Using d3's built-in color scheme

      // Create arrays of points for each campaign
      const campaignLines = Array.from(groupedData).map(([id, values]) => {
        return values.sort((a, b) => a.redialAttempts - b.redialAttempts);
      });

      // Create tooltip
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background-color", "rgba(15,15,15,0.8)")
        .style("color", "white")
        .style("padding", "8px 12px")
        .style("border-radius", "12px")
        .style("pointer-events", "none")
        .style("opacity", 0);

      // Create SVG
      const svg = d3
        .select(svgRef.current)
        .attr("width", containerWidth)
        .attr("height", containerHeight)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Set scales
      const xScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.redialAttempts)])
        .range([0, width])
        .nice();

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.callsAnswered) * 1.2])
        .range([height, 0])
        .nice();

      // Create line generator
      const line = d3
        .line()
        .x((d) => xScale(d.redialAttempts))
        .y((d) => yScale(d.callsAnswered));

      // Add X axis
      svg
        .append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale).ticks(6))
        .append("text")
        .attr("x", width / 2)
        .attr("y", 35)
        .attr("fill", "#666")
        .attr("text-anchor", "middle")
        .text("Redial Attempts")
        .style("font-size", "12px");

      // Add Y axis
      svg
        .append("g")
        .call(d3.axisLeft(yScale))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -45)
        .attr("x", -height / 2)
        .attr("fill", "#666")
        .attr("text-anchor", "middle")
        .text("Number of Calls")
        .style("font-size", "12px");

      // Function to find all points at the same coordinates
      const findCollidingPoints = (currentPoint) => {
        return data.filter(
          (d) =>
            d.redialAttempts === currentPoint.redialAttempts &&
            d.callsAnswered === currentPoint.callsAnswered
        );
      };

      // Function to get campaign name from campaignId
      const getCampaignName = (campaignId) => {
        const campaign = campaigns.find((c) => c._id === campaignId);
        return campaign ? campaign.name : "Unknown Campaign";
      };

      // Function to create tooltip content
      const createTooltipContent = (collidingPoints) => {
        return collidingPoints
          .map(
            (point) =>
              `Campaign: ${getCampaignName(point.campaignId)}<br/>Calls: ${
                point.callsAnswered
              }<br/>Attempts: ${point.redialAttempts}`
          )
          .join('<hr style="margin: 5px 0;">');
      };

      // Add lines with transitions
      campaignLines.forEach((campaign, index) => {
        const campaignId = campaign[0].campaignId;
        const campaignColor = colorScale(campaignId);

        const path = svg
          .append("path")
          .datum(campaign)
          .attr("fill", "none")
          .attr("stroke", campaignColor)
          .attr("stroke-width", 2)
          .attr("d", line);

        const totalLength = path.node().getTotalLength();

        path
          .attr("stroke-dasharray", totalLength + " " + totalLength)
          .attr("stroke-dashoffset", totalLength)
          .transition()
          .duration(1000)
          .ease(d3.easeLinear)
          .attr("stroke-dashoffset", 0);

        // Add dots
        svg
          .selectAll(`circle-group-${index}`)
          .data(campaign)
          .join("circle")
          .attr("cx", (d) => xScale(d.redialAttempts))
          .attr("cy", (d) => yScale(d.callsAnswered))
          .attr("r", 0)
          .attr("fill", "white")
          .attr("stroke", campaignColor)
          .attr("stroke-width", 2)
          .on("mouseover", (event, d) => {
            const collidingPoints = findCollidingPoints(d);
            tooltip.transition().duration(300).style("opacity", 1);
            tooltip
              .html(createTooltipContent(collidingPoints))
              .style("left", `${event.pageX + 10}px`)
              .style("top", `${event.pageY - 28}px`);
          })
          .on("mouseout", () => {
            tooltip.transition().duration(300).style("opacity", 0);
          })
          .transition()
          .duration(300)
          .attr("r", 4);
      });
    };

    // Create initial chart
    createChart();

    // Add resize listener
    const handleResize = () => {
      createChart();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      d3.select(".tooltip").remove();
      window.removeEventListener("resize", handleResize);
    };
  }, [data, campaigns]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "60vh" }}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default RedialBasedRates;
