import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const AuditedCallOutcomes = ({ data = [] }) => {
  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Function to update dimensions
  const updateDimensions = () => {
    if (chartRef.current) {
      const containerWidth = chartRef.current.clientWidth;
      const containerHeight = containerWidth * 0.5; // Maintaining a 2:1 aspect ratio
      setDimensions({ width: containerWidth, height: containerHeight });
    }
  };

  // Initial setup and window resize handler
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (
      !chartRef.current ||
      dimensions.width === 0 ||
      !data ||
      !Array.isArray(data) ||
      data.length === 0
    )
      return;

    // Clear any existing chart
    d3.select(chartRef.current).selectAll("*").remove();

    // Chart dimensions
    const margin = { top: 20, right: 150, bottom: 40, left: 20 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
      .append("g")
      .attr(
        "transform",
        `translate(${dimensions.width / 2},${dimensions.height / 2})`
      );

    // Create pie layout
    const pie = d3.pie().value((d) => d.count);

    // Create arc generator
    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(Math.min(width, height) / 2);

    // Create arc for transition
    const arcTween = (d) => {
      const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
      return (t) => arc(i(t));
    };

    // Create color scale (replace the existing color scale)
    const customColors = {
      null: "#357595",
      valid: "#01784E",
      blank: "#5CA2C5",
      invalid: "#7DB5D1",
      others: ["#EB5757", "#224C61", "#FBC400"], // Additional colors for other categories
    };

    const color = (i) => {
      const category = data[i]?.auditedCallType;

      // Handle null value specifically
      if (category === null) {
        return customColors["null"];
      }

      const categoryKey = category?.toLowerCase() || "";
      return (
        customColors[categoryKey] ||
        customColors.others[i % customColors.others.length]
      );
    };

    // Create tooltip div
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("background-color", "rgba(15, 15, 15, 0.8)")
      .style("color", "white")
      .style("border-radius", "12px")
      .style("padding", "8px 12px")
      .style("font-size", "12px");

    // Update pieChart selection with better tooltip positioning
    const pieChart = svg
      .selectAll(".pie")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "pie")
      .on("mouseover", (event, d) => {
        const bounds = chartRef.current.getBoundingClientRect();
        const tooltipX = event.clientX - bounds.left;
        const tooltipY = event.clientY - bounds.top;

        tooltip
          .style("opacity", 1)
          .html(
            `
            <strong>${
              d.data.auditedCallType === null
                ? "Unassigned"
                : d.data.auditedCallType
            }</strong><br/>
            Count: ${d.data.count}<br/>
            Percentage: ${(
              ((d.endAngle - d.startAngle) / (2 * Math.PI)) *
              100
            ).toFixed(1)}%
          `
          )
          .style("left", `${tooltipX}px`)
          .style("top", `${tooltipY}px`);
      })
      .on("mousemove", (event) => {
        const bounds = chartRef.current.getBoundingClientRect();
        const tooltipX = event.clientX - bounds.left;
        const tooltipY = event.clientY - bounds.top;

        tooltip.style("left", `${tooltipX}px`).style("top", `${tooltipY}px`);
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      });

    // Add slices with transition
    pieChart
      .append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => color(i))
      .attr("stroke", "white")
      .attr("stroke-width", 1)
      .transition()
      .duration(1000)
      .attrTween("d", arcTween);

    // Update legend position and styling
    const legend = svg
      .selectAll(".legend")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr(
        "transform",
        (d, i) => `translate(${width / 3}, ${i * 20 - height / 2})`
      );

    // Update legend fill color
    legend
      .append("rect")
      .attr("x", 0)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d, i) => color(i));

    // Update legend text styling
    legend
      .append("text")
      .attr("x", 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .style("font-size", "14px")
      .style("fill", "#7b7b7b")
      .text((d) =>
        d.auditedCallType === null ? "Unassigned" : d.auditedCallType
      );
  }, [dimensions, data]);

  return <div ref={chartRef} style={{ position: "relative" }} />;
};

export default AuditedCallOutcomes;
