import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const CallVolume = ({ data, startDate, endDate }) => {
  const containerRef = useRef();
  const svgRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Handle resize
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const height = width * 0.7;
        setDimensions({ width, height });
      }
    };

    // Initial size calculation
    handleResize();

    // Create ResizeObserver for more precise container size monitoring
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Clean up
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      !data ||
      !data.length ||
      !dimensions.width ||
      !dimensions.height ||
      !endDate
    )
      return;

    // Generate array of dates for last 7 days (in UTC)
    const dates = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(endDate);
      date.setDate(date.getDate() - (6 - i));
      return date.toISOString().split("T")[0];
    });

    const hours = Array.from({ length: 24 }, (_, i) => i);

    // Create a map for quick lookup of existing data
    const dataMap = new Map(
      data.map((d) => {
        // Extract just the date portion from the ISO string
        const date = d.date.split("T")[0];
        return [`${date}-${d.hour}`, d.totalCalls];
      })
    );

    // Generate complete dataset with 0s for missing hours
    const completeData = dates.flatMap((date) =>
      hours.map((hour) => ({
        date,
        hour,
        totalCalls: dataMap.get(`${date}-${hour}`) || 0,
      }))
    );

    const margin = { top: 40, right: 40, bottom: 40, left: 60 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3
      .select(svgRef.current)
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Use completeData for scales
    const xScale = d3.scaleBand().domain(dates).range([0, width]).padding(0.05);

    const yScale = d3
      .scaleBand()
      .domain(hours)
      .range([height, 0])
      .padding(0.05);

    // Modify color scale to start with a lighter color for empty cells
    const colorScale = d3
      .scaleSequential()
      .domain([0, d3.max(completeData, (d) => d.totalCalls)])
      .interpolator((t) => {
        // Start with rgb(247, 251, 255) for zero values
        if (t === 0) return "rgb(247, 251, 255)";
        // Use darker blues for non-zero values
        return d3.interpolateBlues(t * 0.7 + 0.3);
      });

    // Create heatmap cells using completeData
    svg
      .selectAll("rect")
      .data(completeData)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.date))
      .attr("y", (d) => yScale(d.hour))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .style("fill", (d) => colorScale(d.totalCalls));

    // Add X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3.axisBottom(xScale).tickFormat((d) => {
          const date = new Date(d);
          return `${date.getDate().toString().padStart(2, "0")}/${(
            date.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}`;
        })
      )
      .selectAll("text");

    // Add Y axis
    svg
      .append("g")
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat((hour) => `${hour.toString().padStart(2, "0")}:00`)
      );

    // Add X axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom)
      .text("Date Range")
      .style("font-size", "12px")
      .style("fill", "#7b7b7b");

    // Add Y axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left)
      .attr("x", -height / 2)
      .text("Time")
      .style("font-size", "12px")
      .style("fill", "#7b7b7b");

    // Update tooltip to use the complete data
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "rgba(15,15,15,0.8)")
      .style("color", "white")
      .style("border-radius", "12px")
      .style("padding", "8px 12px")
      .style("font-size", "12px")
      .style("pointer-events", "none")
      .style("opacity", 0);


    svg
      .selectAll("rect")
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(300).style("opacity", 1);
        tooltip
          .html(
            `Date: ${d.date}<br/>
           Time: ${d.hour.toString().padStart(2, "0")}:00<br/>
           Total Calls: ${d.totalCalls}`
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });
  }, [data, dimensions, endDate]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "50vh",
        position: "relative",
      }}
    >
      <svg
        ref={svgRef}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></svg>
    </div>
  );
};

export default CallVolume;
