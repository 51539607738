import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const CallOutcomes = ({ data = [] }) => {
  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Function to update dimensions
  const updateDimensions = () => {
    if (chartRef.current) {
      const containerWidth = chartRef.current.clientWidth;
      const containerHeight = containerWidth * 0.5;
      setDimensions({ width: containerWidth, height: containerHeight });
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!chartRef.current || dimensions.width === 0 || !data?.length) return;

    // Clear existing chart
    d3.select(chartRef.current).selectAll("*").remove();

    // Create tooltip div
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "rgba(15, 15, 15, 0.8)")
      .style("color", "white")
      .style("border-radius", "12px")
      .style("padding", "8px 12px")
      .style("font-size", "12px");

    const margin = { top: 20, right: 150, bottom: 40, left: 20 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create pie layout
    const pie = d3.pie().value((d) => d.count);

    // Create arc generator
    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(Math.min(width, height) / 2);

    // Custom colors matching your design
    const customColors = {
      busy: "#357595",
      rejected: "#5CA2C5",
      "not answered": "#7DB5D1",
      answered: "#FBC400",
      invalid: "#EB5757",
      "no network": "#224C61",
      null: "#7DB5D1", // Color for null outcomes
    };

    // Color function
    const color = (d) => {
      const outcome = d.data?.outcome?.toLowerCase();
      return customColors[outcome] || customColors.null;
    };

    // Create pie chart
    const pieChart = svg
      .selectAll(".pie")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "pie")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Create arc for transition
    const arcTween = (d) => {
      const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
      return (t) => arc(i(t));
    };

    // Update pie slices with transition
    pieChart
      .append("path")
      .attr("d", arc)
      .attr("fill", color)
      .attr("stroke", "white")
      .attr("stroke-width", 1)
      .transition() // Start transition
      .duration(1000) // Animation duration in milliseconds
      .attrTween("d", arcTween) // Animate using arcTween
      .on("end", function () {
        // After transition ends, add event listeners
        d3.select(this)
          .on("mouseover", (event, d) => {
            const outcome =
              d.data.outcome === null
                ? "Not Answered"
                : d.data.outcome.charAt(0).toUpperCase() +
                  d.data.outcome.slice(1);
            const bounds = chartRef.current.getBoundingClientRect();
            const tooltipX = event.clientX - bounds.left;
            const tooltipY = event.clientY - bounds.top;

            tooltip.transition().duration(300).style("opacity", 1);

            tooltip
              .html(`${outcome}<br/>Count: ${d.data.count}`)
              .style("left", `${tooltipX}px`)
              .style("top", `${tooltipY}px`);
          })
          .on("mousemove", (event) => {
            const bounds = chartRef.current.getBoundingClientRect();
            const tooltipX = event.clientX - bounds.left;
            const tooltipY = event.clientY - bounds.top;

            tooltip
              .style("left", `${tooltipX}px`)
              .style("top", `${tooltipY}px`);
          })
          .on("mouseout", () => {
            tooltip.transition().duration(300).style("opacity", 0);
          });
      });

    // Create and animate legend groups
    const legendGroups = svg
      .selectAll(".legend")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(${width + 10}, ${i * 20})`);

    // Add rectangles with correct color mapping
    legendGroups
      .append("rect")
      .attr("x", 0)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d) => {
        const outcome = d.outcome?.toLowerCase();
        return customColors[outcome] || customColors.null;
      });

    // Add text
    legendGroups
      .append("text")
      .attr("x", 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .style("font-size", "14px")
      .style("fill", "#555")
      .text((d) =>
        d.outcome === null
          ? "Not Answered"
          : d.outcome.charAt(0).toUpperCase() + d.outcome.slice(1)
      );

    // Add fade-in animation
    legendGroups
      .style("opacity", 0)
      .transition()
      .delay((d, i) => i * 100)
      .duration(500)
      .style("opacity", 1);
  }, [dimensions, data]);

  return <div ref={chartRef} style={{ position: "relative" }} />;
};

export default CallOutcomes;
