import { Box, Button, IconButton, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import FundamentoTable from "components/FundamentoTable";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { Check, Loader, X } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import ProgressBar from "components/ProgressBar";
import { importSmartDb as importSmartDbApi } from "services";
import { getSmartDbRecord as getSmartDbRecordApi } from "services";
import { getSmartDbRecordCsv as getSmartDbRecordCsvApi } from "services";
import { runInquiries as runInquiriesApi } from "services";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useURLState } from "custom-hooks/useUrlState";
import theme from "theme";
import { IconFilter2, IconUpload2, IconDownload } from "components/SVG";
import moment from "moment";
import { statusTextMapping } from "text-constants/campaign";
import FilterModal from "pages/Campaign/components/FilterModal";
import { SortAscending } from "components/newSVG";
import FundamentoPopup from "components/FundamentoPopup";

const SmartDBRecords = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const smartDb = params.smartDbId;
  const { showSkillrToast } = useSkillrToast();
  const [smartDbDetails, setSmartDbDetails] = useState({});
  const [smartDbRecords, setSmartDbRecords] = useState([]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [smartDbTotalCount, setSmartDbTotalCount] = useState([]);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [sortOrder, setSortOrder] = useURLState("", "sorting");
  const [appliedFilters, setAppliedFilters] = useState({
    status: [],
    minRedialAttempts: "",
    maxRedialAttempts: "",
    minDuration: "",
    maxDuration: "",
    dateFilter: "",
  });
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const acceptedFileTypes = ["text/csv"];

  const contactFields = [
    {
      value: "status",
      label: "Status",
    },
    {
      value: "dateRange",
      label: "Date Range",
    },
  ];

  const {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    uploadStatus,
    uploadFiles,
    removeFile,
    completeFileUpload,
  } = useFileUpload({
    fileTypes: acceptedFileTypes,
    maxUploadLimit: 0,
    sizeAlreadyConsumed: 0,
  });

  const columns = [
    {
      field: "_id",
      header: "Record Id",
    },
    {
      field: "contactId",
      header: "contact Id",
    },
    {
      field: "isClosed",
      header: "Is Closed",
      render: (row) => (row.isClosed ? "Yes" : "No"),
    },
    {
      field: "metadata",
      header: "Metadata",
      render: (row) => JSON.stringify(row.metadata),
    },
  ];

  const handleUpload = () => {
    triggerFileInput();
  };

  const handleStartCall = () => {
    runInquiriesApi(smartDb);
  };

  const trackUploadStatus = async () => {
    try {
      if (uploadStatus?.status === "completed") {
        const csvFiles = uploadStatus.signedUrls.map((url) =>
          url
            ?.split("?")[0]
            ?.replace("https://storage.googleapis.com/", "gs://")
        );
        await importSmartDbApi({ csvFiles, smartDb });
        completeFileUpload();
        showSkillrToast("Files uploaded successfully", "success");
        getSmartRecords();
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ??
        error?.response?.data?.message ??
        "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    console.log("uploadStatus", uploadStatus);
    if (
      uploadStatus?.files?.length > 0 &&
      uploadStatus?.status === "filesValidated"
    ) {
      uploadFiles(uploadStatus.files);
    }
  }, [uploadStatus?.files, uploadStatus?.status]);

  useEffect(() => {
    trackUploadStatus();
  }, [uploadStatus]);

  const handleDownloadCsv = async () => {
    try {
      const response = await getSmartDbRecordCsvApi(smartDb);
      showSkillrToast(response?.message, "success");
    } catch (error) {
      console.error("Error downloading CSV:", error);
      showSkillrToast("Error downloading CSV", "error");
    }
  };

  const getSmartRecords = async () => {
    const queryParams = new URLSearchParams();
    queryParams.set("page", currentPage);
    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        queryParams.set(key, value.join(","));
      } else if (value !== "" && value !== null && value !== undefined) {
        queryParams.set(key, value.toString());
      }
      if (key === "dateFilter" && value === "custom") {
        queryParams.set("dateFilter", "custom");
      }
      if (key === "dateFilter" && value === "last7days") {
        queryParams.set("dateFilter", "7 days");
      }
      if (key === "dateFilter" && value === "today") {
        queryParams.set("dateFilter", "today");
      }
      if (key === "dateFilter" && value === "thisMonth") {
        queryParams.set("dateFilter", "this month");
      }
    });
    const response = await getSmartDbRecordApi(smartDb, queryParams.toString());
    setSmartDbRecords(response.smartDBRecords);
    setSmartDbTotalCount(response.totalCount);
    setSmartDbDetails(response.smartDbDetails);
  };

  const applyFilters = (newFilters) => {
    setAppliedFilters(newFilters);
    setCurrentPage(1);
    // Update URL with new filters
    const searchParams = new URLSearchParams();
    Object.entries(newFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        searchParams.set(key, value.join(","));
      } else if (value !== "" && value !== null && value !== undefined) {
        if (key === "dateFilter") {
          if (value === "last7days") searchParams.set(key, "7 days");
          else if (value === "today") searchParams.set(key, "today");
          else if (value === "thisMonth") searchParams.set(key, "this month");
          else if (value === "custom") {
            searchParams.set(key, "range");
            searchParams.set("startDate", newFilters.startDate);
            searchParams.set("endDate", newFilters.endDate);
          } else searchParams.set(key, value);
        } else {
          searchParams.set(key, value.toString());
        }
      }
    });
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleRemoveFilter = (filterType, filterValue) => {
    if (filterType === "startDate" || filterType === "endDate") {
      setAppliedFilters((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
        dateFilter: "",
      }));

      // Update URL without date filters
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("startDate");
      searchParams.delete("endDate");
      searchParams.delete("dateFilter");
      navigate(`?${searchParams.toString()}`, { replace: true });
      return;
    }

    const newFilters = { ...appliedFilters };
    if (Array.isArray(newFilters[filterType])) {
      newFilters[filterType] = newFilters[filterType].filter(
        (item) => item !== filterValue
      );
    } else {
      newFilters[filterType] = "";
    }
    setAppliedFilters(newFilters);
    applyFilters(newFilters);
  };

  const clearAllFilters = () => {
    const clearedFilters = {
      status: [],
      minRedialAttempts: "",
      maxRedialAttempts: "",
      minDuration: "",
      maxDuration: "",
      startDate: null,
      endDate: null,
      dateFilter: "",
    };
    setAppliedFilters(clearedFilters);
    setCurrentPage(1);
    navigate(location.pathname, { replace: true });
  };

  const isFilterApplied = () => {
    if (!appliedFilters || typeof appliedFilters !== "object") {
      return false;
    }
    return Object.entries(appliedFilters).some(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (key === "dateFilter") {
        return value !== "";
      }
      if (key === "startDate" || key === "endDate") {
        return value !== null;
      }
      return value !== "" && value !== null && value !== undefined;
    });
  };

  const parseFiltersFromURL = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {
      status: searchParams.get("status")
        ? searchParams.get("status").split(",").filter(Boolean)
        : [],
      startDate: searchParams.get("startDate")
        ? moment(searchParams.get("startDate")).toDate()
        : null,
      endDate: searchParams.get("endDate")
        ? moment(searchParams.get("endDate")).toDate()
        : null,
      dateFilter: searchParams.get("dateFilter") || "",
    };

    // Map the dateFilter values
    if (filters.dateFilter === "7 days") {
      filters.dateFilter = "last7days";
    } else if (filters.dateFilter === "today") {
      filters.dateFilter = "today";
    } else if (filters.dateFilter === "this month") {
      filters.dateFilter = "thisMonth";
    } else if (filters.dateFilter === "range") {
      filters.dateFilter = "custom";
    }

    return filters;
  }, [location.search]);

  const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.isSame(end, "day")) {
      return start.format("MMMM D, YYYY");
    } else if (start.isSame(end, "year")) {
      return `${start.format("MMM D")} - ${end.format("MMM D, YYYY")}`;
    } else {
      return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`;
    }
  };

  const handleTablePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getSmartRecords();
  }, [currentPage]);

  useEffect(() => {
    getSmartRecords();
  }, []);

  return (
    <PageWrapper className={styles.container}>
      {["started", "processing"].indexOf(uploadStatus?.status) > -1 && (
        <ProgressBar
          startIcon={
            uploadStatus?.status === "completed" ? (
              <Check width={16} height={16} />
            ) : (
              <Loader className={styles.rotate} width={16} height={16} />
            )
          }
          message={
            uploadStatus?.status === "completed"
              ? "File uploaded"
              : `${uploadStatus?.totalFiles} files are being processed`
          }
          progress={Math.ceil(
            (uploadStatus?.processedFiles / uploadStatus?.totalFiles) * 100
          )}
          cancelGeneration={false}
          handleCancelGeneration={() => {}}
        />
      )}
      <Box padding={8}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4" fontWeight={500} mb={2}>
              {smartDbDetails.name ?? "Smart DB records"}
            </Typography>
            <Typography variant="body1" color={theme.palette.grey[500]}>
              Create and manage multiple smart database to store and organise
              your contact data
            </Typography>
          </Box>
          <FlexBox ml="auto">
            <Button
              startIcon={<IconDownload color="white" />}
              variant="dark"
              sx={{ borderRadius: "12px", padding: "12px 24px" }}
              onClick={handleDownloadCsv}
            >
              Export
            </Button>
            <Button
              startIcon={<IconUpload2 color="white" />}
              variant="dark"
              sx={{ borderRadius: "12px", padding: "12px 24px" }}
              onClick={handleUpload}
            >
              Upload
            </Button>
          </FlexBox>
        </Box>
        <div>
          {smartDb === "67583493d285e9fc286f2892" && (
            <Button
              style={{ marginRight: "20px" }}
              onClick={handleStartCall}
              variant="dark"
              sx={{ borderRadius: "12px" }}
            >
              Start Call
            </Button>
          )}
        </div>

        <input
          type="file"
          multiple
          hidden
          ref={fileInputRef}
          onChange={handleFileChange}
          accept={acceptedFileTypes.join(",")}
        />
        <FlexBox mt={4} mb={2}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<IconFilter2 width={20} height={20} />}
            sx={{
              backgroundColor: "#f9f9f9",
              minWidth: 0,
              alignSelf: "flex-start",
            }}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>

          {isFilterApplied() && (
            <Button
              variant="text"
              color="primary"
              sx={{ minWidth: 0, ml: 2 }}
              onClick={clearAllFilters}
            >
              Clear All
            </Button>
          )}

          {Object.entries(appliedFilters).map(([filterType, filterValues]) => {
            if (
              filterType === "startDate" ||
              filterType === "endDate" ||
              filterType === "dateFilter"
            ) {
              return null;
            }
            if (Array.isArray(filterValues)) {
              return filterValues.map((filterValue) =>
                filterType === "status" && filterValue === "" ? (
                  <></>
                ) : (
                  <FlexBox key={filterValue} className={styles.chip}>
                    {`${
                      filterType.charAt(0).toUpperCase() + filterType.slice(1)
                    }: ${statusTextMapping[filterValue]}`}
                    <IconButton
                      size="small"
                      sx={{ padding: 1 }}
                      onClick={() =>
                        handleRemoveFilter(filterType, filterValue)
                      }
                    >
                      <X width={12} height={12} />
                    </IconButton>
                  </FlexBox>
                )
              );
            } else if (filterValues) {
              return (
                <FlexBox key={filterType} className={styles.chip}>
                  {`${
                    filterType.charAt(0).toUpperCase() + filterType.slice(1)
                  }: ${filterValues}`}

                  <IconButton
                    sx={{ padding: 1 }}
                    size="small"
                    onClick={() => handleRemoveFilter(filterType, filterValues)}
                  >
                    <X width={12} height={12} />
                  </IconButton>
                </FlexBox>
              );
            }
            return null;
          })}

          {appliedFilters.dateFilter &&
            appliedFilters.dateFilter !== "custom" && (
              <FlexBox className={styles.chip}>
                {`Date: ${
                  appliedFilters.dateFilter === "last7days"
                    ? "Last 7 Days"
                    : appliedFilters.dateFilter === "today"
                    ? "Today"
                    : appliedFilters.dateFilter === "thisMonth"
                    ? "This Month"
                    : ""
                }`}
                <IconButton
                  sx={{ padding: 1 }}
                  size="small"
                  onClick={() => handleRemoveFilter("dateFilter")}
                >
                  <X width={12} height={12} />
                </IconButton>
              </FlexBox>
            )}

          {appliedFilters.dateFilter === "custom" &&
            appliedFilters.startDate &&
            appliedFilters.endDate && (
              <FlexBox className={styles.chip}>
                {`Date: ${formatDateRange(
                  appliedFilters.startDate,
                  appliedFilters.endDate
                )}`}
                <IconButton
                  sx={{ padding: 1 }}
                  size="small"
                  onClick={() => {
                    handleRemoveFilter("startDate");
                    handleRemoveFilter("endDate");
                  }}
                >
                  <X width={12} height={12} />
                </IconButton>
              </FlexBox>
            )}

          <FilterModal
            open={filterModalOpen}
            onClose={() => setFilterModalOpen(false)}
            filters={appliedFilters}
            applyFilters={applyFilters}
            fields={contactFields}
            mainPage={true}
            contactPage={true}
          />
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className={styles.popup}
              triggeringComponent={
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<SortAscending />}
                  sx={{
                    padding: 3,
                    minWidth: 0,
                    width: "fit-content",
                  }}
                >
                  Sort
                </Button>
              }
            >
              <Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-ASC")}
                >
                  <Typography variant="body2">Date - Ascending</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-DESC")}
                >
                  <Typography variant="body2">Date - Descending</Typography>
                </Box>
              </Box>
            </FundamentoPopup>
          </div>
        </FlexBox>
        {smartDbRecords.length > 0 && (
          <Box mt={4}>
            <FundamentoTable
              columns={columns}
              data={smartDbRecords}
              passRowToRender={true}
              pagination={smartDbTotalCount > 20}
              totalPageCount={Math.ceil(smartDbTotalCount / 20)}
              currentPage={currentPage}
              onPageChange={handleTablePageChange}
              className={styles.table}
            />
          </Box>
        )}
      </Box>
    </PageWrapper>
  );
};

export default SmartDBRecords;
