import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import FlexBox from "components/FlexBox";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FundamentoTable from "components/FundamentoTable";
import { getDownloads } from "services";
import { useSkillrToast } from "context/toast";
import moment from "moment";
import { IconDownload, IconFilter2 } from "components/SVG";
import EmptyState from "components/EmptyState";
import styles from "./index.module.css";
import FilterModal from "pages/Campaign/components/FilterModal";

const Downloads = () => {
  const [data, setData] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    downloadStatus: [],
    downloadType: [],
  });

  const fields = [
    {
      label: "Status",
      value: "downloadStatus",
    },
    {
      label: "Type",
      value: "downloadType",
    },
    {
      label: "Generated On",
      value: "dateRange",
    },
  ];

  const columns = [
    // { header: "Name", field: "name" },
    {
      header: "Report ID",
      field: "reportId",
      render: (row) => {
        return row?.reportName ?? row?.reportId;
      },
    },
    {
      header: "Data Date Range",
      field: "dateRange",
      render: (row) => {
        return `${moment(row?.dataDateRange?.startDate).format(
          "DD MMM YYYY"
        )} - ${moment(row?.dataDateRange?.endDate).format("DD MMM YYYY")}`;
      },
    },
    {
      header: "Status",
      field: "status",
      render: (row) => {
        return (
          <Typography
            variant="body2"
            className={`${styles.label} ${
              styles[row.status.toLowerCase().replace(" ", "")]
            }`}
          >
            {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
          </Typography>
        );
      },
    },
    {
      header: "Type",
      field: "type",
      render: (row) => {
        if (row.type === "analytics") {
          return "Call Data";
        } else return "Campaign Data";
      },
    },
    {
      header: "Expiry",
      field: "expiry",
      render: (row) => {
        const expiryDate = moment(row.createdAt).add(8, "days");
        const daysUntilExpiry = expiryDate.diff(moment(), "days");
        const hoursUntilExpiry = expiryDate.diff(moment(), "hours");

        return (
          <Typography
            variant="body2"
            color={
              hoursUntilExpiry <= 0
                ? "error.main"
                : daysUntilExpiry <= 3
                ? "error.main"
                : "warning.main"
            }
          >
            {hoursUntilExpiry <= 0
              ? "Expired"
              : daysUntilExpiry < 1
              ? `${hoursUntilExpiry} hour${hoursUntilExpiry !== 1 ? "s" : ""}`
              : `${daysUntilExpiry} day${daysUntilExpiry !== 1 ? "s" : ""}`}
          </Typography>
        );
      },
    },
    {
      header: "Generated On",
      field: "createdAt",
      render: (row) => {
        return moment(row.createdAt).format("DD MMM, hh:mm A");
      },
    },
    {
      header: "Actions",
      field: "actions",
      render: (row) => {
        const isExpired =
          moment(row.createdAt).add(8, "days").diff(moment(), "hours") <= 0;
        return (
          <IconButton
            disabled={row.status.toLowerCase() !== "generated" || isExpired}
            onClick={() => window.open(`${row.downloadLink}`, "_blank")}
          >
            <IconDownload width={20} height={20} />
          </IconButton>
        );
      },
    },
  ];

  const applyFilters = (filters) => {
    let newFilters = { ...filters };

    if (filters.dateFilter) {
      switch (filters.dateFilter) {
        case "today":
          newFilters = {
            ...filters,
            startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          };
          break;
        case "thisMonth":
          newFilters = {
            ...filters,
            startDate: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          };
          break;
        case "last7days":
          newFilters = {
            ...filters,
            startDate: moment()
              .subtract(7, "days")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          };
          break;
        case "custom":
          newFilters = {
            ...filters,
            startDate: filters.startDate,
            endDate: filters.endDate,
          };
          break;
        default:
          break;
      }
    }

    setAppliedFilters(newFilters);
  };

  const getAllDownloads = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("page", currentPage);
      queryParams.set("limit", "10");
      if (appliedFilters.downloadStatus) {
        queryParams.set("status", appliedFilters.downloadStatus);
      }
      if (appliedFilters.downloadType) {
        queryParams.set("type", appliedFilters.downloadType);
      }

      if (appliedFilters.startDate && appliedFilters.endDate) {
        queryParams.set("startDate", appliedFilters.startDate);
        queryParams.set("endDate", appliedFilters.endDate);
      }

      const response = await getDownloads(queryParams);
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response.data.message || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const isFilterApplied = () => {
    return (
      appliedFilters.downloadStatus?.length > 0 ||
      appliedFilters.downloadType?.length > 0 ||
      appliedFilters.startDate ||
      appliedFilters.endDate
    );
  };

  useEffect(() => {
    getAllDownloads();
  }, [currentPage, appliedFilters]);

  return (
    <PageWrapper noPadding>
      <Header
        title="Downloads"
        subTitle="View, download, and manage your exported reports, which will be available for 7 days."
        breadcrumbs={[
          { label: "Analytics", link: "/calls" },
          { label: "Downloads", link: "/downloads" },
        ]}
      />
      <Box px={8}>
        <FlexBox mb={4} sx={{ flexWrap: "wrap" }}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<IconFilter2 width={20} height={20} />}
            sx={{ backgroundColor: "#f9f9f9", minWidth: 0 }}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>

          {isFilterApplied() ? (
            <Button
              variant="text"
              color="primary"
              sx={{ minWidth: 0 }}
              onClick={() =>
                setAppliedFilters({ downloadStatus: [], downloadType: [] })
              }
            >
              Clear All
            </Button>
          ) : null}

          {appliedFilters?.downloadStatus?.length > 0 && (
            <Chip
              label={`Status: ${appliedFilters.downloadStatus
                ?.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                ?.join(", ")}`}
              onDelete={() =>
                setAppliedFilters({
                  ...appliedFilters,
                  downloadStatus: [],
                })
              }
              className={styles.label}
            />
          )}

          {appliedFilters?.downloadType?.length > 0 && (
            <Chip
              label={`Type: ${appliedFilters.downloadType
                ?.map((item) =>
                  item === "analytics" ? "Call Data" : "Campaign Data"
                )
                ?.join(", ")}`}
              onDelete={() =>
                setAppliedFilters({
                  ...appliedFilters,
                  downloadType: [],
                })
              }
              className={styles.label}
            />
          )}

          {appliedFilters.startDate && appliedFilters.endDate && (
            <Chip
              label={`Date: ${moment(appliedFilters.startDate).format(
                "DD-MM-YYYY"
              )} - ${moment(appliedFilters.endDate).format("DD-MM-YYYY")}`}
              onDelete={() =>
                setAppliedFilters({
                  ...appliedFilters,
                  startDate: null,
                  endDate: null,
                  dateFilter: null,
                })
              }
              className={styles.label}
            />
          )}
        </FlexBox>

        {data?.downloadData?.length > 0 ? (
          <FundamentoTable
            columns={columns}
            data={data?.downloadData || []}
            passRowToRender={true}
            rowIdKey="_id"
            pagination={true}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            totalPageCount={data?.totalPages}
          />
        ) : (
          <FlexBox
            sx={{
              height: "100%",
              justifyContent: "center",
            }}
          >
            <EmptyState
              title="No downloads found"
              description="You have not generated any downloads yet."
            />
          </FlexBox>
        )}

        <FilterModal
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          fields={fields}
          filters={appliedFilters}
          applyFilters={(filters) => applyFilters(filters)}
        />
      </Box>
    </PageWrapper>
  );
};

export default Downloads;
