import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import classes from "./index.module.css";
import {
  Archive,
  Copy,
  FlowArrow,
  Path,
  Pencil,
  PencilSimple,
  SparkleCircle,
} from "components/newSVG";
import { Edit3, MoreVertical, Plus, PlusCircle, Trash2 } from "react-feather";
import { useURLState } from "custom-hooks/useUrlState";
import { useEffect, useState } from "react";
import { IconCirclePlus } from "components/SVG";
import CreateWorkFlowModal from "./components/CreateWorkFlowModal";
import {
  createWorkFlow as createWorkFlowApi,
  getAllWorkFlow as getAllWorkFlowApi,
  editWorkFlow as editWorkFlowApi,
  duplicateWorkFlow,
} from "services";
import { useSkillrToast } from "context/toast";
import FundamentoTable from "components/FundamentoTable";
import moment from "moment";
import DeleteModal from "components/DeleteModal";
import { useNavigate } from "react-router-dom";
import WorkFlowLinked from "./components/WorkFlowLinked";
import Header from "components/Header";
import Loader from "components/Loader";
import EmptyState from "components/EmptyState";
import FlexBox from "components/FlexBox";
import FundamentoPopup from "components/FundamentoPopup";

const sortList = [
  {
    name: "Date created",
    value: "dateCreated",
  },
  {
    name: "Last modified",
    value: "lastModified",
  },
  {
    name: "A to Z",
    value: "atoz",
  },
  {
    name: "Z to A",
    value: "ztoa",
  },
];

const WorkFlow = () => {
  const { showSkillrToast } = useSkillrToast();
  const [sortby, setSortBy] = useURLState("dateCreated", "sort");
  const [workflows, setWorkFlows] = useState();
  const [showWorkFlowModal, setShowWorkFlowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [workFlowLinkedModal, setWorkFlowLinkedModal] = useState(false);
  // const tabs = ["Active Workflows", "Archived Workflows"];
  const tabs = ["Active Workflows"];
  const [isArchived, setIsArchived] = useURLState("false", "isArchived");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useURLState("", "search");
  const [page, setCurrentPage] = useURLState(1, "page");
  const recordsPerPage = 10;
  const navigate = useNavigate();
  const handleOnCloseWorkFlowModal = () => {
    setShowWorkFlowModal(false);
    setSelectedRow();
  };

  const columns = [
    {
      field: "name",
      header: "Workflow",
      style: { width: "23%" },
      render: (rowInfo) => (
        <FlexBox columnGap={2}>
          {rowInfo?.isDefault ? (
            <Path width={20} height={20} />
          ) : (
            <FlowArrow width={20} height={20} />
          )}
          <Typography variant="subtitle2" fontWeight={500}>
            {rowInfo?.name}
          </Typography>
        </FlexBox>
      ),
    },
    {
      field: "summary",
      header: "Summary",
      style: { width: "32%" },
      render: (rowInfo) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            maxWidth: "40ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowInfo?.summary ? rowInfo?.summary : "---"}
        </Typography>
      ),
    },
    {
      field: "type",
      header: "Type",
      style: { width: "9%" },
      render: (rowInfo) => (
        <Chip
          className={`${classes.label} ${
            classes[rowInfo?.isDefault ? "system" : "custom"]
          }`}
          label={rowInfo?.isDefault ? "System" : "Custom"}
        />
      ),
    },
    {
      field: "intentsLinked",
      header: "Intents Linked",
      style: { width: "12%" },
      render: (rowInfo) => (
        <Typography variant="subtitle2">{rowInfo?.questionsCount}</Typography>
      ),
    },
    {
      field: "lastUpdatedAt",
      header: "Last Updated",
      style: { width: "14%" },
      render: (rowInfo) => {
        return (
          <Typography variant="caption">
            {moment(rowInfo?.updatedAt).format("D MMM YYYY, hh:mm A")}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      header: "Actions",
      style: { width: "8%" },
      render: (rowInfo) => (
        <Box display="flex" ml="auto" gap={2}>
          {/* {!rowInfo?.isDefault && (
            <IconButton onClick={(e) => handleEditWorkFlow(e, rowInfo)}>
              <PencilSimple color="#0f0f0f" width={16} height={16} />
            </IconButton>
          )}
          {!rowInfo?.isDefault && (
            <IconButton onClick={(e) => handleDeleteWorkFlow(e, rowInfo)}>
              <Trash2 width={16} height={16} color="#eb5757" />
            </IconButton>
          )} */}
          {!rowInfo?.isDefault && (
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              triggeringComponent={
                <IconButton>
                  <MoreVertical width={16} height={16} />
                </IconButton>
              }
              className={classes.popup}
            >
              {isArchived === "false" ? (
                <Box>
                  <FlexBox
                    className={classes.popupItem}
                    onClick={(e) => handleEditWorkFlow(e, rowInfo)}
                  >
                    <PencilSimple width={24} height={24} color="#0f0f0f" />
                    <Typography variant="body2">Rename</Typography>
                  </FlexBox>
                  <FlexBox
                    className={classes.popupItem}
                    onClick={(e) => handleDuplicateWorkFlow(e, rowInfo)}
                  >
                    <Copy width={24} height={24} />
                    <Typography variant="body2">Create Duplicate</Typography>
                  </FlexBox>
                  <FlexBox
                    className={classes.popupItem}
                    onClick={(e) => handleDeleteWorkFlow(e, rowInfo)}
                  >
                    <Trash2 width={24} height={24} strokeWidth={1.5} />
                    <Typography variant="body2">Delete</Typography>
                  </FlexBox>
                </Box>
              ) : (
                <Box>
                  <FlexBox
                    className={classes.popupItem}
                    onClick={(e) => handleUnarchiveWorkFlow(e, rowInfo)}
                  >
                    <Archive width={24} height={24} />
                    <Typography variant="body2">Unarchive Workflow</Typography>
                  </FlexBox>
                </Box>
              )}
            </FundamentoPopup>
          )}
        </Box>
      ),
    },
  ];

  const createWorkFlow = () => {
    setShowWorkFlowModal(true);
  };

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlowApi(data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getTableData = (workflows) => {
    setWorkFlows(workflows);
  };

  const getAllWorkFlow = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.set("isActive", isArchived === "false" ? "true" : "false");
      params.set("search", search);
      params.set("limit", recordsPerPage);
      params.set("page", page);
      const response = await getAllWorkFlowApi(params.toString());
      getTableData(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateWorkFlow = async (workFlowId, updatedWorkFlow) => {
    try {
      const response = await editWorkFlowApi(workFlowId, updatedWorkFlow);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleDeleteWorkFlow = async (event, rowInfo) => {
    event.stopPropagation();
    if (rowInfo?.questionsCount > 0) {
      setWorkFlowLinkedModal(true);
    } else {
      setSelectedRow(rowInfo);
      setDeleteModal(true);
    }
  };

  const handleOnCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRow();
  };

  const saveWorkFlow = async (data) => {
    setLoading(true);
    if (selectedRow) {
      await updateWorkFlow(selectedRow?._id, { name: data?.name });
    } else {
      await createNewWorkFlow(data);
    }
    getAllWorkFlow();
    setLoading(false);
    setShowWorkFlowModal(false);
    setSelectedRow();
  };

  const handleEditWorkFlow = (event, rowInfo) => {
    event.stopPropagation();
    setShowWorkFlowModal(true);
    setSelectedRow(rowInfo);
  };

  const handleArchiveWorkFlow = async (event, rowInfo) => {
    event.stopPropagation();

    // archiveWorkFlow(rowInfo);
  };

  const handleUnarchiveWorkFlow = async (event, rowInfo) => {
    event.stopPropagation();
    try {
      const response = await updateWorkFlow(rowInfo?._id, { isActive: true });
      if (response) {
        showSkillrToast("Workflow unarchived successfully", "success");
        setCurrentPage(1);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleDuplicateWorkFlow = async (event, rowInfo) => {
    event.stopPropagation();
    try {
      const response = await duplicateWorkFlow(rowInfo?._id);
      if (response) {
        showSkillrToast("Workflow duplicated successfully", "success");
        if (page > 1) {
          setCurrentPage(1);
        } else {
          getAllWorkFlow();
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteWorkFlow = async (rowInfo) => {
    try {
      const response = await updateWorkFlow(selectedRow?._id, {
        isActive: false,
      });
      if (response) {
        showSkillrToast("Workflow archived successfully", "success");
        if (page > 1) {
          setCurrentPage(1);
        } else {
          getAllWorkFlow();
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id) {
      navigate(`/workflows/${row.id}`);
    }
  };

  const handleOnHeaderSearch = (searchedValue) => {
    setSearch(searchedValue);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getAllWorkFlow();
  }, [search, isArchived, page]);

  return (
    <PageWrapper className={classes.container}>
      <Header
        title="Workflows"
        subTitle="Create and manage your AI-powered workflows"
        // tabs={tabs}
        // defaultActiveTab={
        //   isArchived === "false" ? "Active Workflows" : "Archived Workflows"
        // }
        // onTabChange={() => {
        //   setIsArchived(isArchived === "false" ? "true" : "false");
        //   setCurrentPage(1);
        //   setLoading(true);
        // }}
        searchBox={true}
        onSearch={handleOnHeaderSearch}
        searchDisabled={loading}
        defaultSearchValue={search}
        onClearSearch={() => {
          setSearch("");
          setCurrentPage(1);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box p={"0 32px 24px"} height="100%">
          <Box height="100%">
            {workflows?.workFlows?.length > 0 && (
              <>
                <Box display="flex" alignItems="center" gap={2}>
                  {/* <FundamentoPopup
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    startIcon={<SortAscending width={16} height={16} />}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Sort by: {sortList.find((s) => s.value === sortby).name}
                  </Button>
                }
                className={classes.popup}
              >
                <Box>
                  {sortList.map((sort) => (
                    <Box className={classes.popupItem} key={sort.value}>
                      <Checkbox
                        icon={<Circle />}
                        checkedIcon={<RadioButton />}
                        value={sort.value}
                        onChange={() => {}}
                        checked={sortby === sort.value}
                        id={sort.value}
                      />
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor={sort.value}
                      >
                        {sort.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </FundamentoPopup>

              <Box className={classes.searchContainer}>
                <SearchBox onChange={() => {}} placeholder="Search by name" />
              </Box> */}
                  {isArchived === "false" && (
                    <Button
                      variant="dark"
                      className={classes.darkButton}
                      onClick={createWorkFlow}
                      startIcon={<Plus width={16} height={16} />}
                    >
                      Create Workflow
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    mt: 4,
                    "& tr": {
                      transitionDuration: "0.3s",
                    },
                    "& tr:hover": {
                      cursor: "pointer",
                      backgroundColor: "#f6f6f6",
                    },
                  }}
                >
                  <FundamentoTable
                    columns={columns}
                    data={workflows?.workFlows || []}
                    passRowToRender={true}
                    pagination={true}
                    totalPageCount={Math.ceil(
                      workflows?.totalCount / recordsPerPage
                    )}
                    currentPage={page}
                    onPageChange={handlePageChange}
                    tableOnClick={handleTableOnClick}
                    rowIdKey="_id"
                  />
                </Box>
              </>
            )}

            {workflows?.workFlows?.length === 0 &&
              !search &&
              isArchived === "false" && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt={13}
                >
                  <SparkleCircle />
                  <Typography
                    variant="h4"
                    fontSize={26}
                    mt={8}
                    fontWeight={500}
                  >
                    Create your first workflow
                  </Typography>
                  <Typography variant="body2" mt={2}>
                    Create your AI-power workflow
                  </Typography>

                  <Button
                    startIcon={<IconCirclePlus />}
                    className={classes.newWorkFlowButton}
                    variant="outline"
                    onClick={createWorkFlow}
                  >
                    New workflow
                  </Button>
                </Box>
              )}

            {((workflows?.workFlows?.length === 0 && search) ||
              (workflows?.workFlows?.length === 0 &&
                isArchived === "true")) && (
              <EmptyState title={"No workflow found"} />
            )}
          </Box>
          <CreateWorkFlowModal
            open={showWorkFlowModal}
            onClose={handleOnCloseWorkFlowModal}
            edit={selectedRow ? true : false}
            data={selectedRow}
            handleSave={saveWorkFlow}
            loading={loading}
          />

          <DeleteModal
            open={deleteModal}
            onClose={handleOnCloseDeleteModal}
            onConfirm={() => deleteWorkFlow()}
            title={"Delete Workflow?"}
            subtitle={
              "This action can't be undone and the data will be deleted from your account permanently"
            }
            list={["This will delete the workflow permanently"]}
          />

          <WorkFlowLinked
            title="This workflow is linked to another resource. Please manage that resource before deleting this workflow."
            open={workFlowLinkedModal}
            onClose={() => setWorkFlowLinkedModal(false)}
          />
        </Box>
      )}
    </PageWrapper>
  );
};

export default WorkFlow;
