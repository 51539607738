import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  Grow,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  Slider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Popover,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material";
import styles from "./index.style";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { DateCalendar } from "@mui/x-date-pickers";
import { Circle, X } from "react-feather";
import { RadioButton } from "components/newSVG";
import moment from "moment";
import { CalendarToday } from "@mui/icons-material";
import FundamentoSlider from "components/FundamentoSlider";
import SearchBox from "components/SearchBox";
import { IconSearch } from "components/SVG";
import { getAuditStatuses } from "services";

const DateSelector = ({ label, value, onChange, minDate, maxDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date) => {
    onChange(date);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box mb={2}>
      <Typography variant="caption" mb={1} color="text.primary">
        {label}
      </Typography>
      <TextField
        fullWidth
        value={value ? moment(value).format("DD/MM/YYYY") : ""}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        placeholder="dd/mm/yyyy"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ zIndex: 9999 }}
      >
        <DateCalendar
          value={value || null}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Popover>
    </Box>
  );
};

const FilterModal = ({
  open,
  onClose,
  filters,
  parentCategories,
  childCategories,
  maxDurationOfCalls,
  applyFilters,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tempFilters, setTempFilters] = useState(filters);
  const [categorySearch, setCategorySearch] = useState("");
  const [subCategorySearch, setSubCategorySearch] = useState("");
  const leftRef = useRef(null);
  const [auditStatuses, setAuditStatuses] = useState([]);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFilterChange = (filterName, value) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const getAllAuditStatuses = async () => {
    const response = await getAuditStatuses();
    if (response) {
      // Flatten all sub-statuses from all parent statuses into a single array
      const allSubStatuses = response.reduce((acc, parentStatus) => {
        const subStatuses = parentStatus.subStatuses.map((subStatus) => ({
          value: subStatus._id,
          name: subStatus.name,
          parentStatus: parentStatus.name, // Including parent status name for context
        }));
        return [...acc, ...subStatuses];
      }, []);

      setAuditStatuses(allSubStatuses);
    }
  };

  const handleApply = () => {
    const appliedFilters = {
      ...tempFilters,
      parentCategoryFilter: (tempFilters.parentCategoryFilter || [])
        .map((cat) => cat?.name)
        .filter(Boolean),
      childCategoryFilter: (tempFilters.childCategoryFilter || [])
        .map((cat) => cat?.name)
        .filter(Boolean),
    };

    if (appliedFilters.startDate) {
      appliedFilters.startDate = moment(appliedFilters.startDate)
        .startOf("day")
        .toISOString();
    }
    if (appliedFilters.endDate) {
      appliedFilters.endDate = moment(appliedFilters.endDate)
        .endOf("day")
        .toISOString();
    }

    applyFilters(appliedFilters);
    onClose();
  };

  useEffect(() => {
    if (open) {
      getAllAuditStatuses();
      const updatedFilters = {
        ...filters,
        parentCategoryFilter: (filters.parentCategoryFilter || [])
          .map(
            (name) =>
              parentCategories.find((cat) => cat.name === name) || { name }
          )
          .filter(Boolean),
        childCategoryFilter: (filters.childCategoryFilter || [])
          .map(
            (name) =>
              childCategories.find((cat) => cat.name === name) || { name }
          )
          .filter(Boolean),
      };

      // check if startDate and endDate are not moment objects, if so, make them moment objects
      if (
        updatedFilters.startDate &&
        !moment.isMoment(updatedFilters.startDate)
      ) {
        updatedFilters.startDate = moment(updatedFilters.startDate);
      }
      if (updatedFilters.endDate && !moment.isMoment(updatedFilters.endDate)) {
        updatedFilters.endDate = moment(updatedFilters.endDate);
      }

      setTempFilters(updatedFilters);
    }
  }, [open, filters, parentCategories, childCategories]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <Box
            pt={8}
            sx={{ textAlign: "center", width: "90%" }}
            display={"flex"}
            mr={2}
          >
            {/* <Slider
              value={[
                tempFilters.minDurationFilter || 0,
                tempFilters.maxDurationFilter || maxDurationOfCalls,
              ]}
              onChange={(event, value) => {
                handleFilterChange("minDurationFilter", value[0]);
                handleFilterChange("maxDurationFilter", value[1]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={maxDurationOfCalls}
              marks={[{ value: 0 }, { value: maxDurationOfCalls }]}
              sx={{
                width: "80%",
                "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                "& .MuiSlider-track": { backgroundColor: "#0F0F0F", border: 0 },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#0F0F0F",
                  width: 15,
                  height: 15,
                },
              }}
            /> */}
            <TextField
              size="small"
              variant="standard"
              value={tempFilters.minDurationFilter || 0}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  handleFilterChange(
                    "minDurationFilter",
                    parseInt(e.target.value)
                  );
                }
              }}
              sx={{
                backgroundColor: theme.palette.grey[200],
                borderRadius: "4px",
                outline: 0,
                overflow: "hidden",
              }}
              inputProps={{
                style: {
                  fontSize: "14px",
                  width: 60,
                  height: 30,
                  padding: 4,
                  textAlign: "center",
                },
              }}
            />
            <Slider
              min={0}
              max={maxDurationOfCalls}
              valueLabelDisplay="auto"
              step={1}
              sx={{
                "& .MuiSlider-rail": {
                  backgroundColor: theme.palette.grey[200],
                },
                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.text.primary,
                  border: 0,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.text.primary,
                  width: 12,
                  height: 12,
                },
                marginTop: 2,
                marginBottom: 0,
                mx: 3,
              }}
              marks={[
                {
                  value: 0,
                  label: (
                    <Typography
                      variant="body2"
                      fontFamily={"monospace"}
                      fontWeight={500}
                    >
                      {0}
                    </Typography>
                  ),
                },
                {
                  value: maxDurationOfCalls,
                  label: (
                    <Typography
                      variant="body2"
                      fontFamily={"monospace"}
                      fontWeight={500}
                    >
                      {maxDurationOfCalls}
                    </Typography>
                  ),
                },
              ]}
              value={[
                tempFilters.minDurationFilter || 0,
                tempFilters.maxDurationFilter || maxDurationOfCalls,
              ]}
              onChange={(e, value) => {
                handleFilterChange("minDurationFilter", value[0]);
                handleFilterChange("maxDurationFilter", value[1]);
              }}
            />
            <TextField
              size="small"
              variant="standard"
              value={tempFilters.maxDurationFilter || maxDurationOfCalls}
              onChange={(e) => {
                if (e.target.value <= maxDurationOfCalls) {
                  handleFilterChange(
                    "maxDurationFilter",
                    parseInt(e.target.value)
                  );
                }
              }}
              sx={{
                backgroundColor: theme.palette.grey[200],
                borderRadius: "4px",
                outline: 0,
                overflow: "hidden",
              }}
              inputProps={{
                style: {
                  fontSize: "14px",
                  width: 60,
                  height: 30,
                  padding: 4,
                  textAlign: "center",
                },
              }}
            />
          </Box>
        );
      case 1:
        return (
          <>
            <FlexBox
              component="label"
              columnGap={1}
              sx={{ cursor: "pointer", mb: 1 }}
            >
              <Checkbox
                checked={
                  auditStatuses?.length > 0 &&
                  tempFilters.statusFilter?.length === auditStatuses?.length
                }
                indeterminate={
                  tempFilters.statusFilter?.length > 0 &&
                  tempFilters.statusFilter?.length < auditStatuses?.length
                }
                onChange={() => {
                  const allStatusIds = auditStatuses?.map(
                    (status) => status.value
                  );
                  handleFilterChange(
                    "statusFilter",
                    tempFilters.statusFilter?.length === auditStatuses?.length
                      ? []
                      : allStatusIds
                  );
                }}
              />
              <Typography
                variant="caption"
                color="text.primary"
                fontWeight={500}
              >
                Select All
              </Typography>
            </FlexBox>
            {auditStatuses.map((option) => (
              <FlexBox
                component="label"
                key={option.value}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={(tempFilters.statusFilter || []).includes(
                    option.value
                  )}
                  onChange={() => {
                    const currentStatuses = tempFilters.statusFilter || [];
                    const updatedStatuses = currentStatuses.includes(
                      option.value
                    )
                      ? currentStatuses.filter((id) => id !== option.value)
                      : [...currentStatuses, option.value];
                    handleFilterChange("statusFilter", updatedStatuses);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.name}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 2:
        return (
          <Box sx={{ position: "relative" }}>
            <Box
              className={styles.searchContainer}
              sx={{
                // position: "sticky",
                // top: "-8px",
                zIndex: 1000,
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Box>
                <TextField
                  fullWidth
                  placeholder="Search"
                  value={subCategorySearch}
                  sx={styles.searchBox}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch
                          width={20}
                          height={20}
                          style={{ flexShrink: 0 }}
                          color="#0f0f0f4d"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: "" && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSubCategorySearch("")}
                          size="small"
                        >
                          <X
                            width={20}
                            height={20}
                            style={{ flexShrink: 0 }}
                            color="#0f0f0f4d"
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // onChange={handleOnSearchChange}
                  onChange={(e) => {
                    setSubCategorySearch(e.target.value);
                  }}
                />
              </Box>
            </Box>
            {childCategories
              ?.filter((item) => {
                if (subCategorySearch) {
                  return item.name
                    ?.toLowerCase()
                    ?.includes(subCategorySearch?.toLocaleLowerCase());
                } else {
                  return item;
                }
              })
              .map((option) => (
                <FlexBox
                  component="label"
                  key={option.id}
                  columnGap={1}
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={(tempFilters.childCategoryFilter || []).some(
                      (cat) => cat?.name === option.name
                    )}
                    onChange={() => {
                      const updatedSubCategories = (
                        tempFilters.childCategoryFilter || []
                      ).some((cat) => cat?.name === option.name)
                        ? (tempFilters.childCategoryFilter || []).filter(
                            (cat) => cat?.name !== option.name
                          )
                        : [...(tempFilters.childCategoryFilter || []), option];
                      handleFilterChange(
                        "childCategoryFilter",
                        updatedSubCategories
                      );
                    }}
                  />
                  <Typography variant="caption" color="text.primary">
                    {option.name}
                  </Typography>
                </FlexBox>
              ))}
          </Box>
        );
      case 3:
        return (
          <>
            <Box
              className={styles.searchContainer}
              sx={{
                position: "sticky",
                top: "-8px",
                zIndex: 1000,
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Box>
                <TextField
                  fullWidth
                  placeholder="Search"
                  value={categorySearch}
                  sx={styles.searchBox}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch
                          width={20}
                          height={20}
                          style={{ flexShrink: 0 }}
                          color="#0f0f0f4d"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: "" && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setCategorySearch("")}
                          size="small"
                        >
                          <X
                            width={20}
                            height={20}
                            style={{ flexShrink: 0 }}
                            color="#0f0f0f4d"
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // onChange={handleOnSearchChange}
                  onChange={(e) => {
                    setCategorySearch(e.target.value);
                  }}
                />
              </Box>
            </Box>
            {parentCategories
              ?.filter((item) => {
                if (categorySearch) {
                  return item.name
                    ?.toLowerCase()
                    ?.includes(categorySearch?.toLocaleLowerCase());
                } else {
                  return item;
                }
              })
              .map((option) => (
                <FlexBox
                  component="label"
                  key={option.id}
                  columnGap={1}
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={(tempFilters.parentCategoryFilter || []).some(
                      (cat) => cat?.name === option.name
                    )}
                    onChange={() => {
                      const updatedCategories = (
                        tempFilters.parentCategoryFilter || []
                      ).some((cat) => cat?.name === option.name)
                        ? (tempFilters.parentCategoryFilter || []).filter(
                            (cat) => cat?.name !== option.name
                          )
                        : [...(tempFilters.parentCategoryFilter || []), option];
                      handleFilterChange(
                        "parentCategoryFilter",
                        updatedCategories
                      );
                    }}
                  />
                  <Typography variant="caption" color="text.primary">
                    {option.name}
                  </Typography>
                </FlexBox>
              ))}
          </>
        );
      case 4:
        return (
          <>
            {["satisfied", "dissatisfied", "unrated"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters.feedback2Filter.includes(option)}
                  onChange={() => {
                    const updatedFeedback2 =
                      tempFilters.feedback2Filter.includes(option)
                        ? tempFilters.feedback2Filter.filter(
                            (item) => item !== option
                          )
                        : [...tempFilters.feedback2Filter, option];
                    handleFilterChange("feedback2Filter", updatedFeedback2);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 5:
        return (
          <>
            {["satisfied", "dissatisfied", "unrated"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters.feedback1Filter.includes(option)}
                  onChange={() => {
                    const updatedFeedback1 =
                      tempFilters.feedback1Filter.includes(option)
                        ? tempFilters.feedback1Filter.filter(
                            (item) => item !== option
                          )
                        : [...tempFilters.feedback1Filter, option];
                    handleFilterChange("feedback1Filter", updatedFeedback1);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 6:
        return (
          <>
            {["agentHandOff", "blank", "virtualAgent"].map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters.callTypes.includes(option)}
                  onChange={() => {
                    const updatedFeedback2 = tempFilters.callTypes.includes(
                      option
                    )
                      ? tempFilters.callTypes.filter((item) => item !== option)
                      : [...tempFilters.callTypes, option];
                    handleFilterChange("callTypes", updatedFeedback2);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {(option === "agentHandOff" && "Agent Handoff") ||
                    (option === "blank" && "Blank") ||
                    (option === "virtualAgent" && "Virtual Agent")}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case 7:
        return (
          <Box>
            <RadioGroup
              value={tempFilters.dateFilter}
              onChange={(e) => handleFilterChange("dateFilter", e.target.value)}
            >
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="today"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Today"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="last7days"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Last 7 Days"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="thisMonth"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="This Month"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="custom"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Custom"
              />
            </RadioGroup>
            <Grow
              in={tempFilters.dateFilter === "custom"}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              <div>
                <DateSelector
                  label="Start Date"
                  value={tempFilters.startDate}
                  onChange={(date) => {
                    handleFilterChange(
                      "startDate",
                      moment(date).startOf("day")
                    );
                  }}
                  maxDate={tempFilters.endDate}
                />
                <DateSelector
                  label="End Date"
                  value={tempFilters.endDate}
                  onChange={(date) => {
                    handleFilterChange("endDate", moment(date).endOf("day"));
                  }}
                  minDate={tempFilters.startDate}
                />
              </div>
            </Grow>
          </Box>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        sx: styles.dialogPaper,
      }}
    >
      <Box
        sx={{
          p: "16px 24px",
          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Filter
        </Typography>
      </Box>

      <FlexBox alignItems="stretch" columnGap={0}>
        <Box
          ref={leftRef}
          width={"50%"}
          sx={{
            borderRight: `1px solid ${theme.palette.borderColor.light}`,
            height: "fit-content",
          }}
        >
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab label="Duration" sx={styles.tab} />
            <Tab label="Audit Status" sx={styles.tab} />
            <Tab label="Sub Category" sx={styles.tab} />
            <Tab label="Category" sx={styles.tab} />
            <Tab label="Feedback 1" sx={styles.tab} />
            <Tab label="Feedback 2" sx={styles.tab} />
            <Tab label="Call Type" sx={styles.tab} />
            <Tab label="Date Range" sx={styles.tab} />
          </Tabs>
        </Box>
        <Box
          width={"50%"}
          p={2}
          sx={{
            ...styles.rightContainer,
            maxHeight: `${leftRef.current?.clientHeight}px`,
          }}
        >
          {renderTabContent()}
        </Box>
      </FlexBox>

      <FlexBox
        sx={{
          p: "16px 24px",
          borderTop: `1px solid ${theme.palette.borderColor.light}`,
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="dark" sx={styles.button} onClick={handleApply}>
          Apply
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default FilterModal;
