import theme from "theme";

const styles = {
  dialogPaper: {
    width: 576,
    borderRadius: "20px",
    overflowY: "auto",
    position: "relative",
    maxHeight: "60vh",
  },
  button: {
    borderRadius: 4,
    padding: "12px 24px",
  },
  tab: {
    textTransform: "none",
    alignItems: "flex-start",
    padding: "16px 24px",
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "DM Sans",
    color: "#0f0f0f",
    borderBottom: `1px solid ${theme.palette.borderColor.light}`,
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    maxHeight: "calc(60vh - 135px)",
  },
  active: {
    backgroundColor: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
      outline: 0,
      // border: 0,
    },
    color: theme.palette.common.white,
  },
  amButton: {
    minWidth: 0,
    width: 50,
    borderRadius: "4px 4px 0px 0px",
    border: `1px solid ${theme.palette.borderColor.light}`,
  },
  pmButton: {
    minWidth: 0,
    width: 50,
    borderRadius: "0px 0px 4px 4px",
    border: `1px solid ${theme.palette.borderColor.light}`,
  },
};

export default styles;
