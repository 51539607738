import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import FlexBox from "components/FlexBox";
import { Box, Button, Chip, Typography } from "@mui/material";
import { IconFilter2 } from "components/SVG";
import { useEffect, useState } from "react";
import FilterModal from "pages/Campaign/components/FilterModal";
import theme from "theme";
import FundamentoTable from "components/FundamentoTable";
import { getErrorLogs, getSingleErrorLog } from "services";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { APICloud, FlowArrow } from "components/newSVG";
import ErrorLogDetailModal from "./components/ErrorLogDetailModal";
import { useSkillrToast } from "context/toast";
import EmptyState from "components/EmptyState";
import moment from "moment";

const ErrorLogs = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [errorLogs, setErrorLogs] = useState([]);
  const [errorLogDetailModalOpen, setErrorLogDetailModalOpen] = useState(false);
  const [selectedErrorLog, setSelectedErrorLog] = useState(null);
  const { showSkillrToast } = useSkillrToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState({
    errorStatus: [],
    errorType: [],
    errorLocation: [],
    latency: [],
  });

  const columns = [
    {
      header: "Name",
      field: "workflowName",
      render: (row) => {
        if (row.url) {
          const slugs = row.url.split("/");
          if (slugs.length > 1) {
            return `.../${slugs[slugs.length - 1]}`;
          } else {
            return slugs;
          }
        }
        return row.workflowName;
      },
    },
    {
      header: "Call Id",
      field: "callId",
      render: (row) => {
        if (row.callId && row.voiceCallId) {
          return (
            <Link to={`/calls/${row.callId}?voiceCallId=${row.voiceCallId}`}>
              {row.callId}
            </Link>
          );
        }
        return row.callId;
      },
    },
    {
      header: "Location",
      field: "location",
      render: (row) => {
        return (
          <FlexBox
            className={styles.label}
            sx={{
              backgroundColor: "#E3F2FF",
              color: theme.palette.primary.main,
            }}
            onClick={() => {
              window.open(
                row.location === "integration"
                  ? `/integrations/webhook`
                  : `/workflows/${row?.workflow}`
              );
            }}
          >
            {row.location === "workflow" ? (
              <FlowArrow width={14} height={14} />
            ) : (
              <APICloud
                width={14}
                height={14}
                color={theme.palette.primary.main}
              />
            )}
            {row.location.charAt(0).toUpperCase() + row.location.slice(1)}:
            {row.node}
          </FlexBox>
        );
      },
    },
    { header: "Latency (ms)", field: "latency" },
    {
      header: "Status",
      field: "status",
      render: (row) => {
        return (
          <FlexBox
            className={styles.label}
            sx={{
              backgroundColor: "#FEEBEF",
              color: "#A01E1E",
            }}
          >
            <div className={styles.statusCircle} />
            {row.status === "error" ? "ERR" : row.status}
          </FlexBox>
        );
      },
    },
    {
      header: "Date",
      field: "date",
      render: (row) => {
        return new Date(row.createdAt).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      },
    },
  ];

  const fields = [
    {
      label: "Status",
      value: "errorStatus",
    },
    {
      label: "Type",
      value: "errorType",
    },
    {
      label: "Location",
      value: "errorLocation",
    },
    {
      label: "Latency",
      value: "latency",
    },
    {
      label: "Date",
      value: "dateRange",
    },
  ];

  const getAllErrorLogs = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("page", currentPage);
      queryParams.set("limit", "10");
      if (appliedFilters?.errorStatus?.length > 0) {
        queryParams.set(
          "status",
          (appliedFilters.errorStatus ?? [])
            .map((item) => (item === "ERR" ? "error" : item))
            .join(",")
        );
      }
      if (appliedFilters?.errorType?.length > 0) {
        queryParams.set("type", appliedFilters.errorType.join(","));
      }
      if (appliedFilters?.errorLocation?.length > 0) {
        queryParams.set("location", appliedFilters.errorLocation.join(","));
      }

      if (
        appliedFilters.latency?.[0] !== undefined &&
        appliedFilters.latency?.[1] !== undefined
      ) {
        queryParams.set("latencyMin", appliedFilters.latency[0]);
        queryParams.set("latencyMax", appliedFilters.latency[1]);
      }

      if (appliedFilters.startDate && appliedFilters.endDate) {
        queryParams.set("startDate", appliedFilters.startDate);
        queryParams.set("endDate", appliedFilters.endDate);
      }

      const response = await getErrorLogs(queryParams);
      if (response) {
        setErrorLogs(response);
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response.data.message || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row) {
      setErrorLogDetailModalOpen(true);
      setSelectedErrorLog(errorLogs.data.find((log) => log._id === row.id));
    }
  };

  const applyFilters = (filters) => {
    let newFilters = { ...filters };

    if (filters.dateFilter) {
      switch (filters.dateFilter) {
        case "today":
          newFilters = {
            ...filters,
            startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          };
          break;
        case "thisMonth":
          newFilters = {
            ...filters,
            startDate: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          };
          break;
        case "last7days":
          newFilters = {
            ...filters,
            startDate: moment()
              .subtract(7, "days")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          };
          break;
        case "custom":
          newFilters = {
            ...filters,
            startDate: filters.startDate,
            endDate: filters.endDate,
          };
          break;
        default:
          break;
      }
    }

    setAppliedFilters(newFilters);
  };

  const isFilterApplied = () => {
    return (
      appliedFilters?.errorStatus?.length ||
      appliedFilters?.errorType?.length ||
      appliedFilters?.errorLocation?.length ||
      (appliedFilters?.latency?.[0] && appliedFilters?.latency?.[1]) ||
      appliedFilters?.dateFilter
    );
  };

  const clearFilters = () => {
    setAppliedFilters({
      errorStatus: [],
      errorType: [],
      errorLocation: [],
      latency: [],
    });
  };

  useEffect(() => {
    getAllErrorLogs();
  }, [currentPage, appliedFilters]);

  return (
    <PageWrapper noPadding>
      <Header
        title="Error Logs"
        subTitle="Review every error without any hassle."
        breadcrumbs={[
          { label: "Analytics", link: "/calls" },
          { label: "Error Logs", link: "/error-logs" },
        ]}
      />
      <Box px={8}>
        <FlexBox
          sx={{
            mb: 8,
            border: `1px solid ${theme.palette.borderColor.light}`,
            borderRadius: 3,
            py: 4,
          }}
        >
          <Box
            sx={{
              flex: 1,
              borderRight: `1px solid ${theme.palette.borderColor.light}`,
              textAlign: "center",
            }}
          >
            <Typography variant="body2">Total Errors</Typography>

            <Typography variant="body1" fontWeight="bold">
              {errorLogs?.totalRecords}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              borderRight: `1px solid ${theme.palette.borderColor.light}`,
              textAlign: "center",
            }}
          >
            <Typography variant="body2">Workflow Errors</Typography>

            <Typography variant="body1" fontWeight="bold">
              {errorLogs?.workflowCount}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="body2">Integration Errors</Typography>

            <Typography variant="body1" fontWeight="bold">
              {errorLogs?.integrationCount}
            </Typography>
          </Box>
        </FlexBox>

        <FlexBox mb={4} sx={{ flexWrap: "wrap" }}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<IconFilter2 width={20} height={20} />}
            sx={{ backgroundColor: "#f9f9f9", minWidth: 0 }}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>

          {isFilterApplied() ? (
            <Button
              variant="text"
              color="primary"
              sx={{ minWidth: 0 }}
              onClick={() => clearFilters()}
            >
              Clear All
            </Button>
          ) : null}

          {appliedFilters?.errorStatus?.length > 0 && (
            <Chip
              label={`Status: ${appliedFilters.errorStatus.join(", ")}`}
              onDelete={() =>
                setAppliedFilters({ ...appliedFilters, errorStatus: [] })
              }
              className={styles.label}
              sx={{ backgroundColor: theme.palette.grey[50] }}
            />
          )}

          {appliedFilters?.errorType?.length > 0 && (
            <Chip
              label={`Type: ${appliedFilters.errorType
                ?.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                ?.join(", ")}`}
              onDelete={() =>
                setAppliedFilters({ ...appliedFilters, errorType: [] })
              }
              className={styles.label}
              sx={{ backgroundColor: theme.palette.grey[50] }}
            />
          )}

          {appliedFilters?.errorLocation?.length > 0 && (
            <Chip
              label={`Location: ${appliedFilters?.errorLocation
                ?.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                ?.join(", ")}`}
              onDelete={() =>
                setAppliedFilters({ ...appliedFilters, errorLocation: [] })
              }
              className={styles.label}
              sx={{ backgroundColor: theme.palette.grey[50] }}
            />
          )}

          {appliedFilters?.latency?.[0] !== undefined &&
            appliedFilters?.latency?.[1] !== undefined && (
              <Chip
                label={`Latency: ${appliedFilters?.latency?.[0]} - ${appliedFilters?.latency?.[1]}`}
                onDelete={() =>
                  setAppliedFilters({ ...appliedFilters, latency: [] })
                }
                className={styles.label}
                sx={{ backgroundColor: theme.palette.grey[50] }}
              />
            )}

          {appliedFilters?.startDate && appliedFilters?.endDate && (
            <Chip
              label={`Date: ${moment(appliedFilters.startDate).format(
                "DD-MM-YYYY"
              )} - ${moment(appliedFilters.endDate).format("DD-MM-YYYY")}`}
              onDelete={() =>
                setAppliedFilters({
                  ...appliedFilters,
                  startDate: null,
                  endDate: null,
                  dateFilter: "",
                })
              }
              className={styles.label}
              sx={{ backgroundColor: theme.palette.grey[50] }}
            />
          )}
        </FlexBox>

        {errorLogs?.data?.length > 0 ? (
          <FundamentoTable
            columns={columns}
            data={errorLogs.data || []}
            passRowToRender={true}
            rowIdKey="_id"
            tableOnClick={handleTableOnClick}
            pagination={true}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            totalPageCount={errorLogs?.totalPages}
          />
        ) : (
          <FlexBox justifyContent="center" alignItems="center" height="100%">
            <EmptyState
              title="No errors found"
              description="There are no errors present"
            />
          </FlexBox>
        )}

        <ErrorLogDetailModal
          open={errorLogDetailModalOpen}
          onClose={() => setErrorLogDetailModalOpen(false)}
          errorLog={selectedErrorLog}
        />

        <FilterModal
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          fields={fields}
          filters={appliedFilters}
          applyFilters={(filters) => applyFilters(filters)}
        />
      </Box>
    </PageWrapper>
  );
};

export default ErrorLogs;
