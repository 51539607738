import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import FundamentoTable from "components/FundamentoTable";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import moment from "moment";
import { getBilling, getBillingPulses } from "services";
import { useEffect, useState } from "react";
import FundamentoPagination from "components/FundamentoPagination";
import { Download } from "react-feather";
import EmptyState from "components/EmptyState";
import { Info } from "components/newSVG";
import { getFormattedTime } from "utils/utils";

const Billing = () => {
  const [billingData, setBillingData] = useState([]);
  const [pulsesData, setPulsesData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const columns = [
    {
      field: "invoiceID",
      header: "Invoice ID",
      style: { width: "25%" },
      render: (row) => (
        <Typography variant="body2" color="grey.600">
          {row.invoice_id}
        </Typography>
      ),
    },
    {
      field: "billingDate",
      header: "Billing Date",
      style: { width: "25%" },
      render: (row) => (
        <Typography variant="body2" color="grey.600">
          {moment(row.createdAt).format("DD MMM YYYY")}
        </Typography>
      ),
    },
    {
      field: "totalPulses",
      style: { width: "25%" },
      header: (
        <FlexBox columnGap={1}>
          <Typography variant="caption" color="grey.600">
            Total Pulses{" "}
          </Typography>
          <Tooltip title="A pulse is a unit of call duration, either 30 seconds or 60 seconds, depending on your account settings. Calls are rounded up to the nearest pulse.">
            <Info width={16} height={16} color="#555" />{" "}
          </Tooltip>
        </FlexBox>
      ),
      render: (row) => (
        <Typography variant="body2" color="grey.600">
          {row.total_pulse} pulses
        </Typography>
      ),
    },
    {
      field: "totalBillableDuration",
      header: "Total Billable Duration",
      style: { width: "25%" },
      render: (row) => (
        <Typography variant="body2" color="grey.600">
          {(() => {
            return getFormattedTime(row.total_duration) || 0;
            // const duration = moment.duration(row.total_duration, "seconds");
            // const hours = duration.asHours();
            // const minutes = duration.minutes();
            // const seconds = duration.seconds();

            // if (hours > 0) {
            //   return `${hours}hr ${minutes}min ${seconds}sec`;
            // } else if (minutes > 0) {
            //   return `${minutes}min ${seconds}sec`;
            // } else {
            //   return `${seconds} seconds`;
            // }
          })()}
        </Typography>
      ),
    },
    // {
    //   field: "actions",
    //   header: "Actions",
    //   style: {
    //     width: "10%",
    //   },
    //   render: (row) => (
    //     <IconButton size="small">
    //       <Download width={16} height={16} />
    //     </IconButton>
    //   ),
    // },
  ];

  const getPulsesData = async () => {
    try {
      const response = await getBillingPulses();
      setPulsesData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getBillingData = async () => {
    try {
      const queryParam = new URLSearchParams();
      queryParam.set("page", currentPage);
      const response = await getBilling(queryParam.toString());
      setBillingData(response.data);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPulsesData();
  }, []);

  useEffect(() => {
    getBillingData();
  }, [currentPage]);

  return (
    <PageWrapper className={styles.container}>
      <FlexBox justifyContent="space-between">
        <Box>
          <Typography variant="h4" fontWeight="bold" mb={2}>
            Billing
          </Typography>
          <Typography variant="body1" color="grey.600">
            Here, you can view your monthly invoices, including total pulses and
            billable durations, based on your account’s pulse definition.
          </Typography>
        </Box>

        {/* <Button variant="dark" sx={{ borderRadius: "12px", minWidth: 0 }}>
          Export
        </Button> */}
      </FlexBox>

      <FlexBox mt={8} justifyContent="space-between">
        <Box>
          <Typography variant="body1" color="grey.600" fontWeight="bold" mb={2}>
            {/* get next month first date */}
            Next Billing Cycle -{" "}
            {moment().add(1, "months").startOf("month").format("DD MMM")}
          </Typography>
          <Typography variant="caption" color="grey.600">
            {/* get days remaining to the above date */}
            {moment()
              .add(1, "months")
              .startOf("month")
              .diff(moment(), "days")}{" "}
            days remaining
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="grey.600" fontWeight="bold" mb={2}>
            Pulses Used This Month
          </Typography>
          <Typography variant="caption" color="grey.600">
            {pulsesData?.total_pulse || 0} pulses
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="grey.600" fontWeight="bold" mb={2}>
            Account Status
          </Typography>
          <Chip
            label="Active"
            sx={{
              backgroundColor: "#EAFFF8",
              color: "#01784E",
              borderRadius: "50px",
              padding: "4px 24px",
            }}
          />
        </Box>
      </FlexBox>

      {billingData.length > 0 ? (
        <div style={{ height: "100%" }}>
          <FundamentoTable
            className={styles.table}
            columns={columns}
            data={billingData}
            passRowToRender={true}
          />
          <FundamentoPagination
            totalPageCount={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <EmptyState title={"No billing data found"} />
      )}
    </PageWrapper>
  );
};

export default Billing;
