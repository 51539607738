import { Box, Typography } from "@mui/material";
import Root from "./index.style";
import confetti from "assets/images/confetti.png";
import { SkillrButton, SkillrTextField } from "components";
import { useForm } from "react-hook-form";
import { createCompany } from "services";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "context/user";

const CreateCompany = () => {
  const { initialLoad, setToken } = useUserContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const navigate = useNavigate();

  const createNewCompany = async (data) => {
    try {
      const response = await createCompany(data);
      initialLoad();
      if (response) {
        localStorage.setItem("token", response?.token);
        setToken(response?.token);
        navigate("/calls/audit");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleCreateCompany = (data) => {
    const apiData = {
      name: data?.companyName,
    };
    createNewCompany(apiData);
  };

  return (
    <Root>
      <Box>
        <img src={confetti} width={150} />
        <Typography variant="h4" fontWeight={"bold"} my={4}>
          Create Company
        </Typography>

        <SkillrTextField
          control={control}
          name="companyName"
          rules={{ required: "Company name is required" }}
          error={errors.companyName}
          label="Company Name"
        />

        <SkillrButton
          size="large"
          type="submit"
          fullWidth
          sx={{ marginTop: 8 }}
          onClick={handleSubmit(handleCreateCompany)}
        >
          Create Company
        </SkillrButton>
      </Box>
    </Root>
  );
};

export default CreateCompany;
