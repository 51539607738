import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Chip } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import Outbound from "./components/Outbound";
import Inbound from "./components/Inbound";
import { IconFilter2 } from "components/SVG";
import FilterModal from "pages/Campaign/components/FilterModal";
import FlexBox from "components/FlexBox";
import moment from "moment";
import styles from "./index.module.css";
import { X } from "react-feather";
import { useSkillrToast } from "context/toast";
import { getCampaigns } from "services";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Inbound");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { showSkillrToast } = useSkillrToast();
  const [appliedFilters, setAppliedFilters] = useState({
    dateFilter: "",
    startDate: moment().subtract(30, "days").startOf("day"),
    endDate: moment().endOf("day"),
  });
  const [campaigns, setCampaigns] = useState([]);
  const parseFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const dateFilter = searchParams.get("dateFilter") || "";
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    const filters = {
      dateFilter,
      startDate: startDate
        ? moment(startDate).startOf("day")
        : moment().subtract(30, "days").startOf("day"),
      endDate: endDate ? moment(endDate).endOf("day") : moment().endOf("day"),
    };

    setAppliedFilters(filters);
  };

  const getDefaultTab = () => {
    const [, , childPath] = location.pathname.split("/");
    if (!childPath) {
      return "Inbound";
    } else if (childPath.includes("outbound")) {
      return "Outbound";
    }
    return "Inbound";
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
    const basePath = location.pathname.split("/").slice(0, 2).join("/");
    const newPath =
      value === "Inbound" ? `${basePath}/inbound` : `${basePath}/outbound`;
    navigate({
      pathname: newPath,
      search: location.search,
    });
  };

  const fields =
    activeTab === "Inbound"
      ? [{ value: "dateRange", label: "Date Range" }]
      : [
          { value: "dateRange", label: "Date Range" },
          { value: "campaign", label: "Campaign" },
        ];

  const getAllCampaigns = async () => {
    try {
      const params = new URLSearchParams();
      params.set("active", true);
      params.set("offset", 0);
      params.set("count", 1000);
      const response = await getCampaigns(params.toString());
      setCampaigns(response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateURL = (filters) => {
    const searchParams = new URLSearchParams();

    if (filters.dateFilter) {
      searchParams.set("dateFilter", filters.dateFilter);
    }
    if (filters.startDate) {
      searchParams.set("startDate", filters.startDate.format("YYYY-MM-DD"));
    }
    if (filters.endDate) {
      searchParams.set("endDate", filters.endDate.format("YYYY-MM-DD"));
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const applyFilters = (newFilters) => {
    if (newFilters.dateFilter === "today") {
      newFilters.startDate = moment().startOf("day");
      newFilters.endDate = moment().endOf("day");
    } else if (newFilters.dateFilter === "thisMonth") {
      newFilters.startDate = moment().startOf("month");
      newFilters.endDate = moment().endOf("day");
    } else if (newFilters.dateFilter === "last7days") {
      newFilters.startDate = moment().subtract(7, "days").startOf("day");
      newFilters.endDate = moment().endOf("day");
    }

    setAppliedFilters(newFilters);
    updateURL(newFilters);
  };

  const clearFilters = (filterType) => {
    if (filterType === "dateFilter") {
      setAppliedFilters({
        ...appliedFilters,
        dateFilter: "",
        startDate: null,
        endDate: null,
      });

      updateURL({
        ...appliedFilters,
        dateFilter: "",
        startDate: null,
        endDate: null,
      });
    } else if (filterType === "campaignIds") {
      setAppliedFilters({
        ...appliedFilters,
        campaignIds: [],
      });
      updateURL({
        ...appliedFilters,
        campaignIds: [],
      });
    }
  };

  console.log(appliedFilters);

  useEffect(() => {
    const defaultTab = getDefaultTab();
    if (defaultTab !== activeTab) {
      setActiveTab(defaultTab);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (activeTab === "Inbound") {
      if (appliedFilters.campaignIds) {
        delete appliedFilters.campaignIds;
      }
      setAppliedFilters({
        ...appliedFilters,
      });
    }

    if (activeTab === "Outbound") {
      if (campaigns.length === 0) {
        getAllCampaigns().then((fetchedCampaigns) => {
          if (fetchedCampaigns?.length > 0) {
            const updatedFilters = {
              ...appliedFilters,
              campaignIds: fetchedCampaigns.map((campaign) => campaign._id),
            };
            setAppliedFilters(updatedFilters);
            updateURL(updatedFilters);
          }
        });
      } else if (!appliedFilters.campaignIds) {
        const updatedFilters = {
          ...appliedFilters,
          campaignIds: campaigns.map((campaign) => campaign._id),
        };
        setAppliedFilters(updatedFilters);
        updateURL(updatedFilters);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    parseFiltersFromURL();
  }, []);

  return (
    <PageWrapper noPadding className={styles.pageWrapper}>
      <Header
        title="Dashboard"
        subTitle="The dashboards page provides a comprehensive and customizable overview of key metrics and data insights."
        tabs={["Inbound", "Outbound"]}
        defaultActiveTab={getDefaultTab()}
        tabValue={activeTab}
        onTabChange={handleTabChange}
      />

      <Box px={8}>
        <FlexBox sx={{ flexWrap: "wrap" }}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<IconFilter2 width={20} height={20} />}
            sx={{
              backgroundColor: "#f9f9f9",
              minWidth: 0,
              alignSelf: "flex-start",
            }}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>

          {appliedFilters.dateFilter && (
            <Chip
              className={styles.chip}
              label={
                appliedFilters.dateFilter === "range"
                  ? `Date: ${moment(appliedFilters.startDate).format(
                      "MMM DD, YYYY"
                    )} - ${moment(appliedFilters.endDate).format(
                      "MMM DD, YYYY"
                    )}`
                  : `Date: ${
                      appliedFilters.dateFilter === "today"
                        ? "Today"
                        : appliedFilters.dateFilter === "thisMonth"
                        ? "This Month"
                        : appliedFilters.dateFilter === "last7days"
                        ? "Last 7 Days"
                        : ""
                    }`
              }
              onDelete={() => {
                clearFilters("dateFilter");
              }}
              deleteIcon={<X color="#555" width={12} height={12} />}
              color="primary"
              size="small"
            />
          )}

          {appliedFilters?.campaignIds?.length > 0 && (
            <Chip
              className={styles.chip}
              label={`Campaign${
                appliedFilters.campaignIds.length > 1 ? "s" : ""
              }: ${appliedFilters.campaignIds.length}`}
              onDelete={() => {
                clearFilters("campaignIds");
              }}
              deleteIcon={<X color="#555" width={12} height={12} />}
              color="primary"
              size="small"
            />
          )}
        </FlexBox>

        <Box sx={{ paddingBottom: 8 }}>
          {activeTab === "Inbound" ? (
            <Inbound filters={appliedFilters} />
          ) : (
            <Outbound filters={appliedFilters} campaigns={campaigns} />
          )}
        </Box>
      </Box>

      <FilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={appliedFilters}
        applyFilters={applyFilters}
        fields={fields}
        campaigns={campaigns}
      />
    </PageWrapper>
  );
};

export default Home;
