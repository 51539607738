import {
  Box,
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import theme from "theme";
import styles from "./index.module.css";
import { Circle, X } from "react-feather";
import { RadioButton } from "components/newSVG";
import { createEmailConfig, editEmailConfig, getEmailConfig } from "services";
import { useUserContext } from "context/user";
import { useSkillrToast } from "context/toast";

const Notifications = () => {
  const { control, reset, watch } = useForm({
    defaultValues: {
      sendEmail: false,
      recipients: [],
      frequency: "realTime",
    },
  });
  const [configData, setConfigData] = useState(null);
  const { showSkillrToast } = useSkillrToast();

  const frequencyOptions = [
    { label: "Real Time", value: "realTime" },
    { label: "Hourly", value: "hourly" },
    // { label: "2X/Day", value: "2xday" },
    // { label: "Daily", value: "daily" },
    // { label: "Alternate Days", value: "alternateDays" },
    // { label: "Weekly", value: "weekly" },
    // { label: "Fortnightly", value: "fortnightly" },
  ];

  const getConfig = async () => {
    try {
      const response = await getEmailConfig();
      reset(response.emailData);
      setConfigData(response.emailData);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const createConfig = async (data) => {
    try {
      const response = await createEmailConfig(data);
      if (response) {
        showSkillrToast("Email notification created successfully", "success");
        getConfig();
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const editConfig = async (id, data) => {
    try {
      const response = await editEmailConfig(id, data);
      if (response) {
        showSkillrToast("Email notification updated successfully", "success");
        getConfig();
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleSubmit = useCallback(
    async (data) => {
      if (configData?._id) {
        editConfig(configData._id, data);
      } else {
        createConfig(data);
      }
    },
    [configData]
  );

  const removeRecipient = (email) => {
    const newRecipients = watch("recipients").filter((e) => e !== email);
    handleSubmit({ ...watch(), recipients: newRecipients });
  };

  const handleEmailSubmit = (value, onChange) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      showSkillrToast("Please enter a valid email address");
      return;
    }

    if (watch("recipients")?.includes(value)) {
      showSkillrToast("This email address is already added");
      onChange("");
      return;
    }

    const newRecipients = [...(watch("recipients") || []), value];
    handleSubmit({ ...watch(), recipients: newRecipients });
    onChange("");
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name && name !== "email") {
        handleSubmit(data);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, handleSubmit]);

  return (
    <Box sx={{ padding: "0 32px" }}>
      <Box
        sx={{
          padding: 6,
          border: `1px solid ${theme.palette.borderColor.light}`,
          borderRadius: 3,
        }}
      >
        <FlexBox columnGap={2}>
          <Typography variant="body1" fontWeight={500}>
            Email Notification
          </Typography>
          <Controller
            control={control}
            name="sendEmail"
            render={({ field }) => (
              <Switch size="small" {...field} checked={field.value} />
            )}
          />
        </FlexBox>
        <Typography variant="body2" color="grey.600">
          Receive alerts for API errors, API Node errors and export event
          failures with customisable frequency, recipients, and escalation rules
          to you Emails.
        </Typography>

        <Typography variant="body1" fontWeight={500} mt={6}>
          Add Recipients
        </Typography>
        <Typography variant="body2" color="grey.600" mb={2}>
          Enter the email addresses to receive API failure alerts.
        </Typography>

        <Typography
          variant="caption"
          color="text.primary"
          component="div"
          mb={1}
        >
          Email
        </Typography>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ width: "50%" }}
              placeholder="Add Email Address"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleEmailSubmit(field.value, field.onChange);
                }
              }}
            />
          )}
        />

        <Typography fontSize={10} component="div" fontWeight={500}>
          Press ENTER to add Emails
        </Typography>

        {watch("recipients")?.length > 0 && (
          <FlexBox className={styles.chipContainer}>
            {watch("recipients").map((recipient) => (
              <Chip
                key={recipient}
                label={recipient}
                className={styles.chip}
                deleteIcon={
                  <X width={14} height={14} color={theme.palette.grey[600]} />
                }
                onDelete={() => {
                  removeRecipient(recipient);
                }}
              />
            ))}
          </FlexBox>
        )}

        <Typography variant="body1" fontWeight={500} mt={6}>
          Alert Frequency
        </Typography>
        <Typography variant="body2" color="grey.600" mb={2}>
          Choose how often to receive email alerts for API failures.{" "}
        </Typography>

        <FlexBox>
          <Controller
            control={control}
            name="frequency"
            render={({ field }) => (
              <RadioGroup row {...field}>
                {frequencyOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2" color="grey.600">
                        {option.label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FlexBox>
      </Box>
    </Box>
  );
};

export default Notifications;
