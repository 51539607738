import FundamentoTable from "components/FundamentoTable";
import styles from "./index.module.css";
import { Box, IconButton, Typography } from "@mui/material";
import { TrashSimple } from "components/newSVG";
import PropTypes from "prop-types";
import FundamentoPopup from "components/FundamentoPopup";
import { MoreVertical } from "react-feather";
import { useNavigate } from "react-router-dom";

const CampaignList = (props) => {
  const navigate = useNavigate();
  const {
    currentPage,
    setCurrentPage,
    contactData,
    totalPageCount,
    deleteContact,
  } = props;

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id) {
      navigate(`/contact/${row.id}`);
    }
  };

  const columns = [
    {
      field: "contactId",
      header: "Contact Id",
    },
    {
      field: "contactNumbers",
      header: "Contact Numbers",
      render: (row) => row.contactNumbers.join(";"),
    },
    {
      header: "Last Called",
      field: "lastCalled",
      render: (row) => {
        if (row.lastCalled)
          return new Date(row.lastCalled).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        return "-";
      },
    },
    {
      field: "callCount",
      header: "Calls ",
      render: (row) => row.callCount ?? 0,
    },
    {
      header: "Created On",
      field: "createdAt",
      render: (row) => {
        if (row.createdAt)
          return new Date(row.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        return "-";
      },
    },
    {
      field: "dnd",
      header: "Do Not Disturb",
      render: (row) => {
        return (
          <Box
            className={styles.box}
            sx={{
              background: row.dnd === true ? "#FEEBEF" : "#E8F5E9",
            }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight="normal"
              color={row.dnd === true ? "#A01E1E" : "#388E3C"}
            >
              {row.dnd === true ? "True" : "False"}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   field: "metadata",
    //   header: "Metadata",
    //   render: (row) => JSON.stringify(row.metadata),
    // },
    {
      field: "actions",
      header: "Actions",
      render: (rowInfo) => {
        return (
          <FundamentoPopup
            triggeringComponent={
              <IconButton>
                <MoreVertical width={20} height={20} />
              </IconButton>
            }
            className={styles.popup}
          >
            <Box
              className={styles.action}
              onClick={() => deleteContact(rowInfo?._id)}
            >
              <TrashSimple width={16} height={16} />
              <Typography variant="body2">Delete</Typography>
            </Box>
          </FundamentoPopup>
        );
      },
    },
  ];

  return (
    <Box>
      <FundamentoTable
        columns={columns}
        data={contactData}
        passRowToRender={true}
        pagination={true}
        currentPage={currentPage}
        onPageChange={handlePageOnChange}
        totalPageCount={totalPageCount}
        tableOnClick={handleTableOnClick}
        className={styles.table}
        rowIdKey="_id"
      />
    </Box>
  );
};

CampaignList.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  contactData: PropTypes.array,
};

export default CampaignList;
