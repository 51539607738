import { Box, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import { getAnalytics, getCampaigns } from "services";
import { useEffect, useState } from "react";
import moment from "moment";
import styles from "./index.module.css";
import CallOutcomes from "./components/CallOutcomes";
import CallDurationCategories from "./components/CallDurationCategories";
import CallTrend from "./components/CallTrend";
import RedialBasedRates from "./components/RedialBasedRates";
import PickUpRates from "./components/PickUpRates";
import EmptyState from "components/EmptyState";
import { useSkillrToast } from "context/toast";
import { Loader } from "react-feather";
import theme from "theme";

const Outbound = ({ filters, campaigns }) => {
  const [outboundData, setOutboundData] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [prevCampaignIds, setPrevCampaignIds] = useState([]);

  useEffect(() => {
    // Only proceed if we have campaignIds
    if (!filters?.campaignIds) return;

    // Set initial dates
    const newStartDate = filters.startDate
      ? moment(filters.startDate)
      : moment().subtract(30, "days").startOf("day");

    const newEndDate = filters.endDate
      ? moment(filters.endDate)
      : moment().endOf("day");

    // Check if dates or campaignIds have changed
    if (
      !startDate?.isSame(newStartDate) ||
      !endDate?.isSame(newEndDate) ||
      filters.campaignIds !== prevCampaignIds
    ) {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setPrevCampaignIds(filters.campaignIds);

      // Make API call with the new dates
      const params = new URLSearchParams({
        startDate: newStartDate,
        endDate: newEndDate,
        campaignIds: filters.campaignIds,
      });

      setLoading(true);
      getAnalytics("outbound", params)
        .then((response) => {
          if (response) {
            setOutboundData(response.analyticData);
          }
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error?.message ?? "Something went wrong";
          showSkillrToast(errorMessage, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filters]); // dependency on entire filters object

  const renderPickUpRate = () => {
    return isNaN(outboundData?.pickUpRate)
      ? "-"
      : `${Math.round(
          (outboundData?.pickUpRate / outboundData?.totalCalls) * 100
        )}% (${outboundData?.pickUpRate})`;
  };

  const renderAbandonmentRate = () => {
    return isNaN(outboundData?.callAbandonmentRate)
      ? "-"
      : `${Math.round(
          (outboundData?.callAbandonmentRate / outboundData?.totalCalls) * 100
        )}% (${outboundData?.callAbandonmentRate})`;
  };

  return (
    <>
      {!loading ? (
        <>
          {!filters?.campaignIds?.length ? (
            <FlexBox sx={{ minHeight: 300 }}>
              <EmptyState
                title="No campaigns selected"
                description={
                  "Please select atleast one campaign from the filters"
                }
              />
            </FlexBox>
          ) : outboundData?.totalCalls ? (
            <>
              <FlexBox mt={4}>
                <Box className={styles.statContainer}>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Total Calls
                  </Typography>
                  <Typography variant="body1" fontWeight={700}>
                    {outboundData?.totalCalls || "-"}
                  </Typography>
                </Box>
                <Box className={styles.statContainer}>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Average Handling Time
                  </Typography>
                  <Typography variant="body1" fontWeight={700}>
                    {outboundData?.averageOutboundHandlingTime &&
                    !isNaN(outboundData?.averageOutboundHandlingTime)
                      ? `${Math.round(
                          outboundData?.averageOutboundHandlingTime
                        )} s`
                      : "-"}
                  </Typography>
                </Box>
                <Box className={styles.statContainer}>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Pick Up Rate
                  </Typography>
                  <Typography variant="body1" fontWeight={700}>
                    {renderPickUpRate()}
                  </Typography>
                </Box>
                <Box className={styles.statContainer}>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Call Abandonment Rate
                  </Typography>
                  <Typography variant="body1" fontWeight={700}>
                    {renderAbandonmentRate()}
                  </Typography>
                </Box>
              </FlexBox>

              <FlexBox alignItems="flex-stretch">
                <Box
                  mt={4}
                  className={styles.statContainer}
                  sx={{ width: "50%", overflow: "hidden" }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    Call Outcomes
                  </Typography>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Total Calls: {outboundData?.totalCalls}
                  </Typography>

                  <CallOutcomes data={outboundData?.callOutcomes} />
                </Box>
                <Box
                  mt={4}
                  className={styles.statContainer}
                  sx={{ width: "50%", overflow: "hidden" }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    Call Duration Distribution
                  </Typography>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Total Calls: {outboundData?.totalCalls}
                  </Typography>

                  <CallDurationCategories
                    data={outboundData?.callDurationCategoriesOutbound}
                  />
                </Box>
              </FlexBox>

              <Box mt={4} className={styles.statContainer}>
                <Typography variant="body1" fontWeight={700}>
                  Call Outcome Trends
                </Typography>
                <Typography variant="body2" color="grey.600" mb={2}>
                  Total Calls: {outboundData?.totalCalls}
                </Typography>

                <CallTrend data={outboundData?.callTrend} />
              </Box>

              <FlexBox alignItems="flex-stretch">
                <Box
                  mt={4}
                  className={styles.statContainer}
                  sx={{ width: "50%", overflow: "hidden" }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    Redial Based Pick-Up Rates
                  </Typography>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Total Calls: {outboundData?.totalCalls}
                  </Typography>

                  {outboundData?.campaignsByRedialAttempt.length &&
                  outboundData?.campaignsByRedialAttempt.some(
                    (item) => item.callsAnswered > 0
                  ) ? (
                    <RedialBasedRates
                      data={outboundData?.campaignsByRedialAttempt}
                      campaigns={campaigns}
                    />
                  ) : (
                    <EmptyState title="No data available" />
                  )}
                </Box>
                <Box
                  mt={4}
                  className={styles.statContainer}
                  sx={{ width: "50%", overflow: "hidden" }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    Pick-Up Rates for Last 7 Days
                  </Typography>
                  <Typography variant="body2" color="grey.600" mb={2}>
                    Total Calls:{" "}
                    {
                      outboundData?.hourlyAnsweredCounts
                        ?.totalHourlyAnsweredCountsData
                    }
                  </Typography>

                  {outboundData?.hourlyAnsweredCounts
                    ?.totalHourlyAnsweredCountsData &&
                  outboundData.hourlyAnsweredCounts?.result.some(
                    (item) => item.totalCalls > 0
                  ) ? (
                    <PickUpRates
                      data={outboundData?.hourlyAnsweredCounts?.result}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ) : (
                    <EmptyState title="No data available" />
                  )}
                </Box>
              </FlexBox>
            </>
          ) : (
            <FlexBox sx={{ minHeight: 300 }}>
              <EmptyState title="No data available" />
            </FlexBox>
          )}
        </>
      ) : (
        <FlexBox sx={{ minHeight: 300 }} justifyContent="center">
          <Loader color={theme.palette.grey[600]} size={24} />
        </FlexBox>
      )}
    </>
  );
};

export default Outbound;
