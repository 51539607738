import { FundamentoLogoMini, IconUserCircle } from "components/SVG";
import { Box, Collapse, Divider, Typography } from "@mui/material";
import { ChevronRight, Link as IconLink, Settings } from "react-feather";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import {
  ChartBar,
  ChatsCircle,
  CreditCard,
  Equalizer,
  Flask,
  FlowArrow,
  HandMic,
  Question,
  Wrench,
  UserPlus,
  DatabaseStats,
  Home,
} from "components/newSVG";
import { useUserContext } from "context/user";
import theme from "theme";
import { getJSONDecoded } from "utils/utils";
import { useLocation } from "react-router-dom";
import logout from "services/logout";
import FunCollapse from "components/FunCollapse";
import { useRef, useState } from "react";
import FlexBox from "components/FlexBox";

const Sidebar = () => {
  const {
    token,
    setToken,
    setUserDetails,
    setCompanyDetails,
    setDataSets,
    companyDetails,
    bot,
  } = useUserContext();
  const storageLeft =
    companyDetails?.maxStorageCapacityInMB -
    Math.round(companyDetails?.usedStorageCapacity);

  const storageLeftInPercentage =
    (Math.round(companyDetails?.usedStorageCapacity) /
      companyDetails?.maxStorageCapacityInMB) *
    100;
  const tokenInfo = getJSONDecoded(token ?? null);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("token");
      setToken();
      setUserDetails();
      setCompanyDetails();
      setDataSets([]);
    } catch (error) {
      console.log(error);
    }
  };
  const currentRoute = useLocation().pathname;
  let finalUrl = bot !== "no bot" ? "/bot/playground" : "/create-bot";

  const handleMenuItemHover = (e) => {
    const menuItem = e.currentTarget;
    const lastY = parseFloat(menuItem.dataset.lastY);
    const currentY = e.clientY;

    // If there's no lastY value, default to moving downward
    const isMovingDown = isNaN(lastY) ? true : currentY > lastY;

    menuItem.style.setProperty("--hover-direction", isMovingDown ? "1" : "-1");
    menuItem.dataset.lastY = currentY;
  };

  const SidebarCollapse = ({ header, children, headerClassName, active }) => {
    const [show, setShow] = useState(false);

    return (
      <>
        <FlexBox
          onClick={() => setShow(!show)}
          style={{ cursor: "pointer", padding: 0, paddingRight: 16 }}
          className={`${headerClassName} ${active ? styles.active : {}}`}
          onMouseMove={handleMenuItemHover}
        >
          <Box
            style={{ flex: 1 }}
            sx={{
              "& span": {
                color: active ? "text.primary" : "",
              },
            }}
          >
            {header}
          </Box>
          <ChevronRight
            width={16}
            height={16}
            color="#0f0f0f"
            style={{ transform: show && "rotate(90deg)", flexShrink: 0 }}
          />
        </FlexBox>
        <Collapse in={show} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
    );
  };

  return (
    <Box
      className={styles.container}
      sx={{ background: theme.palette.grey[50] }}
    >
      <Box className={styles.companyInfo}>
        <Box className={styles.logoContainer}>
          <FundamentoLogoMini />
        </Box>
        <Typography variant="subtitle1DM">Fundamento</Typography>
      </Box>

      <Box className={styles.menuList}>
        {/* <Link to="/dashboard/inbound">
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/dashboard") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <Home width={20} height={20} />
            <Typography variant="subtitle2DM">Dashboard</Typography>
          </li>
        </Link> */}
        <SidebarCollapse
          header={
            <li className={styles.menuItem}>
              <DatabaseStats width={20} height={20} />
              <Typography variant="subtitle2DM">Analytics</Typography>
            </li>
          }
          headerClassName={styles.menuItem}
          active={
            currentRoute.includes("/calls") ||
            currentRoute.includes("/error-logs") ||
            currentRoute.includes("/downloads")
          }
        >
          <Box
            className={styles.menuList}
            sx={{ borderLeft: "2px solid #E0E0E0", ml: 6, pr: 0 }}
          >
            <Link
              to="/calls"
              className={`${styles.menuItem} ${
                currentRoute.includes("/calls") ? styles.active : ""
              }`}
              onMouseMove={handleMenuItemHover}
            >
              <li>
                <Typography variant="subtitle2DM">Call Analytics</Typography>
              </li>
            </Link>

            <Link
              to="/downloads"
              className={`${styles.menuItem} ${
                currentRoute.includes("/downloads") ? styles.active : ""
              }`}
              onMouseMove={handleMenuItemHover}
            >
              <li>
                <Typography variant="subtitle2DM">Downloads</Typography>
              </li>
            </Link>
            <Link
              to="/error-logs"
              className={`${styles.menuItem} ${
                currentRoute.includes("/error-logs") ? styles.active : ""
              }`}
              onMouseMove={handleMenuItemHover}
            >
              <li>
                <Typography variant="subtitle2DM">Error Logs</Typography>
              </li>
            </Link>
          </Box>
        </SidebarCollapse>

        <Link to="/knowledge-base">
          <li
            className={`${styles.menuItem} ${
              currentRoute.indexOf("knowledge-base") > 0 ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <Question width={20} height={20} />
            <Typography variant="subtitle2DM">Knowledge Base</Typography>
          </li>
        </Link>
        <Link to="/ai-labs">
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/ai-labs") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <Flask width={20} height={20} />
            <Typography variant="subtitle2DM">AI Labs</Typography>
          </li>
        </Link>

        <Link to="/workflows">
          <li
            className={`${styles.menuItem} ${
              currentRoute === "/workflows" ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <FlowArrow width={20} height={20} />
            <Typography variant="subtitle2DM">Workflows</Typography>
          </li>
        </Link>

        <SidebarCollapse
          header={
            <li className={styles.menuItem}>
              <DatabaseStats width={20} height={20} />
              <Typography variant="subtitle2DM">Database</Typography>
            </li>
          }
          headerClassName={`${styles.menuItem} ${
            currentRoute.includes("/database") ? styles.active : ""
          }`}
        >
          <Box
            className={styles.menuList}
            sx={{ borderLeft: "2px solidrgb(244, 244, 244)", ml: 6 }}
          >
            <Link
              to="/database/contacts"
              className={`${styles.menuItem} ${
                currentRoute.includes("/database/contacts") ? styles.active : ""
              }`}
            >
              <li>
                <Typography variant="subtitle2DM">Contacts</Typography>
              </li>
            </Link>

            <Link
              to="/database/smart-db"
              className={`${styles.menuItem} ${
                currentRoute.includes("/database/smart-db") ? styles.active : ""
              }`}
            >
              <li>
                <Typography variant="subtitle2DM">Smart DB</Typography>
              </li>
            </Link>
          </Box>
        </SidebarCollapse>

        <Link to="/bot-settings">
          <li
            className={`${styles.menuItem} ${
              currentRoute === "/bot-settings" ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <Equalizer width={20} height={20} />
            <Typography variant="subtitle2DM">Bot Settings</Typography>
          </li>
        </Link>
        <Link to="/campaign">
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/campaign") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <HandMic width={20} height={20} />
            <Typography variant="subtitle2DM">Campaigns</Typography>
          </li>
        </Link>

        <Link to={finalUrl}>
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/bot/") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <ChatsCircle width={20} height={20} />
            <Typography variant="subtitle2DM">Playground</Typography>
          </li>
        </Link>
      </Box>

      <Box
        className={styles.menuList}
        style={{ marginTop: "auto", marginBottom: 12 }}
      >
        <Link to="/billing">
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/billing") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <CreditCard width={20} height={20} />
            <Typography variant="subtitle2DM">Billing</Typography>
          </li>
        </Link>

        <Link to="/integrations">
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/integrations") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <IconLink width={20} height={20} />
            <Typography variant="subtitle2DM">Integrations</Typography>
          </li>
        </Link>
        <Link to="/account">
          <li
            className={`${styles.menuItem} ${
              currentRoute.includes("/account") ? styles.active : ""
            }`}
            onMouseMove={handleMenuItemHover}
          >
            <Settings width={20} height={20} />
            <Typography variant="subtitle2DM">Account</Typography>
          </li>
        </Link>
        <li className={styles.menuItem} onMouseMove={handleMenuItemHover}>
          <IconUserCircle width={20} height={20} />
          <Typography variant="subtitle2DM">{tokenInfo?.name}</Typography>
        </li>
        <li
          onMouseMove={handleMenuItemHover}
          className={styles.menuItem}
          onClick={handleLogout}
        >
          <Logout width={20} height={20} />
          <Typography variant="subtitle2DM">Log out</Typography>
        </li>
      </Box>
    </Box>
  );
};

export default Sidebar;
