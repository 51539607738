import { Box, Fade, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle, useUpdateNodeInternals } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import FlexBox from "components/FlexBox";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import NodeBase from "../../NodeBase";
import { gscUriPrefix } from "text-constants/common";

const AINode = (props) => {
  const data = props.data;
  const [isParentHovered, setIsParentHovered] = useState(false);
  const updateNodeInternals = useUpdateNodeInternals();

  const handleOptionClick = (option) => {
    console.log(option);
    if (data?.connectedOptions?.includes(option.id)) {
      data.onOptionMinusClick(option);
    } else {
      data.onOptionPlusClick(option, { x: props.xPos, y: props.yPos });
    }
  };

  useEffect(() => {
    updateNodeInternals(props.id);
  }, [data]);

  return (
    <NodeBase
      isActive={data.isActive}
      onDelete={data.onDelete}
      localizationMode={data.localizationMode}
      fullyLocalized={data.fullyLocalized}
      error={data.error}
      onDuplicate={data.onDuplicate}
      onDuplicateToAnotherWorkflow={data.onDuplicateToAnotherWorkflow}
    >
      <Typography variant="body2" fontWeight={500}>
        {data.customName
          ? data.customName
          : (data.name || "")
              .replace(/-/g, " ")
              .replace(/ai/i, "AI")
              // .replace(/\b\w/g, (l) => l.toUpperCase())
              .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {data?.prompt ? data.prompt : "configure"}

        {data.type === "audio" && data.gcsUri && (
          <Box mt={4}>
            <audio controls style={{ width: "100%" }} key={data.gcsUri}>
              <source
                src={data.gcsUri.replace("gs://", gscUriPrefix)}
                type={"audio/" + data.gcsUri.split(".").pop()}
              ></source>
            </audio>
          </Box>
        )}
      </Typography>
      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />

      {data.options &&
        data?.options?.map((option, index) => (
          <FlexBox
            key={index}
            sx={{
              p: "8px 16px",
              backgroundColor: theme.palette.grey[100],
              borderRadius: "8px",
              mt: 4,
              minHeight: 51,
              position: "relative",
            }}
          >
            <Typography variant="caption">{option.option}</Typography>

            {/* {data.triggerWorkflow && ( */}
            <CustomHandle
              id={option.id}
              position={"right"}
              style={{ right: 16 }}
              clickHandler={() => handleOptionClick(option)}
              icon={
                data?.connectedOptions?.includes(option.id) ? (
                  <Minus
                    color={theme.palette.text.primary}
                    width={12}
                    height={12}
                  />
                ) : (
                  <Plus
                    color={theme.palette.text.primary}
                    width={12}
                    height={12}
                  />
                )
              }
            />
            {/* )} */}
          </FlexBox>
        ))}

      {data.defaultType === "addNode" && (
        <FlexBox
          sx={{
            p: "8px 16px",
            backgroundColor: theme.palette.grey[100],
            borderRadius: "8px",
            mt: 4,
            minHeight: 51,
            position: "relative",
          }}
        >
          <Typography variant="caption">Default</Typography>

          <CustomHandle
            id={"default"}
            position={"right"}
            style={{ right: 16 }}
            clickHandler={() => {
              data?.connectedOptions?.includes("default")
                ? data.onOptionMinusClick("default")
                : data.onOptionPlusClick("default");
            }}
            icon={
              data?.connectedOptions?.includes("default") ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
          />
        </FlexBox>
      )}

      {/* {(data.defaultType === "nextNode" ||
        (!data.triggerWorkflow && data.setVariable)) && ( */}
      {/* <CustomHandle
        id="source"
        position={"bottom"}
        clickHandler={() => {
          if (data.connectedOptions.includes("source")) {
            data.onOptionMinusClick("source");
          } else {
            data.onOptionPlusClick("source", {
              x: props.xPos,
              y: props.yPos,
            });
          }
        }}
        icon={
          data.connectedOptions.includes("source") ? (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: "0%",
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      /> */}
      {/* )} */}
    </NodeBase>
  );
};

export default AINode;
